import axios from "../../api/axios";
import { dateToColombianZoneRq, timeToColombianZonerq } from "./timezoneRequests";



const URL = process.env.REACT_APP_BACKEND_BASE_URL;
//Realiza peticion para obtener data
export async function makeGetRequest(url) {
  try {
    const response = await axios.get(`${URL + url}`);
    console.log(
      "DATOS DE LA PETICION " + url + ": " + JSON.stringify(response.data)
    );
    return response;
  } catch (error) {
    console.log("Error en la peticion" + error);
    return null;
  }
}
//Realiza peticion para obtener data
export async function makeSafeGetRequest(url, auth) {
  try {
    const response = await axios.get(`${URL + url}`,{headers:{Authorization:`Bearer ${auth}`}});
    console.log(
      "DATOS DE LA PETICION " + url + ": " + JSON.stringify(response.data)
    );
    return response;
  } catch (error) {
    console.log("Error en la peticion" + error);
    return null;
  }
}
//Postea la data recibida en la ruta del endpoint
export async function makePostRequest(url, data) {
  try {
    const response = await axios.post(URL + url, data);
    return response;
  } catch (error) {
    console.log("error al postear la data:" + error);
    return null;
  }
}

//Postea la data de manera segura
export async function makeSafePostRequest(url, data, auth){
  try {
    const response= await axios.post(URL+url,data,{headers:{Authorization:`Bearer ${auth}`}});
    if(response?.status===200){
      return response
    }else{return null;}
  } catch (error) {
    console.log("Error al realizar post seguro:"+error);
  }
}
//Crea un usuario de manera segura
export async function createSafeUser(url, data, auth){
  try {
    
    const response= await axios.post(URL+url,data,{headers:{Authorization:`Bearer ${auth}`}});
    if(response){
      return response;
    }else{return null;}
  } catch (error) {
    console.log("Error al realizar post seguro:"+error);
  }
}
//se autentica para guardar la prueba
export async function authenticate(usr, pwd)  {
  try {
      const response = await axios.post(URL+"auth/local",
          {
              identifier: usr,
              password: pwd
          }
      );
      console.log("en auth")
      return response;
  } catch (error) {
      return Promise.reject(error);
  }
};
//obtiene el rol de un usuario a partir de un token
export async function getRole (token) {
  try {
      const config = {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
          }
      };
      const response = await axios.get(URL+"rol",
          config
      );
      return [response?.data?.name];
  } catch (error) {
      console.log(error);
      Promise.reject(error);
  }
};
//Postea la información de una prueba
export async function postPruebas(prueba, idOp) {
  try {
    console.log("Probando ingresos de pruebas")
    const fechaHoy = await dateToColombianZoneRq();
    const horaHoy = timeToColombianZonerq();
    console.log("fecha y hora"+ fechaHoy+horaHoy)
    const url = "pruebas";
    const data = {
      data: {
        acidez: prueba.acidez,
        observaciones_acidez: prueba.obsAcidez,
        ph: prueba.ph,
        observaciones_ph: prueba.obsPh,
        agua: prueba.agua,
        observaciones_agua: prueba.obsAgua,
        antibiotico: prueba.antibiotico,
        betalactamicos: prueba.betalactamicos,
        tetraciclinas: prueba.tetraciclinas,
        sulfamidas: prueba.sulfamidas,
        observaciones_antibiotico: prueba.obsAntibiotico,
        proteina: prueba.proteina,
        observaciones_proteina: prueba.obsProteina,
        grasa: prueba.grasa,
        observaciones_grasa: prueba.obsGrasa,
        solidos: prueba.solidos,
        observaciones_solidos: prueba.obsSolidos,
        alcohol: prueba.alcohol,
        observaciones_alcohol: prueba.obsAlcohol,
        neutralizantes: prueba.neutralizantes,
        observaciones_neutralizantes: prueba.obsNeutralizantes,
        adulterantes: prueba.adulterantes,
        observaciones_adulterantes: prueba.obsAdulterantes,
        suero: prueba.suero,
        observaciones_suero: prueba.obsSuero,
        densidad: prueba.densidad,
        observaciones_densidad:prueba.obsDensidad,
        fecha: fechaHoy,
        hora: horaHoy,
        observaciones_generales: prueba.obsGenerales,
        operario:idOp
      },
    };
    const response=await makePostRequest(url, data);
    if (response) {
      return response;
    } else {
      return null;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
}
//REALIZA LA ELIMINACIÓN DE UN REGISTRO DE UNA TABLA POR PARAMETRO. USESE CON PRECAUCIÓN!!!
export async function makeDeleteRequest(url){
  try {
    const response=await axios.delete(URL+url);
    if (response){
      return response
    }
    else{
      return null;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
} 
//Actualiza la información de un registro usando el id y la data que viene por parámetro
export async function makePutRequest(url, data){
  try {
    console.log("URL"+url+", DATA"+JSON.stringify(data));
    const response=await axios.put(URL+url, data);
    if(response){
      return response;
    }
    else{
      return null;
    }
  } catch (error) {
    console.log(error);
    return null;
  }
};
//Postea la data de manera segura
export async function makeSafePutRequest(url, data, auth){
  try {
    const response= await axios.put(URL+url,data,{headers:{Authorization:`Bearer ${auth}`}});
    if(response?.status===200){
      return response
    }else{return null;}
  } catch (error) {
    console.log("Error al realizar actualizacion segura:"+error);
  }
}

