import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { getEmpresaAdmin } from "../api/RoleInfo";
import useAuth from "../hooks/useAuth";
import { notifyPendingNotify, popNotification } from "./utils/Notify";
import {
  makeGetRequest,
  makeSafeGetRequest,
  makeSafePostRequest,
  makeSafePutRequest,
} from "./utils/Requests";
import {
  dateToColombianZoneRq,
  timeToColombianZonerq,
} from "./utils/timezoneRequests";

function EditIngresos() {
  const { auth } = useAuth();
  const [idEmpresa, setIdEmpresa] = useState();
  const [nombreEmpresa, setNombreEmpresa] = useState();
  const [rutaNombre, setRutaNombre] = useState("");
  const [acopios, setAcopios] = useState();
  const [selAcopio, setSelAcopio] = useState();
  const [rutasAcopio, setRutasAcopio] = useState();
  const [selRuta, setSelRuta] = useState();
  const [ingresoRuta, setIngresoRuta] = useState();
  const [cantRecorrido, setCantRecorrido] = useState(0);
  const [cantEdit, setCantEdit] = useState(0);
  const [cantNovedadEdit, setCantNovedadEdit] = useState(0);
  const [obsEdit, setObsEdit] = useState("");
  const [difEdit, setDifEdit] = useState(0);
  const [aceptadoEdit, setAceptadoEdit] = useState(true);

  const getIdEmpresa = async () => {
    try {
      const response = await getEmpresaAdmin(auth);
      if (response) {
        console.log("encontro datos de la empresa" + JSON.stringify(response));
        setIdEmpresa(response.empresa?.id);
        setNombreEmpresa(response.empresa?.nombre);
      }
    } catch (error) {
      console.log("Error al obtener los datos de la empresa");
    }
  };

  //Lista los acopios de la empresa
  const getAcopios = async () => {
    try {
      const response = await makeGetRequest("acopiosempresa/" + idEmpresa);
      if (response) {
        setAcopios(response.data);
      }
    } catch (error) {
      console.log("Error al obtener los acopios de la empresa: " * error);
    }
  };
  //obtiene la liste de rutas del acopio seleccionado
  const getRutasAcopio = async (idAcopio) => {
    try {
      setCantRecorrido(0);
      console.log("Obteniendo rutas: " + selAcopio);
      const response = await makeGetRequest("rutasacopio/" + idAcopio);
      if (response) {
        setRutasAcopio(response.data);
      } else {
        setRutasAcopio();
      }
    } catch (error) {
      console.log("Error al obtener las rutas del acopio: " + error);
    }
  };

  //cambio al obtener el acopio seleccionado
  const onSelectedAcopio = (e) => {
    console.log("Cambio en acopio seleccionado");
    setSelAcopio(e.target.value);
    getRutasAcopio(e.target.value);
  };
  //cambio al obtener la ruta seleccionada
  const onSelectedRuta = async (e) => {
    setSelRuta(e.target.value);
    await getIngresoRuta(e.target.value);
    //se extraen los valores a editar
    console.log(JSON.stringify(ingresoRuta));
    recalcularLitrosIngreso();
  };
  const extraerValores = () => {
    if (ingresoRuta) {
      setCantEdit(ingresoRuta.cantidad_medida);
      setCantNovedadEdit(ingresoRuta.cantidad_rechazada);
      setDifEdit(ingresoRuta.diferencia);
      setAceptadoEdit(ingresoRuta.aceptado);
      setObsEdit(ingresoRuta.observaciones)
      getDiferencia();
      recalcularLitrosIngreso();
    }
  };
  //cambios al digitar la nueva medición
  const onCantidadEdit = (e) => {
    const cant = parseInt(e.target.value);
    if (isNaN(cant)) {
      setCantEdit(0);
    } else {
      console.log(cant + "         ....");
      setCantEdit(cant);
    }
    getDiferencia();
  };
  //cambio al digitar nueva medición con novedad
  const onCantidadNovedadEdit = (e) => {
    const cant = parseInt(e.target.value);
    if (isNaN(cant)) {
      setCantNovedadEdit(0);
    } else {
      console.log(cant + "         ....");
      setCantNovedadEdit(cant);
    }
    getDiferencia();
  };
  //Obtiene la diferencia segíun el cambio en el nuevo valor
  const getDiferencia = () => {
    console.log(
      "a punto de sumar" +
        typeof cantEdit +
        " " +
        typeof cantNovedadEdit +
        " " +
        typeof cantRecorrido
    );
    const dif = cantEdit + cantNovedadEdit - cantRecorrido;
    setDifEdit(dif);
  };
  //obtiene el ingreso del día de una ruta
  const getIngresoRuta = async (ruta) => {
    try {
      console.log("rutas" + ruta);
      const response = await makeSafeGetRequest(
        "ingresoruta/" + ruta,
        auth.accessToken
      );
      if (response) {
        setIngresoRuta(response.data);
        console.log("ingreso de la ruta..." + JSON.stringify(response.data));
      } else {
        setIngresoRuta();
        console.log("no hay nada");
      }
    } catch (error) {
      console.log("error al obtener el ingreso de la ruta:" + error);
    }
  };
  //toma los litros del recorrido y los suma
  const recalcularLitrosIngreso = () => {
    if (ingresoRuta) {
      const cantidadRecalculada = ingresoRuta?.recorrido?.recolecciones.reduce(
        (acum, obj) => {
          return acum + obj.cantidad_leche;
        },
        0
      );
      setCantRecorrido(cantidadRecalculada);
      console.log("Cantidad recalculada" + cantidadRecalculada);
    }
    console.log("Probando boton");
  };
  //Actualiza los datos del ingreso de la ruta seleccionada con los valores ingresados
  const updateIngreso = async (notify) => {  
    try {
      console.log("probando update");
      const data = {
        data: {
          cantidad_leche: cantRecorrido,
          cantidad_medida: cantEdit,
          cantidad_rechazada:cantNovedadEdit,
          diferencia: difEdit,
          aceptado:aceptadoEdit,
          observaciones:  obsEdit,
        },
      };
      console.log("datos de la actualizacion:\n" + JSON.stringify(data));
      const response = await makeSafePutRequest(
        "ingresos/" + ingresoRuta.id,
        data,
        auth.accessToken
      );
      if (response && response?.status === 200) {
        popNotification(notify, "success", "Ingreso actualizado correctamente");
        setSelRuta("-1")
        setIngresoRuta();
      } else {
        popNotification(
          notify,
          "error",
          "No se pudo actualizar el ingreso. Verifique e intente de nuevo"
        );
      }
    } catch (error) {
      console.log(error);
      popNotification(notify, "error", "Error al actualizar el ingreso");
    }
  };
  //Guarda el historial (log) del ingreso a punto de actualizarse
  const postIngresoLog = async () => {
    const notify = notifyPendingNotify();
    const fechaHoy = await dateToColombianZoneRq();
    const horaHoy = await timeToColombianZonerq();
    try {
      const data = {
        data: {
          cantidad_leche_anterior: ingresoRuta.cantidad_leche,
          cantidad_medida_anterior: ingresoRuta.cantidad_medida,
          cantidad_novedad_anterior: ingresoRuta.cantidad_rechazada,
          diferencia_anterior: ingresoRuta.diferencia,
          aceptado_anterior: ingresoRuta.aceptado,
          obs_anterior: ingresoRuta.observaciones,
          cantidad_leche_actual: cantRecorrido,
          cantidad_medida_actual: cantEdit,
          cantidad_novedad_actual: cantNovedadEdit,
          diferencia_actual: difEdit,
          aceptado_actual: aceptadoEdit,
          fecha_actualizacion: fechaHoy,
          hora_actualizacion:horaHoy,
          obs_actual: obsEdit,
          ingreso:ingresoRuta.id
        },
      };
      const response = await makeSafePostRequest(
        "ingresos-logs",
        data,
        auth.accessToken
      );
      if(response.status===200){
        popNotification(notify,"success","Log guardado correctamente.")
        return notify;
      }else{
        popNotification(notify,"error","No se pudo guardar.Intente de nuevo.");
      }
    } catch (error) {
      popNotification(notify,"error","Error al guardar.")
      console.log("Error al postear el log de ingreso: "+error)
    }
  };
  const postData=async()=>{
    const notify=await postIngresoLog();
    if(notify){
      await updateIngreso(notify); 
    }
  }
  //cambio en ruta seleccionada
  useEffect(() => {
    console.log("cambio en ruta seleccionada" + selRuta);
  }, [selRuta]);
  //Cambio en acopios selecciolnados
  useEffect(() => {
    console.log("Cambio en acopio seleccionado:" + selAcopio);
  }, [selAcopio]);
  //useefect empresa
  useEffect(() => {
    if (idEmpresa) {
      console.log("Obteniendo acopios...");
      getAcopios();
    }
  }, [idEmpresa]);
  //useeffect cant edit
  useEffect(() => {
    console.log("cambio en cantedit" + cantEdit);
    console.log("cambio en cantnovedit" + cantNovedadEdit);
    console.log("cambio en difedit" + difEdit);
    console.log("Cambio en aceptado" + aceptadoEdit);
    getDiferencia();
  }, [cantEdit, cantNovedadEdit, difEdit, aceptadoEdit]);
  useEffect(() => {
    console.log(JSON.stringify(ingresoRuta));
    if (ingresoRuta) {
      setDifEdit(0);
      extraerValores();
      getDiferencia();
      setRutaNombre(ingresoRuta?.recorrido?.ruta?.nombre);
    }
  }, [ingresoRuta]);
  //Useefect inicial
  useEffect(() => {
    getIdEmpresa();
    if (idEmpresa) {
      getAcopios();
    }
  }, []);
  return (
    <div className="h-full">
      <div className="grid grid-cols-1 my-3">
        <h1 className="text-4xl">Edición de Ingresos</h1>
        <label className="label">Empresa:{nombreEmpresa}</label>
        <label className="label">Acopio</label>
        {acopios ? (
          <select
            className="select select-bordered"
            onChange={(e) => {
              onSelectedAcopio(e);
            }}
          >
            <option key={"-1"} value={"-1"}>
              Seleccione un centro de acopio
            </option>
            {acopios?.map((acopio) => (
              <option key={acopio.id} value={acopio.id}>
                {acopio?.nombre}
              </option>
            ))}
          </select>
        ) : (
          <>Cargando acopios...</>
        )}
        <label className="label">Seleccione una ruta</label>
        {rutasAcopio ? (
          <select
          value={selRuta}
            className="select select-bordered"
            onChange={(e) => onSelectedRuta(e)}
          >
            <option key={"-1"} value={"-1"}>
              Seleccione una ruta
            </option>
            {rutasAcopio?.map((ruta) => (
              <option key={ruta.id} value={ruta.id}>
                {ruta?.nombre}
              </option>
            ))}
          </select>
        ) : (
          <>Cargando rutas...</>
        )}
        {ingresoRuta ? (
          <div className="card w-96 bg-base-100 shadow-xl my-5">
            <div className="card-body text-left">
              <h2 className="card-title">Ingreso Encontrado</h2>
              <p>
                <span className="font-bold">Ruta: </span>
                {ingresoRuta?.recorrido?.ruta?.nombre}
              </p>
              <p>
                <span className="font-bold">Fecha: </span>
                {ingresoRuta?.fecha.split("T")[0]}
              </p>
              <p>
                <span className="font-bold">Hora: </span>
                {ingresoRuta?.hora.split(".")[0]}
              </p>

              <p className="line-through">
                <span className="font-bold">
                  Cantidad registrada en Sistema:{" "}
                </span>
                {ingresoRuta?.cantidad_leche}
              </p>

              <p>
                <span className="font-bold">Cantidad medida: </span>
                {ingresoRuta?.cantidad_medida}
              </p>
              <p>
                <span className="font-bold">Diferencia: </span>
                {ingresoRuta?.diferencia >= 0 ? (
                  <span className="text-success">
                    {ingresoRuta.diferencia} litros
                  </span>
                ) : (
                  <span className="text-error">
                    {ingresoRuta.diferencia} litros
                  </span>
                )}
              </p>
              <div className="divider"></div>
              <div className="flex flex-col">
                <h2 className="card-title">Editar</h2>
                {cantRecorrido ? (
                  <p>
                    <span className="font-bold">
                      -Cantidad recalculada sistema:{" "}
                    </span>
                    {" " + cantRecorrido + " litros"}
                  </p>
                ) : (
                  <p>
                    <span className="font-bold">Cantidad Sistema: </span>
                    {}
                  </p>
                )}
                <label className="label">Cantidad medida</label>
                <input
                  type="number"
                  className="input input-bordered"
                  value={cantEdit}
                  onChange={(e) => {
                    onCantidadEdit(e);
                  }}
                  onBlur={getDiferencia}
                  min={0}
                ></input>
                <label className="label">Ingreso aceptado?</label>
                <label className="label cursor-pointer">
                  <span className="label-text">No</span>
                  <input
                    type={"checkbox"}
                    className="toggle toggle-accent"
                    checked={aceptadoEdit}
                    onChange={() => setAceptadoEdit((prevCheck) => !prevCheck)}
                  ></input>
                  <span className="label-text">Si</span>
                </label>
                {aceptadoEdit === false ? (
                  <>
                    <label className="label">Cantidad con novedad</label>
                    <input
                      type="number"
                      className="input input-bordered"
                      value={cantNovedadEdit}
                      onChange={(e) => {
                        onCantidadNovedadEdit(e);
                      }}
                      onBlur={getDiferencia}
                      min={0}
                    ></input>
                  </>
                ) : (
                  <></>
                )}
                {difEdit >= 0 ? (
                  <label className="label text-success">
                    Diferencia de {difEdit} litros
                  </label>
                ) : (
                  <label className="label text-error">
                    Diferencia de {difEdit} litros
                  </label>
                )}

                <label className="label">Observaciones</label>
                <textarea
                  className="textarea textarea-bordered"
                  value={obsEdit}
                  onChange={(e) => {
                    setObsEdit(e.target.value);
                  }}
                ></textarea>
              </div>
              <div className="card-actions justify-end">
                <label className="btn btn-primary" htmlFor="editModal">
                  Editar Ingreso
                </label>
              </div>
            </div>
          </div>
        ) : (
          <div className="alert alert-info my-5">
            No se encontraron ingresos para la ruta seleccionada.
          </div>
        )}
      </div>
      {/* Modal editar */}
      <input type={"checkbox"} id="editModal" className="modal-toggle"></input>
      <div className="modal">
        <div className="modal-box ">
          <h3 className="font-bold text-lg">Aviso</h3>
          <p>Realmente desea editar el siguiente ingreso?</p>
          <p>
            <span className="font-bold">Ruta: </span>
            {rutaNombre}
          </p>
          <p>
            <span className="font-bold">Cantidad por sistema: </span>
            {cantRecorrido + " litros"}
          </p>
          <p>
            <span className="font-bold">Cantidad medida: </span>
            {cantEdit + " litros"}
          </p>
          <p>
            <span className="font-bold">Diferencia:</span>
            {difEdit + " litros"}
          </p>
          <p>
            <span className="font-bold">Observaciones: </span>
            {obsEdit}
          </p>
          <div className="modal-action">
            <label
              className="btn btn-accent"
              htmlFor="editModal"
              onClick={() => {
                postData();
              }}
            >
              Sí
            </label>
            <label className="btn btn-active" htmlFor="editModal">
              Aún No
            </label>
          </div>
        </div>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default EditIngresos;
