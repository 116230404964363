import React, { useEffect, useState } from "react";
import useAuth from "../../hooks/useAuth";
import { getEmpresaAdmin } from "../../api/RoleInfo";
import { ToastContainer } from "react-toastify";
import {
  makeSafeGetRequest,
  makeSafePostRequest,
  makeSafePutRequest,
} from "../utils/Requests";
import { notifyPendingNotify, popNotification } from "../utils/Notify";
import EditRecolector from "./EditRecolector";

function VerRecolector() {
  const [idEmpresa, setIdEmpresa] = useState();
  const [nombreEmpresa, setNombreEmpresa] = useState();
  const [recolectoresEmpresa, setRecolectoresEmpresa] = useState();
  const [searchedRecolectores, setSearchedRecolectores] = useState();
  const [prodId, setProdId] = useState();
  const [recolectorNombre, setRecolectorNombre] = useState();
  const [recoActivo, setRecoActivo] = useState(false);

  const { auth } = useAuth();
  const [recolector, setRecolector] = useState();

  //obtiene el id de la empresa
  const getIdEmpresa = async () => {
    try {
      const response = await getEmpresaAdmin(auth);
      if (response) {
        console.log("encontro datos de la empresa" + JSON.stringify(response));
        setIdEmpresa(response.empresa?.id);
        setNombreEmpresa(response.empresa?.nombre);
      }
    } catch (error) {
      console.log("Error al obtener los datos de la empresa");
    }
  };

  //Lista los recolectores de la empresa
  const getRecolectores = async () => {
    try {
      const response = await makeSafeGetRequest(
        "recolectoresempresa/" + idEmpresa,
        auth.accessToken
      );
      if (response && response.status === 200) {
        setRecolectoresEmpresa(response.data);
        setSearchedRecolectores([]);
      }
    } catch (error) {
      console.log("error al obtener los productores: " + error);
    }
  };
  //Busqueda de productores por coincidencia de caracteres
  const onsearch = (searchTerm) => {
    console.log("termino :" + searchTerm);
    if (searchTerm === "" || searchTerm === undefined) {
      setSearchedRecolectores();
    } else {
      const filteredItems = recolectoresEmpresa?.filter((prod) => {
        return prod?.nombre_corto
          .toUpperCase()
          .includes(searchTerm.toUpperCase());
      });
      setSearchedRecolectores(filteredItems?.slice(0, 10));
    }
  };

  //Determina las acciones a  realizar al seleccionar un productor
  const onSelectedRecolector = (e) => {
    const selProductor = searchedRecolectores.filter(
      (prod) => prod.id === parseInt(e.target.value)
    );
    console.table(selProductor);
    setSearchedRecolectores([...selProductor]);
  };
  //obtiene el productor que tiene el id indicado
  const getRecolector = (idRecolector) => {
    const recolector = recolectoresEmpresa.find(
      (recolector) => recolector.id === idRecolector
    );
    console.log("recolector:"+ JSON.stringify(recolector))
    setRecolector(recolector);
  };
  //actiualiza el estado del productor a activo o inactivo
  const changeActivo = async () => {
    const notify = notifyPendingNotify();
    try {
      const response = await makeSafePutRequest(
        "recolectors/" + prodId,

        { data: { activo: recoActivo ? !recoActivo : true } },
        auth.accessToken
      );
      if (response && response?.status === 200) {
        popNotification(
          notify,
          "success",
          "Productor " +
            recolectorNombre +
            (recoActivo && !Object.is(recoActivo, null)
              ? " desactivado "
              : " activado ") +
            "correctamente"
        );
        await getRecolectores();
      } else {
        popNotification(
          notify,
          "error",
          "Productor no actualizado.Intente de nuevo"
        );
      }
    } catch (error) {
      console.log(error);
      popNotification(notify, "error", "Ha ocurrido un error al actualizar.");
    }
  };
  //productor compacrto para activar
  const setProdData = (productor) => {
    console.log("aaaaaaaaaaaaaaaaaaaaa");
    setProdId(productor.id);
    setRecolectorNombre(productor.nombre_corto);
    setRecoActivo(productor.activo);
  };
  useEffect(() => {
    console.log(
      "cambio en porod a editar" + prodId + recolectorNombre + recoActivo
    );
  }, [prodId, recoActivo, recolectorNombre]);
  //useeffect inicial
  useEffect(() => {
    getIdEmpresa();
  }, []);
  //useeffect idempresa
  useEffect(() => {
    if (idEmpresa) {
      console.log("si hay idempresa" + idEmpresa);
      const myProductor = { ...recolector };
      myProductor["empresa"] = { id: idEmpresa };
      getRecolectores();
    }
  }, [idEmpresa]);

  return (
    <div className="section h-full py-5 mx-2 md:w-9/12">
      <h1 className="text text-3xl mb-3">Ver Recolectores</h1>
      <h6 className="text-start">Empresa: {nombreEmpresa}</h6>
      <div className="grid grid-cols-1">
        <label htmlFor="productor" className="label">
          Seleccione un Recolector
        </label>
        <div className="dropdown dropdown-bottom w-full">
          <label className="input-group w-max">
            <input
              type={"search"}
              className="input input-bordered "
              onChange={(e) => onsearch(e.target.value)}
            ></input>
            <span>Buscar</span>
          </label>

          {searchedRecolectores && searchedRecolectores.length > 0 ? (
            <ul
              tabIndex={0}
              className="dropdown-content menu p-2 shadow bg-base-100 w-52 "
            >
              {searchedRecolectores?.map((prod) => (
                <li
                  className="text-left"
                  key={prod.id}
                  value={prod.id}
                  tabIndex={0}
                  onClick={(e) => {
                    onSelectedRecolector(e);
                  }}
                >
                  {prod?.nombre_corto}
                </li>
              ))}
            </ul>
          ) : (
            <></>
          )}
        </div>
        {searchedRecolectores ? (
          <div className="overflow-x-auto my-2">
            <table className="table table-compact">
              <thead>
                <tr>
                  <th></th>
                  <th>Documento</th>
                  <th>Nombre</th>
                  <th>Teléfono</th>
                  <th>Acopio</th>
                  <th>Rutas</th>
                  <th colSpan={3}>Opciones</th>
                </tr>
              </thead>
              <tbody>
                {searchedRecolectores.map((recolector) => (
                  <tr key={recolector.id} value={recolector.id}>
                    <td></td>
                    <td>{recolector?.numero_documento}</td>
                    <td>{recolector?.nombre_corto}</td>
                    <td>{recolector?.telefono}</td>
                    <td>{recolector?.rutas[0]?.acopio?.nombre}</td>
                    <td>
                      <></>
                      {recolector?.rutas ? (
                        recolector?.rutas?.map((ruta) => (
                          <span className="badge mx-1" key={ruta?.id}>
                            {ruta.nombre}
                          </span>
                        ))
                      ) : (
                        <span>Rutas no asignadas</span>
                      )}
                    </td>
                    <th>
                      {recolector?.activo === true ? (
                        <label
                          className="btn btn-ghost"
                          htmlFor="modal-activate"
                          onClick={() => {
                            setProdData(recolector);
                          }}
                        >
                          Inactivar
                        </label>
                      ) : (
                        <label
                          className="btn btn-primary"
                          htmlFor="modal-activate"
                          onClick={() => {
                            setProdData(recolector);
                          }}
                        >
                          Activar
                        </label>
                      )}
                    </th>
                    <td>
                      <label
                        className="btn btn-warning"
                        onClick={() => getRecolector(recolector.id)}
                      >
                        Editar
                      </label>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div>-</div>
        )}
        {recolector ? (
          <EditRecolector
          recolector={recolector}
          idEmpresa={idEmpresa}
          nombreEmpresa={nombreEmpresa}
          getRecolectores={getRecolectores}
          onsearch={onsearch}
        ></EditRecolector>
        ) : (
          
          <div className="alert">
            Busque o seleccione un recolector para empezar
          </div>
        )}
      </div>
      {/* Modal de confirmar */}
      <input type="checkbox" id="modal-activate" className="modal-toggle" />
      <div className="modal">
        <div className="modal-box">
          <h3 className="font-bold text-lg">Aviso</h3>
          <p className="py-4">
            Realmente desea {recoActivo === true ? "INACTIVAR" : "ACTIVAR"} el
            siguiente recolector?
          </p>
          <p>
            <span className="font-semibold">Nombre: </span>
            {recolectorNombre}
          </p>
          <div className="modal-action">
            <label
              htmlFor="modal-activate"
              className="btn btn-accent"
              onClick={changeActivo}
            >
              Actualizar productor
            </label>
            <label htmlFor="modal-activate" className="btn">
              Aún no
            </label>
          </div>
        </div>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default VerRecolector;
