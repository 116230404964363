import React, { useEffect, useState } from "react";
import useAuth from "../../hooks/useAuth";
import { getEmpresaAdmin } from "../../api/RoleInfo";
import { ToastContainer } from "react-toastify";
import {
  makeSafeGetRequest,
  makeSafePutRequest,
} from "../utils/Requests";
import { notifyPendingNotify, popNotification } from "../utils/Notify";
import EditProductor from "./EditProductor";

function VerProductor() {
  const [idEmpresa, setIdEmpresa] = useState();
  const [nombreEmpresa, setNombreEmpresa] = useState();
  const [productoresEmpresa, setProductoresEmpresa] = useState();
  const [searchedProductores, setSearchedProductores] = useState();
  const [prodId,setProdId]= useState();
  const [prodNombre,setProdNombre]= useState();
  const [prodActivo, setProdActivo]= useState(false);

  const { auth } = useAuth();
  const [productor, setProductor] = useState();

  //obtiene el id de la empresa
  const getIdEmpresa = async () => {
    try {
      const response = await getEmpresaAdmin(auth);
      if (response) {
        console.log("encontro datos de la empresa" + JSON.stringify(response));
        setIdEmpresa(response.empresa?.id);
        setNombreEmpresa(response.empresa?.nombre);
      }
    } catch (error) {
      console.log("Error al obtener los datos de la empresa");
    }
  };

  //Lista los productores de la empresa
  const getProductores = async () => {
    try {
      const response = await makeSafeGetRequest(
        "productoresempresadetailed/" + idEmpresa,
        auth.accessToken
      );
      if (response && response.status === 200) {
        setProductoresEmpresa(response.data);
        setSearchedProductores([]);
      }
    } catch (error) {
      console.log("error al obtener los productores: "+error)
    }
  };
  //Busqueda de productores por coincidencia de caracteres
  const onsearch = (searchTerm) => {
    console.log("termino :" + searchTerm);
    if (searchTerm === "" || searchTerm === undefined) {
      setSearchedProductores();
    } else {
      const filteredItems = productoresEmpresa?.filter((prod) => {
        return prod?.nombre_corto
          .toUpperCase()
          .includes(searchTerm.toUpperCase());
      });
      setSearchedProductores(filteredItems?.slice(0, 10));
    }
  };

  //Determina las acciones a  realizar al seleccionar un productor
  const onSelectedProductor = (e) => {
    const selProductor = searchedProductores.filter(
      (prod) => prod.id === parseInt(e.target.value)
    );
    console.table(selProductor);
    setSearchedProductores([...selProductor]);
  };
  //obtiene el productor que tiene el id indicado
  const getProductor = (idProductor) => {
    
    const productor = productoresEmpresa.find(
      (productor) => productor.id === idProductor
    );
    setProductor({...productor});
  };
  //actiualiza el estado del productor a activo o inactivo
  const changeActivo = async () => {
    const notify = notifyPendingNotify();
    try {
      const response = await makeSafePutRequest(
        "productores/" + prodId,
        
          {data: { activo: prodActivo ? !prodActivo : true }}
        ,
        auth.accessToken
      );
      if (response && response?.status === 200) {
        popNotification(
          notify,
          "success",
          "Productor " +
              prodNombre +
            (prodActivo && !Object.is(prodActivo, null)
              ? " desactivado "
              : " activado ") +
            "correctamente"
        );
        await getProductores();
      }else{
        popNotification(notify, "error","Productor no actualizado.Intente de nuevo")
      }
    } catch (error) {
      console.log(error);
      popNotification(notify, "error","Ha ocurrido un error al actualizar.")
    }
  };
  //productor compacrto para activar
  const setProdData=(productor)=>{
    console.log("aaaaaaaaaaaaaaaaaaaaa")
    setProdId(productor.id);
    setProdNombre(productor.nombre_corto);
    setProdActivo(productor.activo)
  }
  useEffect(()=>{
    console.log("cambio en porod a editar"+prodId+prodNombre+prodActivo);
  },[prodId, prodActivo, prodNombre])
  //useeffect inicial
  useEffect(() => {
    getIdEmpresa();
  }, []);
  //useeffect idempresa
  useEffect(() => {
    if (idEmpresa) {
      console.log("si hay idempresa" + idEmpresa);
      const myProductor = { ...productor };
      myProductor["empresa"] = { id: idEmpresa };
      getProductores();
    }
  }, [idEmpresa]);

  return (
    <div className="section h-full py-5 mx-2 md:w-9/12">
      <h1 className="text text-3xl mb-3">Ver Productores</h1>
      <h6 className="text-start">Empresa: {nombreEmpresa}</h6>
      <div className="grid grid-cols-1">
        <label htmlFor="productor" className="label">
          Productor
        </label>
        <div className="dropdown dropdown-bottom w-full">
          <label className="input-group w-max">
            <input
              type={"search"}
              className="input input-bordered "
              onChange={(e) => onsearch(e.target.value)}
            ></input>
            <span>Buscar</span>
          </label>

          {searchedProductores && searchedProductores.length > 0 ? (
            <ul
              tabIndex={0}
              className="dropdown-content menu p-2 shadow bg-base-100 w-52 "
            >
              {searchedProductores?.map((prod) => (
                <li
                  className="text-left"
                  key={prod.id}
                  value={prod.id}
                  tabIndex={0}
                  onClick={(e) => {
                    onSelectedProductor(e);
                  }}
                >
                  {prod?.nombre_corto}
                </li>
              ))}
            </ul>
          ) : (
            <></>
          )}
        </div>
        {searchedProductores ? (
          <div className="overflow-x-auto my-2">
            <table className="table table-compact">
              <thead>
                <tr>
                  <th></th>
                  <th>Documento</th>
                  <th>Nombre</th>
                  <th>Teléfono</th>
                  <th>Acopio</th>
                  <th>Ruta</th>
                  <th colSpan={3}>Opciones</th>
                </tr>
              </thead>
              <tbody>
                {searchedProductores.map((productor) => (
                  <tr key={productor.id} value={productor.id}>
                    <td></td>
                    <td>{productor?.numero_documento}</td>
                    <td>{productor?.nombre_corto}</td>
                    <td>{productor?.telefono}</td>
                    <td>{productor?.ruta?.acopio?.nombre}</td>
                    <td>{productor?.ruta?.nombre}</td>
                    <th>
                      {productor?.activo === true ? (
                        <label
                          className="btn btn-ghost"
                          htmlFor="modal-activate"
                          onClick={() => {
                            setProdData(productor);
                            
                          }}
                        >
                          Inactivar
                        </label>
                      ) : (
                        <label
                          className="btn btn-accent"
                          htmlFor="modal-activate"
                          onClick={() => {
                            setProdData(productor);
                            
                          }}
                        >
                          Activar
                        </label>
                      )}
                    </th>
                    <td>
                      <label
                        className="btn btn-warning"
                        onClick={() => getProductor(productor.id)}
                      >
                        Editar
                      </label>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div>-</div>
        )}
        {productor ? (
          <EditProductor
            productor={productor}
            idEmpresa={idEmpresa}
            nombreEmpresa={nombreEmpresa}
            getProductores={getProductores}
          ></EditProductor>
        ) : (
          <div className="alert">
            Busque o seleccione un productor para empezar
          </div>
        )}
      </div>
      {/* Modal de confirmar */}
      <input type="checkbox" id="modal-activate" className="modal-toggle" />
      <div className="modal">
        <div className="modal-box">
          <h3 className="font-bold text-lg">Aviso</h3>
          <p className="py-4">
            Realmente desea{" "}
            {prodActivo === true ? "INACTIVAR" : "ACTIVAR"} el siguiente
            productor?
          </p>
          <p>
            <span className="font-semibold">Nombre: </span>
            {prodNombre}
          </p>
          <div className="modal-action">
            <label
              htmlFor="modal-activate"
              className="btn btn-accent"
              onClick={changeActivo}
            >
              Actualizar productor
            </label>
            <label htmlFor="modal-activate" className="btn">
              Aún no
            </label>
          </div>
        </div>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default VerProductor;
