import React, { useEffect, useState } from "react";
import useAuth from "../../hooks/useAuth";
import { getEmpresaAdmin } from "../../api/RoleInfo";
import { ToastContainer } from "react-toastify";
import { makeSafeGetRequest, makeSafePostRequest } from "../utils/Requests";
import { notifyPendingNotify, popNotification } from "../utils/Notify";

function CreateRecolector() {
  const [recolector, setRecolector] = useState({
    nombre_corto: "",
    primer_nombre: "",
    segundo_nombre: "",
    primer_apellido: "",
    segundo_apellido: "",
    tipo_documento: null,
    numero_documento: null,
    fecha_nacimiento: null,
    lugar_nacimiento: "",
    fecha_expedicion: null,
    lugar_expedicion: "",
    departamento: "",
    municipio: "",
    direccion: "",
    vereda: "",
    telefono: "",
    telefono_2: "",
    correo: "",
    banco: null,
    tipo_pago: "",
    tipo_cuenta: "",
    numero_cuenta: "",
    documento_titular_cuenta: "",
    telefonos: "",
    estado: "",
    acopio: null,
    rutas: [],
    activo: false,
    empresa: null,
  });
  const [nombreRuta, setNombreRuta] = useState("");
  const [nombreAcopio, setNombreAcopio] = useState("");
  const [idEmpresa, setIdEmpresa] = useState();
  const [nombreEmpresa, setNombreEmpresa] = useState();
  const [bancos, setBancos] = useState();
  const [acopios, setAcopios] = useState();
  const [rutas, setRutas] = useState();
  const { auth } = useAuth();
  const [dataValid, setDataValid] = useState(false);
  const [validatePNombre, setValidatePNombre] = useState("");
  const [validatePApellido, setValidatePApellido] = useState("");
  const [validateNumDoc, setValidateNumDoc] = useState("");
  const [telefonoRange, setTelefonoRange] = useState("");
  const [validateBanco, setValidateBanco] = useState("");
  const [validateRuta, setValidateRuta] = useState("");
  const [validateTipoPago, setValidateTipoPago] = useState("");
  const [validateTipoCta, setValidateTipoCta] = useState("");
  const [validateNumCta, setValidateNumCta] = useState("");

  //obtiene el id de la empresa
  const getIdEmpresa = async () => {
    try {
      const response = await getEmpresaAdmin(auth);
      if (response) {
        console.log("encontro datos de la empresa" + JSON.stringify(response));
        setIdEmpresa(response.empresa?.id);
        setNombreEmpresa(response.empresa?.nombre);
      }
    } catch (error) {
      console.log("Error al obtener los datos de la empresa");
    }
  };
  const getAcopios = async () => {
    try {
      console.log("obteniendo acopios");
      const response = await makeSafeGetRequest(
        "acopiosempresa/" + idEmpresa,
        auth.accessToken
      );
      console.log("obteniendo acopios" + JSON.stringify(response));
      if (response && response.status === 200) {
        console.log("etrea");
        setAcopios(response.data);
      }
    } catch (error) {
      console.log("error al obtener acopios");
    }
  };
  //obtiene la lista de rutas del acopio
  const getRutas = async (id) => {
    try {
      const response = await makeSafeGetRequest(
        "rutasacopio/" + id,
        auth.accessToken
      );
      if (response && response?.data) {
        setRutas(response?.data);
      }
    } catch (error) {
      console.log("Error al obtener rutas: "+error)
    }
  };

  //funciones de cambios
  //acopios
  const onAcopioChange = async (e) => {
    setNombreAcopio(e.target.selectedOptions[0].text);
    await getRutas(e.target.value);
  };
//Cambio de rutas
  const onRutasChange = async (e) => {
    console.log("imprimiendo e"+e.target.value)
    if (
      e.target.value !== -1 && e.target.value>0 &&
      !recolector?.rutas.some(
        (r) => parseInt(r.id) === parseInt(e.target.value)
      )
    ) {
      const myRecolector = { ...recolector ,
        rutas:[...recolector.rutas,{id:parseInt(e.target.value),nombre:e.target.selectedOptions[0].text}],
      
      };
      setRecolector(myRecolector);
    } else {
      console.log("ruta no agregada");
    }
  };
  //elimina una ruta de la lista de rutas seleccionadas
  const deleteRuta = (r) => {
    console.log("iniciando borrar ruta");
    console.log(JSON.stringify(r));
    if (r.id !== -1 && r.id) {
      console.log("este es el recolector"+JSON.stringify(recolector))
      const updatedRecolector = {
        ...recolector,
        rutas: recolector.rutas.filter((ruta) => parseInt(ruta.id) !== parseInt(r.id)),
      };
      setRecolector(updatedRecolector);
    } else {
      console.log("no se encontro ruta a borrar");
    }
  };
  //cambio de parametro
  const onParametroChange = (e, parametro, isRelationship) => {
    console.log("parametro a cambiar: " + parametro + "," + e.target.value);
    const myRecolector = { ...recolector };
    if (isRelationship) {
      myRecolector[parametro] = parseInt(e.target.value);
    } else {
      myRecolector[parametro] = e.target.value.toUpperCase();
    }
    setRecolector(myRecolector);
    console.log("Recolector:" + JSON.stringify(recolector));
    validateData();
  };
  //validar parametro
  const checkParametro = (e, parametro) => {
    switch (parametro) {
      case "rutas":
        if (e.target.value === "-1") {
          setValidateRuta("Seleccione una ruta válida");
        } else {
          setValidateRuta("");
        }
        break;
      case "numero_documento":
        if (e.target.value === "") {
          setValidateNumDoc("Digite un n° de documento válido");
        } else {
          setValidateNumDoc("");
        }
        break;
      case "primer_nombre":
        if (e.target.value === "") {
          setValidatePNombre("Por favor, digite un nombre");
        } else {
          setValidatePNombre("");
        }
        break;
      case "primer_apellido":
        if (e.target.value === "") {
          setValidatePApellido("Por favor, digite un apellido");
        } else {
          setValidatePApellido("");
        }
        break;
      case "telefono":
        if (e.target.value === "" || isNaN(parseInt(e.target.value))) {
          setTelefonoRange("Por favor, digite un teléfono válido");
        } else {
          setTelefonoRange("");
        }
        break;
      case "banco":
        if (e.target.value === "-1") {
          setValidateBanco("Seleccione un banco");
        } else {
          setValidateBanco("");
        }
        break;
      case "tipo_cuenta":
        if (e.target.value === "-1") {
          setValidateTipoCta("Seleccione una cuenta");
        } else {
          setValidateTipoCta("");
        }
        break;
      case "tipo_pago":
        if (e.target.value === "-1") {
          setValidateTipoPago("Seleccione un tipo de pago");
        } else {
          setValidateTipoPago("");
        }
        break;
      case "numero_cuenta":
        if (e.target.value === "" || isNaN(parseInt(e.target.value))) {
          setValidateNumCta("Seleccione un número de cuenta");
        } else {
          setValidateNumCta("");
        }
        break;
      case "documento_titular_cuenta":
        if (e.target.value === "" || isNaN(parseInt(e.target.value))) {
          setValidateNumCta("Seleccione un número de cuenta");
        } else {
          setValidateNumCta("");
        }
        break;

      default:
        break;
    }
  };
  const onActivoChange = () => {
    setRecolector((prevRecolector) => ({
      ...prevRecolector,
      activo: !prevRecolector.activo, // Toggle the value
    }));
  };
  //Valida la data para asegurarse que el recolector contiene los datos básicos
  const validateData = () => {
    if (
      recolector?.rutas?.length>0 &&
      
      recolector.primer_nombre !== "" &&
      recolector.primer_apellido !== "" &&
      recolector.numero_documento !== "" &&
      recolector.telefono !== "" 
    ) {
      console.log("La ruta esta" + true);
      setDataValid(true);
    } else {
      console.log("la ruta no esta" + false);
      setDataValid(false);
    }
  };
  //Crea el nopmbre corto del recolector
  const createNombreCorto = () => {
    const myRecolector = { ...recolector };
    myRecolector["nombre_corto"] ="";
    myRecolector["nombre_corto"] =
      recolector["primer_nombre"] +
      " " +
      recolector["segundo_nombre"] +
      " " +
      recolector["primer_apellido"] +
      " "+recolector["segundo_apellido"];
    setRecolector(myRecolector);
    console.log("Recolector:" + JSON.stringify(recolector));
  };
  //Postea  la data del recolector
  const postData = async () => {
    const notify = notifyPendingNotify();
    //VALIDA LA DATA
    validateData();
    if (dataValid === true) {
      try {
        const response = await makeSafePostRequest(
          "recolectors",
          { data: recolector },
          auth.accessToken
        );
        if (response && response.status === 200) {
          popNotification(
            notify,
            "success",
            "Recolector guardado correctamente."
          );
        } else {
          popNotification(
            notify,
            "error",
            "No se pudo guardar el recolector. Intente de nuevo"
          );
        }
      } catch (error) {
        popNotification(notify, "error", "Error al guardar el recolector.");
      }
    } else {
      popNotification(notify, "error", "Verifique los datos e intente de nuevo.");
    }
  };
  const getBancosEmpresa = async () => {
    try {
      const response = await makeSafeGetRequest("bancos", auth.accessToken);
      if (response && response.status === 200) {
        console.log("bancos obtenidos" + JSON.stringify(response.data));
        setBancos(response.data.data);
      } else {
        console.log("error al obtener los bancos");
      }
    } catch (error) {
      console.log("error al obtener los bancos" + error);
    }
  };
  //USEEFFECT VALID
  useEffect(()=>{
    console.log("CAMBIO EN DATA VALID"+dataValid)
  },[dataValid])
  //useeffect inicial
  useEffect(() => {
    getIdEmpresa();
    getBancosEmpresa();
  }, []);
  //useeffect idempresa
  useEffect(() => {
    if (idEmpresa) {
      console.log("si hay idempresa" + idEmpresa);
      const myRecolector = { ...recolector };
      myRecolector["empresa"] = { id: idEmpresa };
      setRecolector(myRecolector);
      getAcopios();
    }
  }, [idEmpresa]);

  return (
    <div className="section h-full md:w-10/12 py-5 mx-2">
      <h1 className="text text-3xl mb-3">Crear Recolector</h1>
      <div className="grid grid-cols-1  py-3">
        <h3 className="text-xl text-left font-bold">Datos del Recolector</h3>
        <h6 className="text-start">Empresa: {nombreEmpresa}</h6>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 px-5">
          <div className="flex flex-col">
            <label className="label">Acopio </label>
            <select
              className="select select-bordered"
              onChange={(e) => {
                onAcopioChange(e);
              }}
            >
              <option key="-1" value={"-1"}>
                Seleccione un Acopio
              </option>
              {acopios ? (
                acopios?.map((acopio) => (
                  <option key={acopio.id} value={acopio.id}>
                    {acopio.nombre}
                  </option>
                ))
              ) : (
                <option>Cargando acopios</option>
              )}
            </select>
          </div>
          <div className="flex flex-col">
            <label className="label">
              Rutas asignadas<span className="text-error">*</span>
            </label>
            <select
              className="select select-bordered"
              onChange={(e) => {
                onRutasChange(e);
                checkParametro(e, "ruta");
              }}
              onBlur={(e) => {
                checkParametro(e, "ruta");
              }}
            >
              <option key={"-1"} value={"-1"}>
                Seleccione una ruta
              </option>
              {!rutas ? (
                <option key={"n/a"} value={"n/a"}>
                  Cargando Rutas
                </option>
              ) : (
                rutas.map((ruta) => (
                  <option key={ruta.id} value={ruta.id}>
                    {ruta?.nombre}
                  </option>
                ))
              )}

            </select>
          
            {recolector?.rutas && recolector?.rutas?.length > 0 ? (
                  <div className="display display-flex my-1">
                    {recolector?.rutas?.map((ruta) => (
                      <span
                        className="badge mx-1"
                        key={ruta?.id}
                        value={ruta?.id}
                      >
                        {ruta?.nombre}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                          onClick={() => {
                            deleteRuta(ruta);
                          }}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                          />
                        </svg>
                      </span>
                    ))}
                  </div>
                ) : (
                  <></>
                )}

            {validateRuta ? (
              <label className="label text-error">{validateRuta}</label>
            ) : (
              <></>
            )}
            
          </div>
          <div className="flex flex-col">
            <label className="label">Activo?</label>
            <div className="flex justify-around">
              <label className="label">No</label>
              <input
                type={"checkbox"}
                className="toggle toggle-accent"
                checked={!!recolector.activo}
                onChange={() => {
                  onActivoChange((prevCheck) => !prevCheck);
                }}
              ></input>
              <label className="label">Sí</label>
            </div>
          </div>
        </div>
        {/* Datos personales */}
        <h3 className="text-xl text-left font-bold">Datos Personales</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 px-5">
          <div className="flex flex-col">
            <label className="label">Tipo de Documento</label>
            <select
              className="select select-bordered"
              onChange={(e) => onParametroChange(e, "tipo_documento")}
              onBlur={(e) => {
                checkParametro(e, "tipo_documento");
              }}
            >
              <option key={-1} value={-1}>
                Seleccione un Tipo de Documento
              </option>
              <option key={1} value={1}>
                Nit
              </option>
              <option key={2} value={2}>
                Cédula de Ciudadanía
              </option>
              <option key={3} value={3}>
                Cédula de Extranjería
              </option>
              <option key={4} value={4}>
                Tarjeta de Identidad
              </option>
              <option key={5} value={5}>
                Pasaporte
              </option>
            </select>
          </div>

          <div className="flex flex-col">
            <label className="label">
              Número de Documento<span className="text-error">*</span>
            </label>
            <input
              type={"number"}
              className="input input-bordered "
              min={0}
              step={1}
              onChange={(e) => {
                onParametroChange(e, "numero_documento");
              }}
              onBlur={(e) => {
                checkParametro(e, "numero_documento");
              }}
            ></input>
            {validateNumDoc ? (
              <label className="label text-error">{validateNumDoc}</label>
            ) : (
              <></>
            )}
          </div>
          <div className="flex flex-col">
            <label className="label">Primer Nombre</label>
            <input
              type={"text"}
              className="input input-bordered"
              onChange={(e) => {
                onParametroChange(e, "primer_nombre");
              }}
              onBlur={(e) => {
                createNombreCorto();
                checkParametro(e, "primer_nombre");
              }}
            ></input>
            {validatePNombre ? (
              <label className="label text-error">{validatePNombre}</label>
            ) : (
              <></>
            )}
          </div>
          <div className="flex flex-col">
            <label className="label">Segundo Nombre</label>
            <input
              type={"text"}
              className="input input-bordered"
              onChange={(e) => {
                onParametroChange(e, "segundo_nombre");
              }}
            ></input>
          </div>
          <div className="flex flex-col">
            <label className="label">Primer Apellido</label>
            <input
              type={"text"}
              className="input input-bordered"
              onChange={(e) => {
                onParametroChange(e, "primer_apellido");
              }}
              onBlur={(e) => {
                createNombreCorto();
                checkParametro(e, "primer_apellido");
              }}
            ></input>
            {validatePApellido ? (
              <label className="label text-error">{validatePApellido}</label>
            ) : (
              <></>
            )}
          </div>

          <div className="flex flex-col">
            <label className="label">Segundo Apellido</label>
            <input
              type={"text"}
              className="input input-bordered"
              onChange={(e) => {
                onParametroChange(e, "segundo_apellido");
              }}
            ></input>
          </div>
          <div className="flex flex-col align-middle">
            <label className="text text-base text-left align-middle">
              <br></br>
              <span className="font-semibold">Nombre corto: </span> {recolector?.nombre_corto}
            </label>
          </div>
          <div className="flex flex-col">
            <label className="label">Lugar de Nacimiento</label>
            <input
              type={"text"}
              className="input input-bordered"
              onChange={(e) => {
                onParametroChange(e, "lugar_nacimiento");
              }}
            ></input>
          </div>
          <div className="flex flex-col">
            <label className="label">Fecha de Nacimiento</label>
            <input
              type={"date"}
              className="input input-bordered"
              onChange={(e) => {
                onParametroChange(e, "fecha_nacimiento");
              }}
            ></input>
          </div>
          <div className="flex flex-col">
            <label className="label">Lugar de Expedición del Documento</label>
            <input
              type={"text"}
              className="input input-bordered"
              onChange={(e) => {
                onParametroChange(e, "lugar_expedicion");
              }}
            ></input>
          </div>
          <div className="flex flex-col">
            <label className="label">Fecha de Expedición</label>
            <input
              type={"date"}
              className="input input-bordered"
              onChange={(e) => {
                onParametroChange(e, "fecha_expedicion");
              }}
            ></input>
          </div>
        </div>
        {/* Datos de contacto */}
        <h3 className="text-xl text-left font-bold my-5">Datos de Contacto</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 px-5">
          <div className="flex flex-col">
            <label className="label">Departamento de Residencia</label>
            <input
              type={"text"}
              className="input input-bordered"
              onChange={(e) => {
                onParametroChange(e, "departamento");
              }}
            ></input>
          </div>

          <div className="flex flex-col">
            <label className="label">Municipio de Residencia</label>
            <input
              type={"text"}
              className="input input-bordered"
              onChange={(e) => {
                onParametroChange(e, "municipio");
              }}
            ></input>
          </div>
          <div className="flex flex-col">
            <label className="label">Vereda</label>
            <input
              type={"text"}
              className="input input-bordered"
              onChange={(e) => {
                onParametroChange(e, "vereda");
              }}
            ></input>
          </div>
          <div className="flex flex-col">
            <label className="label">Dirección /Nombre Finca</label>
            <input
              type={"text"}
              className="input input-bordered"
              onChange={(e) => {
                onParametroChange(e, "direccion");
              }}
            ></input>
          </div>
          <div className="flex flex-col">
            <label className="label">Teléfono<span className="text-error">*</span></label>
            <input
              type={"tel"}
              className="input input-bordered"
              onChange={(e) => {
                onParametroChange(e, "telefono");
              }}
              onBlur={(e) => {
                checkParametro(e, "telefono");
              }}
            ></input>
            {telefonoRange ? (
              <label className="label text-error">{telefonoRange}</label>
            ) : (
              <></>
            )}
          </div>
          <div className="flex flex-col">
            <label className="label">Teléfono 2</label>
            <input
              type={"tel"}
              className="input input-bordered"
              onChange={(e) => {
                onParametroChange(e, "telefono_2");
              }}
            ></input>
          </div>
          <div className="flex flex-col">
            <label className="label">Celular</label>
            <input
              type={"phone"}
              className="input input-bordered"
              onChange={(e) => {
                onParametroChange(e, "celular");
              }}
            ></input>
          </div>
          <div className="flex flex-col">
            <label className="label">Correo</label>
            <input
              type={"email"}
              className="input input-bordered"
              onChange={(e) => {
                onParametroChange(e, "correo");
              }}
            ></input>
          </div>
        </div>
        {/* Datos Bancarios*/}
        <h3 className="text-xl text-left font-bold my-5">Datos Bancarios</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 px-5">
          <div className="flex flex-col">
            <label className="label">
              Tipo de Pago <span className="text-error">*</span>{" "}
            </label>
            <select
              className="select select-bordered"
              onChange={(e) => {
                onParametroChange(e, "tipo_pago");
              }}
              onBlur={(e) => checkParametro(e, "tipo_pago")}
            >
              <option key="-1" value={"-1"}>
                Seleccione un tipo de Pago
              </option>
              <option key={1} value={"CONSIGNACION"}>
                Consignación
              </option>
              <option key={2} value={"OTROS BANCOS"}>
                Otros Bancos
              </option>
              <option key={3} value={"EFECTIVO"}>
                Efectivo
              </option>
            </select>
            {validateTipoPago ? (
              <label className="label text-error">{validateTipoPago}</label>
            ) : (
              <></>
            )}
          </div>
          <div className="flex flex-col">
            <label className="label">
              Tipo de Cuenta <span className="text-error">*</span>
            </label>
            <select
              className="select select-bordered"
              onChange={(e) => {
                onParametroChange(e, "tipo_cuenta");
              }}
              onBlur={(e) => checkParametro(e, "tipo_cuenta")}
            >
              <option key="-1" value={"-1"}>
                Seleccione un tipo de Cuenta
              </option>
              <option key={1} value={"AHORROS"}>
                Ahorros
              </option>
              <option key={2} value={"CORRIENTE"}>
                Corriente
              </option>
              <option key={3} value={"EFECTIVO"}>
                Efectivo
              </option>
            </select>
            {validateTipoCta ? (
              <label className="label text-error">{validateTipoCta}</label>
            ) : (
              <></>
            )}
          </div>
          <div className="flex flex-col">
            <div className=""></div>
            <label className="label">
              Banco <span className="text-error">*</span>
            </label>
            <select
              className="select select-bordered"
              onChange={(e) => {
                onParametroChange(e, "banco", true);
              }}
              onBlur={(e) => checkParametro(e, "banco")}
            >
              <option key="-1" value={"-1"}>
                Seleccione un Banco
              </option>
              {bancos ? (
                bancos?.map((banco) => (
                  <option key={banco.id} value={banco.id}>
                    {banco?.attributes?.nombre_banco}
                  </option>
                ))
              ) : (
                <option>Cargando bancos</option>
              )}
            </select>
            {validateBanco ? (
              <label className="label text-error">{validateBanco}</label>
            ) : (
              <></>
            )}
          </div>

          <div className="flex flex-col">
            <label className="label">
              Número de cuenta <span className="text-error">*</span>
            </label>
            <input
              type={"number"}
              className="input input-bordered"
              min={0}
              onChange={(e) => {
                onParametroChange(e, "numero_cuenta");
              }}
              onBlur={(e) => checkParametro(e, "numero_cuenta")}
            ></input>
            {validateNumCta ? (
              <label className="label text-error">{validateNumCta}</label>
            ) : (
              <></>
            )}
          </div>
          <div className="flex flex-col">
            <label className="label">
              Nombre del Titular <span className="text-error">*</span>
            </label>
            <input
              type={"text"}
              className="input input-bordered"
              onChange={(e) => {
                onParametroChange(e, "telefonos");
              }}
              onBlur={(e) => checkParametro(e, "telefonos")}
            ></input>
          </div>
          <div className="flex flex-col">
            <label className="label">
              N° Documento del Titular <span className="text-error"></span>
            </label>
            <input
              type={"number"}
              className="input input-bordered"
              onChange={(e) => {
                onParametroChange(e, "documento_titular_cuenta");
              }}
            ></input>
          </div>
        </div>
      </div>

      <label className="btn btn-accent my-1" htmlFor="modal-confirm">
        Guardar Usuario
      </label>
      {/* Modal de confirmar */}
      <input type="checkbox" id="modal-confirm" className="modal-toggle" />
      <div className="modal">
        <div className="modal-box">
          <h3 className="font-bold text-lg">Aviso</h3>
          <p className="py-4">
            Realmente desea crear el recolector con los siguientes datos?
          </p>
          <p>
            <span className="font-semibold">Empresa:</span>
            {nombreEmpresa}
          </p>
          <p>
          <p>
            <span className="font-semibold">Acopio: </span>
            {nombreAcopio}
          </p>
          <p>
            <span className="font-semibold">Rutas: </span>
            {recolector?.rutas?.map((ruta)=>(<>{ruta?.nombre+", "}</>))}
          </p>
            <span className="font-semibold">Nombre corto: </span>
            {recolector?.nombre_corto}
          </p>
          <p>
            <span className="font-semibold">NUúmero Documento: </span>
            {recolector?.numero_documento}
          </p>
          <p>
            <span className="font-semibold">Teléfono:</span>
            {recolector?.telefono}
          </p>
          <p>
            <span className="font-semibold">Correo: </span>
            {recolector?.correo}
          </p>
          <p>
            <span className="font-semibold">Municipio: </span>
            {recolector?.municipio}
          </p>
          <p>
            <span className="font-semibold">Dirección:</span>
            {recolector?.direccion}
          </p>
          <p>
            <span className="font-semibold">Número de cuenta: </span>
            {recolector?.numero_cuenta}
          </p>

          <div className="modal-action">
            <label
              htmlFor="modal-confirm"
              className="btn btn-accent"
              onClick={postData}
            >
              Crear recolector
            </label>
            <label htmlFor="modal-confirm" className="btn">
              Aún no
            </label>
          </div>
        </div>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default CreateRecolector;
