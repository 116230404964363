import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/axios";
import {
  dateToColombianZoneRq,
  timeToColombianZonerq,
} from "./utils/timezoneRequests";
import Prueba from "./Prueba";
import {
  notifyPendingNotify,
  popNotification,
} from "../components/utils/Notify";
import useAuth from "../hooks/useAuth";
import {
  authenticate,
  getRole,
  makeGetRequest,
  makePostRequest,
  postPruebas,
} from "./utils/Requests";
import { checkAuthFunction } from "./utils/checkAuth";

function CreateIngreso() {
  const URL = process.env.REACT_APP_BACKEND_BASE_URL;
  const { auth } = useAuth();

  const [recorridosNoIngresados, setRecorridosNoIngresados] = useState();
  const [selectedRecorrido, setselectedRecorrido] = useState();

  //states para la prueba
  const [prueba, setPrueba] = useState({});
  const [pruebasStage, setPruebasStage] = useState([{}]);
  const [pruebaSelRecorrido, setPruebaSelRecorrido] = useState();

  const [obsGenerales, setObsGenerales] = useState("");
  const [ingresoAceptado, setIngresoAceptado] = useState(true);
  const [lecheMedida, setLecheMedida] = useState(0);
  const [lecheRechazada, setLecheRechazada] = useState(0);
  //
  const [lecheCalculadaRecorrido, setLecheCalculadaRecorrido] = useState(0);
  const [diferenciaLeche, setDiferenciaLeche] = useState(0);
  const [idOperario, setIdOperario] = useState();
  const [idAcopio, setIdAcopio] = useState();
  //Usuarios y contraseñas de usuario responsable
  const [usr, setUsr] = useState("");
  const [pwd, setPwd] = useState("");
  const [recoNombre, setRecoNombre] = useState();

  //Obtiene el id del operarioal que pertenece el usuario logueado
  const getIdOperario = async () => {
    try {
      const idUser = auth.id;
      console.log("Datos del usuario:" + JSON.stringify(auth));
      const url = URL + "operarioporusuario/" + idUser;
      const response = await axios.get(`${url}`);

      console.log("Datos operario:" + JSON.stringify(response.data));
      if (response?.status === 200) {
        console.log("hay operario asociado al usuario");
        setIdOperario(response?.data?.id);
        getIdAcopio(response.data);
      } else {
        console.log("No hay operario disponiblee");
      }
    } catch (error) {
      console.log("Error al obtener el id del operario: " + error);
    }
  };
  //obtiene el id del acopio del operario
  const getIdAcopio = (dataOperario) => {
    const idAco = dataOperario.acopio.id;
    console.log("Id del acopio del recolector" + idAco);
    setIdAcopio(idAco);
  };
  //obtiene el cambio en el componente reutilizable de la prueba
  function handlePruebaChange(pruebaE) {
    setPrueba({ ...pruebaE });
  }
  //obtiene los recorridos de hoy que aun no se han ingresado y que pertenecen al acopio del operario
  const getRecorridosNoIngresados = async () => {
    const url = URL + "recorridossiningresohoy/" + idAcopio;
    try {
      const response = await axios.get(`${url}`);
      console.log(JSON.stringify(response.data));
      console.log(JSON.stringify(response));
      if (response.status === 200 && response.data.length !== 0) {
        console.log("entra a guardar la respuesta");
        setRecorridosNoIngresados(response.data);
      } else {
        setRecorridosNoIngresados();
      }
    } catch (error) {
      console.log("Error al buscar los recorridos no ingresados" + error);
    }
  };
  //Consulta y obtiene la suma de la leche obtenida en un recorrido
  const getLecheCalculadaRecorrido = async (idRecorrido) => {
    const url = URL + "getsumarecorrido/" + idRecorrido;
    try {
      const response = await axios.get(`${url}`);
      const suma = response.data.suma;
      console.log("Suma de la leche: " + response.data.suma);
      setLecheCalculadaRecorrido(suma);
    } catch (error) {
      console.log(error);
    }
  };
  //Compara la leche que fue medida con la que calcula el sistema
  const checkDiferenciaLeche = () => {
    const diferenciaLeche =
      lecheMedida + lecheRechazada - lecheCalculadaRecorrido;
    console.log("ESTA ES LA DIF" + diferenciaLeche);
    setDiferenciaLeche(diferenciaLeche);
  };
  //Agrega el state pruebas al localStorage
  const addPruebasToLS = () => {
    console.log(JSON.stringify(pruebasStage));
    localStorage.setItem("pruebas", JSON.stringify(pruebasStage));
  };
  //obtiene las pruebas staged de localStorage
  const getPruebasFromLS = () => {
    if (localStorage.getItem("pruebas") !== null) {
      console.log("Obteniendo pruebas " + localStorage.getItem("pruebas"));
      const p = JSON.parse(localStorage.getItem("pruebas"));
      return p;
    } else {
      return null;
    }
  };
  //Agrega una prueba temporalmente en el objeto pruebasStage
  const addPruebaStage = () => {
    //verificar que la prueba no exista
    const pruebaExiste = checkPruebasStage();
    console.log("prueba existente?\n" + pruebaExiste);
    if (!pruebaExiste) {
      console.log("Probando boton guardar prueba");
      const p = { id: selectedRecorrido, prueba: prueba };
      const pruebaStage = pruebasStage;
      pruebaStage.push(p);
      console.log("Pruebas en stage" + JSON.stringify(pruebaStage));
      setPruebasStage(pruebaStage);
      addPruebasToLS(pruebasStage);
    } else {
      console.log("Ya existe prueba asociada al reco");
    }
  };
  //verifica si la prueba a agregar está completa
  const checkPruebaEsCompleta = () => {
    if (
      prueba.acidez != null &&
      prueba.ph != null &&
      (prueba.agua != null) & (prueba.grasa != null) &&
      prueba.proteina != null &&
      prueba.solidos != null &&
      prueba.densidad != null
    ) {
      return true;
    } else {
      return false;
    }
  };
  //verifica si la prueba que está a punto de guardarse en stage es viable y si se accede con usuario y contraseña
  const checkPrueba = async () => {
    const notify = notifyPendingNotify();
    const pruebaCompleta = checkPruebaEsCompleta();
    if (pruebaCompleta) {
      popNotification(notify, "success", "La prueba está completa");
      const idOperario = await checkAuthFunction(usr, pwd);
      if (idOperario) {
        popNotification(notify, "success", "Autorizado. Guardando prueba");
        addPruebaStage();
      }
    } else {
      popNotification(notify, "error", "Complete los datos de la prueba");
    }
    findPruebasStage(selectedRecorrido);
    clearAuth();
  };
  //limpia los states de usuario y contraseña
  const clearAuth=()=>{
    setUsr("");
    setPwd("");
  }
  //verifica si en el array de pruebas hay una prueba con ese mismo id
  const checkPruebasStage = () => {
    console.log("verificando stage pruebaa");
    return pruebasStage.some((prueba) => prueba.id === selectedRecorrido);
  };
  //Busca si en el objeto de pruebas temporales hay una con el id del recorrido
  const findPruebasStage = (idPruebaStage) => {
    const pruebasLS = getPruebasFromLS();
    if (pruebasLS) {
      console.log("Pruebas encontradas en el ls");
      setPruebasStage(pruebasLS);
      //buscar la prueba en la memoria
      const foundPrueba = pruebasLS.find((p) => p.id === idPruebaStage);
      console.log("Prueba Encontrada:" + JSON.stringify(foundPrueba));
      if (foundPrueba) {
        setPruebaSelRecorrido(foundPrueba);
      } else {
        setPruebaSelRecorrido();
      }
    } else {
      console.log("BNo se encontró prueba en el LS");
      setPruebaSelRecorrido();
    }
  };
  //Elimina una prueba del stage de pruebas
  const deletePruebasStage = (idPruebaStage) => {
    const notify = notifyPendingNotify();
    try {
      localStorage.removeItem("pruebas");
      const deletedLS = pruebasStage.filter((p) => p === idPruebaStage);
      console.log("prueba eliminada en" + JSON.stringify(deletedLS));
      localStorage.setItem("pruebas", JSON.stringify(deletedLS));
      setPruebasStage(getPruebasFromLS());
      findPruebasStage(idPruebaStage);

      popNotification(notify, "success", "Prueba temporal eliminada");
    } catch (error) {
      popNotification(
        notify,
        "error",
        "No se pudo eliminar la prueba temporal"
      );
    }
  };
  //orhganiza el proceso de posteo de la prueba y del ingreso
  const postData = async () => {
    const idOp = await checkAuthFunction(usr, pwd);
    if (idOp) {
      const notify = notifyPendingNotify();
      if (selectedRecorrido && selectedRecorrido !== "-1" && lecheMedida>=0) {
        const idPrueba = await postPrueba(idOp);
        if (idPrueba) {
          popNotification(
            notify,
            "success",
            "Prueba guardada. Cargando Ingreso"
          );
          await postIngreso(idPrueba, idOp);
        } else {
          popNotification(notify, "error", "Error al postear la prueba");
        }
      } else {
        popNotification(notify, "error", "Complete los datos del Ingreso");
      }
    } else {
      console.log("error de autenticación");
    }
    clearAuth();

  };

  //postea la prueba junto con el recorrido seleccionado y la cantidad de leche medida
  const postPrueba = async (idOp) => {
    const notify = notifyPendingNotify();
    try {
      if (selectedRecorrido && selectedRecorrido !== "-1") {
        console.log(selectedRecorrido + "recorrido seleccionado");
        let resp;

        if (pruebaSelRecorrido) {
          console.log("Guardando prueba temporal");
          resp = await postPruebas(pruebaSelRecorrido?.prueba, idOp);
          if (resp !== null) {
            console.log("probando notificacion");
            popNotification(notify, "success", "Prueba agregada...");
          } else {
            popNotification(notify, "error", "Error al agregar la prueba");
          }
          const idPrueba = resp.data.data.id;
          console.log(
            "respuesta: " + JSON.stringify(resp.data.data) + "id:" + idPrueba
          );
          return idPrueba;
        } else {
          popNotification(
            notify,
            "error",
            "Se debe agregar la prueba antes de guardar el ingreso"
          );
        }
      } else {
        popNotification(notify, "error", "Seleccione un ingreso");
      }
    } catch (error) {
      console.log("Descripcion del error: " + error);
    }
  };

  //hacemos el ingreso de la leche
  const postIngreso = async (idPrueba, idOp) => {
    console.log(idPrueba + "probando post Ingreso");
    try {
      const notify = notifyPendingNotify();
      console.log("Reco seleccionado" + selectedRecorrido);
      console.log("id operario: " + idOperario);
      const idrecorrido = selectedRecorrido.toString();
      const fechaHoy = await dateToColombianZoneRq();
      console.log("seguimirento" + fechaHoy);
      const horaHoy = timeToColombianZonerq();
      const url = URL + "ingresos";
      const response = await axios.post(url, {
        data: {
          observaciones: obsGenerales + " .Hora " + horaHoy,
          fecha: fechaHoy,
          hora: horaHoy,
          cantidad_leche: lecheCalculadaRecorrido,
          cantidad_medida: lecheMedida,
          cantidad_rechazada: lecheRechazada,
          diferencia: diferenciaLeche,
          aceptado: ingresoAceptado,

          prueba: {
            id: idPrueba,
          },
          acopio: {
            id: idAcopio, //cambiar al acopio por el de la persona que ingresa
          },
          recorrido: idrecorrido,
          operario: { id: idOp },
        },
      });
      if (response && response?.status===200) {
        console.log("probando notificacion ingreso:entra___");
        popNotification(notify, "success", "ingreso realizado correctamente.");
        //cambio para reiniciar el select
        setselectedRecorrido(-1);
        setLecheMedida(0);
        setLecheRechazada(0);
        setObsGenerales("");
        setRecoNombre("");
        setPruebaSelRecorrido();
        clearAuth();
      }
      console.log("Ingreso realizado: " + JSON.stringify(response.data));
    } catch (error) {
      console.log("descripcion del error de ingreso " + error);
    }
  };
  
  //Effects de Ingreso
  useEffect(() => {
    console.log("cambio en Acopio");
    if (idAcopio) {
      getRecorridosNoIngresados();
    }
  }, [idAcopio]);

  useEffect(() => {
    getIdOperario();
  }, []);
  useEffect(() => {
    if (selectedRecorrido === -1) {
      getRecorridosNoIngresados();
    }
    checkDiferenciaLeche();
  }, [selectedRecorrido]);
  useEffect(() => {
    console.log("cambio en la prueba stage");
  }, [pruebasStage]);
  useEffect(() => {
    console.log("cambio diferencia leche" + lecheCalculadaRecorrido);
  }, [diferenciaLeche]);
  //Effect de ingreso aceptado
  useEffect(() => {
    console.log("cambio en ingreso aceptado" + ingresoAceptado);
  }, [ingresoAceptado]);
  //cambio en leche rechazada
  useEffect(() => {
    console.log("cambio encleche rechazada" + lecheRechazada);
    checkDiferenciaLeche();
  }, [lecheRechazada]);

  //____________________________________________________________________________________________
  //________________________Inicio del render___________________________________________________
  //__________________________________________________________________________________________

  return (
    <section className="h-full">
      <h1 className="text-5xl py-5">Realizar Ingreso de Ruta</h1>
      {!recorridosNoIngresados || recorridosNoIngresados.length===0 ? (
        <>
          <div className="alert alert-success">
            No existen recorridos sin ingresar para el centro de acopio
          </div>
        </>
      ) : (
        <>
          <label className="label " htmlFor="recorrido">
            Seleccione un recorrido
          </label>
          <select
            id="recorrido"
            className="select select-bordered"
            defaultValue={""}
            onChange={(ev) => {
              setselectedRecorrido(ev.target.value);
              setRecoNombre(ev.target.selectedOptions[0].text);
              getLecheCalculadaRecorrido(ev.target.value);

              findPruebasStage(ev.target.value);
              console.log("recoseleccionado" + ev.target.value);
            }}
          >
            <option value={"-1"} key={"-1"}>
              Seleccione un Recorrido
            </option>
            {!recorridosNoIngresados ? (
              <option value={"n/a"}>{"Cargando Recorridos"}</option>
            ) : (
              recorridosNoIngresados.map((recorrido) => (
                <option key={recorrido.id} value={recorrido.id}>
                  
                  {recorrido.ruta?.nombre}
                </option>
              ))
            )}
          </select>
          <p>Recorrido Seleccionado: {selectedRecorrido}</p>
          {/* Tarjeta que contiene los datos de la prueba */}
          {pruebaSelRecorrido && selectedRecorrido !== "-1" ? (
            <div className="card card-bordered shadow-xl my-5">
              <div className="card-body">
                <p className="card-title">Prueba guardada temporalmente</p>

                <p className="text-left">
                  <span className="font-bold">Acidez: </span>
                  {pruebaSelRecorrido.prueba.acidez}%
                </p>
                <p className="text-left">
                  <span className="font-bold">pH: </span>
                  {pruebaSelRecorrido.prueba.ph}%
                </p>
                <p className="text-left">
                  <span className="font-bold">Agua: </span>
                  {pruebaSelRecorrido.prueba.agua}%
                </p>
                <p className="text-left">
                  <span className="font-bold">Antibiótico: </span>
                  {pruebaSelRecorrido.prueba.antibiotico === true ? (
                    <span className="text-error">Positivo</span>
                  ) : (
                    <span className="text-success">Negativo</span>
                  )}
                </p>
                <p className="text-left">
                  <span className="font-bold">- Betalactámicos: </span>
                  {pruebaSelRecorrido.prueba.betalactamicos === true ? (
                    <span className="text-error">Positivo</span>
                  ) : (
                    <span className="text-success">Negativo</span>
                  )}
                </p>
                <p className="text-left">
                  <span className="font-bold">- Tetraciclinas: </span>
                  {pruebaSelRecorrido.prueba.tetraciclinas === true ? (
                    <span className="text-error">Positivo</span>
                  ) : (
                    <span className="text-success">Negativo</span>
                  )}
                </p>
                <p className="text-left">
                  <span className="font-bold">- Sulfamidas: </span>
                  {pruebaSelRecorrido.prueba.sulfamidas === true ? (
                    <span className="text-error">Positivo</span>
                  ) : (
                    <span className="text-success">Negativo</span>
                  )}
                </p>

                <p className="text-left">
                  <span className="font-bold">Proteína: </span>
                  {pruebaSelRecorrido.prueba.proteina}%
                </p>
                <p className="text-left">
                  <span className="font-bold">Grasa: </span>
                  {pruebaSelRecorrido.prueba.grasa}%
                </p>
                <p className="text-left">
                  <span className="font-bold">Sólidos: </span>
                  {pruebaSelRecorrido.prueba.solidos}%
                </p>
                
                <p className="text-left">
                  <span className="font-bold">Neutralizantes: </span>
                  {pruebaSelRecorrido.prueba.neutralizantes === true ? (
                    <span className="text-error">Positivo</span>
                  ) : (
                    <span className="text-success">Negativo</span>
                  )}
                </p>
                <p className="text-left">
                  <span className="font-bold">Adulterantes: </span>
                  {pruebaSelRecorrido.prueba.adulterantes === true ? (
                    <span className="text-error">Positivo</span>
                  ) : (
                    <span className="text-success">Negativo</span>
                  )}
                </p>
                <p className="text-left">
                  <span className="font-bold">Suero: </span>
                  {pruebaSelRecorrido.prueba.suero === true ? (
                    <span className="text-error">Positivo</span>
                  ) : (
                    <span className="text-success">Negativo</span>
                  )}
                </p>
                <p className="text-left">
                  <span className="font-bold">Densidad: </span>
                  {pruebaSelRecorrido.prueba.densidad}%
                </p>
                <p className="text-left">
                  <span className="font-bold">Obs. Generales: </span>
                  {pruebaSelRecorrido?.prueba?.obsGenerales}...
                </p>
                <div className="card-actions">
                  <button
                    className="btn btn-primary"
                    onClick={() => deletePruebasStage(pruebaSelRecorrido.id)}
                  >
                    Cambiar
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <>
              {selectedRecorrido && selectedRecorrido !== "-1" ? (
                <div>
                  <Prueba onPruebaChange={handlePruebaChange} />
                  <label
                    htmlFor="modal-prueba"
                    className="btn btn-accent"
                    
                  >
                    Agregar prueba temporalmente
                  </label>
                </div>
              ) : (
                <div className="alert alert-warning">
                  Seleccione un recorrido
                </div>
              )}
            </>
          )}
          {selectedRecorrido && selectedRecorrido !== "-1" ? (
            <>
              <h2 className=" text-3xl text-center font-semibold px-11">
                Datos del Ingreso
              </h2>
              <h3 htmlFor="datosIngreso" className="text text-xl">
                Ruta: {recoNombre}
              </h3>
              {!lecheCalculadaRecorrido ? (
                <label className="text text-error text-lg">
                  Seleccione un recorrido para calcular la cantidad de litros
                </label>
              ) : (
                <label htmlFor="cantidadCalculada" className="text text-xl">
                  Cantidad de leche Calculada:{" "}
                  <p className="font-bold text-success">
                    {lecheCalculadaRecorrido} Litros{" "}
                  </p>
                </label>
              )}

              <div className="card-body grid grid-cols-1 gap-2 justify-items-center ">
                

                <label className="label" htmlFor="cantLecheMedida">
                  Cantidad de leche aceptada
                </label>
                <input
                  id="cantLecheMedida"
                  type="number"
                  step={1}
                  min={0}
                  value={lecheMedida}
                  required
                  placeholder="Cantidad de leche aceptada"
                  className="input input-bordered input-md w-full max-w-xs"
                  onChange={(e) => setLecheMedida(parseInt(e.target.value))}
                  onBlur={(e) => checkDiferenciaLeche()}
                />
                <div className="grid grid-cols-1 gap-3 px-3 items-start ">
                  <label className="label " htmlFor="observacionesGenerales">
                    Observaciones del Ingreso
                  </label>
                </div>

                <textarea
                  id="observacionesGenerales"
                  type="text"
                  placeholder="Observaciones"
                  className="input input-bordered input-md w-full max-w-xs"
                  onChange={(e) => setObsGenerales(e.target.value)}
                />
                {!ingresoAceptado ? (
                  <>
                    <label className="label" htmlFor="cantLecheMedida">
                      Cantidad de leche con novedad
                    </label>
                    <input
                      id="cantLecheMedida"
                      type="number"
                      step={1}
                      min={0}
                      value={lecheRechazada}
                      required
                      placeholder="Cantidad de leche con novedad"
                      className="input input-bordered input-md w-full max-w-xs"
                      onChange={(e) =>
                        setLecheRechazada(parseInt(e.target.value))
                      }
                      onBlur={() => checkDiferenciaLeche()}
                    />
                  </>
                ) : (
                  <></>
                )}
                {<label>{ingresoAceptado}</label>}
                {diferenciaLeche >= 0 ? (
                  <label className="text text-success">
                    Hay una diferencia de {diferenciaLeche} Litros{" "}
                  </label>
                ) : (
                  <label className="text text-error">
                    Hay una diferencia de {diferenciaLeche} Litros
                  </label>
                )}

                <label className="label" htmlFor="observacionesGenerales">
                  Ingreso aceptado?
                </label>

                <div className="flex ">
                  <label className="label-text">No</label>
                  <input
                    type="checkbox"
                    className="toggle toggle-lg toggle-accent"
                    value={ingresoAceptado}
                    defaultChecked={"true"}
                    onClick={() => {
                      setIngresoAceptado((prevCheck) => !prevCheck);
                      setLecheRechazada(0);
                      checkDiferenciaLeche();
                    }}
                  />
                  <label className="label-text">Sí</label>
                </div>
                <label htmlFor="modal-confirm" className="btn btn-active">
                  Agregar ingreso
                </label>
              </div>
            </>
          ) : (
            <></>
          )}

          {/* modal de confirmar ingreso*/}

          <input type="checkbox" id="modal-confirm" className="modal-toggle" />
          <div className="modal">
            <div className="modal-box ">
              <label
                htmlFor="modal-confirm"
                className="btn btn-sm btn-circle absolute right-2 top-2"
              >
                ✕
              </label>
              <h3 className="text-lg font-bold">Aviso</h3>
              <p className="py-4">
                Para guardar el ingreso, continúe con sus credenciales
              </p>
              <form className="form  grid grid-cols-1">
                <label htmlFor="usuario" className="label">
                  Usuario
                </label>
                <input
                  type={"text"}
                  className="input input-bordered"
                  placeholder="Usuario"
                  onChange={(e) => setUsr(e.target.value)}
                  defaultValue={usr}
                ></input>
                <label htmlFor="password" className="label">
                  Contraseña
                </label>
                <input
                  type={"password"}
                  className="input input-bordered"
                  placeholder="Contraseña"
                  onChange={(e) => {
                    setPwd(e.target.value);
                  }}
                  defaultValue={pwd}
                ></input>
              </form>

              <div className="modal-action">
                <label
                  htmlFor="modal-confirm"
                  onClick={() => postData()}
                  className="btn btn-accent"
                >
                  Guardar
                </label>
                <label htmlFor="modal-confirm" className="btn">
                  Cancelar
                </label>
              </div>
              <div className="modal-action"></div>
            </div>
          </div>

          {/* modal de confirmar prueba*/}

          <input type="checkbox" id="modal-prueba" className="modal-toggle" />
          <div className="modal">
            <div className="modal-box ">
              <label
                htmlFor="modal-prueba"
                className="btn btn-sm btn-circle absolute right-2 top-2"
              >
                ✕
              </label>
              <h3 className="text-lg font-bold">Guardado de prueba temporal</h3>
              <p className="py-4">
                Verifique que la prueba esté completa y acceda con sus
                credenciales.
              </p>
              <h3 className="card-title">Datos de la prueba</h3>

              <p className="text-left">
                <span className="font-bold">Acidez: </span>
                {prueba.acidez}%
              </p>
              <p className="text-left">
                <span className="font-bold">pH: </span>
                {prueba.ph}%
              </p>
              <p className="text-left">
                <span className="font-bold">Agua: </span>
                {prueba.agua}%
              </p>
              <p className="text-left">
                <span className="font-bold">Antibiótico: </span>
                {prueba.antibiotico === true ? (
                  <span className="text-error">Positivo</span>
                ) : (
                  <span className="text-success">Negativo</span>
                )}
              </p>
              <p className="text-left">
                <span className="font-bold">- Betalactámicos: </span>
                {prueba.betalactamicos === true ? (
                  <span className="text-error">Positivo</span>
                ) : (
                  <span className="text-success">Negativo</span>
                )}
              </p>
              <p className="text-left">
                <span className="font-bold">- Tetraciclinas: </span>
                {prueba.tetraciclinas === true ? (
                  <span className="text-error">Positivo</span>
                ) : (
                  <span className="text-success">Negativo</span>
                )}
              </p>
              <p className="text-left">
                <span className="font-bold">- Sulfamidas: </span>
                {prueba.sulfamidas === true ? (
                  <span className="text-error">Positivo</span>
                ) : (
                  <span className="text-success">Negativo</span>
                )}
              </p>

              <p className="text-left">
                <span className="font-bold">Proteína: </span>
                {prueba.proteina}%
              </p>
              <p className="text-left">
                <span className="font-bold">Grasa: </span>
                {prueba.grasa}%
              </p>
              <p className="text-left">
                <span className="font-bold">Sólidos: </span>
                {prueba.solidos}%
              </p>
              <p className="text-left">
                <span className="font-bold">Proteína: </span>
                {prueba.proteina}%
              </p>
              <p className="text-left">
                <span className="font-bold">Neutralizantes: </span>
                {prueba.neutralizantes === true ? (
                  <span className="text-error">Positivo</span>
                ) : (
                  <span className="text-success">Negativo</span>
                )}
              </p>
              <p className="text-left">
                <span className="font-bold">Adulterantes: </span>
                {prueba.adulterantes === true ? (
                  <span className="text-error">Positivo</span>
                ) : (
                  <span className="text-success">Negativo</span>
                )}
              </p>
              <p className="text-left">
                <span className="font-bold">Suero: </span>
                {prueba.suero === true ? (
                  <span className="text-error">Positivo</span>
                ) : (
                  <span className="text-success">Negativo</span>
                )}
              </p>
              <p className="text-left">
                <span className="font-bold">Densidad: </span>
                {prueba.densidad}%
              </p>
              <p className="text-left">
                <span className="font-bold">Obs. Generales: </span>
                {prueba?.obsGenerales}...
              </p>

              <form className="form  grid grid-cols-1">
                <label htmlFor="usuario" className="label">
                  Usuario
                </label>
                <input
                  type={"text"}
                  className="input input-bordered"
                  placeholder="Usuario"
                  onChange={(e) => setUsr(e.target.value)}
                  defaultValue={usr}
                ></input>
                <label htmlFor="password" className="label">
                  Contraseña
                </label>
                <input
                  type={"password"}
                  className="input input-bordered"
                  placeholder="Contraseña"
                  onChange={(e) => {
                    setPwd(e.target.value);
                  }}
                  defaultValue={pwd}
                ></input>
              </form>

              <div className="modal-action">
                <label
                  htmlFor="modal-prueba"
                  onClick={() => {
                    checkPrueba();
                    findPruebasStage(selectedRecorrido);
                  }}
                  className="btn btn-accent"
                >
                  Guardar
                </label>
                <label htmlFor="modal-prueba" className="btn">
                  Cancelar
                </label>
              </div>
              <div className="modal-action"></div>
            </div>
          </div>
        </>
      )}

      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </section>
  );
}
export default CreateIngreso;
