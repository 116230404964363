import React, { useEffect, useRef, useState } from "react";
import { makeSafeGetRequest } from "../utils/Requests";
import { getEmpresaAdmin } from "../../api/RoleInfo";
import useAuth from "../../hooks/useAuth";

function ReporteDevoluciones() {
  const { auth } = useAuth();
  const [fechaInicial, setFechaInicial] = useState();
  const [fechaFinal, setFechaFinal] = useState();
  const [devoluciones, setDevoluciones] = useState();
  const [idEmpresa, setIdEmpresa] = useState();
  const [nombreEmpresa, setNombreEmpresa] = useState();
  //useRef tabla
  const tableRef = useRef(null);
  //Obtiene el id de la empresa
  const getIdEmpresa = async () => {
    try {

      const response = await getEmpresaAdmin(auth);
      if (response) {
        console.log("encontro datos de la empresa" + JSON.stringify(response));
        setIdEmpresa(response.empresa?.id);
        setNombreEmpresa(response.empresa?.nombre);
      }
    } catch (error) {
      console.log("Error al obtener los datos de la empresa");
    }
  };

  //obtiene la lista de devoluciones de la empresa en una fecha determinada
  const getDevoluciones = async () => {
    try {
      const response = await makeSafeGetRequest(
        "devolucionesempresa?idempresa=" +
          idEmpresa +
          "&fechainicial=" +
          fechaInicial +
          "&fechafinal=" +
          fechaFinal,
        auth.accessToken
      );
      if (response) {
        setDevoluciones(response.data);
      }
    } catch (error) {
      console.log("Error al obtener las devoluciones" + error);
      setDevoluciones();
    }
  };
  //
  useEffect(() => {
    getIdEmpresa();
  }, []);
  return (
    <div className="h-full">
      <div className="grid grid-cols-1 max-w-lg">
        <h2 className="text-3xl">Reporte Devoluciones Empresa</h2>
        <label className="label">Empresa: {nombreEmpresa}</label>
        <label className="label">Fecha Inicial</label>
        <input
          type="date"
          className="input input-bordered"
          onChange={(e) => {
            setFechaInicial(e.target.value);
          }}
        ></input>
        <label className="label">Fecha Final</label>
        <input
          type="date"
          className="input input-bordered"
          onChange={(e) => {
            setFechaFinal(e.target.value);
          }}
        ></input>
        <div className="flex justify-evenly my-5">
          <button className="btn btn-accent" onClick={getDevoluciones}>
            Generar reporte
          </button>
          <button className=" btn btn-active">Exportar Excel</button>
        </div>
        <div className="overflow-x-auto">
          <table className="table table-compact">
            {devoluciones && devoluciones.length > 0 ? (
              <>
                <thead>
                  <tr>
                    <th colSpan={7}>Datos de la Devolución</th>
                    <th colSpan={11}>Datos de la Prueba</th>
                  </tr>
                  <tr>
                    <th>Fecha</th>
                    <th>Hora</th>
                    <th>Cliente</th>
                    <th>Cantidad</th>
                    <th>Es Novedad</th>
                    <th>Observaciones</th>
                    <th>Acopio</th>
                    <th>Acidez</th>
                    <th>pH</th>
                    <th>Agua</th>
                    <th>Proteína</th>
                    <th>Grasa</th>
                    <th>Sólidos</th>
                    <th>Densidad</th>
                    <th>Antibiótico</th>
                    <th>Alcohol</th>
                    <th>Neutralizantes</th>
                    <th>Adulterantes</th>
                    <th>Firma</th>
                  </tr>
                </thead>
                <tbody>
                  {devoluciones.map((devolucion) => (
                    <tr key={devolucion.id}>
                      <td>{devolucion?.fecha}</td>
                      <td>{devolucion?.hora.split(".")[0]}</td>
                      <td>{devolucion?.cliente?.nombre}</td>
                      <td>{devolucion?.cantidad}</td>
                      <td>
                        {devolucion?.devolucion_novedad === true ? (
                          <span className="text-error">SI</span>
                        ) : (
                          <span className="text-success">NO</span>
                        )}
                      </td>
                      <td>{devolucion?.observaciones}</td>
                      <td>{devolucion?.acopio?.nombre}</td>
                      <td>{devolucion?.prueba?.acidez}</td>
                      <td>{devolucion?.prueba?.ph}</td>
                      <td>{devolucion?.prueba?.agua}</td>
                      <td>{devolucion?.prueba?.proteina}</td>
                      <td>{devolucion?.prueba?.grasa}</td>
                      <td>{devolucion?.prueba?.solidos}</td>
                      <td>{devolucion?.prueba?.densidad}</td>
                      <td>
                        {devolucion?.prueba?.antibiotico === true ? (
                          <span className="text-error">Positivo</span>
                        ) : (
                          <span className="text-success">Negativo</span>
                        )}
                      </td>
                      <td>
                        {devolucion?.prueba?.alcohol === true ? (
                          <span className="text-error">Positivo</span>
                        ) : (
                          <span className="text-success">Negativo</span>
                        )}
                      </td>
                      <td>
                        {devolucion?.prueba?.neutralizantes === true ? (
                          <span className="text-error">Positivo</span>
                        ) : (
                          <span className="text-success">Negativo</span>
                        )}
                      </td>
                      <td>
                        {devolucion?.prueba?.adulterantes === true ? (
                          <span className="text-error">Positivo</span>
                        ) : (
                          <span className="text-success">Negativo</span>
                        )}
                      </td>
                      <td>{devolucion?.prueba?.operario?.nombre_corto}</td>
                    </tr>
                  ))}
                </tbody>
              </>
            ) : (
              <></>
            )}
          </table>
        </div>
      </div>
    </div>
  );
}

export default ReporteDevoluciones;
