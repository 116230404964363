import React, { useEffect, useRef, useState } from "react";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { getEmpresaAdmin } from "../api/RoleInfo";
import useAuth from "../hooks/useAuth";
import { makeGetRequest } from "./utils/Requests";


function ReporteIngresosFechaEmpresa() {
  
  const {auth}= useAuth();
  const [fechaInicial, setFechaInicial] = useState();
  const [fechaFinal, setFechaFinal] = useState();
  const [ingresos, setIngresos] = useState();
  const [idEmpresa, setIdEmpresa]= useState();
  const [nombreEmpresa, setNombreEmpresa]= useState();
  //useRef tabla
  const tableRef = useRef(null);
  //Obtiene el id de la empresa
  const getIdEmpresa = async () => {
    try {
      const response = await getEmpresaAdmin(auth);
      if (response) {
        console.log("encontro datos de la empresa" + JSON.stringify(response));
        setIdEmpresa(response.empresa?.id);
        setNombreEmpresa(response.empresa?.nombre);
      }
    } catch (error) {
      console.log("Error al obtener los datos de la empresa");
    }
  };
  //obtiene los ingresos de una ruta en un rango de fechas
  const getIngresosFechaEmpresa = async () => {
    try {
      console.log("Ingresando al try");
      const myUrl =
        
        "ingresosporfechaempresa?idempresa=" +
        idEmpresa +
        "&fechainicial=" +
        fechaInicial +
        "&fechafinal=" +
        fechaFinal;
      const response = await makeGetRequest(myUrl);
      
      if (response.status === 200) {
        if (response.data.length > 0) {
          console.log(
            "Ingresos en rango de fechas por empresa" + JSON.stringify(response.data)
          );
          setIngresos(response.data);
        } else {
          setIngresos();
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  //Effect al iniciar
  useEffect(() => {
    getIdEmpresa();
  }, []);
 
  //effect al cambio del state de ingresos ruta seleccionada
  useEffect(() => {
    console.log("ingreso cambio:" + JSON.stringify(ingresos));
  }, [ingresos]);
  //useEffect cambio fechas
  useEffect(() => {
    console.log("cambio en fechas");
  }, [fechaInicial, fechaFinal]);
  return (
    <div className="flex flex-col h-full">
      <label className="label" htmlFor="rutas">
        Empresa: {nombreEmpresa}
      </label>
      
      <label className="label" htmlFor="fechainicial">
        Fecha Inicial
      </label>
      <input
        type={"date"}
        className={"input input-bordered"}
        id="fechainicial"
        onChange={(e) => {
          setFechaInicial(e.target.value);
        }}
      ></input>
      <label className="label" htmlFor="fechafinal  ">
        Fecha final
      </label>
      <input
        type={"date"}
        className={"input input-bordered"}
        id="fechafinal"
        onChange={(e) => {
          setFechaFinal(e.target.value);
        }}
      ></input>
      <button
        className="btn btn-accent my-2"
        onClick={(e) => {
          getIngresosFechaEmpresa();
          console.log("probando boton");
        }}
      >
        Generar reporte
      </button>
      {ingresos ? (
        <DownloadTableExcel
          filename={
            "ingresos" +
            nombreEmpresa +
            fechaInicial +
            "_" +
            fechaFinal
          }
          sheet={"ingreso por dias "+nombreEmpresa}
          currentTableRef={tableRef.current}
        >
          <button className="btn btn-active mb-10 mt-3">Exportar excel</button>
        </DownloadTableExcel>
      ) : (
        <></>
      )}
      <div className="overflow-x-auto overflow-y-auto">
      <table className="table table-compact" ref={tableRef}>
        {!ingresos ? (
          <tbody>
            <tr>
              <td>No hay Ingresos en el rango de fecha seleccionado</td>
            </tr>
          </tbody>
        ) : (
          <>
            <thead>
              <tr>
                <th colSpan={7}>Empresa:{nombreEmpresa}</th>
              </tr>
              <tr>
                <th>ACOPIO</th>
                <th>RUTA</th>
                <th>FECHA</th>
                <th>LITROS ACOPIO</th>
                <th>LITROS NOVEDAD</th>
                <th>LITROS RUTA</th>
                <th>DIFERENCIA</th>
              </tr>
            </thead>
            <tbody>
              {ingresos?.map((ingreso) => (
                <tr key={ingreso.id}>
                  <td>{ingreso?.recorrido?.ruta?.acopio.nombre}</td>
                  <td>{ingreso?.recorrido?.ruta?.nombre}</td>
                  <td>{ingreso.fecha.split("T", [1])}</td>
                  <td>{ingreso.cantidad_medida}</td>
                  <td>{ingreso?.cantidad_rechazada}</td>
                  <td>{ingreso.cantidad_leche}</td>
                  <td>
                    {ingreso.diferencia >= 0 ? (
                      <span className="text-success">{ingreso.diferencia}</span>
                    ) : (
                      <span className="text-error">{ingreso.diferencia}</span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </>
        )}
      </table>
      </div>
      
    </div>
  );
}

export default ReporteIngresosFechaEmpresa;
