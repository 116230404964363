import React, { useEffect, useState } from "react";
import {
  createSafeUser,
  makeGetRequest,
  makePostRequest,
  makeSafeGetRequest,
  makeSafePostRequest,
} from "./utils/Requests";
import { notifyPendingNotify, popNotification } from "./utils/Notify";
import { ToastContainer } from "react-toastify";
import useAuth from "../hooks/useAuth";
import { getEmpresaAdmin } from "../api/RoleInfo";

function CreateUser() {
  const URL = process.env.REACT_APP_BACKEND_BASE_URL;
  const { auth } = useAuth();
  const [idEmpresa, setIdEmpresa] = useState(0);
  const [nombreEmpresa, setNombreEmpresa] = useState();
  const [bancos, setBancos] = useState();
  const [rutas, setRutas] = useState();
  const [acopios, setAcopios] = useState();
  const [selAcopio, setSelAcopio] = useState();
  const [selectedRutas, setSelectedRutas] = useState([]);
  const [tipoUsuario, setTipoUsuario] = useState();
  const [activo, setActivo] = useState(true);
  const [tipoDoc, setTipoDoc] = useState();
  const [usuario, setUsuario] = useState();
  const [password, setPass] = useState();
  const [numDoc, setNumDoc] = useState();
  const [primerNombre, setPrimerNombre] = useState("");
  const [segundoNombre, setSegundoNombre] = useState("");
  const [primerApellido, setPrimerApellido] = useState("");
  const [segundoApellido, setSegundoApellido] = useState("");
  const [fechaNacimiento, setFechaNacimiento] = useState();
  const [lugarNacimiento, setLugarNacimiento] = useState();
  const [fechaExpedicion, setFechaExpedicion] = useState();
  const [lugarExpedicion, setLugarExpedicion] = useState();
  const [departamento, setDepartamento] = useState();
  const [municipio, setMunicipio] = useState();
  const [direccion, setDireccion] = useState();
  const [vereda, setVereda] = useState();
  const [celular, setCelular] = useState();
  const [telefono, setTelefono] = useState();
  const [telefono2, setTelefono2] = useState();
  const [correo, setCorreo] = useState();
  const [selectedEstado, setSelectedEstado] = useState();
  const [selectedBanco, setSelectedBanco] = useState();
  const [tipoPago, setTipoPago] = useState();
  const [tipoCuenta, setTipoCuenta] = useState();
  const [nombreTitular, setNombreTitular] = useState();
  const [docTitular, setDocTitular] = useState();
  const [numCuenta, setNumCuenta] = useState();
  //Obtiene el id de la empresa
  const getIdEmpresa = async () => {
    try {
      const response = await getEmpresaAdmin(auth);
      if (response) {
        console.log("encontro datos de la empresa" + JSON.stringify(response));
        setIdEmpresa(response.empresa?.id);
        setNombreEmpresa(response.empresa?.nombre);
      }
    } catch (error) {
      console.log("Error al obtener los datos de la empresa");
    }
  };

  //OBTIENE LA LISTA DE RUTAS DE LA EMPRESA
  const getRutas = async (idAcopio) => {
    try {
      const response = await makeGetRequest("rutasacopio/" + idAcopio);
      if (response) {
        setRutas(response.data);
      }
    } catch (error) {
      console.log("Error al obtener rutas:" + error);
    }
  };

  //OBTIENE LA LISTA DE BANCOS REGISTRADOS EN LA EMPRESA
  const getBancos = async () => {
    try {
      const response = await makeGetRequest("bancos");
      if (response) {
        console.log(JSON.stringify(response));
        setBancos(response.data.data);
      }
    } catch (error) {
      console.log("Error al obtener los bancos:" + error);
    }
  };
  //OBTIENE LA LISTA DE ACOPIOS REGISTRADOS EN LA EMPRESA
  const getAcopios = async () => {
    try {
      const response = await makeSafeGetRequest(
        "acopiosempresa/" + idEmpresa,
        auth.accessToken
      );
      if (response) {
        console.log(JSON.stringify(response));
        setAcopios(response.data);
      }
    } catch (error) {
      console.log("Error al obtener los Acopios:" + error);
    }
  };
  //Crea un usuario en el sistema
  const postUser = async (rolUsuario) => {
    try {
      const notify = notifyPendingNotify();
      console.log(
        usuario + "," + password + "," + correo + "\n" + auth.accessToken
      );

      if (usuario !== "" && password !== "" && correo !== "") {
        console.log("Entra en el request");
        const data = {
          username: usuario,
          password: password,
          email: correo,
          confirmed: activo,
          role: rolUsuario,
          empresa: idEmpresa,
        };
        console.log(JSON.stringify(data));
        const response = await createSafeUser("users", data, auth.accessToken);
        if (response) {
          popNotification(notify, "success", "Usuario creado correctamente.");
          return response;
        } else {
          popNotification(notify, "error", "El usuario o contraseña ya están registrados en el sistema. Intente con otros");
          return null;
        }
      } else {
        console.log("nuentra");
      }
    } catch (error) {}
  };
  //Postea la información del usuario registrada en el formulario.
  const postData = async () => {
    try {
      let data = {
        data: {
          numero_documento: numDoc,
          nombre_corto:
            primerNombre + " " +
            segundoNombre +" " +
            primerApellido +" " +
            segundoApellido,
          primer_nombre: primerNombre,
          segundo_nombre: segundoNombre,
          primer_apellido: primerApellido,
          segundo_apellido: primerApellido,
          numero_documento:numDoc,
          tipo_documento:tipoDoc,
          fecha_nacimiento: fechaNacimiento,
          lugar_nacimiento: lugarNacimiento,
          fecha_expedicion: fechaExpedicion,
          lugar_expedicion: lugarExpedicion,
          departamento: departamento,
          municipio: municipio,
          vereda:vereda,
          direccion: direccion,
          telefono: telefono,
          telefono_2: telefono2,
          celular:celular,
          correo: correo,
          estado:selectedEstado,
          banco:selectedBanco,
          tipo_pago:tipoPago,
          tipo_cuenta:tipoCuenta,
          nombre_titular:nombreTitular,
          documento_titular:docTitular,
          numero_cuenta:numCuenta,
          activo:activo,
          empresa:idEmpresa,
          rutas:{...selectedRutas}
        },
      };
      console.log("tipo de usuario" + tipoUsuario);
      switch (tipoUsuario) {
        case "1":
          console.log("Caso Operario");
          break;
        case "2":
          //Primero 3en vconstruirse por su simplicidad
          console.log("Caso Recolector");
          const user=await postUser(5);
          console.log("usuario "+JSON.stringify(user))
          await postRecolector(user,data);
          break;
        case "3":
          console.log("Caso Productor");
          data.data.rutas = selectedRutas;
          break;
        case "4":
          console.log("Caso administrador");
          break;
        default:
          console.log("Caso Defecto");

          break;
      }
      // const response = null; await makePostRequest("user", data);
      // if (response) {
      //   popNotification(notify, "success", "Usuario creado correctamente");
      // } else {
      //   popNotification(notify, "error", "Error al crear usuario");
      // }
    } catch (error) {
      console.log("Error:" + console.log(error));
    }
  };
  //Cuando se seleccione el tipo de usuario recolector, creará uno y lo asociará al usuario creado
  const postRecolector=async(user,data)=>{
    try {
      const idUser=user.id;
      data["users_permissions_user"]=idUser;
      console.log("datos a postear en recolector"+JSON.stringify(data))
      const response= await makeSafePostRequest("recolectors",data,auth.accessToken);
      if(response){
        console.log("recolector guardado"+JSON.stringify(response))
      }
    } catch (error) {
      
    }
  }
  //Determina lo que pasa cuando se selecciona un acopio
  const onAcopioChange = async (e) => {
    setSelAcopio(e.target.value);
    await getRutas(e.target.value);
  };
  //Agrega una ruta a la lista SI AÚN NO EXISTE
  const onSelectedRuta = (e) => {
    const idRuta = e.target.value;
    console.log("Entrando a seleccion de ruta" + e.target.value);
    if (idRuta !== "-1" && !rutaExists(idRuta)) {
      console.log("hey probando");
      let ruta = {
        id: parseInt(e.target.value),
        nombre: e.target.selectedOptions[0].text,
      };
      setSelectedRutas([...selectedRutas, ruta]);
    }
  };
  //Verifica si una ruta ya existe en el array de rutas seleccionadas
  const rutaExists = (idRuta) => {
    const exists = selectedRutas.some((r) => r.id === idRuta);
    console.log(exists);
    return exists;
  };
  //Borra una ruta de la lista
  const deleteSelRuta = (idRuta) => {
    const rutas = selectedRutas.filter((ruta) => ruta.id !== idRuta);
    console.log("borrando ruta:" + typeof idRuta);
    setSelectedRutas(rutas);
  };
  //UseEffect inicial
  useEffect(() => {
    getIdEmpresa();
    getBancos();
  }, []);
  //Cambio en empresa
  useEffect(() => {
    if (idEmpresa) {
      getAcopios();
    }
  }, [idEmpresa]);
  //Cambio en bancos
  useEffect(() => {
    console.log("cambio en bancos" + JSON.stringify(bancos));
  }, [bancos]);
  //cambio en rol
  useEffect(() => {
    console.log("cambio en Rol seleccionado" + tipoUsuario);
  }, [tipoUsuario]);
  //Cambio en estado de acopio
  useEffect(() => {
    console.log("Cambio en acopio seleccionado" + selAcopio);
  }, [selAcopio]);
  //Cambio en rutas seleccionadas
  useEffect(() => {
    console.log("cambio Rutas seleccionadas:" + JSON.stringify(selectedRutas));
  }, [selectedRutas]);
  return (
    <div className="section h-full py-5 mx-2">
      <h1 className="text text-3xl mb-3">Crear Usuario</h1>
      <div className="grid grid-cols-1  py-3">
        <h3 className="text-xl text-left font-bold">Datos del Usuario</h3>
        <h6 className="text-start">Empresa: {nombreEmpresa}</h6>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 px-5">
          <div className="flex flex-col">
            <label className="label">Tipo de usuario</label>
            <select
              className="select select-bordered"
              onChange={(e) => setTipoUsuario(e.target.value)}
            >
              <option key={-1} value={-1}>
                Seleccione un rol de Usuario
              </option>
              <option key={1} value={1}>
                Operario
              </option>
              <option key={2} value={2}>
                Recolector
              </option>
              <option key={3} value={3}>
                Productor
              </option>
              <option key={4} value={4}>
                Administrador
              </option>
            </select>
          </div>
          <div className="flex flex-col">
            <label className="label">Acopios </label>
            <select
              className="select select-bordered"
              onChange={(e) => {
                onAcopioChange(e);
              }}
            >
              <option key="-1" value={"-1"}>
                Seleccione un Acopio
              </option>
              {acopios ? (
                acopios.map((acopio) => (
                  <option key={acopio.id} value={acopio.id}>
                    {acopio.nombre}
                  </option>
                ))
              ) : (
                <option>Cargando acopios</option>
              )}
            </select>
          </div>
          <div className="flex flex-col">
            <label className="label">Ruta(s) a las que pertenece</label>
            <select
              className="select select-bordered"
              onChange={(e) => onSelectedRuta(e)}
            >
              <option key={"-1"} value={"-1"}>
                Seleccione una ruta
              </option>
              {!rutas ? (
                <option key={"n/a"} value={"n/a"}>
                  Cargando Rutas
                </option>
              ) : (
                rutas.map((ruta) => (
                  <option key={ruta.id} value={ruta.id}>
                    {ruta?.nombre}
                  </option>
                ))
              )}
            </select>
            <div className="flex m-2 flex-wrap">
              {selectedRutas ? (
                selectedRutas.map((r) => (
                  <div className="badge badge-lg m-1" key={r.id}>
                    {r.nombre}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                      onClick={() => deleteSelRuta(r.id)}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </div>
                ))
              ) : (
                <>Seleccione una ruta</>
              )}
            </div>
          </div>
          <div className="flex flex-col">
            <label className="label">Activo?</label>
            <div className="flex justify-around">
              <label className="label">No</label>
              <input
                type={"checkbox"}
                className="toggle toggle-accent"
                onChange={(e) => {
                  setActivo();
                }}
              ></input>
              <label className="label">Sí</label>
            </div>
          </div>

          <div className="flex flex-col">
            <label className="label">Nombre de Usuario</label>
            <input
              type={"text"}
              className="input input-bordered "
              onChange={(e) => {
                setUsuario(e.target.value);
              }}
            ></input>
          </div>
          <div className="flex flex-col">
            <label className="label">Contraseña</label>
            <input
              type={"password"}
              className="input input-bordered "
              onChange={(e) => {
                setPass(e.target.value);
              }}
            ></input>
          </div>
        </div>
        {/* Datos personales */}
        <h3 className="text-xl text-left font-bold">Datos Personales</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 px-5">
          <div className="flex flex-col">
            <label className="label">Tipo de Documento</label>
            <select
              className="select select-bordered"
              onChange={(e) => setTipoDoc(e.target.value)}
            >
              <option key={-1} value={-1}>
                Seleccione un Tipo de Documento
              </option>
              <option key={1} value={1}>
                Nit
              </option>
              <option key={2} value={2}>
                Cédula de Ciudadanía
              </option>
              <option key={3} value={3}>
                Cédula de Extranjería
              </option>
              <option key={4} value={4}>
                Tarjeta de Identidad
              </option>
              <option key={5} value={5}>
                Pasaporte
              </option>
            </select>
          </div>

          <div className="flex flex-col">
            <label className="label">Número de Documento</label>
            <input
              type={"number"}
              className="input input-bordered "
              min={0}
              onChange={(e) => {
                setNumDoc(e.target.value);
              }}
            ></input>
          </div>
          <div className="flex flex-col">
            <label className="label">Primer Nombre</label>
            <input
              type={"text"}
              className="input input-bordered"
              onChange={(e) => {
                setPrimerNombre(e.target.value);
              }}
            ></input>
          </div>
          <div className="flex flex-col">
            <label className="label">Segundo Nombre</label>
            <input
              type={"text"}
              className="input input-bordered"
              onChange={(e) => {
                setSegundoNombre(e.target.value);
              }}
            ></input>
          </div>
          <div className="flex flex-col">
            <label className="label">Primer Apellido</label>
            <input
              type={"text"}
              className="input input-bordered"
              onChange={(e) => {
                setPrimerApellido(e.target.value);
              }}
            ></input>
          </div>
          <div className="flex flex-col">
            <label className="label">Segundo Apellido</label>
            <input
              type={"text"}
              className="input input-bordered"
              onChange={(e) => {
                setSegundoApellido(e.target.value);
              }}
            ></input>
          </div>

          <div className="flex flex-col">
            <label className="label">Nombre Corto</label>
            <input
              type={"text"}
              className="input input-bordered"
              onChange={(e) => {
                setSegundoApellido(e.target.value);
              }}
            ></input>
          </div>
          <div></div>
          <div className="flex flex-col">
            <label className="label">Lugar de Nacimiento</label>
            <input
              type={"text"}
              className="input input-bordered"
              onChange={(e) => {
                setLugarNacimiento(e.target.value);
              }}
            ></input>
          </div>
          <div className="flex flex-col">
            <label className="label">Fecha de Nacimiento (Opcional)</label>
            <input
              type={"date"}
              className="input input-bordered"
              onChange={(e) => {
                setFechaExpedicion(e.target.value);
              }}
            ></input>
          </div>
          <div className="flex flex-col">
            <label className="label">Lugar de Expedición del Documento </label>
            <input
              type={"text"}
              className="input input-bordered"
              onChange={(e) => {
                setLugarExpedicion(e.target.value);
              }}
            ></input>
          </div>
          <div className="flex flex-col">
            <label className="label">Fecha de Expedición</label>
            <input
              type={"date"}
              className="input input-bordered"
              onChange={(e) => {
                setFechaNacimiento(e.target.value);
              }}
            ></input>
          </div>
        </div>
        {/* Datos de contacto */}
        <h3 className="text-xl text-left font-bold my-5">Datos de Contacto</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 px-5">
          <div className="flex flex-col">
            <label className="label">Departamento de Residencia</label>
            <input
              type={"text"}
              className="input input-bordered"
              onChange={(e) => {
                setDepartamento(e.target.value);
              }}
            ></input>
          </div>

          <div className="flex flex-col">
            <label className="label">Municipio de Residencia</label>
            <input
              type={"text"}
              className="input input-bordered"
              onChange={(e) => {
                setMunicipio(e.target.value);
              }}
            ></input>
          </div>
          <div className="flex flex-col">
            <label className="label">Vereda</label>
            <input
              type={"text"}
              className="input input-bordered"
              onChange={(e) => {
                setVereda(e.target.value);
              }}
            ></input>
          </div>
          <div className="flex flex-col">
            <label className="label">Dirección /Nombre Finca</label>
            <input
              type={"text"}
              className="input input-bordered"
              onChange={(e) => {
                setDireccion(e.target.value);
              }}
            ></input>
          </div>
          <div className="flex flex-col">
            <label className="label">Teléfono</label>
            <input
              type={"text"}
              className="input input-bordered"
              onChange={(e) => {
                setTelefono(e.target.value);
              }}
            ></input>
          </div>
          <div className="flex flex-col">
            <label className="label">Teléfono 2</label>
            <input
              type={"text"}
              className="input input-bordered"
              onChange={(e) => {
                setTelefono2(e.target.value);
              }}
            ></input>
          </div>
          <div className="flex flex-col">
            <label className="label">Celular</label>
            <input
              type={"text"}
              className="input input-bordered"
              onChange={(e) => {
                setCelular(e.target.value);
              }}
            ></input>
          </div>
          <div className="flex flex-col">
            <label className="label">Correo</label>
            <input
              type={"email"}
              className="input input-bordered"
              onChange={(e) => {
                setCorreo(e.target.value);
              }}
            ></input>
          </div>
        </div>

        {/* Datos de cuenta */}
        <h3 className="text-xl text-left font-bold my-5">Datos de Cuenta</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 px-5">
          <div className="flex flex-col">
            <label className="label">Estado</label>
            <select
              className="select select-bordered"
              onChange={(e) => setSelectedEstado(e.target.value)}
            >
              <option key={"-1"} value={"-1"}>
                Seleccione un estado
              </option>
              <option key={"AS"} value={"AS"}>
                Asociado
              </option>
              <option key={"AF"} value={"AF"}>
                Afiliado
              </option>
            </select>
          </div>
          <div className="flex flex-col">
            <label className="label">Tipo de Pago</label>
            <select
              type={"number"}
              className="select select-bordered"
              onChange={(e) => {
                setTipoPago(e.target.value);
              }}
            >
              <option key={"-1"} value={"-1"}>
                Seleccione un Tipo de Pago
              </option>
              <option key={"EFECTIVO"} value={"EFECTIVO"}>
                Efectivo
              </option>
              <option key={"CONSIGNACION"} value={"CONSIGNACION"}>
                Consignación
              </option>
              <option key={"OTROS BANCOS"} value={"OTROS BANCOS"}>
                Otros Bancos
              </option>
            </select>
          </div>
          <div className="flex flex-col">
            <label className="label">Tipo de Cuenta</label>
            <select
              className="select select-bordered"
              onChange={(e) => {
                setTipoCuenta(e.target.value);
              }}
            >
              <option key={"-1"} value={"-1"}>
                Seleccione un Tipo de Cuenta
              </option>
              <option key={"AHORROS"} value={"AHORROS"}>
                Ahorros
              </option>
              <option key={"CORRIENTE"} value={"CORRIENTE"}>
                Corriente
              </option>
            </select>
          </div>
          <div className="flex flex-col">
            <label className="label">Banco</label>
            <select
              className="select select-bordered"
              onChange={(e) => setSelectedBanco(e.target.value)}
            >
              <option key={-1} value={-1}>
                Seleccione un banco
              </option>
              {!bancos ? (
                <option key={"n/a"} value={"n/a"}>
                  Cargando Bancos
                </option>
              ) : (
                bancos.map((banco) => (
                  <option key={banco.id} value={banco.id}>
                    {banco?.attributes?.nombre_banco}
                  </option>
                ))
              )}
            </select>
          </div>

          <div className="flex flex-col">
            <label className="label">Nombre del Titular</label>
            <input
              type={"text"}
              className="input input-bordered"
              onChange={(e) => {
                setNombreTitular(e.target.value);
              }}
            ></input>
          </div>
          <div className="flex flex-col">
            <label className="label">Documento Titular</label>
            <input
              type={"number"}
              min={1}
              className="input input-bordered"
              onChange={(e) => {
                setDocTitular(e.target.value);
              }}
            ></input>
          </div>
          <div className="flex flex-col">
            <label className="label">Número de Cuenta</label>
            <input
              type={"number"}
              min={1}
              className="input input-bordered"
              onChange={(e) => {
                setNumCuenta(e.target.value);
              }}
            ></input>
          </div>
        </div>
      </div>

      <label className="btn btn-accent my-1" htmlFor="modal-confirm">
        Guardar Usuario
      </label>
      {/* Modal de confirmar */}
      <input type="checkbox" id="modal-confirm" className="modal-toggle" />
      <div className="modal">
        <div className="modal-box">
          <h3 className="font-bold text-lg">Aviso</h3>
          <p className="py-4">Desea crear el usuario?</p>
          <div className="modal-action">
            <label
              htmlFor="modal-confirm"
              className="btn btn-accent"
              onClick={
                postData
              }
            >
              Sí
            </label>
            <label htmlFor="modal-confirm" className="btn">
              Aún no
            </label>
          </div>
        </div>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default CreateUser;
