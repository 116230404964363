import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { notifyPendingNotify, popNotification } from "./utils/Notify";
import { makePostRequest } from "./utils/Requests";

function UploadClientes() {
  const [archivo, setArchivo] = useState([]);
  var [success, setSuccess] = useState(0);
  var [failed, setFailed] = useState(0);

  const fileReader = new FileReader();
  //trae la lista de los productores del input
  const submitArchivo = (e) => {
    fileReader.readAsText(e.target.files[0], "UTF-8");
    fileReader.onload = (e) => {
      console.log("e.target.result:" + e.target.result);
      console.log("TIPO:" + typeof e.target.result);
      setArchivo(JSON.parse(e.target.result));
    };
  };
  //postea la data del objeto masivamente
  const postMassiveData = async () => {
    console.log("subiendo masivamente");
    const notify= notifyPendingNotify()
    for (const i of archivo) {
      console.log("dentro del for:", i?.id);
      try {
        const response = await makePostRequest("clientes", { data: i });
        if (response) {
          setSuccess(success++);
        } else {
          console.error("DATOS CON ERROR" + JSON.stringify(i));
          setFailed(failed++);
        }
      } catch (error) {
        console.error(error);
        setFailed(failed++);

        console.error("datos al catch"+JSON.stringify(i));
      }
    }
    popNotification(notify, "success", "Proceso finalizado")
  };
  useEffect(() => {
    console.log("cAMBIO EN ARCHIVO" + JSON.stringify(archivo));
  }, [archivo]);
  useEffect(()=>{
    console.log("");
  },[success, failed]);
  return (
    <div className="h-full ">
      <h1 className="text text-4xl">Subir Lista de Clientes</h1>
      <p>
        {" "}
        Agregue un archivo de formato.json y presione "agregar" para subirlo
      </p>
      <form className="grid grid-cols-1 gap-3 py-3">
        <input
          className="input input-bordered "
          type={"file"}
          id="csvFileInput"
          accept=".json"
          onChange={submitArchivo}
        ></input>
      </form>
      <button className="btn btn-active" onClick={() => postMassiveData()}>
        Subir datos{" "}
      </button>
      <h3 className="text-3xl">Tamaño del archivo:{archivo?.length} registros</h3>
      <label htmlFor="s" className="label">Porcentaje de éxito</label>
      <progress
        id="s"
        className="progress progress-success w-56"
        value={success}
        max={archivo?.length}
      ></progress>
      <label className="label-text-alt">{success}/{archivo?.length}</label>
      <label htmlFor="f" className="label">Porcentaje de fallo</label>
      <progress
        id="f"
        className="progress progress-error w-56"
        value={failed}
        max={archivo?.length}
      ></progress>
      <label className="label-text-alt">{failed}/{archivo?.length}</label>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default UploadClientes;
