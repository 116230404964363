import logo from "./logo.svg";
import "./App.css";
import Login from "./login";
import Layout from "./components/layout";
import CreateIngreso from "./components/CreateIngreso";
import CreateRecollection from "./components/CreateRecollection";
import GetAcopios from "./components/GetAcopios";
import CreateVenta from "./components/CreateVenta";
import CreateTraslado from "./components/CreateTraslado";
import CreateRecibirTraslado from "./components/CreateRecibirTraslado";
import GetConsolidados from "./components/GetConsolidados";
import OpenConsolidado from "./components/OpenConsolidado";
import CloseConsolidado from "./components/CloseConsolidado";
import GetRecorridos from "./components/GetRecorridos";
import BookEditForm from "./components/BookEditForm";
import Reportes from "./components/Reportes";
import RequireAuth from "./components/RequireAuth";
import { Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import WelcomeLayout from "./components/WelcomeLayout";
import { APP_ROLES } from "./config/constants";
import Unauthorized from "./components/Unauthorized";
import CreateDevolucion from "./components/CreateDevolucion";
import CreateUser from "./components/CreateUser";
import ListRecolectores from "./components/ListRecolectores";
import CreateBancos from "./components/CreateBancos";
import ListBancos from "./components/ListBancos";
import ListRutas from "./components/ListRutas";

import GetAcopiosEmpresa from "./components/GetAcopiosEmpresa";
import SearchBar from "./components/utils/SearchBar";
import UploadProductores from "./components/UploadProductores";
import UploadClientes from "./components/UploadClientes";
import ReporteVentas from "./components/ReporteVentasAcopio";
import ReporteVentasAcopio from "./components/ReporteVentasAcopio";
import ReporteVentasCliente from "./components/ReporteVentasCliente";
import ReporteTrasladosFavAcopio from "./components/ReporteTrasladosFavAcopio";
import ReporteTrasladosSalAcopio from "./components/ReporteTrasladosSalAcopio";
import ReporteDevolucionesCliente from "./components/ReporteDevolucionesCliente";
import ReporteDevolucionesEmpresa from "./components/reports/ReporteDevoluciones";
import ReporteConsolidadosAcopio from "./components/ReporteConsolidadosAcopio";
import CreateRecollectionSearchBar from "./components/CreateRecollectionSearchBar";
import ReporteVentasEmpresa from "./components/ReporteVentasEmpresa";
import EditRecolecciones from "./components/EditRecolecciones";
import CreatePedido from "./components/CreatePedido";
import EditIngresos from "./components/EditIngresos";
import ReportCalidadIngresos from "./components/ReportCalidadIngresos";
import MonitorEmpresa from "./components/MonitorEmpresa";
import MonitorAcopio from "./components/reports/MonitorAcopio";
import VerPedidos from "./components/reports/VerPedidos";
import EditVentas from "./components/EditVentas";
import IngresoPorAjuste from "./components/IngresoPorAjuste";
import CreateRecollectionSafe from "./components/CreateRecollectionSafe";
import CreateRecollectionSearchBarSafe from "./components/CreateRecollectionSearchBarSafe";
import CreateProductor from "./components/cruds/CreateProductor";
import ReporteVentasCalidad from "./components/ReporteVentasCalidad";
import GetRecoleccionesProductor from "./components/GetRecoleccionesProductor";
import VerProductor from "./components/cruds/VerProductor";
import VerRecolector from "./components/cruds/VerRecolector";
import VerOperario from "./components/cruds/VerOperario";
import CreateRecolector from "./components/cruds/CreateRecolector";
import CreateOperario from "./components/cruds/CreateOperario";
import CreateAcopio from "./components/cruds/CreateAcopio";
import CreateRuta from "./components/cruds/CreateRuta";
import GetRecoleccionesProductorRol from "./components/reports/GetRecoleccionesProductorRol";
import GetIngresosRecolector from "./components/reports/GetIngresosRecolector";
import CreateProducto from "./components/cruds/CreateProducto";
import EditConsolidados from "./components/EditConsolidados";
import EditAperturaConsolidado from "./components/EditAperturaConsolidado";

function App() {
  console.log("lA URL ES" + process.env.REACT_APP_BACKEND_BASE_URL);
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* define public Routes */}
        <Route path="login" element={<Login />} />

        <Route path="lista-acopios" element={<GetAcopios />} />

        <Route path="obtener-consolidados" element={<GetConsolidados />} />
        <Route path="book-form" element={<BookEditForm />} />
        <Route path="search" element={<SearchBar />}></Route>
        {/* <Route path="create-user" element={<CreateUser />} />
        <Route path="list-recolector" element={<ListRecolectores />} />
        <Route path="create-banco" element={<CreateBancos />}></Route> */}

        {/* define protected Routes */}
        <Route element={<RequireAuth allowedRoles={APP_ROLES} />}>
          <Route path="/" element={<Home />}>
            <Route path="/" element={<WelcomeLayout />} />
            <Route
              element={
                <RequireAuth allowedRoles={["operario", "recolector"]} />
              }
            >
              <Route
                path="registro-recoleccion"
                element={<CreateRecollection />}
              />
              <Route
                path="registro-recoleccion-safe"
                element={<CreateRecollectionSafe />}
              />
              <Route
                path="registro-recoleccion-search"
                element={<CreateRecollectionSearchBar />}
              />
              <Route
                path="registro-recoleccion-search-safe"
                element={<CreateRecollectionSearchBarSafe />}
              />
              <Route path="recorridos-recolector" element={<GetRecorridos />} />
              <Route
                path="recolecciones-productor"
                element={<GetRecoleccionesProductor />}
              />
              <Route path="ingresos-recolector"element={<GetIngresosRecolector/>}/>
              <Route path="registro-pedido" element={<CreatePedido />} />
            </Route>
            <Route element={<RequireAuth allowedRoles={["operario"]} />}>
              <Route path="registro-ingreso" element={<CreateIngreso />} />
              <Route path="registro-venta" element={<CreateVenta />} />
              <Route path="abrir-consolidado" element={<OpenConsolidado />} />
              <Route path="cerrar-consolidado" element={<CloseConsolidado />} />
              <Route path="registro-traslado" element={<CreateTraslado />} />
              <Route
                path="registro-recibir-traslado"
                element={<CreateRecibirTraslado />}
              />
              <Route
                path="registro-devolucion"
                element={<CreateDevolucion></CreateDevolucion>}
              ></Route>
              <Route
                path="monitor-acopio"
                element={<MonitorAcopio></MonitorAcopio>}
              ></Route>
            </Route>
            {/* define ACTIONS ASSIGNED TO SUPERADMIN */}
            <Route
              element={<RequireAuth allowedRoles={["superAdministrador"]} />}
            >
              <Route path="lista-acopios" element={<GetAcopios />} />
            </Route>
            {/* define ACTIONS ASSIGNED TO PRODUCTOR */}
            <Route element={<RequireAuth allowedRoles={["productor"]} />}>
              <Route path="recolecciones-productor-rol" element={<GetRecoleccionesProductorRol />} />
            </Route>
            {/* define ACTIONS ASSIGNED TO admin */}
            <Route element={<RequireAuth allowedRoles={["administrador"]} />}>
              <Route path="list-acopios" element={<GetAcopiosEmpresa />} />
              <Route path="monitor-empresa" element={<MonitorEmpresa />} />
              <Route path="list-recolector" element={<ListRecolectores />} />
              <Route path="list-productor" element={<VerProductor />} />
              <Route path="list-recolectores" element={<VerRecolector />} />
              <Route path="list-operarios" element={<VerOperario />} />
              <Route path="create-banco" element={<CreateBancos />}></Route>
              <Route path="create-acopio" element={<CreateAcopio />} />
              <Route path="create-ruta" element={<CreateRuta />} />
              <Route path="create-user" element={<CreateUser />}></Route>
              <Route
                path="create-recolector"
                element={<CreateRecolector />}
              ></Route>
              <Route
                path="create-operario"
                element={<CreateOperario />}
              ></Route>
              <Route path="list-banco" element={<ListBancos />}></Route>
              <Route path="list-rutas" element={<ListRutas />}></Route>
              <Route path="reportes" element={<Reportes />} />
              <Route path="reporte-ventas" element={<ReporteVentasAcopio />} />
              <Route path="ventas-calidad" element={<ReporteVentasCalidad />} />
              <Route
                path="ventas-clientes"
                element={<ReporteVentasCliente />}
              />
              <Route path="ventas-empresa" element={<ReporteVentasEmpresa />} />
              <Route
                path="traslados-favor"
                element={<ReporteTrasladosFavAcopio />}
              />
              <Route
                path="traslados-sal"
                element={<ReporteTrasladosSalAcopio />}
              />
              <Route
                path="devoluciones-cliente"
                element={<ReporteDevolucionesCliente />}
              />
              <Route
                path="devoluciones-empresa"
                element={<ReporteDevolucionesEmpresa />}
              />
              <Route
                path="reporte-consolidados"
                element={<ReporteConsolidadosAcopio />}
              />
              <Route
                path="ingresos-calidad"
                element={<ReportCalidadIngresos />}
              />
              <Route
                path="pedidos-empresa"
                element={<VerPedidos />}
              />
              <Route path="upload-prods" element={<UploadProductores />} />
              <Route path="create-productor" element={<CreateProductor />} />
              <Route path="create-producto" element={<CreateProducto />} />
              <Route path="upload-clientes" element={<UploadClientes />} />
              <Route path="reco-edit" element={<EditRecolecciones />} />
              <Route path="ingreso-edit" element={<EditIngresos />} />
              <Route path="venta-edit" element={<EditVentas />} />
              <Route path="consolidado-edit" element={<EditConsolidados />} />
              <Route path="apertura-consolidado-edit" element={<EditAperturaConsolidado />} />
              <Route path="ingreso-ajuste" element={<IngresoPorAjuste />} />
            </Route>
            {/* When not authorized */}
            <Route path="unauthorized" element={<Unauthorized />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
