import React from "react";
import useAuth from "../hooks/useAuth";
import logo from "../../src/img/logo.png";

function NavBar() {
  const {auth, setAuth } = useAuth();

  const signOut = () => {
    setAuth({});
    localStorage.clear();
  };
  return (
    <div className="navbar bg-slate-700 text-white">
      <div className="flex-none">
        <label
          htmlFor="my-drawer-2"
          className="btn btn-square btn-ghost btn-primary drawer-button lg:hidden"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            className="inline-block w-5 h-5 stroke-current"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16M4 18h16"
            ></path>
          </svg>
        </label>
      </div>
      <div className="flex-1">
        
          <a className="btn btn-ghost normal-case text-xl"><img src={logo} alt="Logo agronit" className="h-full"></img><span className="invisible md:visible">Recolecciones</span>  </a>
          
      </div>
          <div className="px-3">Bienvenido, {auth?.username}</div>

      <div className="flex-none">
        <div className="dropdown dropdown-end">
          <button tabIndex="0" className="btn btn-square btn-ghost">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              className="inline-block w-5 h-5 stroke-current"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
              ></path>
            </svg>
          </button>

          <div
            tabIndex="0"
            className="dropdown-content card card-compact w-64 p-2 shadow-lg bg-slate-800"
          >
            <div className="card-body ">
              <button
              
                onClick={() => {
                  signOut();
                }}
              >
                Cerrar Sesión
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NavBar;
