import { useEffect, useState } from "react";

const Prueba = (props) => {
  const [antibiotico, setAntibiotico] = useState(false);
  const [betalactamicos,setBetalactamicos]= useState(false);
  const [tetraciclinas, setTetraciclinas]=useState(false);
  const [sulfamidas, setSulfamidas]=useState(false);
  const [alcohol, setAlcohol] = useState(false);
  const [neutralizantes, setNeutralizantes] = useState(false);
  const [adulterantes, setAdulterantes] = useState(false);
  const [suero, setSuero]= useState(false);
  //states para cambiar el label indicador del parametro de prueba
  const [acidezRange, setAcidezRange] = useState();
  const [phRange, setPhRange] = useState("");
  const [aguaRange, setAguaRange] = useState("");
  const [proteinaRange, setProteinaRange] = useState("");
  const [grasaRange, setGrasaRange] = useState("");
  const [solidosRange, setSolidosRange] = useState("");
  const [densidadRange, setDensidadRange] =useState("");

  const [prueba, setPrueba] = useState({
    acidez: null,
    obsAcidez: null,
    ph: null,
    obsPh: null,
    agua: null,
    obsAgua: null,
    antibiotico: false,
    betalactamicos: false,
    tetraciclinas: false,
    sulfamidas: false,
    obsAntibiotico: null,
    proteina: null,
    obsProteina: null,
    grasa: null,
    obsGrasa: null,
    solidos: null,
    obsSolidos: null,
    alcohol: false,
    obsAlcohol: null,
    neutralizantes: false,
    obsNeutralizantes: null,
    adulterantes: false,
    obsAdulterantes: null,
    suero:false,
    obsSuero:null,
    densidad:null,
    obsDensidad:null,
    obsGenerales: null,
    datos_validos:false,
    operario: props.idAcopio,
  });

  const updatePruebaParameter = (parameterE, parameterName) => {
    let myPrueba = { ...prueba };
    myPrueba[parameterName] = parameterE;
    setPrueba(myPrueba);
  };
  //si el valor de antibiotico es negativo nos resetea los valores de los parametros de antibiotico
  const resetItemsAntibiotico=()=>{
    if(prueba[antibiotico]===false){
      prueba[betalactamicos]=false;
      prueba[tetraciclinas]=false;
      prueba[sulfamidas]=false;
    }else{
      prueba[betalactamicos]=true;
      prueba[tetraciclinas]=true;
      prueba[sulfamidas]=true;
    }
  }
  //verifica si el valor de la prueba está en los parámetros aceptables y en caso que no. muestra un mensaje
  const checkPruebaParameter = (parameterE, parameterName) => {
    console.log("Probando onBlur acidez ");
    console.log(parameterE + "VALOR ONBLUR");
    var pruebaE=prueba;
    switch (parameterName) {
      case "acidez":
        if (parameterE < 10 || parameterE>20) {
          setAcidezRange("Los rangos de acidez se encuentran entre 10 y 20");
          pruebaE["acidez"]="";
          console.log(JSON.stringify(prueba));
          setPrueba(prueba);
        } else if (parameterE < 13 || parameterE >= 15.3) {
          //rechazo
          setAcidezRange(
            "La acidez menor a 13% o mayor a 15.3% es causal de rechazo"
          );
        } else {
          setAcidezRange("");
        }
        break;
      case "ph":
        if (parameterE < 6.62 || parameterE > 6.8) {
          setPhRange("Los valores aceptables están entre 6.62 y 6.8");
        } else {
          setPhRange("");
        }
        break;
      case "agua":
        if (parameterE < 0 || parameterE > 2) {
          setAguaRange(
            "El porcentaje aceptable de agua está entre el0 y el 2%."
          );
        } else {
          setAguaRange("");
        }
        break;
      case "proteina":
        if (parameterE < 2.9) {
          setProteinaRange(
            "La leche con un contenido proteico menor al 2.9% no se considera apta"
          );
        } else {
          setProteinaRange("");
        }
        break;
      case "grasa":
        if (parameterE < 3.4) {
          setGrasaRange(
            "La leche con un contenido graso menor a 3.4% no se considera apta"
          );
        } else {
          setGrasaRange("");
        }
        break;
      case "solidos":
        if (parameterE < 10) {
          setSolidosRange(
            "La leche con un contenido de sólidos menor a 10% no se considera apta"
          );
        } else {
          setSolidosRange("");
        }
        break;
      case "densidad":
        if(parameterE<1.027 || parameterE>1.035){
          setDensidadRange("La densidad aceptable es entre 1.028 y 1.035")
        }else{
          setDensidadRange("");
        }
        break;

      default:
        break;
    }
  };
  //Useeffect para cambiar la prueba
  useEffect(() => {
    console.log("cambio en acidezrange");
  }, [acidezRange, phRange]);
  //useeffect para el componente reutilizable prueba
  useEffect(() => {
    console.log("effect de prueba");
    props.onPruebaChange(prueba);
  }, [prueba, antibiotico, alcohol, neutralizantes, adulterantes]);

  return (
    <div className="prueba">
      <h2 className="card-title text-3xl ">Prueba</h2>

      <div className="card-body grid grid-cols-1 gap-1 md:grid-cols-2 ">
        <div className="grid grid-cols-1 gap-1 px-3 items-start ">
          <label className="label text-xl font-bold" htmlFor="acidez">
            Acidez
          </label>
          <span className="input-group">
            <input
              id="acidez"
              type="number"
              step={0.1}
              min="10"
              max="20"
              
              required
              className="input input-bordered input-sm w-full"
              onChange={(e) => updatePruebaParameter(e.target.value, "acidez")}
              onBlur={(e) => checkPruebaParameter(e.target.value, "acidez")}
            />
            <span>°D</span>
          </span>
          <label className="label">
            {!acidezRange ? (
              <></>
            ) : acidezRange !== "" ? (
              <span className="label-text-alt text-error">{acidezRange}</span>
            ) : (
              <span></span>
            )}
          </label>
        </div>

        <div className="grid grid-cols-1 gap-1 px-3 items-start ">
          <label className="label" htmlFor="observacionesAcidez">
            Observaciones Acidez
          </label>
          <textarea
            type="text"
            placeholder="Observaciones Acidez"
            className="input input-bordered input-md w-full max-w-xs"
            onChange={(e) => updatePruebaParameter(e.target.value, "obsAcidez")}
          />
        </div>

        <div className="grid grid-cols-1 gap-1 px-3 items-start ">
          <label className="label text-xl font-bold " htmlFor="ph">
            Nivel de pH <p className="text-success">{prueba.ph}</p>
          </label>
          <input
            id="ph"
            type="range"
            min="6.4"
            max="6.8"
            required
            step={0.01}
            className="range range-accent range-xs"
            onChange={(e) => updatePruebaParameter(e.target.value, "ph")}
            onBlur={(e) => {
              checkPruebaParameter(e.target.value, "ph");
            }}
          />
          <div className="w-full flex justify-between text-xs px-2">
            <span>|</span>
            <span>|</span>
            <span>|</span>
            <span>|</span>
            <span>|</span>
            <span>|</span>
            <span>|</span>
            <span>|</span>
            <span>|</span>
            <span>|</span>
            <span>|</span>
            <span>|</span>
            <span>|</span>
            <span>|</span>
            <span>|</span>
          </div>

          
          <label className="label">
            {phRange === "" ? (
              <span></span>
            ) : (
              <span className="label-text-alt text-error">{phRange}</span>
            )}
          </label>
        </div>

        <div className="grid grid-cols-1 gap-1 px-3 items-start ">
          <label className="label" htmlFor="observacionesph">
            Observaciones pH
          </label>
          <textarea
            id="observacionesPh"
            type="text"
            placeholder="Observaciones pH"
            className="input input-bordered input-md w-full max-w-xs"
            onChange={(e) => updatePruebaParameter(e.target.value, "obsPh")}
          />
        </div>

        <div className="grid grid-cols-1 gap-1 px-3 items-start ">
          <label className="label text-xl font-bold" htmlFor="agua">
            Porcentaje de agua
          </label>
          <span className="input-group">
            <input
              id="agua"
              type="number"
              step={0.1}
              min="0"
              max="50"
              required
              className="input input-bordered input-sm w-full"
              onChange={(e) => updatePruebaParameter(e.target.value, "agua")}
              onBlur={(e) => checkPruebaParameter(e.target.value, "agua")}
            />
            <span>%</span>
          </span>
          <label className="label">
            {aguaRange === "" ? (
              <span></span>
            ) : (
              <span className="label-text-alt text-error">{aguaRange}</span>
            )}
          </label>
        </div>

        <div className="grid grid-cols-1 gap-1 px-3 items-start ">
          <label className="label" htmlFor="observacionesAgua">
            Observaciones (Agua)
          </label>
          <textarea
            id="observacionesAgua"
            type="text"
            placeholder="Observaciones Agua"
            className="input input-bordered input-md w-full max-w-xs"
            onChange={(e) => updatePruebaParameter(e.target.value, "obsAgua")}
          />
        </div>

        <div className="grid grid-cols-1 gap-1 px-3 items-start ">
          <label className="label text-xl font-bold" htmlFor="antibiotico">
            Antibiótico
          </label>
          <label className="label cursor-pointer flex justify-evenly">
            <span className="label-text">Negativo</span>
            <input
              id="antibiotico"
              type="checkbox"
              className="toggle toggle-accent"
              onClick={() => {
                setAntibiotico((prevCheck) => !prevCheck);
                console.log(antibiotico + "valor antibiotico");
                updatePruebaParameter(!antibiotico, "antibiotico");
                resetItemsAntibiotico();
                
              }}
            />
            <span className="label-text">Positivo</span>
          </label>
          {antibiotico === true ? (
            <div>
              <label className="label cursor-pointer">
                <label className="label-text">Betalactámicos</label>
                <input
                  type={"checkbox"}
                  className="checkbox checkbox-accent"
                  
                  onClick={() => {
                    setBetalactamicos((prevCheck) => !prevCheck);
                    console.log(betalactamicos + "valor BETALACTAMICOS");
                    updatePruebaParameter(!betalactamicos, "betalactamicos");
                  }}
                  
                ></input>
              </label>
              <label className="label cursor-pointer">
                <label className="label-text">Tetraciclinas</label>
                <input
                  type={"checkbox"}
                  className="checkbox checkbox-accent"
                  onClick={() => {
                    setTetraciclinas((prevCheck) => !prevCheck);
                    console.log(tetraciclinas + "valor tetraciclinas");
                    updatePruebaParameter(!tetraciclinas, "tetraciclinas");
                  }}
                ></input>
              </label>
              <label className="label cursor-pointer">
                <label className="label-text">Sulfamidas</label>
                <input
                  type={"checkbox"}
                  className="checkbox checkbox-accent"
                  onClick={() => {
                    setSulfamidas((prevCheck) => !prevCheck);
                    console.log(sulfamidas + "valor sulfamidas");
                    updatePruebaParameter(!sulfamidas, "sulfamidas");
                  }}
                ></input>
              </label>
            </div>
          ) : (
            <></>
          )}
        </div>

        <div className="grid grid-cols-1 gap-1 px-3 items-start ">
          <label className="label" htmlFor="observacionesAntibiotico">
            Observaciones (Antibiótico)
          </label>
          <textarea
            id="observacionesAntibiotico"
            type="text"
            placeholder="Observaciones Antibiótico"
            className="input input-bordered input-md w-full max-w-xs"
            onChange={(e) =>
              updatePruebaParameter(e.target.value, "obsAntibiotico")
            }
          />
        </div>

        <div className="grid grid-cols-1 gap-1 px-3 items-start ">
          <label className="label text-xl font-bold" htmlFor="proteina">
            Porcentaje de proteína
          </label>
          <span className="input-group">
            <input
              id="proteina"
              type="number"
              step={0.1}
              min="0"
              max="5"
              required
              className="input input-bordered input-sm w-full"
              onChange={(e) =>
                updatePruebaParameter(e.target.value, "proteina")
              }
              onBlur={(e) => checkPruebaParameter(e.target.value, "proteina")}
            />
            <span>%</span>
          </span>
          <label className="label">
            {proteinaRange === "" ? (
              <span></span>
            ) : (
              <span className="label-text-alt text-error">{proteinaRange}</span>
            )}
          </label>
        </div>

        <div className="grid grid-cols-1 gap-1 px-3 items-start ">
          <label className="label" htmlFor="observacionesProteina">
            Observaciones (Proteína)
          </label>
          <textarea
            id="observacionesProteina"
            type="text"
            placeholder="Observaciones Proteína"
            className="input input-bordered input-md w-full max-w-xs"
            onChange={(e) =>
              updatePruebaParameter(e.target.value, "obsProteina")
            }
          />
        </div>
        <div className="grid grid-cols-1 gap-1 px-3 items-start ">
          <label className="label text-xl font-bold" htmlFor="grasa">
            Porcentaje de Grasa
          </label>
          <span className="input-group">
            <input
              id="grasa"
              type="number"
              step={0.1}
              min="0"
              max="5"
              required
              className="input input-bordered input-sm w-full"
              onChange={(e) => updatePruebaParameter(e.target.value, "grasa")}
              onBlur={(e) => checkPruebaParameter(e.target.value, "grasa")}
            />
            <span>%</span>
          </span>
          <label className="label">
            {grasaRange === "" ? (
              <span></span>
            ) : (
              <span className="label-text-alt text-error">{grasaRange}</span>
            )}
          </label>
        </div>

        <div className="grid grid-cols-1 gap-1 px-3 items-start ">
          <label className="label" htmlFor="observacionesGrasa">
            Observaciones (Grasa)
          </label>
          <textarea
            id="observacionesGrasa"
            type="text"
            placeholder="Observaciones Grasa"
            className="input input-bordered input-md w-full max-w-xs"
            onChange={(e) => updatePruebaParameter(e.target.value, "obsGrasa")}
          />
        </div>

        <div className="grid grid-cols-1 gap-1 px-3 items-start ">
          <label className="label text-xl font-bold" htmlFor="solidos">
            Porcentaje de Sólidos
          </label>
          <span className="input-group">
            <input
              id="solidos"
              type="number"
              step={0.1}
              min="5"
              max="15"
              required
              className="input input-bordered input-sm w-full"
              onChange={(e) => updatePruebaParameter(e.target.value, "solidos")}
              onBlur={(e) => checkPruebaParameter(e.target.value, "solidos")}
            />
            <span>%</span>
          </span>
          <label className="label">
            {solidosRange === "" ? (
              <span></span>
            ) : (
              <span className="label-text-alt text-error">{solidosRange}</span>
            )}
          </label>
        </div>

        <div className="grid grid-cols-1 gap-1 px-3 items-start ">
          <label className="label" htmlFor="observacionesSolidos">
            Observaciones (Sólidos)
          </label>
          <textarea
            id="observacionesSolidos"
            type="text"
            placeholder="Observaciones Sólidos"
            className="input input-bordered input-md w-full max-w-xs"
            onChange={(e) =>
              updatePruebaParameter(e.target.value, "obsSolidos")
            }
          />
        </div>
        <div className="grid grid-cols-1 gap-1 px-3 items-start ">
          <label className="label text-xl font-bold" htmlFor="Alcohol">
            Alcohol
          </label>
          <label className="label cursor-pointer flex justify-evenly">
            <span className="label-text">Negativo</span>
            <input
              id="alcohol"
              type="checkbox"
              className="toggle toggle-accent"
              onClick={() => {
                setAlcohol((prevCheck) => !prevCheck);
                updatePruebaParameter(!alcohol, "alcohol");
              }}
            />
            <span className="label-text">Positivo</span>
          </label>
        </div>

        <div className="grid grid-cols-1 gap-1 px-3 items-start ">
          <label className="label" htmlFor="observacionesAlcohol">
            Observaciones (Alcohol)
          </label>
          <textarea
            id="observacionesAlcohol"
            type="text"
            placeholder="Observaciones Alcohol"
            className="input input-bordered input-md w-full max-w-xs"
            onChange={(e) =>
              updatePruebaParameter(e.target.value, "obsAlcohol")
            }
          />
        </div>

        <div className="grid grid-cols-1 gap-1 px-3 items-start ">
          <label className="label text-xl font-bold" htmlFor="neutralizantes">
            Neutralizantes
          </label>
          <label className="label cursor-pointer  flex justify-evenly">
            <span className="label-text">Negativo</span>
            <input
              id="neutralizantes"
              type="checkbox"
              className="toggle toggle-accent"
              onClick={() => {
                setNeutralizantes((prevCheck) => !prevCheck);
                updatePruebaParameter(!neutralizantes, "neutralizantes");
              }}
            />
            <span className="label-text">Positivo</span>
          </label>
        </div>

        <div className="grid grid-cols-1 gap-1 px-3 items-start ">
          <label className="label" htmlFor="observacionesNeutralizantes">
            Observaciones (Neutralizantes)
          </label>
          <textarea
            id="observacionesNeutralizantes"
            type="text"
            placeholder="Observaciones Neutralizantes"
            className="input input-bordered input-md w-full max-w-xs"
            onChange={(e) =>
              updatePruebaParameter(e.target.value, "obsNeutralizantes")
            }
          />
        </div>
        
        <div className="grid grid-cols-1 gap-1 px-3 items-start ">
          <label className="label text-xl font-bold" htmlFor="adulterantes">
            Adulterantes
          </label>
          <label className="label cursor-pointer flex justify-evenly">
            <span className="label-text">Negativo</span>
            <input
              id="adulterantes"
              type="checkbox"
              className="toggle toggle-accent"
              onClick={() => {
                setAdulterantes((prevCheck) => !prevCheck);
                updatePruebaParameter(!adulterantes, "adulterantes");
              }}
            />
            <span className="label-text">Positivo</span>
          </label>
        </div>

        <div className="grid grid-cols-1 gap-1 px-3 items-start ">
          <label className="label" htmlFor="observacionesAdulterantes">
            Observaciones (Adulterantes)
          </label>
          <textarea
            id="observacionesAdulterantes"
            type="text"
            placeholder="Observaciones Adulterantes"
            className="input input-bordered input-md w-full max-w-xs"
            onChange={(e) =>
              updatePruebaParameter(e.target.value, "obsAdulterantes")
            }
          />
        </div>

        <div className="grid grid-cols-1 gap-1 px-3 items-start ">
          <label className="label text-xl font-bold" htmlFor="suero">
            Suero
          </label>
          <label className="label cursor-pointer  flex justify-evenly">
            <span className="label-text">Negativo</span>
            <input
              id="suero"
              type="checkbox"
              className="toggle toggle-accent"
              onClick={() => {
                setSuero((prevCheck) => !prevCheck);
                updatePruebaParameter(!suero, "suero");
              }}
            />
            <span className="label-text">Positivo</span>
          </label>
        </div>

        <div className="grid grid-cols-1 gap-1 px-3 items-start ">
          <label className="label" htmlFor="observacionesSuero">
            Observaciones (Suero)
          </label>
          <textarea
            id="observacionesSuero"
            type="text"
            placeholder="Observaciones Suero"
            className="input input-bordered input-md w-full max-w-xs"
            onChange={(e) =>
              updatePruebaParameter(e.target.value, "obsSuero")
            }
          />
        </div>

        <div className="grid grid-cols-1 gap-1 px-3 items-start ">
          <label className="label text-xl font-bold" htmlFor="densidad">
            Densidad
          </label>
          <span className="input-group">
            <input
              id="densidad"
              type="number"
              step={0.001}
              min="1.010"
              max="1.050"
              required
              className="input input-bordered input-sm w-full"
              onChange={(e) => updatePruebaParameter(e.target.value, "densidad")}
              onBlur={(e) => checkPruebaParameter(e.target.value, "densidad")}
            />
            <span>%</span>
          </span>
          <label className="label">
            {!densidadRange ? (
              <></>
            ) : densidadRange !== "" ? (
              <span className="label-text-alt text-error">{densidadRange}</span>
            ) : (
              <span></span>
            )}
          </label>
        </div>

        <div className="grid grid-cols-1 gap-1 px-3 items-start ">
          <label className="label" htmlFor="observacionesDensidad">
            Observaciones (Densidad)
          </label>
          <textarea
            id="observacionesDensidad"
            type="text"
            placeholder="Observaciones Suero"
            className="input input-bordered input-md w-full max-w-xs"
            onChange={(e) =>
              updatePruebaParameter(e.target.value, "obsDensidad")
            }
          />
        </div>

        <div className="grid grid-cols-1 gap-1 px-3 items-start ">
          <label className="label " htmlFor="observacionesGenerales">
            Observaciones Generales
          </label>
        </div>

        <textarea
          id="observacionesGenerales"
          type="text"
          placeholder="Observaciones Generales"
          className="input input-bordered input-md w-full max-w-xs"
          onChange={(e) =>
            updatePruebaParameter(e.target.value, "obsGenerales")
          }
        />
      </div>
    </div>
  );
};
export default Prueba;
