import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import axios from "../api/axios";
import { getOperarioInfo } from "../api/RoleInfo";
import useAuth from "../hooks/useAuth";
import { notifyPendingNotify, popNotification } from "./utils/Notify";
import { makeGetRequest } from "./utils/Requests";
import {
  dateToColombianZoneRq,
  timeToColombianZonerq,
} from "./utils/timezoneRequests";

function CloseConsolidado() {
  const URL = process.env.REACT_APP_BACKEND_BASE_URL;
  const { auth } = useAuth();
  //state para almacenar datos del operario
  const [operarioInfo, setOperarioInfo] = useState();
  const [cierreHoy, setCierreHoy] = useState();
  const [consolidadoHoy, setConsolidadoHoy] = useState();
  const [ingresoshoy, setIngresosHoy] = useState();
  const [sumaIngresosHoy, setSumaIngresosHoy] = useState(0);
  const [rechazoshoy, setRechazosHoy] = useState();
  const [sumaRechazosHoy, setSumaRechazosHoy] = useState(0);
  const [cantidadInicial, setCantidadInicial] = useState(0);
  const [cantidadCalculada, setCantidadCalculada] = useState(0);
  const [cantidadFinal, setCantidadFinal] = useState(0);
  const [diferencia, setDiferencia] = useState(0);
  const [observaciones, setObservaciones] = useState();
  const [fecha, setFecha] = useState();
  //States ventas
  const [ventasHoy, setVentasHoy] = useState();
  const [sumaVentasHoy, setSumaVentasHoy] = useState(0);

  //states traslados a favor
  const [trasladosAFavorHoy, setTrasladosAFavorHoy] = useState();
  const [sumaTrasladosAFavorHoy, setSumaTrasladosAFavorHoy] = useState(0);
  //States traslados salientes
  const [trasladosSalientesHoy, setTrasladosSalientesHoy] = useState();
  const [sumaTrasladosSalientesHoy, setSumaTrasladosSalientesHoy] = useState(0);

  //States devoluciones
  const [devolucionesHoy, setDevolucionesHoy] = useState();
  const [sumaDevolucionesHoy, setSumaDevolucionesHoy] = useState(0);
  //states devoluciones novedad
  const [devolucionesNovedadHoy, setDevolucionesNovedadHoy] = useState();
  const [sumaDevolucionesNovedadHoy, setSumaDevolucionesNovedadHoy] =
    useState(0);
  //states ventas novedad
  const [novedadInicial, setNovedadInicial] = useState();
  const [ventasNovedadHoy, setVentasNovedadHoy] = useState();
  const [sumaVentasNovedadHoy, setSumaVentasNovedadHoy] = useState(0);
  const [trasladosFNovedadHoy, setTrasladosFNovedadHoy] = useState();
  const [sumaTrasFNovedadHoy, setSumaTrasFNovedadHoy] = useState(0);
  const [trasladosSalNovedadHoy, setTrasladosSalNovedadHoy] = useState();
  const [sumaTrasSalNovedadHoy, setSumaTrasSalNovedadHoy] = useState(0);
  const [cantidadNovedadCalculada, setCantidadNovedadCalculada] = useState();
  const [cantidadNovedadFinal, setCantidadNovedadFina] = useState();
  const [diferenciaNovedad, setDiferenciaNovedad] = useState(0);
  //Obtiene en un objeto la el id de centro de acopio y de operario
  const getOperario = async () => {
    const idUser = auth.id;
    const operario = await getOperarioInfo(idUser);
    setOperarioInfo(operario);
  };

  //obtiene el consolidado de hoy
  const getConsolidadoHoy = async () => {
    try {
      console.log("datos del acopio" + operarioInfo.idAcopio);
      const myUrl = URL + "consolidadohoy/" + operarioInfo.idAcopio;
      const response = await axios.get(`${myUrl}`);
      if (response.status === 200) {
        console.log("trajo un consolidado apertural ");
        setConsolidadoHoy(response.data);
        setCantidadInicial(response.data.cantidad_medida);
        console.log(
          "cantidad de leche inicial" + response.data.cantidad_medida
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
  //obtiene los valores iniciales de leche y leche con novedad del state de consolidado de hoy
  const getInicialesApertura = () => {
    if (consolidadoHoy) {
      setCantidadInicial(consolidadoHoy.cantidad_medida);
      setNovedadInicial(consolidadoHoy.novedad_medida);
    }
  };
  //obtiene el cierre del dia de hoy
  const getCierreHoy = async () => {
    try {
      console.log("obteniendo cierre de");
      const myUrl = URL + "cierrehoy/" + operarioInfo.idAcopio; //+operarioInfo?.idAcopio;
      const response = await axios.get(`${myUrl}`);
      return response;
    } catch (error) {
      console.log(error);
    }
  };
  //verifica si el cierre de hoy existe
  const checkCierreHoy = async () => {
    const response = await getCierreHoy();
    console.log("cierre" + JSON.stringify(response?.data));
    if (response?.status === 200) {
      console.log(JSON.stringify(response) + "Cierre de hoy");
      setCierreHoy(response.data);
    }
  };
  //Obtiene  el total de los ingresos realizados en el acopio por fecha de hoy
  const getIngresosHoy = async () => {
    try {
      const myUrl = URL + "ingresosacopiohoy/" + operarioInfo.idAcopio;
      const response = await axios.get(`${myUrl}`);
      console.log(JSON.stringify(response) + "ingresos hoy");
      if (response.data && response.data.length > 0) {
        setIngresosHoy(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //Obtiene los ingresos parcialesque se realizaron por medio de rechazo
  const getSumaIngresosParcialesHoy = () => {
    if (rechazoshoy) {
      const sumaIngresosParcialesHoy = rechazoshoy
        .map((i) => i.cantidad_medida)
        .reduce((a, b) => a + b);
      console.log(
        "Ingresos aceptadoe en el rechazo" + sumaIngresosParcialesHoy
      );
      return sumaIngresosParcialesHoy;
    } else {
      return 0;
    }
  };

  //Obtiene la suma de los ingresos realizados hoy
  const getSumaIngresosHoy = () => {
    console.log(ingresoshoy);
    let sumaIngresosHoy = 0;
    if (ingresoshoy) {
      sumaIngresosHoy = ingresoshoy
        .map((i) => i.cantidad_medida)
        .reduce((a, b) => a + b);
    }
    const ingresosParcialesHoy = getSumaIngresosParcialesHoy();
    console.log("Ingresos Parciales:" + ingresosParcialesHoy);
    setSumaIngresosHoy(sumaIngresosHoy + ingresosParcialesHoy);
  };
  //Obtiene  el total de los ingresos rechazados realizados en el acopio por fecha de hoy
  const getRechazosHoy = async () => {
    try {
      const myUrl = "rechazosacopiohoy/" + operarioInfo.idAcopio;
      const response = await makeGetRequest(myUrl);
      console.log(JSON.stringify(response) + "Ingresos rechazados hoy");
      if (response.data && response.data.length > 0) {
        setRechazosHoy(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //Obtiene la suma de los ingresos realizados quer fueron rechazados hoy, tanto total como parcialemente
  const getSumaRechazosHoy = () => {
    if (rechazoshoy && rechazoshoy.length > 0) {
      console.log("Hay rechazos" + JSON.stringify(rechazoshoy));
      console.log(rechazoshoy);
      let sumaRechazosHoy = 0;
      if (rechazoshoy) {
        sumaRechazosHoy = rechazoshoy
          .map((i) => i.cantidad_rechazada)
          .reduce((a, b) => a + b);
        console.log("Rechazos totales" + sumaRechazosHoy);
        setSumaRechazosHoy(sumaRechazosHoy);
      }
    } else {
      console.log("No hay rechazos");
    }
  };
  // obtiene la lista de ventas realizadas hoy
  const getVentasHoy = async () => {
    try {
      const myUrl = URL + "ventasacopiohoy/" + operarioInfo.idAcopio;
      const response = await axios.get(`${myUrl}`);
      if (response.data && response.data.length > 0) {
        setVentasHoy(response.data);
        console.log(response.data);
      } else {
        console.log("ventas a null");
        setVentasHoy(null);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //Obtiene la suma de las ventas realizadas hoy
  const getSumaVentasHoy = () => {
    if (ventasHoy && ventasHoy.length > 0) {
      const sumaVentasHoy = ventasHoy
        .map((i) => i.cantidad_leche)
        .reduce((a, b) => a + b);
      setSumaVentasHoy(sumaVentasHoy);
    }
  };
  // obtiene la lista de ventas con novedad realizadas hoy
  const getVentasNovedadHoy = async () => {
    try {
      const myUrl = URL + "ventasacopiohoynovedad/" + operarioInfo.idAcopio;
      const response = await axios.get(`${myUrl}`);
      if (response.data && response.data.length > 0) {
        setVentasNovedadHoy(response.data);
        console.log(response.data);
      } else {
        console.log("ventas a null");
        setVentasNovedadHoy(null);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //obtiene la suma de las ventas con novedad del dia
  const getSumaVentasNovedadHoy = () => {
    if (ventasNovedadHoy && ventasNovedadHoy.length > 0) {
      const sumaVentasNovHoy = ventasNovedadHoy
        .map((i) => i.cantidad_leche)
        .reduce((a, b) => a + b);
      setSumaVentasNovedadHoy(sumaVentasNovHoy);
    }
  };
  //Obtiene la lista de traslados realizados a favor del acopio
  const getTrasladosAFavorHoy = async () => {
    try {
      const response = await makeGetRequest(
        "trasladosafavor/" + operarioInfo.idAcopio
      );
      if (response && response.data.length > 0) {
        console.log(
          "Traslados a favor del acopio:" + JSON.stringify(response.data)
        );
        setTrasladosAFavorHoy(response.data);
      }
    } catch (error) {
      console.log("Error al obtener los traslados" + error);
    }
  };
  //Obtiene la suma de los traslados realizados a favor de nuentro acopio
  const getSumaTrasladosAFavorHoy = () => {
    if (trasladosAFavorHoy && trasladosAFavorHoy.length > 0) {
      const sumaTrasladosHoy = trasladosAFavorHoy
        .map((i) => i.cantidad_recibida)
        .reduce((a, b) => a + b);
      console.log("traslados a favor hoy______" + sumaTrasladosAFavorHoy);
      setSumaTrasladosAFavorHoy(sumaTrasladosHoy);
    }
  };
  //Obtiene la lista de traslados salientes del acopio
  const getTrasladosSalientesHoy = async () => {
    try {
      const response = await makeGetRequest(
        "trasladossalientes/" + operarioInfo.idAcopio
      );
      if (response && response.data.length > 0) {
        console.log(
          "traslados salientes del acopio:" + JSON.stringify(response.data)
        );
        setTrasladosSalientesHoy(response.data);
      }
    } catch (error) {
      console.log("Error al obtener los traslados salientes:" + error);
    }
  };
  //obtiene la suma de traslados salientes del acopio
  const getSumaTrasladosSalientesHoy = () => {
    if (trasladosSalientesHoy && trasladosSalientesHoy.length > 0) {
      const sumaTrasladosHoy = trasladosSalientesHoy
        .map((i) => i.cantidad_leche)
        .reduce((a, b) => a + b);
      setSumaTrasladosSalientesHoy(sumaTrasladosHoy);
    }
  };
  //Obtiene la lista de traslados realizados a favor del acopio con novedad
  const getTrasladosFNovedadHoy = async () => {
    try {
      const response = await makeGetRequest(
        "trasladosafavornovedad/" + operarioInfo.idAcopio
      );
      if (response && response.data.length > 0) {
        console.log(
          "Traslados a favor del acopio (con novedad):" +
            JSON.stringify(response.data)
        );
        setTrasladosFNovedadHoy(response.data);
      }
    } catch (error) {
      console.log("Error al obtener los traslados" + error);
    }
  };
  //Obtiene la suma de los traslados a favor con novedad de hoy
  const getSumaTrasFNovedadHoy = () => {
    if (trasladosFNovedadHoy && trasladosFNovedadHoy.length > 0) {
      const sumaTrasFNovedadHoy = trasladosFNovedadHoy
        .map((i) => i.cantidad_recibida)
        .reduce((a, b) => a + b);
      setSumaTrasFNovedadHoy(sumaTrasFNovedadHoy);
    }
  };
  //Obtiene la lista de los traslados salientes con novedad
  const getTrasladosSalNovedadHoy = async () => {
    try {
      const response = await makeGetRequest(
        "trasladossalientesnovedad/" + operarioInfo.idAcopio
      );
      if (response) {
        console.log(JSON.stringify(response.data));
        setTrasladosSalNovedadHoy(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getSumaTrasSalNovedadHoy = () => {
    if (trasladosSalNovedadHoy && trasladosSalNovedadHoy.length > 0) {
      const sumaTrasSalNovedadHoy = trasladosSalNovedadHoy
        .map((i) => i.cantidad_leche)
        .reduce((a, b) => a + b);
      setSumaTrasSalNovedadHoy(sumaTrasSalNovedadHoy);
    }
  };
  //obtiene la lista de devoluciones realizadas al acopio
  const getDevolucionesHoy = async () => {
    try {
      const response = await makeGetRequest(
        "devolucioneshoy/" + operarioInfo.idAcopio
      );
      if (response && response.data.length > 0) {
        console.log("Devoluciones del acopio:" + JSON.stringify(response.data));
        setDevolucionesHoy(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //obtiene la suma de devoluciones del acopio
  const getsumaDevolucionesHoy = async () => {
    if (devolucionesHoy && devolucionesHoy?.length > 0) {
      const suma = devolucionesHoy
        .map((t) => t?.cantidad)
        .reduce((a, b) => a + b);
      setSumaDevolucionesHoy(suma);
    }
  };
  //obtiene la lista de devoluciones realizadas al acopio
  const getDevolucionesNovedadHoy = async () => {
    try {
      const response = await makeGetRequest(
        "devolucionesnovedadhoy/" + operarioInfo.idAcopio
      );
      if (response && response.data.length > 0) {
        console.log(
          "Devoluciones del acopio (novedad):" + JSON.stringify(response.data)
        );
        setDevolucionesNovedadHoy(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //obtiene la suma de devoluciones del acopio
  const getsumaDevolucionesNovedadHoy = async () => {
    if (devolucionesNovedadHoy && devolucionesNovedadHoy?.length > 0) {
      const suma = devolucionesNovedadHoy
        .map((t) => t?.cantidad)
        .reduce((a, b) => a + b);
      setSumaDevolucionesNovedadHoy(suma);
    }
  };
  //realiza la suma de la cantidad inicial mas los ingresos menos las ventas, menos los traslados en contra  mas los traslados a favor
  const getTotalLitrosCalculados = () => {
    const cantidadCalculada =
      cantidadInicial +
      sumaIngresosHoy +
      sumaTrasladosAFavorHoy +
      sumaDevolucionesHoy -
      sumaVentasHoy -
      sumaTrasladosSalientesHoy; //agregar las variables faltantes
    console.log(cantidadCalculada + ": Numero de litros totales");
    setCantidadCalculada(cantidadCalculada);
  };
  //Realiza la suma de los ingresos y ventas con novedad
  const getTotalNovedadesCalculada = () => {
    const novedadesCalculada =
      novedadInicial +
      sumaRechazosHoy +
      sumaDevolucionesNovedadHoy +
      sumaTrasFNovedadHoy -
      sumaVentasNovedadHoy -
      sumaTrasSalNovedadHoy; // Agregar iniciales de novedad en la apertura
    setCantidadNovedadCalculada(novedadesCalculada);
  };
  //obtiene la diferencia entre la leche calculada y la leche  medida al cerrar el consolidado
  const getDiferencia = () => {
    const diferencia = cantidadFinal - cantidadCalculada;
    setDiferencia(diferencia);
  };
  //obtiene la diferencia entre la leche calculada y la leche  medida al cerrar el consolidado para las novedades
  const getDiferenciaNovedad = () => {
    const diferencia = cantidadNovedadFinal - cantidadNovedadCalculada;
    setDiferenciaNovedad(diferencia);
  };
  //Cierra el consolidado guardando los valores generados e ingresados por el usuario
  const postCerrarConsolidado = async () => {
    const notify = notifyPendingNotify();
    if (
      parseInt(cantidadFinal) - cantidadCalculada === diferencia &&
      parseInt(cantidadNovedadFinal) - cantidadNovedadCalculada ===
        diferenciaNovedad &&
      cantidadFinal !== undefined &&
      cantidadNovedadFinal !== undefined
    ) {
      console.log(typeof cantidadFinal + " " + cantidadFinal);
      console.log(typeof cantidadNovedadFinal + " " + cantidadNovedadFinal);
      console.log(typeof cantidadCalculada + " " + cantidadCalculada);
      console.log(
        typeof cantidadNovedadCalculada + " " + cantidadNovedadCalculada
      );
      try {
        const myUrl = URL + "consolidados-leche";
        const fechaHoy = await dateToColombianZoneRq();
        const horaHoy = timeToColombianZonerq();
        const response = await axios.post(myUrl, {
          data: {
            fecha: fechaHoy,
            hora: horaHoy,
            cantidad_inicial: cantidadInicial,
            cantidad_recibida: sumaIngresosHoy,
            cantidad_vendida: sumaVentasHoy,
            traslados_favor: sumaTrasladosAFavorHoy,
            traslados_salientes: sumaTrasladosSalientesHoy,
            devoluciones: sumaDevolucionesHoy,
            cantidad_calculada: cantidadCalculada,
            cantidad_final_dia: cantidadFinal,
            diferencia: diferencia,
            ingresos_novedad: sumaRechazosHoy,
            ventas_novedad: sumaVentasNovedadHoy,
            traslados_favor_novedad: sumaTrasFNovedadHoy,
            traslados_sal_novedad: sumaTrasSalNovedadHoy,
            devoluciones_novedad: sumaDevolucionesNovedadHoy,
            novedad_inicial: novedadInicial,
            novedad_calculada: cantidadNovedadCalculada,
            novedad_final_dia: cantidadNovedadFinal,
            novedad_diferencia: diferenciaNovedad,
            observaciones: observaciones,
            acopio: operarioInfo.idAcopio, //cambiar en cuanto sea posible por el verdadero valor del acopio
          },
        });
        if (response) {
          console.log(JSON.stringify(response) + " Respuesta al guardar");
          popNotification(notify, "success", "Cierre de consolidado exitoso.");
          await checkCierreHoy();
        }
      } catch (error) {
        console.log(error + "error al guardar el cierre");
        popNotification(notify, "error", "Error al cerrar consolidado.");
      }
    } else {
      popNotification(notify, "error", "Complete los datos del cierre.");
    }
  };
  //obtiene los datos de movimientos del sistema
  const getMovimientos = async () => {
    await getIngresosHoy();
    await getRechazosHoy();
    await getVentasHoy();
    await getVentasNovedadHoy();
    await getTrasladosAFavorHoy();
    await getTrasladosSalientesHoy();
    await getTrasladosFNovedadHoy();
    await getTrasladosSalNovedadHoy();
    await getDevolucionesHoy();
    await getDevolucionesNovedadHoy();
    await getTotalLitrosCalculados();
    await getTotalNovedadesCalculada();
  };
  //OBTIENE LA SUMA DE LOS MOVIMINETOS
  const getSumaMovimientos = () => {
    getSumaRechazosHoy();
    getSumaIngresosHoy();
    getSumaVentasHoy();
    getSumaVentasNovedadHoy();
    getSumaTrasladosAFavorHoy();
    getSumaTrasladosSalientesHoy();
    getSumaTrasFNovedadHoy();
    getSumaTrasSalNovedadHoy();
    getsumaDevolucionesHoy();
    getsumaDevolucionesNovedadHoy();
    getTotalLitrosCalculados();
    getTotalNovedadesCalculada();
  };
  //obtiene la fecha del sistema
  const getFecha = async () => {
    const fechaHoy = await dateToColombianZoneRq();
    setFecha(fechaHoy);
  };

  useEffect(() => {
    console.log("UseEffect Inicial");
    getFecha();
    getOperario();
  }, []);
  useEffect(() => {
    if (operarioInfo) {
      console.log("ingresando al useEffect de cambio en operario info");
      console.log("info del ope" + JSON.stringify(operarioInfo));
      getConsolidadoHoy();
      checkCierreHoy();
      if (!cierreHoy) {
        console.log("oBTENEMOS LOS INGRESOS REALIZADOS HOY");
        getMovimientos();
      }
    }
  }, [operarioInfo]);
  //Effect para obtener los inicales de la apertura de consolidados
  useEffect(() => {
    if (consolidadoHoy) {
      getInicialesApertura();
    }
  }, [consolidadoHoy]);

  useEffect(() => {
    if (
      ingresoshoy ||
      ventasHoy ||
      ventasNovedadHoy ||
      trasladosAFavorHoy ||
      trasladosSalientesHoy ||
      trasladosFNovedadHoy ||
      trasladosSalNovedadHoy ||
      devolucionesHoy ||
      devolucionesNovedadHoy ||
      rechazoshoy
    ) {
      getSumaMovimientos();
    }
  }, [
    ingresoshoy,
    ventasHoy,
    ventasNovedadHoy,
    trasladosAFavorHoy,
    trasladosSalientesHoy,
    trasladosFNovedadHoy,
    trasladosSalNovedadHoy,
    devolucionesHoy,
    devolucionesNovedadHoy,
    rechazoshoy,
  ]);
  useEffect(() => {
    if (rechazoshoy) {
      getSumaIngresosHoy();
    }
  }, [rechazoshoy]);
  useEffect(() => {
    getSumaVentasNovedadHoy();
  }, [ventasNovedadHoy]);
  useEffect(() => {
    if (cantidadCalculada || cantidadInicial || cantidadFinal) {
      getSumaMovimientos();
    }
  }, [cantidadCalculada, cantidadFinal, cantidadInicial]);
  return (
    <section className="h-full max-w-full">
      <p className="text-3xl my-5">Cierre de Consolidados</p>

      {consolidadoHoy ? (
        !cierreHoy ? (
          <div className="overflow-x-scroll">
            <div className="card ">
              <div className="card-body">
                <h1 className="text-2xl">Crear Cierre de consolidado</h1>

                <h1 className="card-title">Detalles: </h1>
                <h1 className="text-xl">
                  Cantidad medida al inicio del día:{cantidadInicial} litros
                </h1>
                <div className="card bg-base-100 shadow-xl">
                  <div className="card-body">
                    <h1 className="card-title">Leche Ingresada y Aceptada:</h1>
                    {ingresoshoy ? (
                      <>
                        <table className="table table-compact">
                          <thead>
                            <tr>
                              <th>Ruta</th>
                              <th>Cantidad</th>
                            </tr>
                          </thead>
                          <tbody>
                            {ingresoshoy?.map((ingreso) => (
                              <tr key={ingreso.id}>
                                <td>{ingreso?.recorrido?.ruta?.nombre}</td>
                                <td>{ingreso?.cantidad_medida}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </>
                    ) : (
                      <div>No han habido Ingresos hasta el momento</div>
                    )}
                    {/* Aceptados parciales */}
                    <h1 className="card-title">
                      Leche Aceptada durante una Novedad:
                    </h1>
                    {rechazoshoy ? (
                      <>
                        <table className="table table-compact">
                          <thead>
                            <tr>
                              <th>Hora</th>
                              <th>Ruta</th>
                              <th>Cantidad</th>
                            </tr>
                          </thead>
                          <tbody>
                            {rechazoshoy?.map((rechazo) => (
                              <tr key={rechazo.id}>
                                <td>{rechazo?.hora.split(".")[0]}</td>
                                <td>{rechazo?.recorrido?.ruta?.nombre}</td>
                                <td>{rechazo?.cantidad_medida}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </>
                    ) : (
                      <div>No han habido Novedades hasta el momento</div>
                    )}
                    <div className="badge badge-lg badge-info">
                      <h6 className="text text-lg">
                        Total de ingresos:
                        {sumaIngresosHoy} litros
                      </h6>
                    </div>
                  </div>
                </div>

                <div className="card shadow-xl">
                  <div className="card-body">
                    {/* ventas */}
                    <h1 className="card-title">Ventas realizadas:</h1>
                    {ventasHoy ? (
                      <>
                        <table className="table table-compact">
                          <thead>
                            <tr>
                              <th>Nombre del cliente</th>
                              <th>Placa</th>
                              <th>Litros Vendidos</th>
                            </tr>
                          </thead>
                          <tbody>
                            {ventasHoy.map((venta) => (
                              <tr key={venta.id}>
                                <td>{venta?.cliente?.nombre}</td>
                                <td>{venta?.placa_vehiculo}</td>
                                <td>{venta?.cantidad_leche}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </>
                    ) : (
                      <div>No existen ventas realizadas en el Acopio</div>
                    )}
                    <div className="badge badge-lg badge-info">
                      <span className="text-lg">
                        Total de ventas:{sumaVentasHoy}
                        {" litros"}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="card shadow-xl">
                  {/* Traslados A favor */}
                  <div className="card-body">
                    <h1 className="card-title">Traslados a favor:</h1>
                    {trasladosAFavorHoy ? (
                      <div className="overflow-x-auto">
                        <table className="table table-compact">
                          <thead>
                            <tr>
                              <th>Hora</th>
                              <th>Origen</th>
                              <th>Litros Enviados</th>
                              <th>Litros Recibidos</th>
                            </tr>
                          </thead>
                          <tbody>
                            {trasladosAFavorHoy.map((traslado) => (
                              <tr key={traslado.id}>
                                <td>{traslado?.hora.split(".")[0]}</td>
                                <td>{traslado?.acopio_origen?.nombre}</td>
                                <td>{traslado?.cantidad_enviada}</td>
                                <td>{traslado?.cantidad_recibida}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <div>No existen traslados a favor del acopio</div>
                    )}
                    <div className="badge badge-lg badge-info">
                      <span className="text-lg">
                        Total de traslados a favor:
                        {sumaTrasladosAFavorHoy}
                        {" litros"}
                      </span>
                    </div>
                  </div>
                  <div>{/* fin de los traslados a favor */}</div>
                </div>
                <div className="card shadow-xl">
                  <div className="card-body">
                    {/* Traslados salientes */}
                    <h1 className="card-title">Traslados Salientes:</h1>
                    {trasladosSalientesHoy ? (
                      <div className="overflow-x-auto">
                        <table className="table table-compact">
                          <thead>
                            <tr>
                              <th></th>
                              <th>Hora</th>
                              <th>Acopio destino</th>
                              <th>Litros Enviados</th>
                            </tr>
                          </thead>
                          <tbody>
                            {trasladosSalientesHoy.map((traslado) => (
                              <tr key={traslado?.id}>
                                <td></td>
                                <td>{traslado?.hora.split(".")[0]}</td>
                                <td>{traslado?.acopio_destino?.nombre}</td>
                                <td>{traslado?.cantidad_leche}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <div>No existen traslados salientes en el acopio</div>
                    )}
                    <div className="badge badge-lg badge-info">
                      <span className="text-lg">
                        Total de traslados salientes:
                        {sumaTrasladosSalientesHoy}
                        {" litros"}
                      </span>
                    </div>
                    {/* fin de los traslados salientes */}
                  </div>
                </div>
                <div className="card shadow-xl">
                  <div className="card-body">
                    {/* Devoluciones*/}
                    <h1 className="card-title">Devoluciones realizadas:</h1>
                    {devolucionesHoy ? (
                      <div>
                        <table className="table table-compact">
                          <thead>
                            <tr>
                              <th>Hora</th>
                              <th>Cliente</th>
                              <th>Litros devueltos</th>
                            </tr>
                          </thead>
                          <tbody>
                            {devolucionesHoy?.map((devo) => (
                              <tr key={devo?.id}>
                                <td>{devo?.hora.split(".")[0]}</td>
                                <td>{devo?.cliente?.nombre}</td>
                                <td>{devo?.cantidad}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <div>No existen devoluciones al acopio</div>
                    )}
                    <div className="badge badge-lg badge-info">
                      <span className="text-lg">
                        Total de devoluciones:
                        {sumaDevolucionesHoy}
                        {" litros"}
                      </span>
                    </div>
                    {/* fin de las devoluciones */}
                  </div>
                </div>
                {/* movimientos de leche con novedad */}
                <h3 className="text-xl font-bold">
                  Movimientos de leche con Novedad
                </h3>
                <h1 className="text-xl">
                  Cantidad medida con novedad al inicio del día:{novedadInicial}
                  {" litros"}
                </h1>
                <div className="card shadow-xl">
                  <div className="card-body">
                    {/* rechazos */}
                    <h1 className="card-title">
                      Leche Ingresada con Novedad :
                    </h1>
                    {rechazoshoy ? (
                      <div className="overflow-x-auto">
                        <table className="table table-compact">
                          <thead>
                            <tr>
                              <th>Ruta</th>
                              <th>Cantidad</th>
                              <th>Hora</th>
                            </tr>
                          </thead>
                          <tbody>
                            {rechazoshoy?.map((rechazo) => (
                              <tr key={rechazo.id}>
                                <td>{rechazo?.recorrido?.ruta?.nombre}</td>
                                <td>{rechazo?.cantidad_rechazada}</td>
                                <td>{rechazo?.hora.split(".")[0]}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <div>No han habido Novedades hasta el momento</div>
                    )}

                    <div className="badge badge-lg badge-error">
                      <span className="text text-lg">
                        Ingresos con Novedad:
                        {sumaRechazosHoy} litros
                      </span>
                    </div>
                  </div>
                </div>
                <div className="card shadow-xl">
                  <div className="card-body">
                    {/* ventas con novedad */}
                    <h1 className="card-title">Ventas realizadas:</h1>
                    {ventasNovedadHoy ? (
                      <div className="overflow-x-auto">
                        <table className="table table-compact">
                          <thead>
                            <tr>
                              <th>Nombre del cliente</th>
                              <th>Placa</th>
                              <th>Litros Vendidos</th>
                            </tr>
                          </thead>
                          <tbody>
                            {ventasNovedadHoy.map((venta) => (
                              <tr key={venta.id}>
                                <td>{venta?.cliente?.nombre}</td>
                                <td>{venta?.placa_vehiculo}</td>
                                <td>{venta?.cantidad_leche}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <div>
                        No existen ventas con novedad realizadas en el Acopio
                      </div>
                    )}
                    <div className="badge badge-lg badge-error">
                      <span className="text-lg">
                        Ventas con Novedad:
                        {sumaVentasNovedadHoy} litros
                      </span>{" "}
                    </div>
                  </div>
                </div>
                {/* Traslados a favor con novedad */}
                <div className="card shadow-xl">
                  <div className="card-body">
                    {/* ventas con novedad */}
                    <h1 className="card-title">
                      Traslados a favor con novedad:
                    </h1>
                    {trasladosFNovedadHoy ? (
                      <div className="overflow-x-auto">
                        <table className="table table-compact">
                          <thead>
                            <tr>
                              <th>Hora</th>
                              <th>Origen</th>
                              <th>enviado</th>
                              <th>recibido</th>
                            </tr>
                          </thead>
                          <tbody>
                            {trasladosFNovedadHoy.map((t) => (
                              <tr key={t.id}>
                                <td>{t?.hora.split(".")[0]}</td>
                                <td>{t?.acopio_origen?.nombre}</td>
                                <td>{t?.cantidad_enviada}</td>
                                <td>{t?.cantidad_recibida}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <div>
                        No existen traslados a favor con novedad realizados en
                        el Acopio
                      </div>
                    )}
                    <div className="badge badge-lg badge-error">
                      <span className="text-lg">
                        traslados a favor con Novedad:
                        {sumaTrasFNovedadHoy + " litros"}
                      </span>{" "}
                    </div>
                  </div>
                </div>

                {/* Traslados salientes con novedad */}
                <div className="card shadow-xl">
                  <div className="card-body">
                    <h1 className="card-title">
                      Traslados salientes con novedad:
                    </h1>
                    {trasladosSalNovedadHoy ? (
                      <div className="overflow-x-auto">
                        <table className="table table-compact">
                          <thead>
                            <tr>
                              <th>Hora</th>
                              <th>Acopio Destino</th>
                              <th>Litros enviados</th>
                            </tr>
                          </thead>
                          <tbody>
                            {trasladosSalNovedadHoy.map((t) => (
                              <tr key={t.id}>
                                <td>{t?.hora.split(".")[0]}</td>
                                <td>{t?.acopio_destino?.nombre}</td>
                                <td>{t?.cantidad_leche}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <div>
                        No existen traslados salientes con novedad realizadas en
                        el Acopio
                      </div>
                    )}
                    <div className="badge badge-lg badge-error">
                      <span className="text-lg">
                        traslados salientes con novedad:
                        {sumaTrasSalNovedadHoy}
                      </span>
                      {" litros"}
                    </div>
                  </div>
                </div>
                {/* devoluciones con novedad */}
                <div className="card shadow-xl">
                  <div className="card-body">
                    {/* Devoluciones*/}
                    <h1 className="card-title">
                      Devoluciones con novedad realizadas:
                    </h1>
                    {devolucionesNovedadHoy ? (
                      <div>
                        <table className="table table-compact">
                          <thead>
                            <tr>
                              <th>Hora</th>
                              <th>Cliente</th>
                              <th>Litros devueltos</th>
                            </tr>
                          </thead>
                          <tbody>
                            {devolucionesNovedadHoy?.map((devo) => (
                              <tr key={devo?.id}>
                                <td>{devo?.hora.split(".")[0]}</td>
                                <td>{devo?.cliente?.nombre}</td>
                                <td>{devo?.cantidad}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <div>No existen devoluciones al acopio</div>
                    )}
                    <div className="badge badge-lg badge-error">
                      <span className="text-lg">
                        Total de devoluciones:
                        {sumaDevolucionesNovedadHoy}
                        {" litros"}
                      </span>
                    </div>
                    {/* fin de las devoluciones */}
                  </div>
                </div>
              </div>
            </div>
            <h1 className="text text-xl">
              Cantidad restante en el acopio:{cantidadCalculada} litros
            </h1>
            <h1 className="text text-xl">
              Cantidad novedad restante en el acopio:{cantidadNovedadCalculada}{" "}
              litros
            </h1>
            <div className="divider"></div>
            <div className="flex flex-col mx-5">
              <h1 className="text text-2xl">Cerrar Consolidado</h1>
              <label htmlFor="cantidadMedida" className="label">
                Cantidad de leche medida
              </label>
              <span className=" input input-group ">
                <input
                  id="cantidadMedida"
                  type="number"
                  step={1}
                  min="0"
                  required
                  className="input input-bordered w-full"
                  onChange={(e) => setCantidadFinal(e.target.value)}
                  onBlur={() => getDiferencia()}
                />
                <span>litros</span>
              </span>
              <label className="label">
                {diferencia >= 0 ? (
                  <span className="label-alt text-success">
                    La diferencia es de {diferencia} litros
                  </span>
                ) : (
                  <span className="label-alt text-error">
                    La diferencia es de {diferencia} litros
                  </span>
                )}
              </label>
              <label htmlFor="cantidadNovedad" className="label">
                Cantidad de leche medida (Novedades)
              </label>
              <span className=" input input-group ">
                <input
                  id="cantidadNovedad"
                  type="number"
                  step={1}
                  min="0"
                  required
                  className="input input-bordered w-full"
                  onChange={(e) => setCantidadNovedadFina(e.target.value)}
                  onBlur={() => getDiferenciaNovedad()}
                />
                <span>litros</span>
              </span>
              <label className="label">
                {diferenciaNovedad >= 0 ? (
                  <span className="label-alt text-success">
                    La diferencia es de {diferenciaNovedad} litros
                  </span>
                ) : (
                  <span className="label-alt text-error">
                    La diferencia es de {diferenciaNovedad} litros
                  </span>
                )}
              </label>
              <label htmlFor="obs" className="label">
                Observaciones
              </label>
              <textarea
                className="textarea textarea-bordered"
                onChange={(e) => {
                  setObservaciones(e.target.value);
                }}
              ></textarea>
              <label htmlFor="modal-confirm" className="btn btn-active my-5 ">
                cerrar consolidado
              </label>
            </div>
          </div>
        ) : (
          <div>
            <div className="alert alert-success">
              El cierre de hoy ya fue creado. Puede verificar la información a
              continuación
            </div>
            <div className="card  shadow-xl w-full">
              <div className="card-body">
                <h2 className="card-title">Detalles del cierre</h2>
                <p className="text-left">
                  <span className="font-bold justify-start">
                    Fecha de cierre:{" "}
                  </span>
                  {cierreHoy?.fecha}
                </p>
                <p className="text-left">
                  <span className="font-bold justify-start">
                    Leche al inicio de la jornada:{" "}
                  </span>
                  {cierreHoy?.cantidad_inicial} litros
                </p>
                <p className="text-left">
                  <span className="font-bold justify-start">
                    Leche Ingresada:{" "}
                  </span>
                  {cierreHoy?.cantidad_recibida} litros
                </p>
                <p className="text-left">
                  <span className="font-bold">Leche Vendida: </span>
                  {cierreHoy?.cantidad_vendida} litros
                </p>
                <p className="text-left">
                  <span className="font-bold">
                    Traslados a favor del acopio:{" "}
                  </span>
                  {cierreHoy?.traslados_favor} litros
                </p>
                <p className="text-left">
                  <span className="font-bold">Traslados salientes: </span>
                  {cierreHoy?.traslados_salientes} litros
                </p>
                <p className="text-left">
                  <span className="font-bold">Devoluciones: </span>
                  {cierreHoy?.devoluciones} litros
                </p>
                <p className="text-left">
                  <span className="font-bold">Leche al final del Día: </span>
                  {cierreHoy?.cantidad_final_dia} litros
                </p>
                <p className="text-left">
                  <span className="font-bold">
                    Leche Calculada por Sistema:{" "}
                  </span>
                  {cierreHoy?.cantidad_calculada} litros
                </p>

                <p className="text-left">
                  <span className="font-bold">Diferencia: </span>
                  {cierreHoy?.diferencia} litros
                </p>
                <div className="divider"></div>
                <p className="font-bold">Movimientos de leche con novedad</p>
                <p className="text-left">
                  <span className="font-bold">
                    Leche con novedad al empezar el día:{" "}
                  </span>
                  {cierreHoy?.novedad_inicial} litros
                </p>
                <p className="text-left">
                  <span className="font-bold">Leche ingresada Novedad : </span>
                  {cierreHoy?.ingresos_novedad} litros
                </p>
                <p className="text-left">
                  <span className="font-bold">Ventas con Novedad : </span>
                  {cierreHoy?.ventas_novedad} litros
                </p>
                <p className="text-left">
                  <span className="font-bold">
                    Traslados a favor del acopio con novedad:{" "}
                  </span>
                  {cierreHoy?.traslados_favor_novedad} litros
                </p>
                <p className="text-left">
                  <span className="font-bold">
                    Traslados salientes con novedad:{" "}
                  </span>
                  {cierreHoy?.traslados_sal_novedad} litros
                </p>
                <p className="text-left">
                  <span className="font-bold">Devoluciones con novedad: </span>
                  {cierreHoy?.devoluciones_novedad} litros
                </p>
                <p className="text-left">
                  <span className="font-bold">
                    Leche con novedad calculada:{" "}
                  </span>
                  {cierreHoy?.novedad_calculada} litros
                </p>
                <p className="text-left">
                  <span className="font-bold">
                    Leche con novedad al final del Día:{" "}
                  </span>
                  {cierreHoy?.novedad_final_dia} litros
                </p>
                <p className="text-left">
                  <span className="font-bold">Diferencia: </span>
                  {cierreHoy?.novedad_diferencia} litros
                </p>
                <p className="text-left">
                  <span className="font-bold">Observaciones: </span>
                  {cierreHoy?.observaciones}
                </p>
              </div>
            </div>
          </div>
        )
      ) : (
        <div className="alert">
          Para crear un cierre, se debe abrir el consolidado. Vaya a la opción
          "Abrir Consolidado" del menú
        </div>
      )}
      {/* Modal para confirmar el cierre del consolidado */}
      <input
        type={"checkbox"}
        className="modal-toggle"
        id="modal-confirm"
      ></input>
      <div className="modal">
        <div className="modal-box">
          <h3 className="font-bold text-lg">Aviso</h3>
          <p className="py-4">
            Realmente desea realizar el Cierre de Consolidado diario con los
            siguientes datos?
          </p>
          <p>
            <span className="font-bold">Fecha: </span>
            {fecha}
          </p>
          <p>
            <span className="font-bold">Cantidad Inicial: </span>
            {cantidadInicial + " litros"}
          </p>
          <p>
            <span className="font-bold">Cantidad Ingresada: </span>
            {sumaIngresosHoy + " litros"}
          </p>
          <p>
            <span className="font-bold">Cantidad vendida: </span>
            {sumaVentasHoy + " litros"}
          </p>
          <p>
            <span className="font-bold">Devoluciones: </span>
            {sumaDevolucionesHoy + " litros"}
          </p>
          <p>
            <span className="font-bold">Traslados a favor: </span>
            {sumaTrasladosAFavorHoy + " litros"}
          </p>
          <p>
            <span className="font-bold">Traslados salientes: </span>
            {sumaTrasladosSalientesHoy + " litros"}
          </p>
          <p>
            <span className="font-bold">Cantidad por sistema: </span>
            {cantidadCalculada + " litros"}
          </p>
          <p>
            <span className="font-bold">Cantidad medida: </span>
            {cantidadFinal + " litros"}
          </p>
          <p>
            <span className="font-bold">Diferencia: </span>
            {diferencia + " litros"}
          </p>
          <div className="divider"></div>
          <p className="font-bold">Movimientos de leche con novedad</p>
          <p>
            <span className="font-bold">Cantidad Inicial con novedad: </span>
            {novedadInicial + " litros"}
          </p>
          <p>
            <span className="font-bold">Ingresos con novedad: </span>
            {sumaRechazosHoy + " litros"}
          </p>
          <p>
            <span className="font-bold">Ventas con novedad: </span>
            {sumaVentasNovedadHoy + " litros"}
          </p>
          <p>
            <span className="font-bold">Traslados a favor con novedad: </span>
            {sumaTrasFNovedadHoy + " litros"}
          </p>
          <p>
            <span className="font-bold">Traslados salientes con novedad: </span>
            {sumaTrasSalNovedadHoy + " litros"}
          </p>
          <p>
            <span className="font-bold">Devoluciones con novedad: </span>
            {sumaDevolucionesNovedadHoy + " litros"}
          </p>
          <p>
            <span className="font-bold">
              Cantidad con novedad por sistema:{" "}
            </span>
            {cantidadNovedadCalculada + " litros"}
          </p>
          <p>
            <span className="font-bold">
              Cantidad con novedad al final del día:{" "}
            </span>
            {cantidadNovedadFinal + " litros"}
          </p>
          <p>
            <span className="font-bold">Diferencia en novedad: </span>
            {diferenciaNovedad + " litros"}
          </p>
          <p>
            <span className="font-bold">Observaciones: </span>
            {observaciones}
          </p>
          <div className="modal-action">
            <label
              htmlFor="modal-confirm"
              className="btn btn-accent"
              onClick={postCerrarConsolidado}
            >
              Realizar cierre
            </label>
            <label htmlFor="modal-confirm" className="btn">
              Aún No
            </label>
          </div>
        </div>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </section>
  );
}

export default CloseConsolidado;
