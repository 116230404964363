import { makeSafeGetRequest } from "./Requests";

export async function getRutasAcopio(idAcopio, tk){
    try {
        const response =await makeSafeGetRequest("rutasacopio/"+idAcopio, tk);
        if(response &&response.status===200){
            return response.data;
        }else{
            return null;
        }
    } catch (error) {
        console.log(error)
    }
}