import React, { useEffect, useState } from "react";
import { makeSafeGetRequest } from "./utils/Requests";
import useAuth from "../hooks/useAuth";
import { getEmpresaAdmin } from "../api/RoleInfo";
import RecoleccionesCounter from "./RecoleccionesCounter";

function MonitorEmpresa() {
  const { auth } = useAuth();
  const [idEmpresa, setIdEmpresa] = useState();
  const [empresaNombre, setEmpresaNombre] = useState();
  const [acopiosDetalle, setAcopiosDetalle] = useState();
  const [sumas, setSumas] = useState([]);
  //Obtiene el id de la empresa
  const getIdEmpresa = async () => {
    try {
      const response = await getEmpresaAdmin(auth);
      if (response) {
        console.log("encontro datos de la empresa" + JSON.stringify(response));
        setIdEmpresa(response.empresa?.id);
        setEmpresaNombre(response.empresa?.nombre);
      }
    } catch (error) {
      console.log("Error al obtener los datos de la empresa");
    }
  };
  //obtiene la información detallada de los acopios de una empresa, tal como
  //rutas y recolectores
  const getAcopiosDetalle = async () => {
    try {
      const response = await makeSafeGetRequest(
        "acopiosdetalle/" + idEmpresa,
        auth.accessToken
      );
      if (response && response?.data) {
        setAcopiosDetalle(response.data);
      }
    } catch (error) {
      console.log("Error al obtener los acopios" + error);
    }
  };
 
  const getSumValues = async () => {
    console.log("ACOPIOS A DETALLE" + JSON.stringify(acopiosDetalle));
    var s = [];
    var x = 0;
    for (const a of acopiosDetalle) {
      console.log(x);
      console.log(JSON.stringify(a.id));
      console.log("entra");
      try {
        const response = await makeSafeGetRequest(
          "inventarioacopio/" + a.id,
          auth.accessToken
        );
        if (response && response?.status === 200) {
          console.log(JSON.stringify(response.data));
          s.push({ ...response.data, nombre: a.nombre });
          console.log("inventario acopio" + JSON.stringify(s));
        } else {
          console.log("no entraaaaaaaaaaaa==================>");
        }
      } catch (error) {
        console.log("error al obtener inventario:" + error);
      }
    }
    setSumas(s);
  };
  //refresca la pagina
  const refresh = () => {
    window.location.reload(false);
  };
  //UseEffect Al obtener el detalle de los acopio
  useEffect(() => {
    if (acopiosDetalle && acopiosDetalle.length > 0) {
      getSumValues();
    }
  }, [acopiosDetalle]);

  //cuando se obtenga el id de la empresa, obtener el detalle de los acopios que pertenecen a ella
  useEffect(() => {
    if (idEmpresa) {
      getAcopiosDetalle();
    }
  }, [idEmpresa]);

  //useeffect inicial
  useEffect(() => {
    getIdEmpresa();
  }, []);
  return (
    <div className="section h-full md:w-8/12 my-3 py-3">
      <h1 className="text text-3xl">Monitor de Actividades</h1>
      <div className="grid grid-cols-1">
        <h3 className="text text-2xl">
          <span className="font-bold">Empresa: </span>
          {empresaNombre}
        </h3>
        <button className="btn btn-success btn-block my-3" onClick={refresh}>
          Actualizar
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
            />
          </svg>
        </button>

        {sumas && sumas.length > 0 ? (
          <div className="collapse ">
            <h3 className="collapse-title text-xl text-center font-semibold display-flex items-center">
              Vista general ▼
            </h3>
            <input type="checkbox" />
            <div className="collapse-content grid md:grid-cols-2 shadow-md p-3 m-3">
              {sumas.map((suma) => (
                <>
                  <div className="text-left grid grid-cols-1 m-5" key={suma.id}>
                    <span className="text font-semibold text-lg">
                      {suma?.nombre}
                    </span>
                    <span className="text">
                      <span className="text font-semibold">
                        Cantidad inicial:
                      </span>{" "}
                      {suma?.cantidad_inicial + " litros"}
                    </span>
                    <span className="text">
                      <span className="text font-semibold">
                        Cantidad inicial (novedad):
                      </span>{" "}
                      {suma?.novedad_inicial + " litros"}
                    </span>
                    <span className="text">
                      <span className="text font-semibold">Ingresos:</span>{" "}
                      {suma?.ingresos + " litros"}
                    </span>
                    <span className="text">
                      <span className="text font-semibold">
                        Ingresos (novedad):
                      </span>{" "}
                      {suma?.ingresos_novedad + " litros"}
                    </span>
                    <span className="text">
                      <span className="text font-semibold">Ventas:</span>{" "}
                      {suma?.ventas + " litros"}
                    </span>
                    <span className="text">
                      <span className="text font-semibold">
                        Ventas (novedad):
                      </span>{" "}
                      {suma?.ventas_novedad + " litros"}
                    </span>
                    <span className="text">
                      <span className="text font-semibold">
                        Traslados a favor:
                      </span>{" "}
                      {suma?.trasladosfavor + " litros"}
                    </span>
                    <span className="text">
                      <span className="text font-semibold">
                        Traslados a favor (novedad):
                      </span>{" "}
                      {suma?.trasladosfavornovedad + " litros"}
                    </span>
                    <span className="text">
                      <span className="text font-semibold">
                        Traslados salientes:
                      </span>{" "}
                      {suma?.trasladossal + " litros"}
                    </span>
                    <span className="text">
                      <span className="text font-semibold">
                        Traslados salientes (novedad):
                      </span>{" "}
                      {suma?.trasladossalnovedad + " litros"}
                    </span>
                    <span className="text">
                      <span className="text font-semibold">Devoluciones :</span>{" "}
                      {suma?.devoluciones + " litros"}
                    </span>
                    <span className="text">
                      <span className="text font-semibold">
                        Devoluciones (novedad):
                      </span>{" "}
                      {suma?.devolucionesnovedad + " litros"}
                    </span>
                    <span className="text">
                      <span className="text font-semibold">Total:</span>{" "}
                      {suma?.total_inventario + " litros"}
                    </span>
                    <span className="text">
                      <span className="text font-semibold">
                        Total (novedad):
                      </span>{" "}
                      {suma?.total_novedades + " litros"}
                    </span>
                  </div>
                </>
              ))}
              <div className="md:col-span-2">
                <span className="text text-lg">
                  <span className="font-semibold ">
                    Total disponible {empresaNombre}:
                  </span>
                  <br></br>
                  <label>
                    {sumas.reduce((c, suma) => c + suma.total_inventario, 0) +
                      " litros"}
                  </label>
                  <br></br>
                  <label>
                    {sumas.reduce((c, suma) => c + suma.total_novedades, 0) +
                      " litros (novedad)"}
                  </label>
                </span>
              </div>
            </div>
          </div>
        ) : (
          <>{"Cargando vista general..."}</>
        )}
        <h3 className="text-2xl my-2">Acopios</h3>
        {acopiosDetalle && acopiosDetalle.length > 0 ? (
          acopiosDetalle?.map((acopio) => (
            <React.Fragment key={acopio?.id}>
              <div
                className="grid md:grid-cols-2 p-5 m-3 gap-3 shadow-md"
                key={acopio.id}
              >
                <h2 className="card-title my-3">{acopio.nombre}</h2>

                {/* aperturas y cierres */}
                <div className="grid grid-cols-1">
                  {acopio?.aperturas_consolidados &&
                  acopio.aperturas_consolidados?.length > 0 ? (
                    <div className="collapse">
                      <input type="checkbox" />
                      <span className="collapse-title text-success text-xl">
                        Apertura realizada. Ver
                      </span>
                      {acopio?.aperturas_consolidados.map((apertura) => (
                        <div
                          className="collapse-content grid grid-cols-1 text-left"
                          key={apertura?.id}
                        >
                          <p>
                            <span className="font-semibold">Hora: </span>
                            {apertura.hora.split(".")[0]}
                          </p>
                          <p>
                            <span className="font-semibold">
                              Cant. Anterior:{" "}
                            </span>
                            {apertura.cantidad_dia_anterior + " litros"}
                          </p>
                          <p>
                            <span className="font-semibold">
                              Cant. Medida:{" "}
                            </span>
                            {apertura.cantidad_medida + " litros"}
                          </p>
                          <p>
                            <span className="font-semibold">Diferencia: </span>
                            {apertura?.diferencia >= 0 ? (
                              <span className="text-success">

                                {apertura.diferencia + " litros"}
                              </span>
                            ) : (
                              <span className="text-error">
                                {apertura.diferencia + " litros"}
                              </span>
                            )}
                          </p>
                          <p>
                            <span className="font-semibold">
                              Cant. Novedad Anterior:{" "}
                            </span>
                            {apertura.novedad_dia_anterior + " litros"}
                          </p>
                          <p>
                            <span className="font-semibold">
                              Novedad Medida:{" "}
                            </span>
                            {apertura.novedad_medida + " litros"}
                          </p>
                          <p>
                            <span className="font-semibold">
                              Diferencia novedad:{" "}
                            </span>
                            {apertura?.diferencia_novedad >= 0 ? (
                              <span className="text-success">
                                {apertura.diferencia_novedad + " litros"}
                              </span>
                            ) : (
                              <span className="text-error">
                                {apertura.diferencia_novedad + " litros"}
                              </span>
                            )}
                          </p>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <span className="text-xl text-warning">
                      Apertura de consolidado pendiente
                    </span>
                  )}
                  {acopio.consolidados_leche &&
                  acopio.consolidados_leche?.length > 0 ? (
                    <div className="collapse">
                      <input type="checkbox" />
                      <span className="collapse-title text-success">
                        Cierre realizado. Ver
                      </span>
                      {acopio?.consolidados_leche?.map((consolidado) => (
                        <div
                          className="collapse-content grid grid-cols-1 text-left"
                          key={consolidado?.id}
                        >
                          <p>
                            <span className="font-semibold">Hora: </span>
                            {consolidado.hora?.split(".")[0]}
                          </p>
                          <p>
                            <span className="font-semibold">
                              Cant. Inicial:{" "}
                            </span>
                            {consolidado.cantidad_inicial + " litros"}
                          </p>
                          <p>
                            <span className="font-semibold">Ingresos: </span>
                            {consolidado.cantidad_recibida + " litros"}
                          </p>
                          <p>
                            <span className="font-semibold">Ventas: </span>
                            {consolidado.cantidad_vendida + " litros"}
                          </p>
                          <p>
                            <span className="font-semibold">Traslados: </span>
                            {consolidado.traslados_favor + " litros"}
                          </p>
                          <p>
                            <span className="font-semibold">
                              Traslados Salientes:{" "}
                            </span>
                            {consolidado.traslados_salientes + " litros"}
                          </p>
                          <p>
                            <span className="font-semibold">
                              Devoluciones:{" "}
                            </span>
                            {consolidado.traslados_salientes + " litros"}
                          </p>
                          <p>
                            <span className="font-semibold">
                              Cant. Calculada:{" "}
                            </span>
                            {consolidado.cantidad_calculada + " litros"}
                          </p>
                          <p>
                            <span className="font-semibold">Cant. Final: </span>
                            {consolidado.cantidad_final_dia + " litros"}
                          </p>
                          <p>
                            <span className="font-semibold">Diferencia: </span>
                            {consolidado.diferencia + " litros"}
                          </p>
                          {/* novedad */}
                          <h6 className="font-semibold text-center">
                            Novedades
                          </h6>
                          <p>
                            <span className="font-semibold">
                              Cant. Novedad Inicial:{" "}
                            </span>
                            {consolidado.novedad_inicial + " litros"}
                          </p>
                          <p>
                            <span className="font-semibold">
                              Ingresos Novedad:{" "}
                            </span>
                            {consolidado.ingresos_novedad + " litros"}
                          </p>
                          <p>
                            <span className="font-semibold">
                              Ventas Novedad:{" "}
                            </span>
                            {consolidado.ventas_novedad + " litros"}
                          </p>
                          <p>
                            <span className="font-semibold">
                              Traslados a favor Novedad:{" "}
                            </span>
                            {consolidado.traslados_favor_novedad + " litros"}
                          </p>
                          <p>
                            <span className="font-semibold">
                              Traslados Sal. Novedad:{" "}
                            </span>
                            {consolidado.traslados_sal_novedad + " litros"}
                          </p>
                          <p>
                            <span className="font-semibold">
                              Cant. Novedad Calculada:{" "}
                            </span>
                            {consolidado.novedad_calculada + " litros"}
                          </p>
                          <p>
                            <span className="font-semibold">
                              Cant. Final Novedad:{" "}
                            </span>
                            {consolidado.novedad_final_dia + " litros"}
                          </p>
                          <p>
                            <span className="font-semibold">
                              Diferencia en Novedad:{" "}
                            </span>
                            {consolidado.novedad_diferencia + " litros"}
                          </p>
                          <p>
                            <span className="font-semibold">
                              Observaciones:{" "}
                            </span>
                            {consolidado.observaciones}
                          </p>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <span className=" text-xl text-warning">
                      Cierre de consolidado pendiente
                    </span>
                  )}
                </div>

                <p className="text text-xl font-semibold md:col-span-2">
                  DETALLE DE AVANCE DE RUTAS
                </p>
                {acopio?.rutas?.map((ruta) => (
                  <div className="flex-col mx-2">
                    <p className="font-bold">{ruta.nombre}</p>
                    <RecoleccionesCounter
                      ruta={ruta.id}
                      cant={1}
                      key={ruta.id}
                    ></RecoleccionesCounter>
                  </div>
                ))}

                {acopio.ingresos && acopio?.ingresos?.length > 0 ? (
                  <>
                    <h2 className="text-xl font-semibold md:col-span-2">
                      Ingresos
                    </h2>
                    {acopio.ingresos.map((ingreso) => (
                      <div
                        className="grid grid-cols-1 text-left"
                        key={ingreso?.id}
                      >
                        <span className="text">
                          <span className="text font-semibold">Ruta: </span>
                          {ingreso?.recorrido?.ruta?.nombre}
                        </span>
                        <span className="text">
                          <span className="text font-semibold">Hora: </span>
                          {ingreso?.hora.split(".")[0]}
                        </span>
                        <span className="text">
                          <span className="text font-semibold">
                            Cantidad calculada:{" "}
                          </span>
                          {ingreso?.cantidad_leche + " litros"}
                        </span>
                        <span className="text">
                          <span className="text font-semibold">
                            Cantidad medida:
                          </span>
                          {ingreso?.cantidad_medida + " litros"}
                        </span>

                        <span className="text">
                          <span className="text font-semibold">
                            Cantidad con novedad:
                          </span>
                          {ingreso?.cantidad_rechazada + " litros"}
                        </span>
                        <span className="text">
                          <span className="text font-semibold">
                            {"Diferencia: "}
                          </span>
                          {ingreso?.diferencia >= 0 ? (
                            <span className="text-success">
                              {ingreso?.diferencia + " litros"}
                            </span>
                          ) : (
                            <span className="text-error">
                              {ingreso?.diferencia + " litros"}
                            </span>
                          )}
                        </span>
                        <span className="text">
                          <span className="text font-semibold">
                            ingreso CON novedad:{" "}
                          </span>
                          {ingreso?.aceptado ? (
                            <span className="text-success">NO</span>
                          ) : (
                            <span className="text-error">SI</span>
                          )}
                        </span>
                        <span className="text">
                          <span className="font-semibold">Observaciones: </span>
                          {ingreso?.observaciones?.substring(0, 30) + "..."}
                        </span>
                        <div className="divider "></div>
                      </div>
                    ))}
                  </>
                ) : (
                  <div className="text text-xl text-error md:col-span-2">
                    El acopio aún no tiene ingresos
                  </div>
                )}
                {acopio?.ventas_leche && acopio?.ventas_leche?.length > 0 ? (
                  <>
                    <h2 className="text-xl font-semibold md:col-span-2">
                      Ventas
                    </h2>
                    {acopio?.ventas_leche?.map((venta) => (
                      <div
                        className="grid grid-cols-1 m-1 shadow-sm p-2 text-left"
                        key={venta?.id}
                      >
                        <p>
                          <span className="font-bold">Hora: </span>
                          {venta?.hora?.split(".")[0]}
                        </p>
                        <p>
                          <span className="font-bold">Cliente: </span>
                          {venta?.cliente?.nombre}
                        </p>
                        <p>
                          <span className="font-bold">Placa: </span>
                          {venta.placa_vehiculo}
                        </p>
                        <p>
                          <span className="font-bold">Cantidad: </span>
                          {venta.cantidad_leche+ " litros"}
                        </p>
                        <p>
                          <span className="font-bold">Es Novedad: </span>
                          {venta.venta_novedad === true ? (
                            <span className="text-error">SI</span>
                          ) : (
                            <span className="text-success">NO</span>
                          )}
                        </p>
                        <p>
                          <span className="font-bold">Observaciones: </span>
                          {venta.observaciones}
                        </p>
                      </div>
                    ))}
                  </>
                ) : (
                  <div className="text text-xl text-error md:col-span-2">
                    El acopio aún no tiene ventas registradas
                  </div>
                )}
                {acopio?.traslado_ingresos &&
                acopio?.traslado_ingresos?.length > 0 ? (
                  <>
                    <h2 className="text-xl font-semibold md:col-span-2">
                      Traslados a favor
                    </h2>
                    {acopio?.traslado_ingresos?.map((traslado) => (
                      <div
                        className="grid grid-cols-1 m-1 shadow-sm p-2 text-left"
                        key={traslado?.id}
                      >
                        <p>
                          <span className="font-bold">Acopio origen: </span>
                          {traslado?.acopio_origen?.nombre}
                        </p>
                        <p>
                          <span className="font-bold">Hora: </span>
                          {traslado?.hora?.split(".")[0]}
                        </p>
                        <p>
                          <span className="font-bold">Cantidad enviada: </span>
                          {traslado?.cantidad_enviada + " litros"}
                        </p>
                        <p>
                          <span className="font-bold">Cantidad recibida: </span>
                          {traslado?.cantidad_recibida + " litros"}
                        </p>
                        <p>
                          <span className="font-bold">Diferencia : </span>
                          {traslado?.diferencia >= 0 ? (
                            <span className="text-success">
                              {traslado?.diferencia + " litros"}
                            </span>
                          ) : (
                            <span className="text-error">
                              {traslado?.diferencia + " litros"}
                            </span>
                          )}
                        </p>
                        <p>
                          <span className="font-bold">Es Novedad: </span>
                          {traslado?.traslado_novedad === true ? (
                            <span className="text-error">SI</span>
                          ) : (
                            <span className="text-success">NO</span>
                          )}
                        </p>
                        <p>
                          <span className="font-bold">Observaciones: </span>
                          {traslado?.observaciones}
                        </p>
                      </div>
                    ))}
                  </>
                ) : (
                  <div className="text text-xl text-error md:col-span-2">
                    El acopio aún no tiene traslados a favor registrados
                  </div>
                )}
                {acopio?.devoluciones &&
                acopio?.devoluciones?.length > 0 ? (
                  <>
                    <h2 className="text-xl font-semibold md:col-span-2">
                      Devoluciones
                    </h2>
                    {acopio?.devoluciones?.map((devolucion) => (
                      <div
                        className="grid grid-cols-1 m-1 shadow-sm p-2 text-left"
                        key={devolucion?.id}
                      >
                        <p>
                          <span className="font-bold">Acopio origen: </span>
                          {devolucion?.acopio_origen?.nombre}
                        </p>
                        <p>
                          <span className="font-bold">Hora: </span>
                          {devolucion?.hora?.split(".")[0]}
                        </p>
                        <p>
                          <span className="font-bold">Cliente: </span>
                          {devolucion?.cliente?.nombre}
                        </p>
                        <p>
                          <span className="font-bold">Cantidad: </span>
                          {devolucion?.cantidad+" litros"}
                        </p>
                        
                        <p>
                          <span className="font-bold">Es Novedad: </span>
                          {devolucion?.traslado_novedad === true ? (
                            <span className="text-error">SI</span>
                          ) : (
                            <span className="text-success">NO</span>
                          )}
                        </p>
                        <p>
                          <span className="font-bold">Observaciones: </span>
                          {devolucion?.observaciones}
                        </p>
                      </div>
                    ))}
                  </>
                ) : (
                  <div className="text text-xl text-error md:col-span-2">
                    El acopio aún no tiene devoluciones registradas
                  </div>
                )}
              </div>
            </React.Fragment>
          ))
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default MonitorEmpresa;
