import React, { useEffect, useRef, useState } from "react";
import { useDownloadExcel } from "react-export-table-to-excel";
import { makeSafeGetRequest } from "./utils/Requests";
import useAuth from "../hooks/useAuth";
import { getEmpresaAdmin } from "../api/RoleInfo";

function ReporteTrasladosSalAcopio() {
  const { auth } = useAuth();
  const [idEmpresa, setIdEmpresa] = useState();
  const [nombreEmpresa, setNombreEmpresa] = useState();
  const [trasladosSalientes, setTrasladosSalientes] = useState();
  const [acopios, setAcopios] = useState();
  const [selAcopio, setSelAcopio] = useState();
  const [selAcopioNombre, setSelAcopioNombre] = useState();
  const [fechaInicial, setFechaInicial] = useState();
  const [fechaFinal, setFechaFinal] = useState();
  const tableRef = useRef(null);
  //obtiene datos de la empresa
  const getEmpresa = async () => {
    try {      
      const response = await getEmpresaAdmin(auth);
      if (response) {
        console.log("encontro datos de la empresa" + JSON.stringify(response));
        setIdEmpresa(response.empresa?.id);
        setNombreEmpresa(response.empresa?.nombre);
      }
    } catch (error) {
      console.log("Error al obtener los datos de la empresa");
    }
  };

  //Obtiene la lista de los acopios de la empresa
  const getAcopios = async () => {
    try {
      const response = await makeSafeGetRequest(
        "acopiosempresa/" + idEmpresa,
        auth.accessToken
      ); //cambiar por el id de la empresa del admin
      if (response) {
        setAcopios(response.data);
      } else {
        setAcopios();
      }
    } catch (error) {
      console.log("Error al obtener las empresas");
    }
  };
  //obtiene la lista de traslados salientes en una fecha determinada
  const getTrasladosSalientes = async () => {
    try {
      const response = await makeSafeGetRequest(
        "trasladossalientesrango?idacopio=" +
          selAcopio +
          "&fechainicial=" +
          fechaInicial +
          "&fechafinal=" +
          fechaFinal,
        auth.accessToken
      );
      if (response) {
        setTrasladosSalientes(response.data);
      } else {
        setTrasladosSalientes();
      }
    } catch (error) {
      console.log("error al obtener los traslados" + error);
    }
  };

  //genera el reporte en excel
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "traslados_salientes_"+selAcopioNombre+"_"+fechaInicial+"_"+fechaFinal,
    sheet: "traslados salientes",
  });
  
  //cambio de acopio seleccionado
  const onAcopioChange=(e)=>{
    setSelAcopio(e.target.value);
    setSelAcopioNombre(e.target.selectedOptions[0].text)
  };
    useEffect(() => {
    if (idEmpresa) {
      getAcopios();
    }
  }, [idEmpresa]);
  //useEffect inicial
  useEffect(() => {
    getEmpresa();
  }, []);
  //useeffect fechas
  useEffect(() => {
    console.log("Cambio en fechas");
  }, [fechaFinal, fechaInicial]);
  return (
    <div className="h-full w-7/12">
      <h1 className="text-3xl">Reporte de traslados salientes por acopio</h1>
      <div className="grid grid-cols-1">
        {acopios ? (
          <div className="form-control">
            <label className="label">Empresa: {nombreEmpresa}</label>
            <label className="label">Seleccione un acopio</label>
            <select
              className="select select-bordered"
              onChange={(e) => {
                onAcopioChange(e);
              }}
            >
              <option key={"-1"} value={-1}>
                Seleccione un acopio
              </option>
              {acopios?.map((acopio) => (
                <option key={acopio?.id} value={acopio?.id}>
                  {acopio?.nombre}
                </option>
              ))}
            </select>
          </div>
        ) : (
          <></>
        )}
        <label className="label">Fecha Inicial</label>
        <input
          type={"date"}
          className="input input-bordered"
          onChange={(e) => setFechaInicial(e.target.value)}
        ></input>
        <label className="label">Fecha Final</label>
        <input
          type={"date"}
          className="input input-bordered"
          onChange={(e) => setFechaFinal(e.target.value)}
        ></input>
        <button className="btn btn-accent my-5" onClick={getTrasladosSalientes}>
          Generar reporte
        </button>
        {trasladosSalientes && trasladosSalientes.length > 0 ? (
          <div className="overflow-x-auto">
            <table className="table table-compact " ref={tableRef}>
              <thead>
                <tr>
                  <th></th>
                  <th>Acopio Origen</th>
                  <th>Acopio destino</th>
                  <th>Fecha</th>
                  <th>Hora</th>
                  <th>cant. enviada</th>

                  <th>Novedad?</th>
                </tr>
              </thead>
              <tbody>
                {trasladosSalientes?.map((traslado) => (
                  <tr key={traslado?.id} value={traslado.id}>
                    <td></td>
                    <td>{traslado?.acopio_origen?.nombre}</td>
                    <td>{traslado?.acopio_destino?.nombre}</td>
                    <td>{traslado?.fecha}</td>
                    <td>{traslado?.hora?.split(".")[0]}</td>
                    <td>{traslado?.cantidad_leche}</td>

                    <td>
                      {traslado?.traslado_novedad === true ? (
                        <span className="font-bold text-error">Si</span>
                      ) : (
                        <span className="font-bold text-success">No</span>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            
          </div>
        ) : (
          <></>
        )}
      <button className="btn" onClick={onDownload}>
              Exportar Excel
            </button>
      </div>
    </div>
  );
}

export default ReporteTrasladosSalAcopio;
