import React, { useEffect, useState } from "react";
import useAuth from "../../hooks/useAuth";
import { getEmpresaAdmin } from "../../api/RoleInfo";
import { ToastContainer } from "react-toastify";
import { makeSafeGetRequest, makeSafePutRequest } from "../utils/Requests";
import { notifyPendingNotify, popNotification } from "../utils/Notify";

import EditOperario from "./EditOperario";
import DeleteTable from "./DeleteTable";

function VerOperario() {
  const { auth } = useAuth();
  const [idEmpresa, setIdEmpresa] = useState();
  const [nombreEmpresa, setNombreEmpresa] = useState();
  const [operariosEmpresa, setOperariosEmpresa] = useState();
  const [searchedOperarios, setSearchedOperarios] = useState();
  const [operarioId, setOperarioId] = useState();
  const [operarioNombre, setOperarioNombre] = useState();
  const [recoActivo, setRecoActivo] = useState(false);
  const [operario, setOperario] = useState();

  //obtiene el id de la empresa
  const getIdEmpresa = async () => {
    try {
      const response = await getEmpresaAdmin(auth);
      if (response) {
        console.log("encontro datos de la empresa" + JSON.stringify(response));
        setIdEmpresa(response.empresa?.id);
        setNombreEmpresa(response.empresa?.nombre);
      }
    } catch (error) {
      console.log("Error al obtener los datos de la empresa");
    }
  };

  //Lista los operarioes de la empresa
  const getOperarios = async () => {
    try {
      const response = await makeSafeGetRequest(
        "operariosempresa/" + idEmpresa,
        auth.accessToken
      );
      if (response && response.status === 200) {
        setOperariosEmpresa(response.data);
        setSearchedOperarios([]);
      }
    } catch (error) {
      console.log("error al obtener los operarios: " + error);
    }
  };
  //Busqueda de productores por coincidencia de caracteres
  const onsearch = (searchTerm) => {
    console.log("termino :" + searchTerm);
    if (searchTerm === "" || searchTerm === undefined) {
      setSearchedOperarios();
    } else {
      const filteredItems = operariosEmpresa?.filter((prod) => {
        return prod?.nombre_corto
          .toUpperCase()
          .includes(searchTerm.toUpperCase());
      });
      setSearchedOperarios(filteredItems?.slice(0, 10));
    }
  };

  //Determina las acciones a  realizar al seleccionar un productor
  const onSelectedOperario = (e) => {
    const selProductor = searchedOperarios.filter(
      (prod) => prod.id === parseInt(e.target.value)
    );
    console.table(selProductor);
    setSearchedOperarios([...selProductor]);
  };
  //obtiene el productor que tiene el id indicado
  const getOperario = (idOperario) => {
    const operario = operariosEmpresa.find(
      (operario) => operario.id === idOperario
    );
    console.log("operario:" + JSON.stringify(operario));
    setOperario(operario);
  };
  //actiualiza el estado del operario a activo o inactivo
  const changeActivo = async () => {
    const notify = notifyPendingNotify();
    try {
      const response = await makeSafePutRequest(
        "operarios/" + operarioId,

        { data: { activo: recoActivo ? !recoActivo : true } },
        auth.accessToken
      );
      if (response && response?.status === 200) {
        popNotification(
          notify,
          "success",
          "Productor " +
            operarioNombre +
            (recoActivo && !Object.is(recoActivo, null)
              ? " desactivado "
              : " activado ") +
            "correctamente"
        );
        await getOperarios();
      } else {
        popNotification(
          notify,
          "error",
          "Productor no actualizado.Intente de nuevo"
        );
      }
    } catch (error) {
      console.log(error);
      popNotification(notify, "error", "Ha ocurrido un error al actualizar.");
    }
  };
  //productor compacrto para activar
  const setProdData = (productor) => {
    console.log("aaaaaaaaaaaaaaaaaaaaa");
    setOperarioId(productor.id);
    setOperarioNombre(productor.nombre_corto);
    setRecoActivo(productor.activo);
  };
  useEffect(() => {
    console.log(
      "cambio en porod a editar" + operarioId + operarioNombre + recoActivo
    );
  }, [operarioId, recoActivo, operarioNombre]);
  //useeffect inicial
  useEffect(() => {
    getIdEmpresa();
  }, []);
  //useeffect idempresa
  useEffect(() => {
    if (idEmpresa) {
      console.log("si hay idempresa" + idEmpresa);
      const myProductor = { ...operario };
      myProductor["empresa"] = { id: idEmpresa };
      getOperarios();
    }
  }, [idEmpresa]);

  return (
    <div className="section h-full py-5 mx-2 md:w-9/12">
      <h1 className="text text-3xl mb-3">Ver Operarios</h1>
      <h6 className="text-start">Empresa: {nombreEmpresa}</h6>
      <div className="grid grid-cols-1">
        <label htmlFor="productor" className="label">
          Seleccione un operario
        </label>
        <div className="dropdown dropdown-bottom w-full">
          <label className="input-group w-max">
            <input
              type={"search"}
              className="input input-bordered "
              onChange={(e) => onsearch(e.target.value)}
            ></input>
            <span>Buscar</span>
          </label>

          {searchedOperarios && searchedOperarios.length > 0 ? (
            <ul
              tabIndex={0}
              className="dropdown-content menu p-2 shadow bg-base-100 w-52 "
            >
              {searchedOperarios?.map((operario) => (
                <li
                  className="text-left"
                  key={operario.id}
                  value={operario.id}
                  tabIndex={0}
                  onClick={(e) => {
                    onSelectedOperario(e);
                  }}
                >
                  {operario?.nombre_corto}
                </li>
              ))}
            </ul>
          ) : (
            <></>
          )}
        </div>
        {searchedOperarios ? (
          <div className="overflow-x-auto my-2">
            <table className="table table-compact">
              <thead>
                <tr>
                  <th></th>
                  <th>Documento</th>
                  <th>Nombre</th>
                  <th>Teléfono</th>
                  <th>Acopio</th>
                  <th colSpan={3}>Opciones</th>
                </tr>
              </thead>
              <tbody>
                {searchedOperarios.map((operario) => (
                  <tr key={operario.id} value={operario.id}>
                    <td></td>
                    <td>{operario?.numero_documento}</td>
                    <td>{operario?.nombre_corto}</td>
                    <td>{operario?.telefono}</td>
                    <td>{operario?.acopio?.nombre}</td>

                    <th>
                      {operario?.activo === true ? (
                        <label
                          className="btn btn-ghost"
                          htmlFor="modal-activate"
                          onClick={() => {
                            setProdData(operario);
                          }}
                        >
                          Inactivar
                        </label>
                      ) : (
                        <label
                          className="btn btn-primary"
                          htmlFor="modal-activate"
                          onClick={() => {
                            setProdData(operario);
                          }}
                        >
                          Activar
                        </label>
                      )}
                    </th>
                    <td>
                      <label
                        className="btn btn-warning"
                        onClick={() => getOperario(operario.id)}
                      >
                        Editar
                      </label>
                    </td>
                    <td>
                      {/*Boton parametrizable eliminar  */}
                      <DeleteTable
                        nombre="operarios"
                        registro={operario}
                        registroNombre={"nombre_corto"}
                        queryFunction={getOperarios}
                        onClick={() => getOperario(operario.id)}
                      ></DeleteTable>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div>-</div>
        )}
        {operario ? (
          <EditOperario
            operario={operario}
            idEmpresa={idEmpresa}
            nombreEmpresa={nombreEmpresa}
            getOperarios={getOperarios}
            onsearch={onsearch}
          ></EditOperario>
        ) : (
          <div className="alert">
            Busque o seleccione un operario para empezar
          </div>
        )}
      </div>
      {/* Modal de confirmar */}
      <input type="checkbox" id="modal-activate" className="modal-toggle" />
      <div className="modal">
        <div className="modal-box">
          <h3 className="font-bold text-lg">Aviso</h3>
          <p className="py-4">
            Realmente desea {recoActivo === true ? "INACTIVAR" : "ACTIVAR"} el
            siguiente opererario?
          </p>
          <p>
            <span className="font-semibold">Nombre: </span>
            {operarioNombre}
          </p>
          <div className="modal-action">
            <label
              htmlFor="modal-activate"
              className="btn btn-accent"
              onClick={changeActivo}
            >
              Actualizar productor
            </label>
            <label htmlFor="modal-activate" className="btn">
              Aún no
            </label>
          </div>
        </div>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default VerOperario;
