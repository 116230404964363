import React, { useEffect, useState } from "react";
import { getEmpresaAdmin } from "../api/RoleInfo";
import useAuth from "../hooks/useAuth";
import { makeSafeGetRequest, makeSafePostRequest } from "./utils/Requests";
import { notifyPendingNotify, popNotification } from "./utils/Notify";
import { ToastContainer } from "react-toastify";
import { dateToColombianZoneRq, timeToColombianZonerq } from "./utils/timezoneRequests";

function IngresoPorAjuste() {
  const { auth } = useAuth();
  const [idEmpresa, setIdEmpresa] = useState();
  const [nombreEmpresa, setNombreEmpresa] = useState();
  const [acopios, setAcopios] = useState();
  const [selAcopio, setSelAcopio] = useState();
  const [acopioNombre, setAcopioNombre] = useState();
  const [cant, setCant] = useState(0);
  const [observaciones, setObservaciones] = useState("");
  const [aceptado, setAceptado] = useState(false);

  //Obtiene los datos de la empresa a la que pertenece  el administrador
  const getIdEmpresa = async () => {
    try {
      
      const response = await getEmpresaAdmin(auth);
      if (response) {
        setIdEmpresa(response.empresa?.id);
        setNombreEmpresa(response.empresa?.nombre);
      }
    } catch (error) {
      console.log("Error al obtener los datos de la empresa");
    }
  };
  //Lista los acopios de la empresa
  const getAcopios = async () => {
    try {
      const response = await makeSafeGetRequest(
        "acopiosempresa/" + idEmpresa,
        auth
      );
      if (response) {
        setAcopios(response.data);
      }
    } catch (error) {
      console.log("Error al obtener los acopios de la empresa: " * error);
    }
  };
  //Realiza el guardado del ingreso por ajuste
  const postIngreso = async () => {
    const notify = notifyPendingNotify();
    if(selAcopio && selAcopio!=="-1" && cant>0){
      const fechaHoy=await dateToColombianZoneRq();
      const horaHoy=await timeToColombianZonerq();
      try {
        let data;
        if(aceptado===false){
           data = {
            fecha:fechaHoy,
            hora: horaHoy,
            cantidad_leche: cant,
            cantidad_medida: cant,
            cantidad_rechazada:0,
            diferencia: 0,            
            observaciones: observaciones,
            aceptado: aceptado,
          };
        }else{
          data = {
            fecha:fechaHoy,
            hora: horaHoy,
            cantidad_leche: cant,
            cantidad_medida: 0,
            cantidad_rechazada:cant, 
            diferencia:0,
            observaciones: observaciones,
            aceptado: aceptado,
          };
        }
        const response = await makeSafePostRequest(
          "ingresoporajuste/" + selAcopio,
          data,
          auth.accessToken
        );
        if (response && response.status === 200) {
          popNotification(
            notify,
            "success",
            "Ingreso por ajuste creado correctamente."
          );
        } else {
          popNotification(
            notify,
            "error",
            "No se pudo realizar el ingreso. Intente de nuevo."
          );
        }
      } catch (error) {
        popNotification(
          notify,
          "error",
          "No se pudo realizar el ingreso. Intente de nuevo."
        );
      }
    }else{
      popNotification(
        notify,
        "error",
        "Seleccione un acopio."
      );
    }
  };
  //cambio al obtener el acopio seleccionado
  const onSelectedAcopio = (e) => {
    console.log("Cambio en acopio seleccionado");
    setSelAcopio(e.target.value);
    setAcopioNombre(e.target.selectedOptions[0].text);
  };
  //determina lo que ocurre cuando se modifica la cantidad
  const onCantidadChange = (e) => {
    setCant(e.target.value);
  };
  //captura el cambio en observaciones
  const onObsChange = (e) => {
    setObservaciones(e.target.value);
  };
  const onChangeAceptado = (e) => {
    setAceptado(e.target.checked);
  };
  //useEffect inicial
  useEffect(() => {
    getIdEmpresa();
  }, []);
  //useEffect inicial
  useEffect(() => {
    if (idEmpresa) {
      getAcopios();
    }
  }, [idEmpresa]);
  return (
    <div className="h-full md:w-3/6 my-3 mx-2">
      <div className="grid grid-cols-1 ">
        <h1 className="text text-4xl">Ingreso por ajuste</h1>
        <div className="alert alert-success mt-3">
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 18v-5.25m0 0a6.01 6.01 0 001.5-.189m-1.5.189a6.01 6.01 0 01-1.5-.189m3.75 7.478a12.06 12.06 0 01-4.5 0m3.75 2.383a14.406 14.406 0 01-3 0M14.25 18v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 10-7.517 0c.85.493 1.509 1.333 1.509 2.316V18"
              />
            </svg>
          </span>
          El ingreso por ajuste se usa cuando se requiere tener leche extra para
          completar una venta o un traslado.
        </div>
        <label className="label">Empresa:{nombreEmpresa}</label>
        <label className="label">Acopio</label>
        {acopios ? (
          <select
            className="select select-bordered"
            onChange={(e) => {
              onSelectedAcopio(e);
            }}
          >
            <option key={"-1"} value={"-1"}>
              Seleccione un centro de acopio
            </option>
            {acopios?.map((acopio) => (
              <option key={acopio.id} value={acopio.id}>
                {acopio?.nombre}
              </option>
            ))}
          </select>
        ) : (
          <>Cargando acopios...</>
        )}
        {selAcopio ? (
          <div className="card">
            <label className="label">Cantidad a ajustar</label>
            <label className="input-group">
              <input
                type="number"
                placeholder="Cantidad"
                className="input input-bordered"
                min={0}
                onChange={(e) => onCantidadChange(e)}
              />
              <span>Litros</span>
            </label>
            <label className="label">Observaciones</label>
            <textarea
              className="textarea textarea-bordered"
              onChange={(e) => {
                onObsChange(e);
              }}
            ></textarea>
            <label className="label">Ingreso rechazado</label>
            <label className="label cursor-pointer">
              <span className="label-text">No</span>
              <input
                type="checkbox"
                className="toggle toggle-accent"
                checked={aceptado}
                onChange={onChangeAceptado}
              />
              <span className="label-text">Sí</span>
            </label>
            <label className="btn btn-accent" htmlFor="modalConfirm">
              Confirmar ingreso
            </label>
          </div>
        ) : (
          <></>
        )}
        <input
          type={"checkbox"}
          id="modalConfirm"
          className="modal-toggle"
        ></input>
        <div className="modal">
          <div className="modal-box">
            <h3 className="font-bold text-lg">Aviso</h3>
            <p>¿Realmente desea realizar el siguiente ingreso por ajuste?</p>
            <p>
              <span className="font-bold">Acopio: </span>
              {acopioNombre}
            </p>
            <p>
              <span className="font-bold">Total: </span>
              {cant + " litros"}
            </p>
            <p>
              <span className="font-bold">Ingreso rechazado: </span>
              {aceptado === true ? (
                <span className="text-error">Si</span>
              ) : (
                <span className="text-success">No</span>
              )}
            </p>
            <p>
              <span className="font-bold">Observaciones: </span>
              {observaciones}
            </p>
            <div className="modal-action">
              <label
                htmlFor="modalConfirm"
                className="btn btn-accent"
                onClick={() => postIngreso()}
              >
                Sí
              </label>
              <label htmlFor="modalConfirm" className="btn">
                Aún no
              </label>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default IngresoPorAjuste;
