import React, { useEffect, useState } from "react";
import useAuth from "../../hooks/useAuth";
import { makeSafeGetRequest, makeSafePostRequest } from "../utils/Requests";
import { ToastContainer } from "react-toastify";
import { notifyPendingNotify, popNotification } from "../utils/Notify";
import { getEmpresaAdmin } from "../../api/RoleInfo";

function CreateRuta() {
  const { auth } = useAuth();
  const [idEmpresa, setIdEmpresa] = useState(); //en adelante llamar desde aqui el getempresa
  const [nombreEmpresa, setNombreEmpresa] = useState();
  const [acopios, setAcopios] = useState();
  const [selAcopio, setSelAcopio] = useState();
  const [selAcopioNombre, setSelAcopioNombre] = useState();
  const [nombre, setNombre] = useState("");
  const [observaciones, setObservaciones] = useState("");
  const [dataValid, setDataValid] = useState("false");

  //obtiene el id de la empresa
  const getIdEmpresa = async () => {
    try {
      const response = await getEmpresaAdmin(auth);
      if (response) {
        console.log("encontro datos de la empresa" + JSON.stringify(response));
        setIdEmpresa(response.empresa?.id);
        setNombreEmpresa(response.empresa?.nombre);
      }
    } catch (error) {
      console.log("Error al obtener los datos de la empresa");
    }
  };
  //Obtiene la lista de acopios de la empresa
  const getAcopios = async () => {
    try {
      const response = await makeSafeGetRequest(
        "acopiosempresa/" + idEmpresa,
        auth.accessToken
      );
      if (response && response.status === 200) {
        setAcopios(response.data);
      }
    } catch (error) {
      console.log("No se pudo obtener los acopios de la empresa:" + error);
    }
  };
  //Crea un nuevo acopio con los datos digitados
  const postRuta = async () => {
    const notify = notifyPendingNotify();
    try {
      const response = await makeSafePostRequest(
        "rutas",
        {
          data: {
            empresa: idEmpresa,
            acopio: selAcopio,
            nombre: nombre,
            observaciones: observaciones,
          },
        },
        auth.accessToken
      );
      if (response && response.status === 200) {
        popNotification(notify, "success", "Acopio creado correctamente");
        console.log("acopio creado");
        clearData();
      } else {
        popNotification(notify, "error", "No se pudo crear el acopio");
        console.log("no se pudo crear el acopio");
      }
    } catch (error) {
      popNotification(notify, "error", "Error al crear el acopio");
      console.log("error al postear el acopio: " + error);
    }
  };
  //Determina los cambios en acopio seleccionado
  const onSelectedAcopio=(e)=>{
    if(parseInt(e)>0){
      setSelAcopio(e.target.value);
      console.log(e.target)
      setSelAcopioNombre(e.target.selectedOptions[0].text);
    }
  }
  //verifica que la data esté completa
  const validateData=()=>{
    if(nombre!=="" && selAcopio!==null && parseInt(selAcopio)>0){
      setDataValid(true);
    }else{
      setDataValid(false);
    }
  }
  //limpia la datad  del formulario
  const clearData = () => {
    setNombre("");    
    setObservaciones("");
  };
  //useEffect rutas
  useEffect(() => {
    if (idEmpresa) {
      getAcopios();
    }
  }, [idEmpresa]);
  //UseEffect inicial
  useEffect(() => {
    getIdEmpresa();
  }, []);
  return (
    <div className="h-full my-2 md:w-1/2 py-5 mx-2">
      <div className="grid grid-cols-1">
        <h1 className="text-2xl">Crear Ruta</h1>
        <h3 className="text-xl text-left">Datos de la Ruta</h3>
        <label className="label">Empresa: {nombreEmpresa}</label>
        <label className="label">Acopio</label>
        <select
          className="select select-bordered"
          onChange={(e) => onSelectedAcopio(e)}
        >
          <option key={"-1"} value={"-1"}>
            {"Seleccione un acopio"}
          </option>
          {acopios && acopios.length > 0 ? (
            <>
              {acopios.map((acopio) => (
                <option key={acopio.id} value={acopio.id}>
                  {acopio.nombre}
                </option>
              ))}
            </>
          ) : (
            <>cargando acopios</>
          )}
        </select>
        <label className="label">Nombre de la Ruta</label>
        <input
          type="text"
          className="input input-bordered"
          onChange={(e) => setNombre(e.target.value)}
          placeholder="Digite un nombre"
        ></input>
        <label className="label">Observaciones</label>
        <textarea
          type="text"
          className="textarea textarea-bordered"
          onChange={(e) => setObservaciones(e.target.value)}
          placeholder="observaciones"
        ></textarea>
        <label className="btn btn-accent my-2" htmlFor="modal-confirm">
          Crear Ruta
        </label>
      </div>
      <input type="checkbox" id="modal-confirm" className="modal-toggle" />
      <div className="modal">
        <div className="modal-box">
          <h3 className="font-bold text-lg">Aviso</h3>
          <p className="py-4">Realmente desea crear la siguiente ruta?</p>
          <p>
            <span className="font-semibold">Nombre: </span>
            {nombre}
          </p>
          <p>
            <span className="font-semibold">Acopio: </span>
            {selAcopioNombre}
          </p>

          <p>
            <span className="font-semibold">Observaciones: </span>
            {observaciones}
          </p>
          <div className="modal-action">
            <label
              htmlFor="modal-confirm"
              className="btn btn-accent"
              onClick={postRuta}
            >
              Crear ruta
            </label>
            <label htmlFor="modal-confirm" className="btn">
              Aún no
            </label>
          </div>
        </div>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default CreateRuta;
