import React, { useState } from "react";
import useAuth from "../../hooks/useAuth";
import { notifyPendingNotify, popNotification } from "../utils/Notify";
import { makeSafePutRequest } from "../utils/Requests";
import { ToastContainer } from "react-toastify";

function DeleteTable(props) {
  const { auth } = useAuth();
  const [nombre] = useState(props.nombre);
  const [registro] = useState(props.registro);
  const [registroNombre]=useState(props.registroNombre)
  //Elimina un registro de la bd
  const deleteData = async () => {
    const notify = notifyPendingNotify();
    try {
      const response = await makeSafePutRequest(
        nombre + "/" + registro.id,
        { data: { eliminado: true } },
        auth.accessToken
      );
      if (response && response.status === 200) {
        popNotification(notify, "success", "Registro eliminado correctamente");
        props.queryFunction();
      } else {
        popNotification(
          notify,
          "error",
          "No se pudo eliminar el registro. Intente de nuevo"
        );
      }
    } catch (error) {
      popNotification(notify, "error", "Error al eliminar el registro");
    }
  };

  return (
    <div>
      <label className="btn btn-error" htmlFor="modal-delete">
        Eliminar
      </label>
      <input type="checkbox" id="modal-delete" className="modal-toggle" />
      <div className="modal">
        <div className="modal-box">
          <h3 className="font-bold text-lg">Aviso</h3>
          <p className="py-4">
            Realmente desea eliminar el siguiente registro?
          </p>
          <p>
            <span className="font-semibold">Nombre:</span>
            {registro[registroNombre]||"NO SE HA ENCONTRADO EL NOMBRE"}
          </p>
          <div className="modal-action">
            <label
              htmlFor="modal-delete"
              className="btn btn-accent"
              onClick={deleteData}
            >
              Eliminar productor
            </label>
            <label htmlFor="modal-delete" className="btn">
              Aún no
            </label>
          </div>
        </div>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default DeleteTable;
