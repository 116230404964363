import React, { useEffect, useState } from "react";
import useAuth from "../../hooks/useAuth";
import { makeSafeGetRequest } from "../utils/Requests";

function GetRecoleccionesProductorRol() {
  const { auth } = useAuth();
  const [idProductor, setIdProductor] = useState();
  const [nombreProductor, setNombreProductor] = useState("");
  const [recoleccionesProductor, setRecoleccionesProductor] = useState();
  const [fechaInicial, setFechaInicial] = useState(null);
  const [fechaFinal, setFechaFinal] = useState(null);

  //obtiene los datos básicos del productor
  const getProductor = async () => {
    try {
      const response = await makeSafeGetRequest(
        "productorusuario/" + auth.id,
        auth.accessToken
      );
      if (response && response.status === 200) {
        setIdProductor(response?.data.id);
        setNombreProductor(response?.data.nombre_corto);
      } else {
        console.log("no se obtuvieron las recolecciones");
      }
    } catch (error) {
      console.log("error al obtener el productor" + error);
    }
  };
  //obtiene las recolecciones del productor en el rango de fechas seleccionado
  const getRecoleccionesProductor = async () => {
    try {
      const response = await makeSafeGetRequest(
        "recoleccionesproductorrol?idproductor=" +
          idProductor +
          "&fechainicial=" +
          fechaInicial +
          "&fechafinal=" +
          fechaFinal,
        auth.accessToken
      );
      if (response && response.status === 200) {
        setRecoleccionesProductor(response.data);
      }
    } catch (error) {
      console.log("error al obtener las recolecciones: " + error);
    }
  };
  //useeffect productor nombre
  useEffect(() => {
    console.log("cambio en nombre de productor" + nombreProductor);
  }, [nombreProductor]);
  //useeffect con productor
  useEffect(() => {
    if (idProductor) {
      getRecoleccionesProductor();
    }
  }, [idProductor]);
  //useeffect inicial
  useEffect(() => {
    getProductor();
  }, []);

  return (
    <div className="h-full md:w-2/4 mx-2 my-5">
      <div className="grid grid-cols-1 ">
        <h1 className="text text-3xl">Entregas del productor</h1>
        <label className="label">Usted es {nombreProductor}</label>
        <label className="label">Seleccione un rango de fechas</label>
        <div className="grid grid-cols-1 md:grid-cols-2 ">
          <div className="p-2 mx-2">
            <label className="label">Desde</label>
            <input
              type="date"
              className="input input-bordered w-full"
              onChange={(e) => setFechaInicial(e.target.value)}
            ></input>
          </div>
          <div className="p-2 mx-2">
            <label className="label">Hasta</label>
            <input
              type="date"
              className="input input-bordered w-full"
              onChange={(e) => setFechaFinal(e.target.value)}
            ></input>
          </div>
        </div>
        <button
          className="btn btn-accent my-2"
          onClick={getRecoleccionesProductor}
        >
          Obtener entregas
        </button>
        {recoleccionesProductor && recoleccionesProductor.length > 0 ? (
          <table className="table table-compact">
            <thead>
              <tr>
                <th></th>
                <th>Fecha</th>
                <th>Hora</th>
                <th>Cantidad</th>
                <th>Ruta</th>
              </tr>
            </thead>
            <tbody>
              {recoleccionesProductor?.map((recoleccion) => (
                <tr key={recoleccion?.id} value={recoleccion.id}>
                  <td></td>
                  <td>{recoleccion.fecha}</td>
                  <td>{recoleccion.hora.split(".")[0]}</td>
                  <td>{recoleccion.cantidad_leche}</td>
                  <td>{recoleccion?.ruta?.nombre}</td>
                </tr>
              ))}
              <tr className=" ">
                <td colSpan={3}></td>
                <td colSpan={2} className="text text-lg text-error font-semibold text-left">
                  Total : 
                  {recoleccionesProductor.reduce((acc, reco) => {
                    return acc + reco.cantidad_leche;
                  }, 0)+" litros"}
                </td>
              </tr>
            </tbody>
          </table>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default GetRecoleccionesProductorRol;
