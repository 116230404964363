import React, { useEffect, useState } from "react";
import useAuth from "../hooks/useAuth";
import { getEmpresaAdmin } from "../api/RoleInfo";
import {
  makeGetRequest,
  makeSafeGetRequest,
  makeSafePutRequest,
} from "./utils/Requests";
import { notifyPendingNotify, popNotification } from "./utils/Notify";
import { ToastContainer } from "react-toastify";
import { validatePlaca, validatePlacaUpdated } from "./utils/CheckPlaca";

function EditVentas() {
  const { auth } = useAuth();
  const [idEmpresa, setIdEmpresa] = useState();
  const [nombreEmpresa, setNombreEmpresa] = useState();
  const [acopios, setAcopios] = useState();
  const [clientesEmpresa, setClientesEmpresa] = useState();
  const [selAcopio, setSelAcopio] = useState();

  const [ventas, setVentas] = useState();
  const [selVenta, setSelVenta] = useState();
  const [ventaEdit, setVentaEdit] = useState();
  const [placaIsValid, setPlacaIsValid] = useState(true);

  //Obtiene los datos de la empresa a la que pertenece  el administrador
  const getIdEmpresa = async () => {
    try {     
      const response = await getEmpresaAdmin(auth);
      if (response) {
        setIdEmpresa(response.empresa?.id);
        setNombreEmpresa(response.empresa?.nombre);
      }
    } catch (error) {
      console.log("Error al obtener los datos de la empresa");
    }
  };
  //Obtiene la lista de clientes de la empresa
  const getClientesEmpresa = async () => {
    try {
      const response = await makeSafeGetRequest(
        "clientesempresa/" + idEmpresa,
        auth.accessToken
      );
      if (response) {
        setClientesEmpresa(response?.data);
      }
    } catch (error) {
      console.log("error al obtener los clientes:" + error);
    }
  };

  //Lista los acopios de la empresa
  const getAcopios = async () => {
    try {
      const response = await makeSafeGetRequest(
        "acopiosempresa/" + idEmpresa,
        auth
      );
      if (response) {
        setAcopios(response.data);
      }
    } catch (error) {
      console.log("Error al obtener los acopios de la empresa: " * error);
    }
  };
  //Obtiene la lista de ventas de un acopio seleccionado.
  const getVentas = async (idAcopio) => {
    try {
      console.log("Acopío seleccionado:" + selAcopio);
      const response = await makeSafeGetRequest(
        "totalventasacopiohoy/" + idAcopio,
        auth.accessToken
      );
      if (response && response.data) {
        setVentas(response.data);
      }
    } catch (error) {
      console.log("Error al obtener las ventas" + error);
    }
  };
  //Postea la información actualizada de la venta
  const updateVenta = async () => {
    const notify = notifyPendingNotify();
    //verificamos que la placa sea valida
    if(placaIsValid){
      try {
        const response = await makeSafePutRequest(
          "updateventa/" + ventaEdit?.id,
          ventaEdit,
          auth.accessToken
        );
        if (response && response.status === 200) {
          console.log("Datos actualizados de la venta");
          popNotification(notify, "success", "Venta actualizada correctamente");
          forgetVentas();
        } else {
          popNotification(notify, "error", "No se pudo actualizar la venta");
        }
      } catch (error) {
        popNotification(notify, "error", "Error al procesar la petición");
      }
    }else{
      popNotification(notify,"error","Debe digitar una placa válida")
    }
  };
  //borra las ventas obtenidas y la venta seleccionada
  const forgetVentas = () => {
    setVentas();
    setSelVenta();
    setVentaEdit();
  };

  //DETERMINA SI EL VALOR QUE VIENE ES UN NUMERO
  const validateNumber = (e) => {
    if (isNaN(parseInt(e.target.value))) {
      console.log("entra");
      e.target.value = 0;
    }
  };

  //cambio al obtener el acopio seleccionado
  const onSelectedAcopio = (e) => {
    console.log("Cambio en acopio seleccionado");
    setSelAcopio(e.target.value);

    getVentas(e.target.value);
  };
  //Cambio cuando se obtiene una venta
  const onSelectedVenta = async (e) => {
    setSelVenta([]);
    setVentaEdit([]);
    const venta = ventas.find((venta) => venta.id === parseInt(e.target.value));
    setSelVenta(JSON.parse(JSON.stringify(venta)));
    setVentaEdit(JSON.parse(JSON.stringify(venta)));
    await getClientesEmpresa();
    console.log("Venta seleccionada: " + JSON.stringify(venta));
  };
  //CAMBIOS AL SELECCIONAR UN CLIENTE ESPECIFICO
  const onSelectedCliente = (e) => {
    let selCliente = clientesEmpresa.find(
      (cliente) => cliente.id === parseInt(e.target.value)
    );
    console.log("cliente encontrado: \n" + JSON.stringify(selCliente));
    let ventaAEditar = { ...ventaEdit };
    ventaAEditar.cliente = selCliente;
    console.log("Nueva venta" + JSON.stringify(ventaAEditar));
    setVentaEdit(ventaAEditar);
  };
  //Valida si la placa es válida
  const valPlaca=(placa)=>{
    setPlacaIsValid(validatePlacaUpdated(placa))
  };
  //cambios en la placa del vehiculo de la venta
  const onChangePlaca = (e) => {
    valPlaca(e.target.value)
    const placa=e.target.value.toUpperCase();
    let ventaAEditar = { ...ventaEdit };
    ventaAEditar.placa_vehiculo = placa;
    console.log("cambio en placa:\n" + JSON.stringify(ventaAEditar));
    setVentaEdit(ventaAEditar);
  };
  //Cambios en las observaciones de la venta
  const onChangeObsVenta = (e) => {
    let ventaAEditar = { ...ventaEdit };
    ventaAEditar.observaciones = e.target.value;
    console.log("cambio en obs:\n" + JSON.stringify(ventaAEditar));
    setVentaEdit(ventaAEditar);
  };
  const onChangeVentaNovedad = (e) => {
    let vEdit={... ventaEdit} ;
    vEdit.venta_novedad= e
    console.log("cambio en toggle:\n" + JSON.stringify(ventaEdit));
    setVentaEdit(vEdit);
  };

  //Cambios en la cantidad del compartimento
  const onChangeCantidad = (e, index) => {
    validateNumber(e);
    console.log("probando index" + index + "heyy");
    let ventaAEditar = {
      ...ventaEdit,
      compartimentos: [...ventaEdit.compartimentos],
    };
    //encuentra el indice compartimento

    if (index !== -1) {
      ventaAEditar.compartimentos[index].cantidad_leche = parseInt(
        e.target.value
      );
      console.log("cambio en cantidad:\n" + JSON.stringify(ventaAEditar));
      setVentaEdit(ventaAEditar);
      setCantidadVenta();
    } else {
      console.log("No se pudo editar");
    }
  };
  //Cuando cambia la cantidad en los compartimientos, este metodo ajusta la cantidad de la venta
  const setCantidadVenta = () => {
    const compartimentos = ventaEdit?.compartimentos;
    console.log("lista de compartimentos" + JSON.stringify(compartimentos));
    const suma = compartimentos.reduce(
      (total, comp) => total + comp.cantidad_leche,
      0
    );
    console.log("total:" + suma);
    const ventaAEditar = getEditableVenta();
    ventaAEditar.cantidad_leche = suma;
    setVentaEdit(ventaAEditar);
  };
  //Cambios en la Temoperatura del compartimento
  const onChangeTemperatura = (e, index) => {
    validateNumber(e);
    console.log("probando index" + index + "heyy");
    let ventaAEditar = {
      ...ventaEdit,
      compartimentos: [...ventaEdit.compartimentos],
    };
    //encuentra el indice compartimento

    if (index !== -1) {
      ventaAEditar.compartimentos[index].temperatura = parseFloat(
        e.target.value
      );
      console.log("cambio en temp:\n" + JSON.stringify(ventaAEditar));
      setVentaEdit(ventaAEditar);
    } else {
      console.log("No se pudo editar");
    }
  };
  const getEditableVenta = () => {
    return {
      ...ventaEdit,
      compartimentos: [...ventaEdit.compartimentos],
    };
  };
  //Cambios en las observaciones del compartimento
  const onChangeObservaciones = (e, index) => {
    console.log("probando index" + index + "heyy");
    let ventaAEditar = {
      ...ventaEdit,
      compartimentos: [...ventaEdit.compartimentos],
    };
    //encuentra el indice compartimento

    if (index !== -1) {
      ventaAEditar.compartimentos[index].observaciones = e.target.value;
      console.log("cambio en obs:\n" + JSON.stringify(ventaAEditar));
      setVentaEdit(ventaAEditar);
    } else {
      console.log("No se pudo editar");
    }
  };
  //Cambio en acopios selecciolnados
  useEffect(() => {
    console.log("Cambio en acopio seleccionado:" + selAcopio);
  }, [selAcopio]);
  //useefect empresa
  useEffect(() => {
    if (idEmpresa) {
      console.log("Obteniendo acopios...");
      getAcopios();
      getClientesEmpresa();
    }
  }, [idEmpresa]);
  //Useefect inicial
  useEffect(() => {
    getIdEmpresa();
    if (idEmpresa) {
      getAcopios();
    }
  }, []);
  return (
    <div className="h-full md:w-7/12">
      <div className="grid grid-cols-1 my-3">
        <h1 className="text-4xl">Edición de Ventas</h1>
        <label className="label">Empresa:{nombreEmpresa}</label>
        <label className="label">Acopio</label>
        {acopios ? (
          <select
            className="select select-bordered"
            onChange={(e) => {
              onSelectedAcopio(e);
            }}
          >
            <option key={"-1"} value={"-1"}>
              Seleccione un centro de acopio
            </option>
            {acopios?.map((acopio) => (
              <option key={acopio.id} value={acopio.id}>
                {acopio?.nombre}
              </option>
            ))}
          </select>
        ) : (
          <>Cargando acopios...</>
        )}

        <label className="label">Ventas del acopio</label>
        {ventas ? (
          <select
            className="select select-bordered"
            onChange={(e) => {
              onSelectedVenta(e);
            }}
          >
            <option key={"-1"} value={"-1"}>
              Seleccione una venta
            </option>
            {ventas?.map((venta) => (
              <option key={venta.id} value={venta.id}>
                {venta?.cliente?.nombre +
                  " - " +
                  venta.cantidad_leche +
                  " litros"}
              </option>
            ))}
          </select>
        ) : (
          <>Seleccione un acopio</>
        )}
        {selVenta ? (
          <div className="card shadow-xl">
            <div className="card-body">
              <div className="card-title">Venta encontrada</div>
              <p>
                <span className="font-bold">Fecha: </span>
                {selVenta?.fecha}
              </p>
              <p>
                <span className="font-bold">Hora: </span>
                {selVenta?.hora?.split(".")[0]}
              </p>
              <p>
                <span className="font-bold">Cliente: </span>
                {selVenta?.cliente?.nombre}
              </p>
              <p>
                <span className="font-bold">Cantidad: </span>
                {selVenta?.cantidad_leche} litros
              </p>
              <p className="text-xl font-bold">
                Distribución en los compartimientos
              </p>

              {selVenta?.compartimentos ? (
                <React.Fragment>
                  <div className="flex flex-col md:flex-row divide-x">
                    {selVenta.compartimentos?.map((comp) => (
                      <div key={comp.id} className="m-3">
                        <p>
                          <span className="font-bold">Compartimento N°: </span>
                          {comp.numero_compartimento}
                        </p>
                        <p>
                          <span className="font-bold">Cantidad: </span>
                          {comp.cantidad_leche} litros
                        </p>
                      </div>
                    ))}
                  </div>
                </React.Fragment>
              ) : (
                <p className="text-lg text-warning font-semibold">
                  La venta no tiene compartimentos
                </p>
              )}

              {ventaEdit ? (
                <div className="grid grid-cols-1">
                  <h3 className="text-xl font-bold">Editar venta</h3>

                  <label className="label">Cliente</label>
                  <select
                    className="select select-bordered"
                    onChange={(e) => onSelectedCliente(e)}
                    value={ventaEdit?.cliente.id || "-1"}
                  >
                    <option value="-1">Seleccione un cliente</option>
                    {clientesEmpresa?.map((cliente) => (
                      <option key={cliente.id} value={cliente.id}>
                        {cliente.nombre}
                      </option>
                    ))}
                  </select>
                  <label className="label">Placas del vehículo</label>
                  <input
                    type="text"
                    className="input input-bordered"
                    value={ventaEdit.placa_vehiculo}
                    
                    onChange={(e) => {
                      onChangePlaca(e);
                    }}
                  ></input>
                  <label className="text-error">
                    {placaIsValid ? (
                      <></>
                    ) : (
                      <>La placa debe estar en formato ABC123</>
                    )}
                  </label>
                  <label className="label">Obs. venta</label>
                  <textarea
                    className="textarea textarea-bordered"
                    value={ventaEdit.observaciones}
                    onChange={(e) => {
                      onChangeObsVenta(e);
                    }}
                  ></textarea>
                  <label className="label">Venta con novedad</label>
                  <label className="label cursor-pointer">
                    <span className="label-text">No</span>
                    <input
                      type="checkbox"
                      className="toggle toggle-accent"
                      checked={!!ventaEdit?.venta_novedad }
                      onChange={
                       (e)=> onChangeVentaNovedad(e.target.checked)}
                    />
                    <span className="label-text">Sí</span>
                  </label>

                  {ventaEdit?.compartimentos ? (
                    <React.Fragment>
                      {ventaEdit?.compartimentos.map((compartimento, index) => (
                        <div
                          className="grid grid-cols-1 m-2 p-2"
                          key={compartimento?.id}
                        >
                          <label className="font-semibold">
                            Compartimento N°{" "}
                            {compartimento?.numero_compartimento}
                          </label>
                          <label className="label">Cantidad de leche</label>
                          <label className="input-group">
                            <input
                              type="number"
                              min={0}
                              step={1}
                              placeholder="Cantidad del compartimento"
                              className="input input-bordered"
                              defaultValue={compartimento.cantidad_leche}
                              onChange={(e) => {
                                onChangeCantidad(e, index);
                              }}
                            />
                            <span>Litros</span>
                          </label>
                          <label className="label">Temperatura</label>
                          <label className="input-group">
                            <input
                              type="number"
                              min={0}
                              max={20}
                              step={0.1}
                              placeholder="Temperatura"
                              className="input input-bordered"
                              defaultValue={compartimento.temperatura}
                              onChange={(e) => {
                                onChangeTemperatura(e, index);
                              }}
                            />
                            <span>°C</span>
                          </label>

                          <label className="label">Observaciones</label>
                          <textarea
                            className="textarea textarea-bordered"
                            placeholder="Observaciones"
                            defaultValue={compartimento.observaciones}
                            onChange={(e) => {
                              onChangeObservaciones(e, index);
                            }}
                          ></textarea>
                          <div className="divider"></div>
                        </div>
                      ))}
                    </React.Fragment>
                  ) : (
                    <div></div>
                  )}
                  <div></div>
                </div>
              ) : (
                <></>
              )}
              <h3 className="text-xl ">
                <span className="font-semibold">
                  Total de la venta a editar:
                </span>
                {ventaEdit?.cantidad_leche + " litros"}
              </h3>
              <div className="card-actions">
                <label className="btn btn-primary" htmlFor="modalConfirm">
                  Editar venta
                </label>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      {/* Modal comfirmar */}
      {/*  Modal de confirmación */}
      <input
        type={"checkbox"}
        id="modalConfirm"
        className="modal-toggle"
      ></input>
      <div className="modal">
        <div className="modal-box">
          <h3 className="font-bold text-lg">Aviso</h3>
          <p>
            ¿Seguro que desea actualizar la venta{" "}
            {selVenta?.cliente?.nombre +
              " - " +
              selVenta?.cantidad_leche +
              " litros"}{" "}
            con la siguinte información?
          </p>
          <p>
            <span className="font-bold">Cliente: </span>
            {ventaEdit?.cliente?.nombre}
          </p>
          <p>
            <span className="font-bold">Total: </span>
            {ventaEdit?.cantidad_leche + " litros"}
          </p>
          <p>
            <span className="font-bold">Observaciones: </span>
            {ventaEdit?.observaciones}
          </p>
          <div className="modal-action">
            <label
              htmlFor="modalConfirm"
              className="btn btn-accent"
              onClick={() => updateVenta()}
            >
              Sí
            </label>
            <label htmlFor="modalConfirm" className="btn">
              Aún no
            </label>
          </div>
        </div>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default EditVentas;
