import { createContext, useState } from "react";

const AuthContext= createContext({} );
const getAuthLocal = ()=>{
    try {
        console.log('obteniendo localstorage')
        const storage=localStorage.getItem("auth");
        const auth = JSON.parse(storage);
        return auth;
    } catch (error) {
        console.log(error);
        return null;
    }
};
export const AuthProvider = ({children})=>{
    const [auth, setAuth]= useState(getAuthLocal()||{});

    return(
        <AuthContext.Provider value={{auth, setAuth}}>
            {children}
        </AuthContext.Provider>
    )
}
export default AuthContext;