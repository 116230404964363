import React, { useEffect, useRef, useState } from "react";
import useAuth from "../hooks/useAuth";
import { makeSafeGetRequest } from "./utils/Requests";
import { getEmpresaAdmin } from "../api/RoleInfo";
import { useDownloadExcel } from "react-export-table-to-excel";

function ReportCalidadIngresos() {
  //states de sesion
  const { auth } = useAuth();
  const [idEmpresa, setIdEmpresa] = useState();
  const [empresaNombre, setEmpresaNombre] = useState();
  //states de los datos
  const [ingresos, setIngresos] = useState();
  const [fechaInicial, setFechaInicial] = useState();
  const [fechaFinal, setFechaFinal] = useState();
  const tableRef = useRef(null);
  //Obtiene el id de la empresa
  const getIdEmpresa = async () => {
    try {      
      const response = await getEmpresaAdmin(auth);
      if (response) {
        console.log("encontro datos de la empresa" + JSON.stringify(response));
        setIdEmpresa(response.empresa?.id);
        setEmpresaNombre(response.empresa?.nombre);
      }
    } catch (error) {
      console.log("Error al obtener los datos de la empresa");
    }
  };
  //obtiene los ingresos en un rango
  const getIngresos = async () => {
    try {
      const response = await makeSafeGetRequest(
        "ingresoscalidad?empresa=" +
          idEmpresa +
          "&fechainicial=" +
          fechaInicial +
          "&fechafinal=" +
          fechaFinal,
        auth.accessToken
      );
      if (response) {
        console.log("Ingresos con calidad" + JSON.stringify(response));
        setIngresos(response.data);
      } else {
        setIngresos(null);
      }
    } catch (error) {
      console.log("error al obtener la lista de ingresos con calidad" + error);
    }
  };
  //genera la descarga del archivo
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Consolidados" + fechaInicial + "_" + fechaFinal,
    sheet: "consolidados",
  });
  //UseEffect inicial
  useEffect(() => {
    getIdEmpresa();
  }, []);
  return (
    <div className="section h-full md:w-4/5">
      <div className="grid grid-cols-1 md:w-11/12">
        <h1 className="text-3xl">Reporte de Calidad</h1>
        <label className="label">Empresa: {empresaNombre}</label>
        <label className="label">Seleccione fecha de inicio</label>
        <input
          className="input input-bordered"
          type={"date"}
          onChange={(e) => {
            setFechaInicial(e.target.value);
          }}
        ></input>
        <label className="label">Seleccione fecha final</label>
        <input
          className="input input-bordered"
          type={"date"}
          onChange={(e) => {
            setFechaFinal(e.target.value);
          }}
        ></input>
        <div className="flex justify-around my-3">
          <button className="btn btn-accent" onClick={() => getIngresos()}>
            Generar reporte
          </button>
          {ingresos ? (
            <button className="btn btn-active" onClick={() => onDownload()}>
              Exportar excel
            </button>
          ) : (
            <></>
          )}
        </div>
      </div>
      {ingresos && ingresos?.length > 0 ? (
        <div className="overflow-x-auto overflow-y-auto">
          <table className="table table-compact overflow-x-auto" ref={tableRef}>
            <thead>
              <tr>
                <th></th>
                <th colSpan={10}>Datos del Ingreso</th>
                <th colSpan={16}>Datos de la prueba</th>
              </tr>
              <tr>
                <th></th>
                <th>Ruta</th>
                <th>Fecha</th>
                <th>Hora</th>
                <th>Cantidad</th>
                <th>Cant Novedad</th>
                <th>Cant Medida</th>
                <th>Diferencia</th>
                <th>Aceptado</th>
                <th>observaciones</th>
                <th>Acidez</th>
                <th>pH</th>
                <th>Agua</th>
                <th>Antibiótico</th>
                <th>Betalactámicos</th>
                <th>Tetraciclinas</th>
                <th>Sulfamidas</th>
                <th>Proteína</th>
                <th>Grasa</th>
                <th>Sólidos</th>
                <th>Alcohol</th>
                <th>Neutralizantes</th>
                <th>Adulterantes</th>
                <th>Suero</th>
                <th>Densidad</th>
                <th>O. Prueba</th>
                <th>Firma</th>
              </tr>
            </thead>
            <tbody>
              {ingresos.map((ingreso) => (
                <tr key={ingreso.id}>
                  <td></td>
                  <td>{ingreso?.recorrido?.ruta?.nombre}</td>
                  <td>{ingreso?.fecha.split("T")[0]}</td>
                  <td>{ingreso?.hora?.split(".")[0]}</td>
                  <td>{ingreso?.cantidad_leche}</td>
                  <td>{ingreso?.cantidad_rechazada}</td>
                  <td>{ingreso?.cantidad_medida}</td>
                  <td>{ingreso?.diferencia}</td>
                  <td>
                    {ingreso?.aceptado === true ? (
                      <span className="text text-success">Si</span>
                    ) : (
                      <span className="text text-error">No</span>
                    )}
                  </td>
                  <td>{ingreso?.observaciones}</td>
                  <td>{ingreso?.prueba?.acidez}</td>
                  <td>{ingreso?.prueba?.ph}</td>
                  <td>{ingreso?.prueba?.agua}</td>
                  <td>
                    {ingreso?.prueba?.antibiotico === true ? (
                      <span className="text text-error">Positivo</span>
                    ) : (
                      <span className="text text-success">Negativo</span>
                    )}
                  </td>
                  <td>
                    {ingreso?.prueba?.betalactamicos === true ? (
                      <span className="text-error">Positivo</span>
                    ) : ingreso?.prueba?.betalactamicos === false ? (
                      <span className="text-success">Negativo</span>
                    ) : (
                      "No marcado"
                    )}
                  </td>
                  <td>
                    {ingreso?.prueba?.tetraciclinas === true ? (
                      <span className="text-error">Positivo</span>
                    ) : ingreso?.prueba?.tetraciclinas === false ? (
                      <span className="text-success">Negativo</span>
                    ) : (
                      "No marcado"
                    )}
                  </td>
                  <td>
                    {ingreso?.prueba?.sulfamidas === true ? (
                      <span className="text-error">Positivo</span>
                    ) : ingreso?.prueba?.sulfamidas === false ? (
                      <span className="text-success">Negativo</span>
                    ) : (
                      "No marcado"
                    )}
                  </td>
                  <td>{ingreso?.prueba?.proteina}</td>
                  <td>{ingreso?.prueba?.grasa}</td>
                  <td>{ingreso?.prueba?.solidos}</td>
                  <td>
                    {ingreso?.prueba?.alcohol === true ? (
                      <span className="text text-error">Positivo</span>
                    ) : (
                      <span className="text text-success">Negativo</span>
                    )}
                  </td>
                  <td>
                    {ingreso?.prueba?.neutralizantes === true ? (
                      <span className="text text-error">Positivo</span>
                    ) : (
                      <span className="text text-success">Negativo</span>
                    )}
                  </td>
                  <td>
                    {ingreso?.prueba?.adulterantes === true ? (
                      <span className="text text-error">Positivo</span>
                    ) : (
                      <span className="text text-success">Negativo</span>
                    )}
                  </td>
                  <td>
                    {ingreso?.prueba?.suero === true ? (
                      <span className="text text-error">Positivo</span>
                    ) : (
                      <span className="text text-success">Negativo</span>
                    )}
                  </td>
                  <td>{ingreso?.prueba?.densidad}</td>
                  <td>{ingreso?.prueba?.observaciones}</td>
                  <td>{ingreso?.operario?.nombre_corto}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : ingresos?.length === 0 ? (
        <div className="alert alert-error">
          No se encontraron ingresos en el rango de fechas
        </div>
      ) : (
        <div className="alert alert-active">
          Seleccione un rango de fechas para generar el reporte
        </div>
      )}
    </div>
  );
}

export default ReportCalidadIngresos;
