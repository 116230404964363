import { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import axios from "../api/axios";
import { getOperarioInfo } from "../api/RoleInfo";
import useAuth from "../hooks/useAuth";
import Prueba from "./Prueba";
import { notifyPendingNotify, popNotification } from "./utils/Notify";
import {
  makeGetRequest,
  makeSafeGetRequest,
  postPruebas,
} from "./utils/Requests";
import {
  dateToColombianZoneRq,
  timeToColombianZonerq,
} from "./utils/timezoneRequests";

function CreateTraslado() {
  const URL = process.env.REACT_APP_BACKEND_BASE_URL;
  const { auth } = useAuth();
  const [acopios, setAcopios] = useState();
  const [selAcopio, setSelAacopio] = useState("-1");
  const [nombreSelAcopio, setNombreSelAcopio] = useState();
  const [cantidad, setCantidad] = useState();
  const [observaciones, setObservaciones] = useState();
  const [trasladoNovedad, setTrasladoNovedad] = useState(false);
  const [operarioInfo, setOperarioInfo] = useState();
  //states de la prueba
  const [prueba, setPrueba] = useState({});
  //states del inventario del acopio
  const [inventarioHoy, setInventarioHoy] = useState();

  //Obtiene en un objeto el id de centro de acopio y de operario
  const getOperario = async () => {
    console.log("Obteniendo operario");
    const idUser = auth.id;
    const operario = await getOperarioInfo(idUser);
    console.log("operario obtenido: " + JSON.stringify(operario));
    setOperarioInfo(operario);
  };

  //obtiene la lista de acopios
  const getAcopios = async () => {
    try {
      console.log("Accediendo a los acopios");
      const myUrl = "acopiosempresa/" + operarioInfo.idEmpresa;
      const response = await makeSafeGetRequest(`${myUrl}`, auth.accessToken);
      console.log("acopio encontrado" + JSON.stringify(response.data));
      setAcopios(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  //postea el traslado
  const postTraslado = async (idPrueba) => {
    const notify = notifyPendingNotify();
    try {
      if (cantidad > 0 || selAcopio !== undefined) {
        const myUrl = URL + "traslados";
        const fechaHoy = await dateToColombianZoneRq();
        const horaHoy = timeToColombianZonerq();
        const response = await axios.post(myUrl, {
          data: {
            fecha: fechaHoy,
            hora: horaHoy,
            cantidad_leche: cantidad,
            observaciones: observaciones,
            acopio_destino: {
              id: selAcopio,
            },
            acopio_origen: {
              id: operarioInfo.idAcopio,
            },
            recibido: false,
            prueba: { id: idPrueba },
            traslado_novedad: trasladoNovedad,
          },
        });
        console.log(JSON.stringify(response.data));
        if (response?.status === 200) {
          popNotification(
            notify,
            "success",
            "Traslado realizado correctamente."
          );
        }
      } else {
        popNotification(notify, "error", "Complete los datos del traslado");
      }
    } catch (error) {
      console.log(error);
      popNotification(notify, "error", "Error al realizar el traslado");
    }
  };
  //maneja los cambios en la prueba
  function handlePruebaChange(pruebaE) {
    setPrueba({ ...pruebaE });
  }

  //postea la prueba junto con el recorrido seleccionado y la cantidad de leche medida
  const postPrueba = async () => {
    const notify = notifyPendingNotify();
    try {
      const response = await postPruebas(prueba);

      if (response) {
        console.log("probando notificacion");
        popNotification(notify, "success", "Prueba agregada...");
      }
      const idPrueba = response.data.data.id;
      console.log(
        "Esta es la respuesta: " +
          JSON.stringify(response.data.data) +
          "id:   " +
          idPrueba
      );
      return idPrueba;
    } catch (error) {
      console.log("Descripcion del error: " + error);
    }
  };
  //verifica si el form es apto para postear
  const checkData = async () => {
    const notify = notifyPendingNotify();
    if (
      cantidad > 0 &&
      selAcopio !== undefined &&
      selAcopio !== "-1" &&
      selAcopio !== "n/a"
    ) {
      const idPrueba = await postPrueba();
      if (idPrueba) {
        await postTraslado(idPrueba);
        popNotification(notify, "success", "proceso finalizado");
        clearFields();
        await getInventarioHoy();
      }
    } else {
      popNotification(notify, "error", "Complete los datos del traslado");
    }
  };
  //Tomando el inventario disponible, nos dice si hay existencia de leche suficiente en los consolidados
  const checkInventario = async () => {
    const notify = notifyPendingNotify();
    if (inventarioHoy) {
      if (
        trasladoNovedad === true &&
        inventarioHoy.total_novedades >= cantidad
      ) {
        popNotification(
          notify,
          "success",
          "Existencias disponibles en novedad"
        );
        await checkData();
      } else if (
        trasladoNovedad === false &&
        inventarioHoy.total_inventario >= cantidad
      ) {
        popNotification(
          notify,
          "success",
          "Existencias disponibles en inventario"
        );
        await checkData();
      } else {
        popNotification(
          notify,
          "error",
          "No hay existencias suficientes para realizar el traslado"
        );
      }
    }
  };
  //obtiene el inventario de hoy para verificar si hay disponibilidad para traslados
  const getInventarioHoy = async () => {
    try {
      const response = await makeGetRequest(
        "inventarioacopio/" + operarioInfo.idAcopio
      );
      if (response) {
        console.log(JSON.stringify(response));
        setInventarioHoy(response.data);
      }
    } catch (error) {
      console.log("Error al obtener el inventario:" + error);
    }
  };

  //Limpia los campos del formulario
  const clearFields = () => {
    setCantidad(0);
    setObservaciones("");
  };

  useEffect(() => {
    getOperario();
  }, []);

  useEffect(() => {
    if (operarioInfo) {
      getAcopios();
      getInventarioHoy();
    }
  }, [operarioInfo]);
  return (
    <section className="h-full my-5">
      <p className="text-3xl font-semibold">Realizar Traslado</p>
      <div className="grid grid-cols-1 justify-center px-5 ">
        {inventarioHoy ? (
          <>
            <p className="text-xl">Inventario:</p>
            <p className="text-xl">
              Leche disponible:{inventarioHoy.total_inventario} litros
            </p>
            <p className="text-xl">
              Leche con novedad:{inventarioHoy.total_novedades} litros
            </p>
          </>
        ) : (
          <></>
        )}
        <p className="text-2xl">Datos del Traslado</p>
        <label htmlFor="acopioDestino" className="label">
          Acopio Destino
        </label>

        <select
          id="cliente"
          className="select select-bordered md:w-full"
          onChange={(e) => {
            setSelAacopio(e.target.value);
            setNombreSelAcopio(e.target.selectedOptions[0].text);
          }}
        >
          <option value={"-1"}>Seleccione un Acopio</option>
          {!acopios ? (
            <option value={"n/a"}>Cargando acopios</option>
          ) : (
            acopios.map((acopio) => {
              if (parseInt(acopio.id) !== parseInt(operarioInfo?.idAcopio)) {
                return (
                  <option key={acopio?.id} value={acopio?.id}>
                    {acopio?.nombre}
                  </option>
                );
              }
              return null; // or <></> if you prefer
            })
          )}
        </select>
        {/* <select
          id="cliente"
          className="select select-bordered md:w-full"
          onChange={(e) => {
            setSelAacopio(e.target.value);
            setNombreSelAcopio(e.target.selectedOptions[0].text);
          }}
        >
          <option value={"-1"}>Seleccione un Acopio</option>
          {!acopios ? (
            <option value={"n/a"}>Cargando acopios</option>
          ) : (
            acopios.map((acopio) => (
              parseInt(acopio.id)!==parseInt(operarioInfo?.idAcopio)?
              <option key={acopio?.id} value={acopio?.id}>
                {acopio?.nombre}
              </option>:<></>
            ))
          )}
        </select> */}

        <label htmlFor="cantidad" className="label">
          Cantidad de Leche a Trasladar
        </label>
        <span className=" input-group ">
          <input
            type={"number"}
            onChange={(e) => setCantidad(e.target.value)}
            className="input input-bordered "
            min={0}
            value={cantidad}
            required
          ></input>
          <span>Litros</span>
        </span>

        <label htmlFor="novedad" className="label">
          Leche con novedad
        </label>
        <div className="flex">
          <span className="label-text text-lg mx-5">No</span>
          <input
            type="checkbox"
            className="toggle toggle-lg toggle-accent"
            onClick={(e) => {
              setTrasladoNovedad((prevCheck) => !prevCheck);
            }}
          ></input>
          <span className="label-text text-lg mx-5">Sí</span>
        </div>

        <label htmlFor="observaciones" className="label">
          Observaciones
        </label>
        <textarea
          id="observaciones"
          className="input input-bordered"
          onChange={(e) => setObservaciones(e.target.value)}
          value={observaciones}
        ></textarea>

        <div className="prueba collapse">
          <input type={"checkbox"}></input>
          <div className="collapse-title text-xl ">
            <span className="btn ">
              Añadir Prueba
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
                />
              </svg>
            </span>
          </div>
          <div className="collapse-content">
            <Prueba onPruebaChange={handlePruebaChange}></Prueba>
          </div>
        </div>

        <label htmlFor="modal-confirm" className="btn btn-accent modal-button">
          Guardar Traslado
        </label>
      </div>
      <input type="checkbox" id="modal-confirm" className="modal-toggle" />
      <div className="modal">
        <div className="modal-box ">
          <label
            htmlFor="modal-confirm"
            className="btn btn-sm btn-circle absolute right-2 top-2"
          >
            ✕
          </label>
          <h3 className="text-lg font-bold">Aviso</h3>
          <p className="py-4">Desea guardar el traslado digitado?</p>
          <h6 className="font-bold">Datos del Traslado</h6>
          <p>
            <span className="font-bold">Nombre del Acopio destino: </span>
            {nombreSelAcopio}
          </p>
          <p>
            <span className="font-bold">Cantidad a trasladar: </span>
            {cantidad + " litros"}
          </p>
          <p>
            <span className="font-bold">Observaciones</span>
            {observaciones}
          </p>
          <div className="modal-action">
            <label
              htmlFor="modal-confirm"
              onClick={(e) => {
                checkInventario();
              }}
              className="btn btn-accent"
              type="submit"
            >
              Realizar traslado
            </label>
            <label htmlFor="modal-confirm" className="btn">
              Aún no
            </label>
          </div>
          <div className="modal-action"></div>
        </div>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </section>
  );
}
export default CreateTraslado;
