import React, { useEffect, useState } from "react";
import useAuth from "../hooks/useAuth";
import { makeSafeGetRequest } from "./utils/Requests";

function GetRecoleccionesProductor() {
  const { auth } = useAuth();
  const [recorridos, setRecorridos] = useState();
  const [idRecolector, setIdRecolector] = useState();
  const [recolecciones, setRecolecciones] = useState();
  const [rutas, setRutas] = useState();
  const [selRuta, setSelRuta] = useState();
  const [productores, setProductores] = useState();
  const [selectedProductor, setSelectedProductor] = useState();
  const [selectedProductorNombre, setSelectedProductorNombre] = useState();
  const [fechaInicial, setFechaInicial] = useState();
  const [fechaFinal, setfechaFinal] = useState();
  const [searchedProds, setSearchedProds] = useState();

  //Obtiene el id del recolector del usuario logueado
  const getIdRecolector = async () => {
    try {
      console.log(
        "Id del usuario: " + auth.id + "\n nombre del usuario:" + auth.username
      );
      const response = await makeSafeGetRequest(
        "recolectorusuario/" + auth.id,
        auth.accessToken
      );
      console.log("DATOS DEL RECOLECTOR: " + JSON.stringify(response.data));
      setIdRecolector(response.data.id);
    } catch (error) {
      console.log(error);
    }
  };
  //Metodo para capturar las rutas del recolector
  const getRutas = async () => {
    try {
      const response = await makeSafeGetRequest(
        "rutasrecolector/" + idRecolector,
        auth.accessToken
      );
      console.log(JSON.stringify(response));
      setRutas(response?.data[0].rutas);
    } catch (error) {
      console.log("Error al obtener las rutas:" + error);
    }
  };
  //obtiene la lista de recolecciones del productor seleccionado
  const getRecoleccionesProductor = async () => {
    try {
      if (fechaFinal && fechaInicial) {
        const myUrl =
          "recoleccionesproductor?idproductor=" +
          selectedProductor +
          "&fechainicial=" +
          fechaInicial +
          "&fechafinal=" +
          fechaFinal;
        const response = await makeSafeGetRequest(`${myUrl}`, auth.accessToken);
        if (response.status === 200) {
          setRecolecciones(response.data);
        }
      } else {
        console.log("seleccione fechas");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getProductores = async () => {
    try {
      const response = await makeSafeGetRequest(
        "productoresruta/" + selRuta,
        auth.accessToken
      );
      if (response && response.status === 200) {
        setProductores(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //on search
  const onsearch = (searchTerm) => {
    if (searchTerm === "" || searchTerm === undefined) {
      console.log("el input esta en blanco");
      setSearchedProds();
    } else {
      const filteredItems = productores?.filter((productor) => {
        return productor.nombre_corto
          .toUpperCase()
          .includes(searchTerm.toUpperCase());
      });
      console.log("clientes encontrados: " + JSON.stringify(filteredItems));
      setSearchedProds(filteredItems?.slice(0, 10));
    }
  };
  //cambio al seleccionar una ruta
  const onRutaChange = (e) => {
    setSelRuta(e.target.value);
  };
  //Cambio de estados al obtener un productor
  const onSelectedProd = async (e) => {
    setSelectedProductor(e.target.value);
    setSelectedProductorNombre(e.target.innerText);
    setSearchedProds();
    await getRecoleccionesProductor();
  };
  //cambio fecha inicial
  const onFechaInicialChange = (e) => {
    setFechaInicial(e.target.value);
  };
  //cambio fecha final
  const onFechaFinalChange = (e) => {
    setfechaFinal(e.target.value);
  };
  //useeffect cambio de ruta
  useEffect(() => {
    getProductores();
  }, [selRuta]);
  //al obtener el id del recolector
  useEffect(() => {
    if (idRecolector) {
      getRutas();
    }
  }, [idRecolector]);
  //inicial
  useEffect(() => {
    getIdRecolector();
  }, []);
  return (
    <div className="h-full md:w-7/12 my-3">
      <div className="grid grid-cols-1 px-5 md:px-20">
        <h1 className="text-3xl">Recolecciones por Productor</h1>
        <label className="label">Seleccione una ruta</label>
        {rutas && rutas.length > 0 ? (
          <div className="flex flex-row flex-wrap content-center">
            {rutas.map((ruta) =>
              parseInt(selRuta) === parseInt(ruta.id) ? (
                <button
                  className="btn btn-accent m-1"
                  key={ruta.id}
                  value={ruta.id}
                  onClick={(e) => onRutaChange(e)}
                >
                  {ruta.nombre}
                </button>
              ) : (
                <button
                  className="btn btn-active m-1"
                  key={ruta.id}
                  value={ruta.id}
                  onClick={(e) => onRutaChange(e)}
                >
                  {ruta.nombre}
                </button>
              )
            )}
          </div>
        ) : (
          <></>
        )}

        <label className="label">Seleccione un productor</label>
        <label className="input-group w-max">
          <input
            type={"search"}
            className="input input-bordered"
            onChange={(e) => onsearch(e.target.value)}
          ></input>
          <span>Buscar</span>
        </label>

        {searchedProds ? (
          // <ul
          //   tabIndex={0}
          //   className="dropdown-content menu p-2 shadow bg-base-100 w-52 absolute bottom-0 z-20"
          //   // className="dropdown-content menu p-2 shadow bg-base-100 w-52 absolute bottom-0 overflow-x-auto"
          // >
          //   {searchedProds.map((productor) => (
          //     <li
          //       className="text-left"
          //       key={productor?.id}
          //       value={productor?.id}
          //       tabIndex={0}
          //       onClick={(e) => {
          //         onSelectedProd(e);
          //       }}
          //     >
          //       {productor.nombre_corto}
          //     </li>
          //   ))}
          // </ul>
          <ul
            tabIndex={0}
            className="dropdown-content menu p-2 shadow bg-base-100 w-52"
          >
            {searchedProds.map((productor) => (
              <li
                className="text-left"
                key={productor?.id}
                value={productor?.id}
                tabIndex={0}
                onClick={(e) => {
                  onSelectedProd(e);
                }}
              >
                {productor.nombre_corto}
              </li>
            ))}
          </ul>
        ) : (
          <></>
        )}
        {selectedProductorNombre ? (
          <div className="badge badge-lg my-1 py-1">
            {selectedProductorNombre}
            <span
              onClick={() => {
                setSelectedProductorNombre();
                setSelectedProductor();
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </span>
          </div>
        ) : (
          <>Seleccione un productor</>
        )}
        {productores && productores.length > 0 ? (
          <></>
        ) : (
          <label className="text">Seleccione una ruta</label>
        )}

        <label htmlFor="fechainicial" className="label">
          Fecha Inicial
        </label>
        <input
          type={"date"}
          className="input input-bordered"
          id="fechainicial"
          onChange={(e) => {
            onFechaInicialChange(e);
          }}
        ></input>
        <label htmlFor="fechafinal" className="label">
          Fecha final
        </label>
        <input
          type={"date"}
          className="input input-bordered"
          id="fechafinal"
          onChange={(e) => {
            onFechaFinalChange(e);
          }}
        ></input>
        <button
          className="btn btn-accent my-5"
          onClick={(e) => getRecoleccionesProductor()}
        >
          consultar
        </button>
        <div className="overflow-x-auto">
          <table className="table table-compact">
            {recolecciones && recolecciones?.length > 0 && selectedProductor ? (
              <>
                <thead>
                  <tr>
                    <th colSpan={4}>
                      Productor: {recolecciones[0]?.productor?.nombre_corto}
                    </th>
                    <th>
                      Estado:{" "}
                      {recolecciones[0].productor?.activo === true ? (
                        <>Activo</>
                      ) : (
                        <>Inactivo</>
                      )}
                    </th>
                  </tr>
                  <tr>
                    <th></th>
                    <th>Fecha</th>
                    <th>Cantidad</th>
                    <th>Observaciones</th>
                    <th>Ruta</th>
                  </tr>
                </thead>
                <tbody>
                  {recolecciones?.map((reco, index) => (
                    <tr key={reco.id}>
                      <th>{index + 1}</th>
                      <td>{reco.fecha}</td>
                      <td>{reco.cantidad_leche}</td>
                      <td>{reco.observaciones.substring(0, 30)}</td>
                      <td>{reco.ruta?.nombre}</td>
                    </tr>
                  ))}
                  {/* <tr colSpan={2}>
                        <td></td>
                      </tr> */}
                  <tr>
                    <th colSpan={2}>Total</th>
                    <td colSpan={3}>
                      {recolecciones.reduce(
                        (c, reco) => c + reco.cantidad_leche,
                        0
                      ) + " litros"}
                    </td>
                  </tr>
                </tbody>
              </>
            ) : (
              <tbody>
                <tr colSpan={2}>
                  <td>
                    No hay recolecciones en esa fecha o rango para ese productor
                  </td>
                </tr>
              </tbody>
            )}
          </table>
        </div>
      </div>
    </div>
  );
}

export default GetRecoleccionesProductor;
