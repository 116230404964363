import React, { useEffect, useRef, useState } from "react";
import { useDownloadExcel } from "react-export-table-to-excel";
import { makeSafeGetRequest } from "./utils/Requests";
import useAuth from "../hooks/useAuth";
import { getEmpresaAdmin } from "../api/RoleInfo";

function ReporteVentasEmpresa() {
  const {auth}= useAuth();
  const [idEmpresa,setIdEmpresa]= useState();
  const [nombreEmpresa, setNombreEmpresa]= useState();
  const [clientesVentas, setClientesVentas] = useState();
  const [fechaInicial, setFechaInicial] = useState();
  const [fechaFinal, setFechaFinal] = useState();

  const tableRef = useRef(null);
  //obtiene datos de la empresa
  const getEmpresa = async () => {
    try {
      const response = await getEmpresaAdmin(auth);
      if (response) {
        console.log("encontro datos de la empresa" + JSON.stringify(response));
        setIdEmpresa(response.empresa?.id);
        setNombreEmpresa(response.empresa?.nombre);
      }
    } catch (error) {
      console.log("Error al obtener los datos de la empresa");
    }
  };
  //obtiene la lista de  clientes y las ventas que han tenido en un rango de fechas
  const getClientesVentas = async () => {
    try {
      const response = await makeSafeGetRequest(
        "clientesventas?idempresa="+idEmpresa+"&fechainicial=" +
          fechaInicial +
          "&fechafinal=" +
          fechaFinal, auth.accessToken
      );
      if (response) {
        setClientesVentas(response.data);
      } else {
        setClientesVentas();
      }
    } catch (error) {
      console.log("Error al obtener los clientes:" + error);
    }
  };
  //genera el archivo a descargar
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "ventas_"+nombreEmpresa+"_"+fechaInicial+"_"+fechaInicial,
    sheet: "ventasrango",
  });
  //useeffect inicial
  useEffect(()=>{
    getEmpresa();
  },[])
  useEffect(()=>{
    console.log("cambio tableRef");
  },[tableRef])

  return (
    <div className="h-full py-5 md:w-7/12">
      <div className="grid grid-cols-1 mx-1 px-5 ">
        <h1 className="text text-3xl pb-5">Ventas en {nombreEmpresa}</h1>
        <label className="label">Fecha inicial</label>
        <input
          className="input input-bordered"
          type={"date"}
          onChange={(e) => {
            setFechaInicial(e.target.value);
          }}
        ></input>
        <label className="label">Fecha final</label>
        <input
          className="input input-bordered"
          type={"date"}
          onChange={(e) => {
            setFechaFinal(e.target.value);
          }}
        ></input>
        <div className="flex justify-around m-1 p-1">
          <button className="btn btn-accent" onClick={getClientesVentas}>
            Generar reporte
          </button>
          {clientesVentas ? (
            <button className="btn btn-active" onClick={onDownload}>Exportar Excel</button>
          ) : (
            <></>
          )}
        </div>
        {/* Div para la tabla */}
        <div className="overflow-x-auto">
          <table className="table table-compact" ref={tableRef}>
            {clientesVentas ? (
              <>
                <thead>
                  <tr>
                    <th></th>
                    <th>Documento</th>
                    <th>Nombre</th>
                    <th>telefono</th>
                    {clientesVentas[0].ventas?.map((v) => (
                      <th>{v.fecha}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {clientesVentas.map((cv) => (
                    <tr>
                      <td></td>
                      <td>{cv.documento}</td>
                      <td>{cv.nombre}</td>
                      <td>{cv.telefono}</td>
                      {cv?.ventas?.map((v) => (
                        <td>{v.cant}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </>
            ) : (
              <></>
            )}
          </table>
        </div>
        {}
      </div>
    </div>
  );
}

export default ReporteVentasEmpresa;
