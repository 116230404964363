import React, { useEffect, useRef, useState } from "react";
import { useDownloadExcel } from "react-export-table-to-excel";
import { makeGetRequest, makeSafeGetRequest } from "./utils/Requests";
import useAuth from "../hooks/useAuth";
import { getEmpresaAdmin } from "../api/RoleInfo";

function ReporteConsolidadosAcopio() {
  const {auth}= useAuth();
  const [idEmpresa,setIdEmpresa]= useState();
  const [nombreEmpresa, setNombreEmpresa]= useState();
  const [acopios, setAcopios] = useState([]);
  const [selAcopio, setSelAcopio] = useState();
  const [consolidados, setConsolidados] = useState();
  const [aperturas, setAperturas] = useState();
  const [opcion, setOpcion] = useState();
  const [fechaInicial, setFechaInicial] = useState();
  const [fechaFinal, setFechaFinal] = useState();
  const tableRef = useRef(null);
  
  //obtiene datos de la empresa
  const getEmpresa = async () => {
    try {
      const response = await getEmpresaAdmin(auth);
      if (response) {
        setIdEmpresa(response.empresa?.id);
        setNombreEmpresa(response.empresa?.nombre);
      }
    } catch (error) {
      console.log("Error al obtener los datos de la empresa");
    }
  };
  //obtiene la lista de acopios de la empresa
  const getAcopios = async () => {
    try {
      const response = await makeSafeGetRequest("acopiosempresa/"+ idEmpresa, auth.accessToken);
      if (response) {
        setAcopios(response.data);
      } else {
        setAcopios();
      }
    } catch (error) {
      console.log(error);
    }
  };
  //obtiene la lista de consolidados o aperturas segun la opcion seleccionada, el rango y el acopio
  const getReport = async () => {
    try {
      //Aperturas de consolidado
      console.log("PROBANDO BOTON");
      if (opcion === "1") {
        console.log("Entra por apertura");
        const response = await getAperturas();
        if (response) {
          setAperturas(response);
          //blanquear el state de consolidados
          setConsolidados();
        } else {
          setAperturas();
        }
      }
      //cierres de consolidado
      else if (opcion === "2") {
        console.log("Entra por cierre");
        const response = await getConsolidados();
        if (response) {
          setConsolidados(response);
          //blanquear el state de acopios
          setAperturas();
        } else {
          setConsolidados();
        }
      }
    } catch (error) {
      console.log("Error al obtener el reporte:" + error);
    }
  };
  //Obtiene la lista de aperturas de consolidado en un acopio y rango de fechas seleccionado
  const getAperturas = async () => {
    try {
      const response = await makeGetRequest(
        "aperturasacopiorango?idacopio=" +
          selAcopio +
          "&fechainicial=" +
          fechaInicial +
          "&fechafinal=" +
          fechaFinal
      );
      if (response) {
        return response?.data;
      } else {
        return null;
      }
    } catch (error) {
      console.log("Error al obtener las aperturas de consolidados" + error);
    }
  };
  //Obtiene la lista de cierres de consolidado en un acopio y rango de fechas seleccionado
  const getConsolidados = async () => {
    try {
      const response = await makeGetRequest(
        "consolidadosacopiorango?idacopio=" +
          selAcopio +
          "&fechainicial=" +
          fechaInicial +
          "&fechafinal=" +
          fechaFinal
      );
      if (response) {
        return response?.data;
      } else {
        return null;
      }
    } catch (error) {
      console.log("Error al obtener los cierres de consolidados" + error);
    }
  };
  //genera la descarga del archivo
  
  
  //genera la descarga del archivo
  const { onDownload} = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Consolidados_"+ nombreEmpresa+"_"+(opcion === "1" ? "apertura" :opcion === "2"? "cierre":"")+ fechaInicial + "_" + fechaFinal,
    sheet: "consolidados",
  });
  //Effect para detectar moviminetos en los refs
  useEffect(() => {
    console.log("CAMBIO EN TABLEREFS");
  }, [tableRef, tableRef]);

  // useeffect obtener acopios
  useEffect(()=>{
    if(idEmpresa){
      getAcopios();
    }
  },[idEmpresa]) 
  //useEffect Inicial
  useEffect(() => {
    getEmpresa();  
  }, []);
  return (
    <div className="h-full md:w-3/4 mx-2 my-3">
      <h1 className="text-3xl">Reportes de consolidados</h1>
      <div className="grid grid-cols-1">
      <label className="label">Empresa: {nombreEmpresa}</label>
        {acopios ? (
          <div className="form-control">
            <label className="label">Seleccione un acopio</label>
            <select
              className="select select-bordered"
              onChange={(e) => setSelAcopio(e.target.value)}
            >
              <option key={-1} value={-1}>
                Seleccione un acopio
              </option>
              {acopios?.map((acopio) => (
                <option key={acopio.id} value={acopio.id}>
                  {acopio.nombre}
                </option>
              ))}
            </select>
          </div>
        ) : (
          <>Cargando acopios</>
        )}
        <label className="label">Fecha Inicial</label>
        <input
          className="input input-bordered"
          type={"date"}
          onChange={(e) => {
            setFechaInicial(e.target.value);
          }}
        ></input>
        <label className="label">Fecha final</label>
        <input
          className="input input-bordered"
          type={"date"}
          onChange={(e) => {
            setFechaFinal(e.target.value);
          }}
        ></input>
        <label className="label">Tipo de consolidado</label>
        <select
          className="select select-bordered"
          onChange={(e) => setOpcion(e.target.value)}
        >
          <option key={"-1"} >
            Tipo de consolidado
          </option>
          <option key={"1"} value={"1"}>
            Apertura
          </option>
          <option key={"2"} value={"2"}>
            Cierre
          </option>
        </select>
        <div className="flex justify-around my-3">
          <button className="btn btn-accent" onClick={getReport}>
            Generar reporte
          </button>
          {aperturas ? (
            <button className="btn" onClick={onDownload}>
              Exportar excel
            </button>
          ) : consolidados ? (
            <button className="btn" onClick={onDownload}>EXPORTAR EXCEL (CIERRE)</button>
          ) : (
            <></>
          )}
        </div>
        {aperturas ? (
          <div className="overflow-x-auto">
            <table className="table table-compact" ref={tableRef}>
              <thead>
                <tr>
                  <th></th>
                  <th colSpan={8}>Acopio:{aperturas[0]?.acopio.nombre}</th>
                </tr>
                <tr>
                  <th></th>
                  <th>FECHA</th>
                  <th>HORA</th>
                  <th>CANT. DIA ANTERIOR</th>
                  <th>CANT. MEDIDA</th>
                  <th>DIFERENCIA</th>
                  <th>CANT. NOVEDAD DIA ANTERIOR</th>
                  <th>CANT.NOVEDAD MEDIDA</th>
                  <th>DIFERENCIA NOVEDAD</th>
                </tr>
              </thead>
              <tbody>
                {aperturas?.map((apertura) => (
                  <tr key={apertura.id}>
                    <th></th>
                    <td>{apertura.fecha}</td>
                    <td>{apertura.hora}</td>
                    <td>{apertura.cantidad_dia_anterior}</td>
                    <td>{apertura.cantidad_medida}</td>
                    <td>{apertura.diferencia}</td>
                    <td>{apertura.novedad_dia_anterior}</td>
                    <td>{apertura.novedad_medida}</td>
                    <td>{apertura.diferencia_novedad}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <></>
        )}
        {/* cierres de consolidado */}
        {consolidados ? (
          <div className="overflow-x-auto">
            <table className="table table-compact " ref={tableRef}>
              <thead>
                <tr>
                  <th>ACOPIO:{consolidados[0]?.acopio?.nombre}</th>
                </tr>
                <tr>
                  <td>FECHA</td>
                  <td>CANT. INICIAL</td>
                  <td>INGRESOS</td>
                  <td>VENTAS</td>
                  <td>TRASLADOS FAVOR</td>
                  <td>TRASLADOS SAL.</td>
                  <td>DEVOLUCIONES</td>
                  <td>INVENTARIO SISTEMA</td>
                  <td>INVENTARIO FINAL</td>
                  <td>DIFERENCIA</td>
                  <td>CANT. NOVEDAD INICIAL</td>
                  <td>INGRESOS NOV.</td>
                  <td>VENTAS NOV.</td>
                  <td>TRASLADOS FAV. NOV.</td>
                  <td>TRASLADOS SAL. NOV.</td>
                  <td>INVENTARIO SISTEMA NOV.</td>
                  <td>INVENTARIO FINAL NOV.</td>
                  <td>DIFERENCIA</td>
                </tr>
              </thead>
              <tbody>
                {consolidados?.map((consolidado) => (
                  <tr key={consolidado.id}>
                    <td>{consolidado.fecha}</td>
                    <td>{consolidado.cantidad_inicial}</td>
                    <td>{consolidado.cantidad_recibida}</td>
                    <td>{consolidado.cantidad_vendida}</td>
                    <td>{consolidado.traslados_favor}</td>
                    <td>{consolidado.traslados_salientes}</td>
                    <td>{consolidado.devoluciones}</td>
                    <td>{consolidado.cantidad_calculada}</td>
                    <td>{consolidado.cantidad_final_dia}</td>
                    <td>{consolidado.diferencia}</td>
                    <td>{consolidado.novedad_inicial}</td>
                    <td>{consolidado.ingresos_novedad}</td>
                    <td>{consolidado.ventas_novedad}</td>
                    <td>{consolidado.traslados_favor_novedad}</td>
                    <td>{consolidado.traslados_sal_novedad}</td>
                    <td>{consolidado.novedad_calculada}</td>
                    <td>{consolidado.novedad_final_dia}</td>
                    <td>{consolidado.novedad_diferencia}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default ReporteConsolidadosAcopio;
