import React from 'react'

function Unauthorized() {
    return (
        <div>
            <div>Unauthorized</div>
            <p>No tiene acceso a esta pagina</p>
        </div>
    )
}

export default Unauthorized