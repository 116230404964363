import React, { useEffect, useRef, useState } from "react";
import { useDownloadExcel } from "react-export-table-to-excel";
import { makeGetRequest } from "./utils/Requests";
import { notifyPendingNotify, popNotification } from "./utils/Notify";
import { ToastContainer } from "react-toastify";
import { dateToColombianZoneRq, timeToColombianZonerq } from "./utils/timezoneRequests";

function ReporteProductoresFechas(props) {
  const { empresa, nombre } = props;
  const [fecha, setFecha]= useState();
  const [hora, sethora] = useState();
  const [productores, setProductores] = useState();
  const [fechaInicial, setFechaInicial] = useState();
  const [fechaFinal, setFechaFinal] = useState();
  //userefs tablas
  const tableRef = useRef(null);
  const [deshabilitado, setDeshabilitado] = useState(false);

  //obtiene la lista de productores y sus recolecciones en un rango de fechas determinado
  const getProductores = async () => {
    setDeshabilitado(true);
    console.log("clic al boton");
    const notify = notifyPendingNotify();
    //fecha y hora del proceso
    setFecha(await dateToColombianZoneRq())
    sethora(await timeToColombianZonerq())
    try {
      const response = await makeGetRequest(
        "recoleccionesproductores?idempresa=" +
          empresa +
          "&fechainicial=" +
          fechaInicial +
          "&fechafinal=" +
          fechaFinal
      );
      if (response) {
        setProductores(response.data);
        popNotification(notify, "success", "Consulta terminada");
      } else {
        setProductores();
        popNotification(notify, "error", "No se pudo realizar la consulta");
      }
    } catch (error) {
      console.log("Error al obtener detos de productores: " + error);
      popNotification(notify, "error", "Error. Consulta no terminada.");
    }
    setDeshabilitado(false);
  };
  //realiza la descarga del elemento table
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "recolecciones_" + nombre + "_" + fechaInicial + "_" + fechaFinal,
    sheet: "recolecciones",
  });
  //cambios enla fecha inicial
  const onFechaInicial = (e) => {
    setFechaInicial(e.target.value);
  };
  //cambios enla fecha final
  const onFechaFinal = (e) => {
    setFechaFinal(e.target.value);
  };
  //useEffect inicial
  useEffect(() => {}, []);
  return (
    <div className="h-full w-full ">
      <div className="grid grid-cols-1">
        <h1 className="text-3xl">Recolecciones todos los productores</h1>
        <label className="label">Empresa: {nombre}</label>
        <label className="label">Fecha inicial</label>
        <input
          type={"date"}
          className="input input-bordered"
          onChange={(e) => onFechaInicial(e)}
        ></input>
        <label className="label">Fecha Final</label>
        <input
          type={"date"}
          className="input input-bordered"
          onChange={(e) => onFechaFinal(e)}
        ></input>
        <div className="flex flex-row m-1 p-1 justify-around">
          <button
            className="btn btn-accent w-2/5"
            disabled={deshabilitado}
            onClick={getProductores}
          >
            Generar reporte
          </button>
          {productores ? (
            <button className="btn btn-active w-2/5" onClick={onDownload}>
              Exportar Excel
            </button>
          ) : (
            <></>
          )}
        </div>
        <div className="overflow-x-auto">
          <table className="table table-compact w-full" ref={tableRef}>
            {productores ? (
              <>
                <thead>
                  <tr>
                    <th></th>
                    <th>Empresa: {nombre}</th>
                    <th>Fecha: {fecha}</th>
                    <th>Hora: {hora}</th>
                  </tr>
                  
                  <tr>
                    <th></th>
                    <th>DOCUMENTO</th>
                    <th>PRODUCTOR</th>
                    <th>TELEFONO</th>
                    <th>ACOPIO</th>
                    <th>RUTA</th>
                    {productores[0]?.recolecciones.map((r) => (
                      <th>{r.fecha}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {productores?.map((prod) => (
                    <tr>
                      <td></td>
                      <td>{prod.doc}</td>
                      <td>{prod.nombre}</td>
                      <td>{prod.tel}</td>
                      <td>{prod.acopio}</td>
                      <td>{prod.ruta}</td>
                      {prod.recolecciones.map((recoleccion) => (
                        <td>{recoleccion.cant}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </>
            ) : (
              <></>
            )}
          </table>
        </div>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default ReporteProductoresFechas;
