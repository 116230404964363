import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { getEmpresaAdmin } from "../api/RoleInfo";
import useAuth from "../hooks/useAuth";
import { notifyPendingNotify, popNotification } from "./utils/Notify";
import {
  makeGetRequest,
  makeSafePostRequest,
  makeSafePutRequest,
} from "./utils/Requests";
import { dateToColombianZoneRq, timeToColombianZonerq } from "./utils/timezoneRequests";

function EditRecolecciones() {
  const { auth } = useAuth();
  const [idEmpresa, setIdEmpresa] = useState();
  const [nombreEmpresa, setNombreEmpresa] = useState();
  const [acopios, setAcopios] = useState();
  const [selAcopio, setSelAcopio] = useState();
  const [rutasAcopio, setRutasAcopio] = useState();
  const [selRuta, setSelRuta] = useState();
  const [productoresRuta, setProductoresRuta] = useState();
  const [selProductor, setSelProductor] = useState();
  const [selProductorNombre, setSelProductorNombre] = useState();
  const [recoleccion, setRecoleccion] = useState();
  //States valores recoleccion
  const [editedCantidad, setEditedCantidad] = useState(0);
  const [editedObs, setEditedObs] = useState("");

  const getIdEmpresa = async () => {
    try {
      const response = await getEmpresaAdmin(auth);
      if (response) {
        console.log("encontro datos de la empresa" + JSON.stringify(response));
        setIdEmpresa(response.empresa?.id);
        setNombreEmpresa(response.empresa?.nombre);
      }
    } catch (error) {
      console.log("Error al obtener los datos de la empresa");
    }
  };

  //Lista los acopios de la empresa
  const getAcopios = async () => {
    try {
      const response = await makeGetRequest("acopiosempresa/" + idEmpresa);
      if (response) {
        setAcopios(response.data);
      }
    } catch (error) {
      console.log("Error al obtener los acopios de la empresa: " * error);
    }
  };
  //obtiene la liste de rutas del acopio seleccionado
  const getRutasAcopio = async (idAcopio) => {
    try {
      console.log("Obteniendo rutas: " + selAcopio);
      const response = await makeGetRequest("rutasacopio/" + idAcopio);
      if (response) {
        setRutasAcopio(response.data);
      } else {
        setRutasAcopio();
      }
    } catch (error) {
      console.log("Error al obtener las rutas del acopio: " + error);
    }
  };
  //obtiene los productores de la ruta seleccionada
  const getProductores = async (idRuta) => {
    try {
      console.log("Probando getproductores");
      const response = await makeGetRequest("productoresruta/" + idRuta);
      if (response) {
        setProductoresRuta(response.data);
      } else {
        setProductoresRuta();
      }
    } catch (error) {
      console.log("Error al obtener productores: " + error);
    }
  };
  //Obtiene la recoleccion del productor seleccionado en el option
  const getRecoleccionProductor = async (idProductor) => {
    try {
      const response = await makeGetRequest(
        "recoleccionproductorhoy/" + idProductor
      );
      if (response && response.data?.length > 0) {
        console.log("Recoleccion encontrada");
        setRecoleccion(response.data[0]);
        setEditedCantidad(response.data[0].cantidad_leche);
        setEditedObs(response.data[0].observaciones);
      } else {
        console.log("Recoleccion No encontrada");
        setRecoleccion();
        setEditedCantidad();
        setEditedObs();
      }
    } catch (error) {
      console.log(error);
    }
  };
  //realiza tanto la actualizacion como la generacion del log
  const makeUpdateTransaction=async()=>{
    try {
      await updateRecoleccion();
      await postRecoleccionLog();
      setEditedCantidad(0);
      setEditedObs("");
    } catch (error) {
      console.log("Error al realizar transaccion de actualizacion"+error)
    }
  }
  //Actualiza la recolección
  const updateRecoleccion = async () => {
    const notify = notifyPendingNotify();
    try {
      console.log("probando update");
      console.log(JSON.stringify(auth));
      const data = {
        data: { cantidad_leche: editedCantidad, observaciones: editedObs },
      };
      const response = await makeSafePutRequest(
        "recolecciones/" + recoleccion?.id,
        data,
        auth.accessToken
      );
      if (response && response?.status == 200) {
        popNotification(notify, "success", "Recolección editada con éxito");
      } else {
        popNotification(notify, "error", "No se pudo editar la recolección");
      }
    } catch (error) {
      console.log(error);
      popNotification(notify, "error", "No se pudo editar la recolección");
    }
  };
  //guarda el valor actual y el anterior en la recoleccion
  const postRecoleccionLog = async () => {
    try {
      const hora=timeToColombianZonerq();
      const fecha=dateToColombianZoneRq();

      console.log("id de la recoleccion:"+recoleccion.id)
      const data = {
        data: {
          cantidad_original: recoleccion.cantidad_leche,
          cantidad_editada: editedCantidad,
          observaciones_original:recoleccion?.observaciones,
          observaciones_editado:editedObs, 
          recoleccion:{id:recoleccion.id},
          
        },
      };
      const response = makeSafePostRequest("recoleccion-logs", data, auth.accessToken);
      if(response && response.status){
        console.stringify(response);
      }
    } catch (error) {
      console.log("Error al postear el log de recoleccion"+error)
    }
  };
  //cambio al obtener el acopio seleccionado
  const onSelectedAcopio = (e) => {
    console.log("Cambio en acopio seleccionado");
    setSelAcopio(e.target.value);
    getRutasAcopio(e.target.value);
  };
  //cambio al obtener la ruta seleccionada
  const onSelectedRuta = (e) => {
    setSelRuta(e.target.value);
    getProductores(e.target.value);
  };
  //cambio al obtener el acopio seleccionado
  const onSelectedProductor = (e) => {
    setSelProductor(e.target.value);
    getRecoleccionProductor(e.target.value);
  };
  //Cambio en acopios selecciolnados
  useEffect(() => {
    console.log("Cambio en acopio seleccionado:" + selAcopio);
  }, [selAcopio]);
  //useefect empresa
  useEffect(() => {
    if (idEmpresa) {
      console.log("Obteniendo acopios...");
      getAcopios();
    }
  }, [idEmpresa]);
  //Useefect inicial
  useEffect(() => {
    getIdEmpresa();
    if (idEmpresa) {
      getAcopios();
    }
  }, []);
  return (
    <div className="section h-full">
      <div className="grid grid-cols-1 mx-2 my-5">
        <h1 className="text-4xl">Edición de recolecciones</h1>
        <label className="label">Empresa:{nombreEmpresa}</label>
        <label className="label">Acopio</label>
        {acopios ? (
          <select
            className="select select-bordered"
            onChange={(e) => {
              onSelectedAcopio(e);
            }}
          >
            <option key={"-1"} value={"-1"}>
              Seleccione un centro de acopio
            </option>
            {acopios?.map((acopio) => (
              <option key={acopio.id} value={acopio.id}>
                {acopio?.nombre}
              </option>
            ))}
          </select>
        ) : (
          <>Cargando acopios...</>
        )}
        <label className="label">Acopio seleccionado: {selAcopio}</label>
        <label className="label">Seleccione una ruta</label>
        {rutasAcopio ? (
          <select
            className="select select-bordered"
            onChange={(e) => onSelectedRuta(e)}
          >
            <option key={"-1"} value={"-1"}>
              Seleccione una ruta
            </option>
            {rutasAcopio?.map((ruta) => (
              <option key={ruta.id} value={ruta.id}>
                {ruta?.nombre}
              </option>
            ))}
          </select>
        ) : (
          <>Cargando rutas...</>
        )}
        <label className="label">Productor</label>
        {productoresRuta ? (
          <select
            className="select select-bordered"
            onChange={(e) => onSelectedProductor(e)}
          >
            <option key={"-1"} value={"-1"}>
              Seleccione un productor
            </option>
            {productoresRuta?.map((prod) => (
              <option key={prod.id} value={prod.id}>
                {prod?.nombre_corto}
              </option>
            ))}
          </select>
        ) : (
          <></>
        )}
        <div className="divider "> </div>
        {recoleccion ? (
          <div className="grid grid-cols-1">
            <h3 className="text-2xl font-bold">Datos de la recolección:</h3>
            <h5 className="text">
              <label className="font-bold">Nombre del productor: </label>
              {recoleccion?.productor?.nombre_corto}
            </h5>
            <label className="label">Cantidad de leche</label>
            <label className="input-group">
              <input
                type="number"
                placeholder=""
                className="input input-bordered"
                value={editedCantidad}
                min={0}
                onChange={(e) => {
                  setEditedCantidad(e.target.value);
                }}
              />
              <span>Litros</span>
            </label>
            <label className="label">Observaciones</label>
            <input
              className="input input-bordered"
              type={"text"}
              value={editedObs}
              onChange={(e) => {
                setEditedObs(e.target.value);
              }}
            ></input>
            <label className="btn btn-accent my-3" htmlFor="modal-edit">
              Editar Recolección
            </label>
          </div>
        ) : (
          <div className="alert alert-warning">
            No se encontraron recolecciones para el productor seleccionado
          </div>
        )}
      </div>
      {/* Modal editar recolecciones */}
      <input type="checkbox" id="modal-edit" className="modal-toggle" />
      <div className="modal">
        <div className="modal-box relative">
          <label
            htmlFor="modal-edit"
            className="btn btn-sm btn-circle absolute right-2 top-2"
          >
            ✕
          </label>
          <h3 className="text-lg font-bold">Aviso!</h3>
          <p className="py-4">
            Realmente desea editar la siguiente recolección?
          </p>
          <p>
            <span className="font-bold">Productor: </span>
            {recoleccion?.productor.nombre_corto}
          </p>
          <p>
            <span className="font-bold">Cantidad: </span>
            {editedCantidad + " litros"}
          </p>
          <p>
            <span className="font-bold">Observaciones: </span>
            {editedObs}
          </p>
          <div className="modal-action">
            <label
              htmlFor="modal-edit"
              onClick={makeUpdateTransaction}
              className="btn btn-accent"
            >
              Sí
            </label>
            <label htmlFor="modal-edit" className="btn ">
              Aún no
            </label>
          </div>
        </div>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default EditRecolecciones;
