import { notifyPendingNotify, popNotification } from "./Notify";
import { authenticate, makeGetRequest } from "./Requests";

//verifica la autenticación del usuario que va a realizar el ingreso
export async function checkAuthFunction(usr, pwd) {
    const notify = notifyPendingNotify();
    try {
      const response = await authenticate(usr, pwd);
      if (response && response.data.user.id) {
        console.log(JSON.stringify(response));

        const operario = await makeGetRequest(
          "operarioporusuario/" + response?.data?.user?.id
        );
        console.log("operario" + JSON.stringify(operario));
        const idOperario = operario.data.id;
        if (idOperario) {
          popNotification(
            notify,
            "success",
            "Usuario autenticado. Cargando..."
          );
          return idOperario;
        } else {
          popNotification(
            notify,
            "error",
            "Error de autenticación o rol incorrecto. Intente de nuevo"
          );
          return null;
        }
      }
    } catch (error) {
      console.log(error);
      popNotification(
        notify,
        "error",
        "No existe el usuario o no es operario.Intente de nuevo"
      );
      return null;
    }
  };