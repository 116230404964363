import { makeGetRequest } from "./Requests";

export async function dateToColombianZoneRq() {
      const d = await makeGetRequest("app-process-date/1");
      if (d) {
        console.log("fecha obtenida" + d?.data?.fecha_proceso);
        return d.data.fecha_proceso;
      } else {
        const date = new Date().toLocaleString("en-US").split(",");
        console.log(date);
        let dateCut = date[0].replace(/\//g, "-").padStart(10, "0");
        console.log(dateCut + "intento fecha");
        const colombianDate = new Date(dateCut).toISOString();
        let colD = colombianDate.substring(0, 10);
        console.log(colD + "intento fecha 2");
        return colD;
      }
    }
    export function timeToColombianZonerq (dateObject) {
      let tDate;
      if (!dateObject) {
        tDate = new Date();
      } else {
        tDate = dateObject;
      }
      const timeFromDate = tDate.toLocaleTimeString("en-US", { hour12: false });
      let time = timeFromDate;
      console.log("hora en colombianoo:" + time);
    
      if (time.substring(0, 2) == "24") {
        time = time.replace("24", "00");
        console.log("nueva hora colombianDate" + time);
      }
      return time;
    };