import React, { useEffect, useState } from "react";
import useAuth from "../../hooks/useAuth";
import { makeSafePostRequest } from "../utils/Requests";
import { ToastContainer } from "react-toastify";
import { notifyPendingNotify, popNotification } from "../utils/Notify";
import { getEmpresaAdmin } from "../../api/RoleInfo";

function CreateAcopio() {
  const { auth } = useAuth();
  const [idEmpresa, setIdEmpresa] = useState(); //en adelante llamar desde aqui el getempresa
  const [nombreEmpresa, setNombreEmpresa] = useState();
  const [nombre, setNombre] = useState("");
  const [codigo, setCodigo] = useState(0);
  const [direccion, setDireccion] = useState("");
  const [observaciones, setObservaciones] = useState("");
  const [dataValid, setDataValid]= useState("false");

  //obtiene el id de la empresa
  const getIdEmpresa = async () => {
    try {
      const response = await getEmpresaAdmin(auth);
      if (response) {
        console.log("encontro datos de la empresa" + JSON.stringify(response));
        setIdEmpresa(response.empresa?.id);
        setNombreEmpresa(response.empresa?.nombre);
      }
    } catch (error) {
      console.log("Error al obtener los datos de la empresa");
    }
  };
  //Crea un nuevo acopio con los datos digitados
  const postAcopio = async () => {
    const notify = notifyPendingNotify();
    try {
      const response = await makeSafePostRequest(
        "acopios",
        {
          data: {
            empresa: idEmpresa,
            nombre: nombre,
            codigo: codigo,
            direccion: direccion,
            observaciones: observaciones,
          },
        },
        auth.accessToken
      );
      if (response && response.status === 200) {
        popNotification(notify, "success", "Acopio creado correctamente");
        console.log("acopio creado");
        clearData();
      } else {
        popNotification(notify, "error", "No se pudo crear el acopio");
        console.log("no se pudo crear el acopio");
      }
    } catch (error) {
      popNotification(notify, "error", "Error al crear el acopio");
      console.log("error al postear el acopio: " + error);
    }
  };
  //limpia la datad  del formulario
  const clearData=()=>{
    setNombre("");
    setCodigo("");
    setDireccion("");
    setObservaciones("");
  }
  useEffect(()=>{
    getIdEmpresa();
  },[])
  return (
    <div className="h-full my-2 md:w-1/2 py-5 mx-2">
      <div className="grid grid-cols-1">
        <h1 className="text-2xl">Crear Acopio</h1>
        <h3 className="text-xl text-left">Datos del Acopio</h3>
        <label className="label">Empresa: {nombreEmpresa}</label>
        <label className="label">Nombre del Acopio</label>
        <input
          type="text"
          className="input input-bordered"
          onChange={(e) => setNombre(e.target.value)}
          placeholder="Digite un nombre"
        ></input>
        <label className="label">Código</label>
        <input
          type="number"
          className="input input-bordered"
          min={0}
          step={1}
          onChange={(e) => setCodigo(e.target.value)}
          placeholder="Código"
        ></input>
        <label className="label">Dirección</label>
        <input
          type="text"
          className="input input-bordered"
          onChange={(e) => setDireccion(e.target.value)}
          placeholder="Dirección del Acopio"
        ></input>
        <label className="label">Observaciones</label>
        <textarea
          type="text"
          className="textarea textarea-bordered"
          onChange={(e) => setObservaciones(e.target.value)}
          placeholder="observaciones"
        ></textarea>
        <label className="btn btn-accent my-2" htmlFor="modal-confirm">
          Crear Acopio
        </label>
      </div>
      <input type="checkbox" id="modal-confirm" className="modal-toggle" />
      <div className="modal">
        <div className="modal-box">
          <h3 className="font-bold text-lg">Aviso</h3>
          <p className="py-4">
            Realmente desea crear el siguiente acopio?
          </p>
          <p>
            <span className="font-semibold">Nombre: </span>
            {nombre}
          </p>
          <p>
            <span className="font-semibold">Código: </span>
            {codigo}
          </p>
          <p>
            <span className="font-semibold">Dirección: </span>
            {direccion}
          </p>          
          <p>
            <span className="font-semibold">Observaciones: </span>
            {observaciones}
          </p>
          <div className="modal-action">
            <label
              htmlFor="modal-confirm"
              className="btn btn-accent"
              onClick={postAcopio}
            >
              Crear acopio
            </label>
            <label htmlFor="modal-confirm" className="btn">
              Aún no
            </label>
          </div>
        </div>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default CreateAcopio;
