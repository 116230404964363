import React from "react";

function SearchBar({ placeholder, data }) {
  return (
    <div className="search">
      <div className="searchInputs">
        <span className="input-group">
          <input type={"number"} placeholder={placeholder}></input>
          <span>
            
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              />
            </svg>
          </span>
        </span>
      </div>
      <div className="result grid grid-cols-1">
        {data?.map((d)=>{
            <div >{d.primer_nombre}</div>
        })}
      </div>
    </div>
  );
}

export default SearchBar;
