import React, { useEffect, useRef, useState } from "react";
import { DownloadTableExcel } from "react-export-table-to-excel";
import axios from "../api/axios";
import useAuth from "../hooks/useAuth";
import { getRutasAcopio } from "./utils/getRutasInfo";
import { getAcopiosEmpresa } from "./utils/getAcopiosinfo";

function ReporteRecorridos(props) {
  const { auth } = useAuth();
  const { empresa } = props;
  const URL = process.env.REACT_APP_BACKEND_BASE_URL;
  const [fecha, setFecha] = useState();
  const [acopios, setAcopios] = useState();
  const [selAcopio, setSelAcopio] = useState();
  const [rutas, setRutas] = useState();
  const [selectedRuta, setSelectedRuta] = useState();
  const [selectedRutaNombre, setSelectedRutaNombre] = useState("");
  const [recorridosRutaFecha, setrecorridosRutaFecha] = useState();
  //useRef tabla
  const tableRef = useRef(null);
  //obtiene los acopios de la empresa
  const getAcopios = async () => {
    console.log("OBTENIENDO acos");
    const aco = await getAcopiosEmpresa(empresa, auth.accessToken);
    setAcopios(aco);
  };
  //obtiene las rutas de la empresa, por ahora agronit
  const getRutas = async () => {
    const rutas = await getRutasAcopio(selAcopio, auth.accessToken);
    setRutas(rutas);
  };
  //obtiene el reporte de la ruta
  const getRecorridosRutaFecha = async () => {
    try {
      const myUrl =
        URL + "recorridosruta?idruta=" + selectedRuta + "&fecha=" + fecha;
      const response = await axios.get(`${myUrl}`);
      if (response.status === 200) {
        console.log(JSON.stringify(response.data));
        setrecorridosRutaFecha(response.data);
      }
    } catch (error) {
      console.log("error al obtener los recorridos: " + error);
    }
  };
  // agrega el valor del input al state de fecha
  const onFechaChange = (e) => {
    const nfecha = e.target.value;
    setFecha(nfecha);
  };
  //Cambio en ruta seleccionada
  const onRutaChange = (e) => {
    setSelectedRuta(e.target.value);
    setSelectedRutaNombre(e.target.selectedOptions[0].text);
  };

  //use effect para traer las rutas
  useEffect(() => {
    if (selAcopio) {
      getRutas();
    }
  }, [selAcopio]);
  //useeffect para traer los acopios
  useEffect(() => {
    if (empresa) {
      getAcopios();
    }
  }, [empresa]);
  useEffect(() => {}, [selectedRuta]);
  useEffect(() => {}, [fecha]);
  return (
    <div className="main flex flex-col px-5">
      <label className="label" htmlFor="rutas">
        Seleccione un acopio
      </label>
      <select
        className="input input-bordered"
        id="rutas"
        onChange={(e) => setSelAcopio(e.target.value)}
      >
        <option value={""}>Seleccione un acopio</option>
        {!acopios ? (
          <option value={-1}>Cargando acopios</option>
        ) : (
          acopios?.map((acopio) => (
            <option value={acopio.id} key={acopio.id}>
              {acopio.nombre}
            </option>
          ))
        )}
      </select>
      <label className="label" htmlFor="rutas">
        Seleccione una ruta
      </label>
      <select
        className="input input-bordered"
        id="rutas"
        onChange={(e) => onRutaChange(e)}
      >
        <option value={""}>Seleccione una ruta</option>
        {!rutas ? (
          <option value={-1}>Cargando rutas</option>
        ) : (
          rutas?.map((ruta) => (
            <option value={ruta.id} key={ruta.id}>
              {ruta.nombre}
            </option>
          ))
        )}
      </select>
      <label className="label" htmlFor="fecha">
        Fecha
      </label>
      <input
        type={"date"}
        className="input input-bordered"
        onChange={(e) => onFechaChange(e)}
      ></input>
      <button
        className="btn btn-accent my-3"
        onClick={(e) => getRecorridosRutaFecha()}
      >
        Generar reporte
      </button>
      <div className="overflow-x-auto">
        <table className="table table-compact py-3 w-full" ref={tableRef}>
          {recorridosRutaFecha &&
          recorridosRutaFecha.length > 0 &&
          selectedRuta ? (
            <>
              <thead>
                <tr>
                  <th></th>
                  <th>Ruta: {recorridosRutaFecha[0]?.ruta?.nombre}</th>
                  <th>
                    Fecha:
                    {recorridosRutaFecha[0]?.fecha}
                  </th>
                  <th>
                    Hora inicio:{" "}
                    {recorridosRutaFecha[0]?.hora_inicio.split(".", [1])}
                  </th>
                </tr>
                <tr>
                  <th></th>
                  <th>Productor</th>
                  <th>Cantidad</th>
                  <th>Observaciones</th>
                </tr>
              </thead>
              <tbody>
                {recorridosRutaFecha[0]?.recolecciones?.map((reco, index) => (
                  <tr key={reco.id}>
                    <th>{index + 1}</th>
                    <td>{reco?.productor?.nombre_corto}</td>
                    <td>{reco.cantidad_leche}</td>
                    <td>{reco.observaciones.substring(0, 35)}</td>
                  </tr>
                ))}
                <tr>
                  <td></td>
                  <th>Total:</th>
                  <td colSpan={2}>
                    {recorridosRutaFecha[0]?.recolecciones?.reduce(
                      (c, reco) => c + reco.cantidad_leche,
                      0
                    ) + " litros"}
                  </td>
                </tr>
              </tbody>
            </>
          ) : (
            <tbody>
              <tr colSpan={2}>
                <td>No existe recorrido para la ruta en la fecha indicada</td>
              </tr>
            </tbody>
          )}
        </table>
      </div>
      <DownloadTableExcel
        filename={"recorrido_" + selectedRutaNombre + "_" + fecha}
        sheet={"reporte ruta por dia"}
        currentTableRef={tableRef.current}
      >
        <button className="btn w-40">Exportar excel</button>
      </DownloadTableExcel>
    </div>
  );
}

export default ReporteRecorridos;
