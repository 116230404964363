import React, { useEffect, useState } from 'react'
import BookTitle from './BookTitle';

function BookEditForm() {
  const [book, setBook] = useState({});
  function handleBookChange(bookE) {
    setBook({...bookE});
  }
  
  return (
    <form>
      <BookTitle onBookChange={handleBookChange}   />
      <label>{book.title}{" /"}{book.year}</label>
    </form>
  )
}
export default BookEditForm;