import React, { useEffect, useState } from "react";
import useAuth from "../../hooks/useAuth";
import { getEmpresaAdmin } from "../../api/RoleInfo";
import { notifyPendingNotify, popNotification } from "../utils/Notify";
import { makeSafePostRequest } from "../utils/Requests";
import { ToastContainer } from "react-toastify";

function CreateProducto() {
  const { auth } = useAuth();
  const [idEmpresa, setIdEmpresa] = useState();
  const [nombreEmpresa, setNombreEmpresa] = useState();
  const [nombre, setNombre] = useState("");
  const [codigo, setCodigo] = useState(0);
  const [presentacion,setPresentacion]= useState("")
  const [marca, setMarca] = useState("");
  const [grupo, setGrupo] = useState("");
  const [observaciones, setObservaciones] = useState("");
  //obtiene la data de la empresa
  const getEmpresa = async () => {
    const response = await getEmpresaAdmin(auth);
    setIdEmpresa(response?.empresa?.id);
    setNombreEmpresa(response?.empresa.nombre);
  };
  //verifica que los datos ingresados estén correctos
  const verifyData = () => {
    if (nombre !== "" && codigo !== 0 && marca !== "") {
      return true;
    } else {
      return false;
    }
  };
  //postea los datos ingresados del producto
  const postProducto = async () => {
    const notify = notifyPendingNotify();
    try {
      if (verifyData()) {
        const data = {
          data: {
            nombre: nombre,
            codigo: codigo,
            marca: marca,
            grupo: grupo,
            presentacion:presentacion,
            observaciones: observaciones,
            empresa: idEmpresa,
          },
        };
        const response = await makeSafePostRequest(
          "productos-agricolas",
          data,
          auth.accessToken
        );
        if (response && response.status === 200) {
          popNotification(notify, "success", "Productor creado correctamente");
        } else {
          popNotification(
            notify,
            "error",
            "No se pudo crear el productor. Intente de nuevo"
          );
        }
      } else {
        popNotification(
          notify,
          "error",
          "No se pudo crear el productor. Verifique los datos y vuelva a intentarlo"
        );
      }
    } catch (error) {
      popNotification(
        notify,
        "error",
        "Error al crear el productor. Intente de nuevo"
      );
    }
  };
  const onNombreChange=(e)=>{
    setNombre(e.target.value.toUpperCase())
  }
  //useeffect inicial
  useEffect(() => {
    getEmpresa();
  }, []);
  return (
    <div className="section h-full md:w-1/2 mx-2 my-5">
      <h1 className="text-2xl">Crear Productor</h1>
      <div className="grid grid-cols-1">
        <label className="label">Empresa: {nombreEmpresa}</label>
        <label className="label">Nombre del producto</label>
        <input
          type="text"
          className="input input-bordered"
          onChange={(e) => {
            onNombreChange(e);
          }}
        ></input>
        <label className="label">Código</label>
        <input
          type="number"
          className="input input-bordered"
          min={0}
          step={1}
          onChange={(e) => {
            setCodigo(e.target.value);
          }}
        ></input>
        <label className="label">Marca</label>
        <input
          className="input input-bordered"
          onChange={(e) => {
            setMarca(e.target.value);
          }}
        ></input>
        <label className="label">Presentación</label>
        <input className="input input-bordered" onChange={(e)=>setPresentacion(e.target.value)}>
        </input>
        <label className="label">Grupo</label>
        <input
          className="input input-bordered"
          onChange={(e) => {
            setGrupo(e.target.value);
          }}
        ></input>
        <label className="label">Observaciones</label>
        <textarea
          className="textarea textarea-bordered"
          onChange={(e) => setObservaciones(e.target.value)}
        ></textarea>
        <label
          className="btn btn-active"
          htmlFor={"modal-confirm"}
          onClick={verifyData}
        >
          Crear productor
        </label>
      </div>
      {/* Modal guardar */}
      <input type="checkbox" id="modal-confirm" className="modal-toggle" />
      <div className="modal">
        <div className="modal-box relative">
          <label
            htmlFor="modal-confirm"
            className="btn btn-sm btn-circle absolute right-2 top-2"
          >
            ✕
          </label>
          <h3 className="text-lg font-bold">Aviso!</h3>
          <p className="py-4">
            ¿Realmente desea gurardar el siguiente producto?
          </p>
          <p className="">
            <span className="font-bold">Código: </span>
            {codigo}
          </p>
          <p className="">
            <span className="font-bold">Nombre del producto: </span>
            {nombre}
          </p>
          <p className="">
            <span className="font-bold">Marca: </span>
            {marca}
          </p>
          <p className="">
            <span className="font-bold">Grupo: </span>
            {grupo}
          </p>
          <p className="">
            <span className="font-bold">Observaciones: </span>
            {observaciones}
          </p>

          <div className="modal-action">
            <label
              className="btn btn-accent"
              htmlFor="modal-confirm"
              onClick={() => postProducto()}
            >
              Sí
            </label>
            <label className="btn btn-accent" htmlFor="modal-confirm">
              Aún no
            </label>
          </div>
        </div>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default CreateProducto;
