import React from 'react'
import { Link, Outlet } from 'react-router-dom';
import CreateRecollection from './CreateRecollection';
import WelcomeLayout from './WelcomeLayout';
import NavBar from './NavBar';
import RequireAuth from './RequireAuth';
import useAuth from '../hooks/useAuth';

function Home() {
    const { auth } = useAuth();
    const roles = auth.roles || [];
    return (
        <div>
            <NavBar />
            <div className="drawer drawer-mobile ">
                <input id="my-drawer-2" type="checkbox" className="drawer-toggle" />
                <div className="drawer-content flex flex-col items-center justify-center bg-base-100">
                    {/* {Page content here} */}
                    <Outlet />

                </div>
                <div className="drawer-side shadow-lg bg-slate-700">

                    <label htmlFor="my-drawer-2" className="drawer-overlay z-10"></label>
                    <ul className="menu p-4 overflow-y-auto w-80 bg-slate-600 text-slate-50">
                        {roles.includes('operario') ?
                            <>
                                <li><Link to='/monitor-acopio'>Monitor </Link></li>
                                <li><Link to='/registro-recoleccion-safe'>Recolección de Rutas</Link></li>
                                <li><Link to='/registro-recoleccion-search-safe'>Recolecciones (Búsqueda) </Link></li>
                                <ul className='dropdown bg-inherit m-3'>
                                        <li tabIndex={0} className="text-left">Reportes</li>
                                        <ul tabIndex={0} className="dropdown-content  menu bg-slate-700">                                   
                                            <li><Link to='/recorridos-recolector'>Recorridos Realizados</Link></li>
                                            <li><Link to='/recolecciones-productor'>Recolecciones por Productor</Link></li>
                                            <li><Link to={'/ingresos-recolector'}>Ingresos de Rutas</Link></li>
                                        </ul>
                                </ul>
                                {/* <li><Link to='/registro-pedido'>Realizar pedido</Link></li> */}
                                <li><Link to='/registro-ingreso'>Registrar Ingresos</Link></li>
                                <li><Link to='/registro-traslado'>Registrar Traslado</Link></li>
                                <li><Link to='/registro-recibir-traslado'>Registrar Ingreso de Traslado</Link></li>
                                <li><Link to='/registro-devolucion'>Registrar Devolución</Link></li>
                                <li><Link to='/registro-venta'>Registrar Venta</Link></li>
                                <li><Link to='/abrir-consolidado'>Abrir Consolidado diario</Link></li>
                                <li><Link to='/cerrar-consolidado'>Cerrar Consolidado diario</Link></li>
                                {/* <li><Link to='/obtener-consolidados'>Lista de consolidados</Link></li> */}
                                
                            </>

                            : roles.includes('recolector') ?
                                <>
                                    {/* <li><Link to='/registro-recoleccion'>Registrar Recolección</Link></li> */}
                                    <li><Link to='/registro-recoleccion-safe'>Registrar Recolección</Link></li>
                                    {/* <li><Link to='/registro-recoleccion-search'>Recolecciones (Búsqueda) </Link></li> */}
                                    <li><Link to='/registro-recoleccion-search-safe'>Recolecciones (Búsqueda)</Link></li>
                                    <li><Link to='/recorridos-recolector'>Recorridos Realizados</Link></li>
                                    <li><Link to='/recolecciones-productor'>Recolecciones por Productor</Link></li>
                                    <li><Link to={'/ingresos-recolector'}>Ingresos de Rutas</Link></li>
                                    {/* <li><Link to='/registro-pedido'>Realizar pedido</Link></li> */}
                                </>
                                : roles.includes('productor') ?
                                <>
                                    <li><Link to='/recolecciones-productor-rol'>Entregas realizadas</Link></li>
                                    
                                </>
                            : roles.includes('administrador') ?
                                <>
                                    <li><Link to='/monitor-empresa'>Monitor </Link></li>                                    
                                    <li><Link to='/reportes'>Reportes Movimientos</Link></li>
                                    <ul className='dropdown bg-inherit m-3'>
                                        <li tabIndex={0} className="text-left">Reportes Ventas</li>
                                        <ul tabIndex={0} className="dropdown-content  menu bg-slate-700">
                                            
                                            <li><Link to='/reporte-ventas'>Ventas (Acopios)</Link></li>
                                            <li><Link to='/ventas-clientes'>Ventas (Clientes)</Link></li>
                                            <li><Link to='/ventas-empresa'>Ventas (Empresa)</Link></li>
                                            <li><Link to='/ventas-calidad'>Ventas (Calidad)</Link></li>
                                            <li><Link to='/pedidos-empresa'>Pedidos Empresa</Link></li>
                                        </ul>
                                    </ul>
                                    <ul className='dropdown bg-inherit m-3'>
                                        <li tabIndex={1} className="text-left">Reportes Traslados, Devoluciones y Cierres</li>
                                        <ul tabIndex={1} className="dropdown-content  menu bg-slate-700">                                            
                                            <li><Link to='/traslados-favor'>Traslados a Favor</Link></li>
                                            <li><Link to='/traslados-sal'>Traslados Salientes</Link></li>
                                            <li><Link to='/devoluciones-empresa'>Devoluciones Empresa</Link></li>
                                            <li><Link to='/devoluciones-cliente'>Devoluciones por Cliente</Link></li>
                                            <li><Link to='/reporte-consolidados'>Consolidados (acopio)</Link></li>
                                            <li><Link to='/ingresos-calidad'>Ingresos Calidad</Link></li>
                                        </ul>
                                    </ul>
                                    <ul className='dropdown bg-inherit m-3'>
                                        <li tabIndex={1} className="text-left">Ver</li>
                                        <ul tabIndex={1} className="dropdown-content  menu bg-slate-700">                                                                                        
                                            <li><Link to="/list-productor">Ver Productores</Link></li>
                                            <li><Link to="/list-recolectores">Ver Recolectores</Link></li>
                                            <li><Link to="/list-operarios">Ver Operarios</Link></li>
                                            <li><Link to='/list-acopios'>Ver Acopios</Link></li>
                                            <li><Link to='/list-rutas'>Ver Rutas</Link></li>
                                            <li><Link to="/list-banco">Ver Bancos</Link></li>
                                            {/* <li><Link to="/list-recolector">Ver Recolectores</Link></li> */}
                                        </ul>
                                    </ul>
                                    <ul className='dropdown bg-inherit m-3'>
                                        <li tabIndex={1} className="text-left">Crear</li>
                                        <ul tabIndex={1} className="dropdown-content  menu bg-slate-700">                                                                                        
                                            <li><Link to={'/create-acopio'}>Crear Acopio</Link></li>
                                            <li><Link to={'/create-ruta'}>Crear Ruta</Link></li>
                                            <li><Link to={'/create-producto'}>Crear Producto agrícola</Link></li>
                                            <li><Link to='/create-productor'>Crear Productor</Link></li>
                                            <li><Link to='/create-recolector'>Crear Recolector</Link></li>
                                            <li><Link to='/create-operario'>Crear Operario</Link></li>
                                            {/* <li><Link to='/create-user'>Crear Usuario</Link></li> */}
                                            <li><Link to="/create-banco">Crear Bancos</Link></li>
                                        </ul>
                                    </ul>

                                    {/* <li><Link to= "/search">Buscar</Link></li> */}
                                    

                                    <ul className='dropdown bg-inherit m-3'>
                                        <li tabIndex={0} className="text-left">Editor </li>
                                        <ul tabIndex={0} className="dropdown-content  menu bg-slate-700">
                                            
                                            <li><Link to= "/reco-edit">Editar Recolecciones</Link></li>
                                            <li><Link to= "/ingreso-edit">Editar Ingresos</Link></li>
                                            <li><Link to= "/venta-edit">Editar Ventas</Link></li>
                                            <li><Link to= "/consolidado-edit">Editar Consolidados</Link></li>
                                            <li><Link to= "/apertura-consolidado-edit">Editar Aperturas </Link></li>
                                            <li><Link to= "/ingreso-ajuste">Ingreso por Ajuste</Link></li>
                                        </ul>
                                    </ul>
                                    <li><Link to= "/upload-prods">Subir Productores</Link></li>
                                    <li><Link to= "/upload-clientes">Subir Clientes</Link></li>
                                    
                                </>
                                : <></>

                        }

                        


                        {/* <li><Link to='/book-form'>Prueba comp reutilizable</Link></li> */}

                    </ul>

                </div>
            </div>
        </div>
    )
}

export default Home;