import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { notifyPendingNotify, popNotification } from "./utils/Notify";
import { makeGetRequest, makePostRequest } from "./utils/Requests";

function CreateBancos() {
  
  const [nombre, setNombre] = useState();
  const [codigo, setCodigo] = useState();
  const [observaciones, setObservaciones] = useState();

  
  //guarda la informacion del banco que registra el usuario
  const postBanco=async()=>{
    const notify= notifyPendingNotify();
    try {
        const data={
            data:{
                nombre_banco:nombre,
                codigo_banco:codigo,
                observaciones:observaciones
            }
        }
        const response=await makePostRequest("bancos",data);
        if(response){
            popNotification(notify,"success","Banco guardado");
        }
        else{
            popNotification(notify,"error","No se pudo guardar el banco-Verifique que el codigo sea único")
        }
    } catch (error) {
        console.log(error);
    }
  }
  //useEffect de inicio
  useEffect(() => {
    console.log("cambio en form")
  }, [nombre, codigo, observaciones]);

  return (
    <section className="w-7/12 h-full p-5">
      <h1 className="text text-3xl">Agregar Banco </h1>
      <div className="grid  grid-cols-1">
        <div className="flex flex-col">
          <label htmlFor="nombre" className="label">
            Nombre del banco
          </label>
          <input
            type={"text"}
            placeholder="Nombre del banco"
            className="input input-bordered"
            onChange={(e) => {
              setNombre(e.target.value);
            }}
          ></input>
        </div>
        <div className="flex flex-col">
          <label htmlFor="codigo" className="label">
            Código del banco
          </label>
          <input
            type={"number"}
            placeholder="Código"
            className="input input-bordered"
            onChange={(e) => {
              setCodigo(e.target.value);
            }}
          ></input>
        </div>
        <div className="flex flex-col">
          <label htmlFor="observaciones" className="label">
            Anotaciones
          </label>
          <input
            type={"text"}
            placeholder="Escriba aquí"
            className="input input-bordered"
            onChange={(e) => {
              setObservaciones(e.target.value);
            }}
          ></input>
        </div>
      </div>
      <label className="btn btn-active my-3" htmlFor="modalConfirm">
        Guardar banco
      </label>
      {/* Modal de confirmacion */}
      <input
        type={"checkbox"}
        id="modalConfirm"
        className="modal-toggle"
      ></input>
      <div className="modal">
        <div className="modal-box">
          <h3 className="font-bold text-lg">Confirmar</h3>
          <p>Desea guardar la información digitada?</p>
          <p>
            <span className="font-bold">Nombre del banco:</span>
            {nombre}
          </p>
          <p>
            <span className="font-bold">Código:</span>
            {codigo}
          </p>
          <p>
            <span className="font-bold">observaciones:</span>
            {observaciones}
          </p>
          <div className="modal-action">
            <label
              htmlFor="modalConfirm"
              className="btn"
              onClick={() => {
                postBanco();
              }}
            >Sí</label>
            <label htmlFor="modalConfirm" className="btn">Aún no</label>
          </div>
        </div>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </section>
  );
}

export default CreateBancos;
