import React from 'react'
import { Outlet } from 'react-router-dom'

function WelcomeLayout() {
  return (
    <div >
        <h1 className='text-4xl'>
          Bienvenido a AgroNIT
        </h1>
        <p>
          Seleccione una de las opciones disponibles en el menú
        </p>
    </div>
  )
}

export default WelcomeLayout