import React, { useEffect, useState } from "react";
import useAuth from "../hooks/useAuth";
import { makeGetRequest, makeSafeGetRequest } from "./utils/Requests";

function RecoleccionesCounter(props) {
  const { ruta, cant } = props;
  const [recoleccionesStats, setRecoleccionesStats] = useState();
  const auth = useAuth();
  //obtiene el porcentaje de recolecciones realizadas
  const getRecoleccionesStats = async () => {
    try {
      const response = await makeGetRequest("recoleccionstats/" + ruta);
      if (response) {
        setRecoleccionesStats(response.data);
      }
    } catch (error) {
      console.log("Error al obtener los stats: " + error);
    }
  };
  //UseEffect
  useEffect(() => {
    getRecoleccionesStats();
  }, [cant]);

  return (
    <div className="section grid grid-cols-1 my-3">
      {recoleccionesStats ? (
        <>
          {recoleccionesStats?.recolecciones <
          recoleccionesStats.productores ? (
            <progress
              className="progress progress-warning w-full"
              value={recoleccionesStats?.recolecciones}
              max={recoleccionesStats?.productores}
            ></progress>
          ) : recoleccionesStats?.recolecciones ===
            recoleccionesStats.productores ? (
            <progress
              className="progress progress-success w-full"
              value={recoleccionesStats?.recolecciones}
              max={recoleccionesStats?.productores}
            ></progress>
          ) : (
            <progress
              className="progress progress-error w-full"
              value={recoleccionesStats?.recolecciones}
              max={recoleccionesStats?.productores}
            ></progress>
          )}

          <label className="label">
            <span className="label-text-alt">
              {recoleccionesStats?.recolecciones}/
              {recoleccionesStats?.productores} recolecciones
            </span>
            <span className="label-text-alt">
              {recoleccionesStats.litros} litros
            </span>
          </label>
        </>
      ) : (
        <></>
      )}
    </div>
  );
}

export default RecoleccionesCounter;
