import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { notifyPendingNotify, popNotification } from "./utils/Notify";
import { makeGetRequest, makePutRequest, makeSafeGetRequest } from "./utils/Requests";
import useAuth from "../hooks/useAuth";
import { getEmpresaAdmin } from "../api/RoleInfo";
//obtiene la lista de acopios existente
function GetAcopiosEmpresa() {
  //UseStates de la aplicacion
  const [idEmpresa, setIdEmpresa]= useState();
  const [empresaNombre,setEmpresaNombre]= useState();
  const {auth}=useAuth();
  const [acopios, setAcopios] = useState();
  const [selectedAcopio, setSelectedAcopio]= useState({

  });
  const [acopioUpdated,setAcopioUpdated]= useState({});
  
  //Obtiene el id de la empresa
  const getIdEmpresa = async () => {
    try {
      const response = await getEmpresaAdmin(auth);
      if (response) {
        setIdEmpresa(response.empresa?.id);
        setEmpresaNombre(response.empresa?.nombre);
      }
    } catch (error) {
      console.log("Error al obtener los datos de la empresa");
    }
  };
  //obtiene los acopios de una empresa especifica
  const getAcopios=async()=>{
    try {
      const response= await makeSafeGetRequest("acopiosempresa/"+idEmpresa, auth.accessToken); //cambiar por el id de la empresa a la q pertenece el admin
      if(response && response.status===200){
        setAcopios(response.data)
      }
    } catch (error) {
      console.log("No se pudo obtener la lista de acopios de la empresa");
    }
  }
  //Obtiene el acopio entre la lista de acopios
  const findAcopio = async (idAcopio) => {
    const acopio = acopios.find((a) => a.id === idAcopio);
    console.log("Acopio encontrado:" + JSON.stringify(acopio));
    setSelectedAcopio(acopio);
  };
  //Agrega un atributo al objeto que guarda la data a actualizar
  const addAttributeUpdated = (position, value) => {
    try {
      const au = {...selectedAcopio};
      au[position] = value;
      setSelectedAcopio(au);
    } catch (error) {
      console.log(error);
    }
  };
  //actualiza un acopio con los datos suministrados en el modal actualizar
  const updateAcopio = async () => {
    const notify = notifyPendingNotify();
    try {
      const response = await makePutRequest("acopios/" + selectedAcopio?.id, {
        data: selectedAcopio,
      });
      if (response) {
        console.log(response);
        popNotification(notify, "success", "Acopio actualizado correctamente");
      } else {
        popNotification(notify, "error", "Error al actualizar el Acopio");
      }
    } catch (error) {
      console.log(error);
      popNotification(
        notify,
        "error",
        "Error al actualizar el Acopio. Fallo en conexión"
      );
    }
    clearAcopioUpdated();
  };
  //Limpia el objeto de datos para actualizar el Acopio
  const clearAcopioUpdated = () => {
    setAcopioUpdated({});
  };
  //Elimina el Acopio seleccionado luego de confirmar la orden en el modal
  const deleteAcopio = async () => {
    const notify = notifyPendingNotify();
    try {
      console.log("Eliminando Acopio:" + JSON.stringify(selectedAcopio));
      const data = { data: { eliminado: true, activo: false } };
      const response = await makePutRequest("acopio/" +selectedAcopio.id, data);
      if (response) {
        console.log(response);
        popNotification(notify, "success", "Acopio eliminado correctamente");
      } else {
        popNotification(notify, "error", "error al eliminar el Acopio");
      }
    } catch (error) {
      console.log("ERROR AL ELIMINAR" + error);
      popNotification(
        notify,
        "error",
        "No se pudo eliminar el Acopio seleccionado.Fallo en conexión"
      );
    }
  };
  useEffect(()=>{
    if(idEmpresa){
      getAcopios();
    }
  },[idEmpresa])
  useEffect(() => {
    getIdEmpresa();
    
  }, []);

  return (
    <div className="h-full m-3 w-3/4">
      <h1 className="text-4xl py-4">Ver  Acopios</h1>
      <label className="label">Empresa: {empresaNombre}</label>
      <div className="overflow-x-auto">
      <table border="1" className="table table-compact w-full">
        <thead>
          <tr>
            <th>Código</th>
            <th>Nombre</th>
            <th>direccion</th>
            <th>Observaciones</th>
            <th>Opciones</th>
          </tr>
        </thead>

        <tbody>
          {!acopios ? (
            <></>
          ) : (
            acopios.map((acopio) => {
              
              return (
                <tr key={acopio.id}>
                  <td>{acopio.codigo}</td>
                  <td>{acopio.nombre}</td>
                  <td>{acopio.direccion}</td>
                  <td>{acopio.observaciones}</td>
                  <td>
                    <div className="flex flex-wrap space-x-1 ">
                      <label
                        htmlFor="modalDetails"
                        className="btn btn-success btn-sm"
                        title="Detalles del acopio"
                        onClick={() => findAcopio(acopio.id)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                          />
                        </svg>
                      </label>
                      <label
                        htmlFor="modalUpdate"
                        className="btn btn-warning btn-sm"
                        onClick={() => {
                          findAcopio(acopio.id);
                        }}
                        title="Actualizar Acopio"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                          />
                        </svg>
                      </label>
                      <label
                        className="btn btn-error btn-sm"
                        htmlFor="modalDelete"
                        title="Eliminar Acopio (precaución)"
                        onClick={() => findAcopio(acopio.id)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                          />
                        </svg>
                      </label>
                    </div>
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </table>
      </div>
      {/* Modal de detalles */}
      <input
        type={"checkbox"}
        id="modalDetails"
        className="modal-toggle"
      ></input>
      <div className="modal">
        <div className="modal-box">
          <h3 className="font-bold text-lg">Detalles del Acopio</h3>
          <div className="grid grid-cols-2 text-left">
            <p className="text-left">
              <span className="font-bold">Nombre: </span>
              {selectedAcopio?.nombre}
            </p>
            <p>
              <span className="font-bold">Código: </span>
              {selectedAcopio?.codigo}
            </p>
            <p>
              <span className="font-bold">Observaciones: </span>
              {selectedAcopio?.observaciones}
            </p>
          </div>

          <div className="modal-action">
            <label htmlFor="modalDetails" className="btn">
              Cerrar
            </label>
          </div>
        </div>
      </div>
      {/* Modal de actualización */}
      <input
        type={"checkbox"}
        id="modalUpdate"
        className="modal-toggle"
      ></input>
      <div className="modal">
        <div className="modal-box">
          <h3 className="font-bold text-lg">Actualizar Acopio</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 text-left">
            <div className="flex flex-col m-1">
              <label className="label">Nombre del Acopio</label>
              <input
                type={"text"}
                className="input input-bordered"
                value={selectedAcopio?.nombre|| ""}
                onChange={(e) => {
                  addAttributeUpdated("nombre", e.target.value);
                }}
              ></input>
            </div>
            <div className="flex flex-col m-1">
              <label className="label">Dirección</label>
              <input
                type={"text"}
                className="input input-bordered"
                value={selectedAcopio?.direccion|| ""}
                onChange={(e) => {
                  addAttributeUpdated("direccion", e.target.value);
                }}
              ></input>
            </div>
            <div className="flex flex-col m-1">
              <label className="label">Código</label>
              <input
                type={"number"|| 0}
                className="input input-bordered"
                value={selectedAcopio?.codigo || 0 }
                onChange={(e) => {
                  addAttributeUpdated("codigo", e.target.value);
                }}
              ></input>
            </div>
            <div className="flex flex-col m-1">
              <label className="label">Observaciones</label>
              <textarea
                type={"text"}
                className="input input-bordered"
                value={selectedAcopio?.observaciones|| ""}
                onChange={(e) => {
                  addAttributeUpdated("observaciones", e.target.value);
                }}
              ></textarea>
            </div>
          </div>
          <div className="modal-action">
            <label
              htmlFor="modalUpdate"
              className="btn btn-accent"
              onClick={() => {
                updateAcopio();
                getAcopios();
                clearAcopioUpdated();
              }}
            >
              Actualizar
            </label>
            <label htmlFor="modalUpdate" className="btn">
              Cerrar
            </label>
          </div>
        </div>
      </div>
      {/* Modal de eliminacion */}
      <input
        type={"checkbox"}
        id="modalDelete"
        className="modal-toggle"
      ></input>
      <div className="modal">
        <div className="modal-box">
          <h3 className="font-bold text-lg">Aviso!</h3>
          <p>
            Seguro que desea eliminar el siguiente Acopio? Esta acción NO se
            puede deshacer
          </p>
          <p>{selectedAcopio?.nombre}</p>
          <div className="modal-action">
            <label
              htmlFor="modalDelete"
              className="btn"
              onClick={() => {
                deleteAcopio();
                //actualiza lista
                getAcopios();
              }}
            >
              Sí
            </label>
            <label htmlFor="modalDelete" className="btn">
              Aún no
            </label>
          </div>
        </div>
      </div>
      {/* Modal de confirmacion */}
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default GetAcopiosEmpresa;
