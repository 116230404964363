import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { notifyPendingNotify, popNotification } from "./utils/Notify";
import { makeGetRequest, makePutRequest } from "./utils/Requests";

function ListRecolectores() {
  const [recolectores, setRecolectores] = useState();
  const [selectedReco, setSelectedReco] = useState();
  const [recoUpdated, setRecoUpdated]=useState({});
  
  //Obtiene la lista de recolectores de la empresa
  const getRecolectoresEmpresa = async () => {
    try {
      console.log("probando metodo");
      const response = await makeGetRequest("recolectoresempresa/1");
      if (response) {
        setRecolectores(response.data);
      }
    } catch (error) {
      console.log("Error al obtener los recolectores de la empresa:" + error);
    }
  };
  //Encuentra un recolector entre la lista usando el id y lo toma como seleccionado
  const findRecolector = (id) => {
    const recolector = recolectores.find((reco) => reco.id === id);
    console.log("Recolector encontrado:" + JSON.stringify(recolector));
    setSelectedReco(recolector);
    
  };
  //Elimina el recolector seleccionado luego de confirmar la orden en el modal
  const deleteRecolector = async () => {
    const notify = notifyPendingNotify();
    try {
      console.log("Eliminando recolector:" + JSON.stringify(selectedReco));
      const data = { data: { eliminado: true, activo: false } };
      const response = await makePutRequest(
        "recolectors/" + selectedReco.id,
        data
      );
      if (response) {
        console.log(response);
        popNotification(
          notify,
          "success",
          "Recolector eliminado correctamente"
        );
      } else {
        popNotification(notify, "error", "error al procesar la peticion");
      }
    } catch (error) {
      console.log("ERROR AL ELIMINAR" + error);
      popNotification(
        notify,
        "error",
        "No se pudo eliminar el recolector seleccionado."
      );
    }
  };
  //actualiza un recolector con los datos suministrados en el modal actualizar
  const updateRecolector=async()=>{
    const notify= notifyPendingNotify();
    try {
      const response= await makePutRequest("recolectors/"+selectedReco?.id,{data:recoUpdated}); 
      if(response){
        console.log(response)
        popNotification(notify, "success","Recolector actualizado correctamente");
      }
      else{
        popNotification(notify, "error","Error al actualizar el recolector");
      }
    } catch (error) {
      console.log(error);
      popNotification(notify, "error","Error al actualizar el recolector. fallo en conexión");
    }
    clearRecoUpdated();
  }
  //Agrega un atributo al objeto de data
  const addAttributeUpdated=(position, value)=>{
    try {
      const ru=recoUpdated;
      ru[position]=value;
      setRecoUpdated(ru);
    } catch (error) {
      console.log(error)
    }
  }
  //Limpia el objeto de datos para actualizar el recolector
  const clearRecoUpdated=()=>{
    setRecoUpdated({});
  }
  //UseEffect Inicial
  useEffect(() => {
    getRecolectoresEmpresa();
  }, []);
  useEffect(() => {
    if(recoUpdated){

      console.log("Cambio en state de actualizacion");
    }
  }, [recoUpdated]);
  return (
    <div className="flex flex-col  h-full">
      <h1 className="text-5xl py-5">Lista de Recolectores</h1>
      <p>lorem ipsum dolor</p>
      {!recolectores ? (
        <div className="alert alert-info">Cargando Recolectores</div>
      ) : (
        <>
          <table className="table overflow-x-scroll">
            <thead>
              <tr>
                <th>ID</th>
                <th>Nombre completo</th>
                <th className="">Direccion</th>
                <th>Correo</th>
                <th>Rutas</th>
                <th>Opciones</th>
              </tr>
            </thead>
            <tbody>
              {recolectores.map((reco) => (
                <tr key={reco.id}>
                  <td>{reco.id}</td>
                  <td>{reco.primer_nombre + " " + reco.primer_apellido}</td>
                  <td>{reco.direccion}</td>
                  <td>{reco.correo}</td>
                  <td>
                    <React.Fragment>
                      <div className="flex flex-wrap  space-x-1">
                        {reco?.rutas?.map((ruta) => (
                          <div className="badge  badge-md " key={ruta?.id}>
                            {ruta?.nombre}
                          </div>
                        ))}
                      </div>
                    </React.Fragment>
                  </td>
                  <td>
                    <React.Fragment>
                      <div className="flex flex-wrap space-x-1 ">
                        <label
                          htmlFor="modalDetails"
                          className="btn btn-success btn-sm"
                          title="Detalles del recolector"
                          onClick={() => findRecolector(reco.id)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                            />
                          </svg>
                        </label>
                        <label
                          htmlFor="modalUpdate"
                          className="btn btn-warning btn-sm"
                          onClick={() => findRecolector(reco.id)}
                          title="Actualizar recolector"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                            />
                          </svg>
                        </label>
                        <label
                          className="btn btn-error btn-sm"
                          htmlFor="modalDelete"
                          title="Eliminar recolector (precaución)"
                          onClick={() => findRecolector(reco.id)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                            />
                          </svg>
                        </label>
                      </div>
                    </React.Fragment>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {/* Modal de detalles */}
          <input
            type={"checkbox"}
            id="modalDetails"
            className="modal-toggle"
          ></input>
          <div className="modal">
            <div className="modal-box">
              <h3 className="font-bold text-lg">Detalles del Recolector</h3>
              <div className="grid grid-cols-2 text-left">
                <p className="text-left">
                  <span className="font-bold">Nombre: </span>
                  {selectedReco?.nombre_corto}
                </p>
                <p>
                  <span className="font-bold">Fecha de Nacimiento: </span>
                  {selectedReco?.fecha_nacimiento}
                </p>
                <p>
                  <span className="font-bold">Número de Documento: </span>
                  {selectedReco?.numero_documento}
                </p>
                <p>
                  <span className="font-bold">Departamento: </span>
                  {selectedReco?.departamento}
                </p>
                <p>
                  <span className="font-bold">Municipio: </span>
                  {selectedReco?.municipio}
                </p>
                <p>
                  <span className="font-bold">Dirección: </span>
                  {selectedReco?.direccion}
                </p>
                <p>
                  <span className="font-bold">Teléfono: </span>
                  {selectedReco?.telefono}
                </p>
                <p>
                  <span className="font-bold">Teléfono 2: </span>
                  {selectedReco?.telefono_2}
                </p>
                <p>
                  <span className="font-bold">Correo: </span>
                  {selectedReco?.correo}
                </p>
                <p>
                  <span className="font-bold">Número de Cuenta: </span>
                  {selectedReco?.numero_cuenta}
                </p>
                <p>
                  <span className="font-bold">Rutas: </span>
                  {selectedReco?.rutas?.map((ruta) => (
                    <span key={ruta.id}>{ruta?.nombre + ", "}</span>
                  ))}
                </p>
              </div>

              <div className="modal-action">
                <label htmlFor="modalDetails" className="btn">
                  Cerrar
                </label>
              </div>
            </div>
          </div>
          {/* Modal de actualización */}
          <input
            type={"checkbox"}
            id="modalUpdate"
            className="modal-toggle"
          ></input>
          <div className="modal">
            <div className="modal-box">
              <h3 className="font-bold text-lg">Actualizar Recolector</h3>
              <div className="grid grid-cols-1 md:grid-cols-2 text-left gap-4 p-4">
                <div className="flex flex-col">
                  <label htmlFor="nombreC">Nombre Completo</label>
                  <input
                    type={"text"}
                    className="input input-bordered"
                    defaultValue={selectedReco?.nombre_corto}
                    onChange={((e)=>{addAttributeUpdated("nombre_corto",e.target.value)})}
                  ></input>
                </div>
                <div className="flex flex-col">
                  <label htmlFor="nombreC">Estado</label>
                  <div className="flex flex-row">
                  <span className="label-text px-3">Inactivo</span>
                  <input
                    type={"checkbox"}
                    className="toggle toggle-accent toggle-lg"
                    defaultValue={selectedReco?.nombre_corto}
                    onChange={((e)=>{addAttributeUpdated("activo",e.target.value)})}
                  ></input>
                  <span className="label-text px-3">Activo</span>
                  </div>
                </div>
                <div className="flex flex-col">
                  <label htmlFor="numeroDoc">Número de Documento</label>
                  <input
                    type={"text"}
                    className="input input-bordered"
                    defaultValue={selectedReco?.numero_documento}
                    onChange={((e)=>{addAttributeUpdated("numero_documento",e.target.value)})}
                  ></input>
                </div>
                <div className="flex flex-col">
                  <label htmlFor="nombreC">Fecha de Expedición</label>
                  <input
                    type={"date"}
                    className="input input-bordered"
                    defaultValue={selectedReco?.fecha_expedicion}
                    onChange={((e)=>{addAttributeUpdated("fecha_expedicion",e.target.value)})}
                  ></input>
                </div>
                <div className="flex flex-col">
                  <label htmlFor="pNombre">Primer Nombre</label>
                  <input
                    type={"text"}
                    className="input input-bordered"
                    defaultValue={selectedReco?.primer_nombre}
                    onChange={((e)=>{addAttributeUpdated("primer_nombre",e.target.value)})}
                  ></input>
                </div>
                <div className="flex flex-col">
                  <label htmlFor="nombreC">Segundo Nombre</label>
                  <input
                    type={"text"}
                    className="input input-bordered"
                    defaultValue={selectedReco?.segundo_nombre}
                    onChange={((e)=>{addAttributeUpdated("segundo_nombre",e.target.value)})}
                  ></input>
                </div>
                <div className="flex flex-col">
                  <label htmlFor="nombreC">Primer Apellido</label>
                  <input
                    type={"text"}
                    className="input input-bordered"
                    defaultValue={selectedReco?.primer_apellido}
                    onChange={((e)=>{addAttributeUpdated("primer_apellido",e.target.value)})}
                  ></input>
                </div>
                <div className="flex flex-col">
                  <label htmlFor="nombreC">Segundo Apellido</label>
                  <input
                    type={"text"}
                    className="input input-bordered"
                    defaultValue={selectedReco?.segundo_apellido}
                    onChange={((e)=>{addAttributeUpdated("segundo_apellido",e.target.value)})}
                  ></input>
                </div>
                <div className="flex flex-col">
                  <label htmlFor="nombreC">Fecha de nacimiento</label>
                  <input
                    type={"date"}
                    className="input input-bordered"
                    defaultValue={selectedReco?.fecha_nacimiento}
                    onChange={((e)=>{addAttributeUpdated("fecha_nacimiento",e.target.value)})}
                  ></input>
                </div>
                <div className="flex flex-col">
                  <label htmlFor="nombreC">Lugar de nacimiento</label>
                  <input
                    type={"text"}
                    className="input input-bordered"
                    defaultValue={selectedReco?.lugar_nacimiento}
                    onChange={((e)=>{addAttributeUpdated("lugar_nacimiento",e.target.value)})}
                  ></input>
                </div>
                <div className="flex flex-col">
                  <label htmlFor="nombreC">Lugar de nacimiento</label>
                  <input
                    type={"text"}
                    className="input input-bordered"
                    defaultValue={selectedReco?.lugar_nacimiento}
                  ></input>
                </div>
              </div>
              <div className="modal-action">
              <label htmlFor="modalUpdate" className="btn"onClick={()=>{updateRecolector();getRecolectoresEmpresa(); clearRecoUpdated();}}>
                  Actualizar
                </label>
                <label htmlFor="modalUpdate" className="btn">
                  Cerrar
                </label>
              </div>
            </div>
          </div>
          {/* Modal de eliminacion */}
          <input
            type={"checkbox"}
            id="modalDelete"
            className="modal-toggle"
          ></input>
          <div className="modal">
            <div className="modal-box">
              <h3 className="font-bold text-lg">Aviso!</h3>
              <p>
                Seguro que desea eliminar el siguiente Recolector? Esta acción
                NO se puede deshacer
              </p>
              <p>
                {!selectedReco?.nombre_corto ? (
                  <>
                    {selectedReco?.primer_nombre +
                      " " +
                      selectedReco?.primer_apellido}
                  </>
                ) : (
                  <>{selectedReco?.nombre_corto}</>
                )}
                {" - " + selectedReco?.numero_documento}
              </p>
              <div className="modal-action">
                <label
                  htmlFor="modalDelete"
                  className="btn"
                  onClick={() => {
                    deleteRecolector();
                    //actualiza lista
                    getRecolectoresEmpresa();
                  }}
                >
                  Sí
                </label>
                <label htmlFor="modalDelete" className="btn">
                  Aún no
                </label>
              </div>
            </div>
          </div>
          <ToastContainer
            position="bottom-center"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </>
      )}
    </div>
  );
}

export default ListRecolectores;
