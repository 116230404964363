import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import axios from "../api/axios";
import useAuth from "../hooks/useAuth";
import { notifyPendingNotify, popNotification } from "./utils/Notify";
import {
  dateToColombianZoneRq,
  timeToColombianZonerq,
} from "./utils/timezoneRequests";

function OpenConsolidado() {
  const URL = process.env.REACT_APP_BACKEND_BASE_URL;
  const { auth } = useAuth();
  const [consolidadoHoy, setConsolidadoHoy] = useState();
  const [consolidadoAnterior, setConsolidadoAnterior] = useState();
  const [cantidadAnterior, setCantidadAnterior] = useState();
  const [medicion, setMedicion] = useState();
  const [observaciones, setObservaciones] = useState("");
  const [diferencia, setDiferencia] = useState(0);
  const [fecha, setFecha]= useState();
  //states de novedades agregados el 11/11/2022
  const [medicionNovedad, setMedicionNovedad]=useState();
  const [diferenciaNovedad, setDiferenciaNovedad] =useState();
  const [cantAnteriorNovedad,setCantAnteriorNovedad]= useState();
  //STATES DE AUTORIZACION
  const [idOperario, setIdOperario] = useState();
  const [idAcopio, setIdAcopio] = useState();

  //Obtiene el id del operario al que pertenece el usuario logueado
  const getIdOperario = async () => {
    try {
      const idUser = auth.id;
      console.log("Datos del usuario:" + JSON.stringify(auth));
      const url = URL + "operarioporusuario/" + idUser;
      const response = await axios.get(`${url}`);

      console.log("Datos operario:" + JSON.stringify(response.data));
      if (response?.status === 200) {
        console.log("hay operario asociado al usuario");
        setIdOperario(response?.data?.id);
        getIdAcopio(response.data);
      } else {
        console.log("No hay operario disponiblee");
      }
    } catch (error) {
      console.log("Error al obtener el id del operario: " + error);
    }
  };
  //obtiene el id del acopio del operario
  const getIdAcopio = (dataOperario) => {
    const idAco = dataOperario.acopio.id;
    console.log("Id del acopio del recolector" + idAco);
    setIdAcopio(idAco);
  };

  //obtiene el dato del consolidado de hoy , si existe
  const getConsolidadoHoy = async () => {
    try {
      console.log("usando busqueda consolidado hoy. Idacopio:" + idAcopio);
      const myUrl = URL + "consolidadohoy/" + idAcopio;
      const response = await axios.get(`${myUrl}`);
      console.log(JSON.stringify(response) + "respuesta consolidado hoy ");
      if (response.status === 200) {
        console.log("Encontrado consolidado de hoy");
        setConsolidadoHoy(response.data);
        console.log(consolidadoHoy);
      } else {
        setConsolidadoHoy();
        console.log("Hoy no fue encontrado");
        await getConsolidadoAnterior();
        console.log("fin busquedaconsolidado anterior");
      }
    } catch (error) {
      console.log(error);
    }
  };
  //En caso de no existir un consolidado de hoy, esta funcion obtiene el consolidado inmediatamente anterior
  const getConsolidadoAnterior = async () => {
    try {
      console.log(
        "Intentando obtener el consolidado Anterior. IdACOPIO=" + idAcopio
      );
      const myUrl = URL + "consolidadoanterior/" + idAcopio;
      const response = await axios.get(`${myUrl}`);
      console.log(
        "Respuesta para consolidado anterior:" +
          JSON.stringify(response) +
          "\n y "
      );

      if (response.status === 200) {
        console.log("Consolidado anterior" + JSON.stringify(response.data));
        setConsolidadoAnterior(response.data);
        console.log("cantidadAnterior=" + response.data.cantidad_final_dia);
        setCantidadAnterior(response.data.cantidad_final_dia);
        setCantAnteriorNovedad(response.data.novedad_final_dia)
      } else {
        setCantidadAnterior(0);
        setCantAnteriorNovedad(0)
      }
    } catch (error) {
      console.log(error);
    }
  };
  //obtiene la diferencia entre los litros del dia anterior y los litros ingresados en el consolidado
  const getDiferencia = () => {
    const dif = medicion - cantidadAnterior;
    setDiferencia(dif);
  };
   //obtiene la diferencia entre los litros con novedad del dia anterior y los litros ingresados en el consolidado
   const getDiferenciaNovedad = () => {
    const dif = medicionNovedad - cantAnteriorNovedad;
    setDiferenciaNovedad(dif);
  };

  //guarda los datos del consolidado
  const postConsolidado = async () => {
    const notify = notifyPendingNotify();
    console.log("Probando  posteo de consolidado");
    const myUrl = URL + "apertura-consolidados";
    const fechaHoy = await dateToColombianZoneRq();
    
    const horaHoy = timeToColombianZonerq();
    try {
      const response = await axios.post(myUrl, {
        data: {
          fecha: fechaHoy,
          hora: horaHoy,
          cantidad_dia_anterior: cantidadAnterior,
          cantidad_medida: medicion,
          diferencia: diferencia,
          novedad_dia_anterior: cantAnteriorNovedad,
          novedad_medida: medicionNovedad,
          diferencia_novedad: diferenciaNovedad,
          observaciones: observaciones,
          acopio: idAcopio,
        },
      });
      if (response.status === 200 && response != []) {
        popNotification(notify, "success", "Apertura de Consolidado exitosa");
        //intenta obtener nuevamente el consolidado anterior
        await getConsolidadoHoy();
      } else {
        popNotification(
          notify,
          "error",
          "Algo salió mal al abrir el consolidado.Intente de nuevo"
        );
      }
      console.log(response);
    } catch (error) {
      console.log(error);
      popNotification(notify, "error", "Error al abrir el consolidado");
    }
  };
  //obtiene la fecha del sistema
  const getFecha=async()=>{
    const fechaHoy= await dateToColombianZoneRq();
    setFecha(fechaHoy);
  }
  //effect para empezar
  useEffect(() => {
    getFecha();
    getIdOperario();
  }, []);
  //Effect al cambio de idAcopio
  useEffect(() => {
    console.log(idAcopio + ",,,," + idOperario);
    if (idAcopio !== undefined && idOperario !== undefined) {
      console.log("cambio en idAcopio" + idAcopio);
      getConsolidadoHoy();
    }
  }, [idAcopio, idOperario]);

  return (
    <section className="h-full">
      <h1 className="text text-3xl">Abrir Consolidado de Leche</h1>
      {!consolidadoHoy || consolidadoHoy?.length <= 0 ? (
        <div className="px-10">
          <div className="form-control">
            <label className="label">
              Leche restante del día anterior:
              {" " + cantidadAnterior} Litros
            </label>
            <label className="label">
              Leche restante (con novedad) del día anterior:
              {" " + cantAnteriorNovedad} Litros
            </label>
            <label htmlFor="lecheInicial" className="label">
              Leche medida al empezar el Día
            </label>
            <span className=" input-group md:w-2/5">
              <input
                id="lecheInicial"
                type={"number"}
                onBlur={(e) => {
                  getDiferencia();
                }}
                onChange={(e) => {
                  setMedicion(e.target.value);
                }}
                className="input input-bordered "
                min={0}
                required
              ></input>
              <span>Litros</span>
            </span>
            <label className="label">
              {!diferencia ? (
                <span className="label-text-alt "></span>
              ) : diferencia > 0 ? (
                <span className="label-text-alt text-accent">
                  Diferencia de {diferencia} Litros
                </span>
              ) : (
                <span className="label-text-alt text-error">
                  Diferencia de {diferencia} Litros
                </span>
              )}
            </label>
            {/* Leche con novedades
             */}
             <label htmlFor="lecheInicialNovedad" className="label">
              Leche medida (con Novedad) al empezar el Día
            </label>
            <span className=" input-group md:w-2/5">
              <input
                id="lecheInicialNovedad"
                type={"number"}
                onBlur={(e) => {
                  getDiferenciaNovedad();
                }}
                onChange={(e) => {
                  setMedicionNovedad(e.target.value);
                }}
                className="input input-bordered "
                min={0}
                required
              ></input>
              <span>Litros</span>
            </span>
            <label className="label">
              {!diferenciaNovedad ? (
                <span className="label-text-alt "></span>
              ) : diferenciaNovedad > 0 ? (
                <span className="label-text-alt text-accent">
                  Diferencia de {diferenciaNovedad} Litros
                </span>
              ) : (
                <span className="label-text-alt text-error">
                  Diferencia de {diferenciaNovedad} Litros
                </span>
              )}
            </label>
            <label className="label " htmlFor="observaciones">
              Observaciones
            </label>
            <textarea
              id="observaciones"
              className="textarea textarea-bordered "
              aria-placeholder="Observaciones"
              onChange={(e) => setObservaciones(e.target.value)}
            ></textarea>
            <label htmlFor="modal-confirm" className="btn  my-3" >
              Abrir consolidado
            </label>
          </div>
        </div>
      ) : (
        <div className="px-10 grid grid-cols-1">
          <div className="alert alert-success my-5">
            El consolidado ya fue abierto. Puede verificar la información a
            continuación
          </div>
          <div className="card  shadow-xl">
            <div className="card-body">
              <h2 className="card-title">Detalles del Consolidado</h2>
              <p className="text-left">
                <span className="font-bold">Fecha:</span> {consolidadoHoy.fecha}{" "}
              </p>

              <p className="text-left">
                <span className="font-bold">Hora: </span>
                {consolidadoHoy.hora.split(".", [1])}
              </p>

              <p className="text-left">
                <span className="font-bold ">Cantidad del día anterior: </span>
                {consolidadoHoy.cantidad_dia_anterior + " litros"}{" "}
              </p>
              <p className="text-left">
                <span className="font-bold">Cantidad medida:</span>{" "}
                {consolidadoHoy.cantidad_medida + " litros"}{" "}
              </p>
              <p className="text-left">
                <span className="font-bold">Diferencia:</span>{" "}
                {consolidadoHoy.diferencia + " litros"}{" "}
              </p>
              <p className="text-left">
                <span className="font-bold ">Cantidad con Novedad del día anterior: </span>
                {consolidadoHoy.novedad_dia_anterior + " litros"}{" "}
              </p>
              <p className="text-left">
                <span className="font-bold">Cantidad con novedad medida:</span>{" "}
                {consolidadoHoy.novedad_medida + " litros"}{" "}
              </p>
              <p className="text-left">
                <span className="font-bold">Diferencia en novedad:</span>{" "}
                {consolidadoHoy.diferencia_novedad + " litros"}{" "}
              </p>
              <p className="text-left">
                <span className="font-bold">Observaciones:</span>{" "}
                {consolidadoHoy.observaciones}{" "}
              </p>
            </div>
          </div>
        </div>
      )}
      {/* Modal para confirmar la apertura del consolidado */}
      <input type={"checkbox"} className="modal-toggle" id="modal-confirm"></input>
      <div className="modal">
        <div className="modal-box">
          <h3 className="font-bold text-lg">
            Aviso
          </h3>
          <p className="py-4">
            Realmente desea realizar la apertura del Cosolidado con los siguientes datos?
          </p>
          <p><span className="font-bold">Fecha: </span>{fecha}</p>
          <p><span className="font-bold">Medición: </span>{medicion}</p>
          <p><span className="font-bold">Cantidad anterior: </span>{cantidadAnterior}</p>
          <p><span className="font-bold">Diferencia: </span>{diferencia}</p>
          <p><span className="font-bold">Medición (con Novedad): </span>{medicionNovedad}</p>
          <p><span className="font-bold">Cantidad anterior: </span>{cantAnteriorNovedad}</p>
          <p><span className="font-bold">Diferencia: </span>{diferenciaNovedad}</p>
          <p><span className="font-bold">Observaciones: </span>{observaciones}</p>
          <div className="modal-action">
            <label htmlFor="modal-confirm" className="btn" onClick={()=>postConsolidado()}>
              Sí
            </label>
            <label htmlFor="modal-confirm" className="btn">
              Aún No
            </label>
          </div>
        </div>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </section>
  );
}

export default OpenConsolidado;
