import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import useAuth from "../../hooks/useAuth";
import { makeSafeGetRequest, makeSafePutRequest } from "../utils/Requests";
import { notifyPendingNotify, popNotification } from "../utils/Notify";

function EditRecolector(props) {
  const { auth } = useAuth();
  const [idEmpresa, setIdEmpresa] = useState(props.idEmpresa);
  const [nombreEmpresa, setNombreEmpresa] = useState(props.nombreEmpresa);
  const [recolector, setRecolector] = useState(props.recolector);

  const [acopios, setAcopios] = useState();
  const [rutas, setRutas] = useState();
  const [nombreAcopio, setNombreAcopio] = useState("");
  const [bancos, setBancos] = useState();
  const [dataValid, setDataValid] = useState(false);
  const [validatePNombre, setValidatePNombre] = useState("");
  const [validatePApellido, setValidatePApellido] = useState("");
  const [validateNumDoc, setValidateNumDoc] = useState("");
  const [telefonoRange, setTelefonoRange] = useState("");
  const [validateBanco, setValidateBanco] = useState("");
  const [validateRuta, setValidateRuta] = useState("");
  const [validateTipoPago, setValidateTipoPago] = useState("");
  const [validateTipoCta, setValidateTipoCta] = useState("");
  const [validateNumCta, setValidateNumCta] = useState("");

  //obtiene la lista de acopios de la empresa
  const getAcopios = async () => {
    try {
      console.log("obteniendo acopios");
      const response = await makeSafeGetRequest(
        "acopiosempresa/" + idEmpresa,
        auth.accessToken
      );
      console.log("obteniendo acopios" + JSON.stringify(response));
      if (response && response.status === 200) {
        console.log("etrea");
        setAcopios(response.data);
      }
    } catch (error) {
      console.log("error al obtener acopios");
    }
  };
  //obtiene la lista de rutas del acopio
  const getRutas = async (id) => {
    try {
      const response = await makeSafeGetRequest(
        "rutasacopio/" + id,
        auth.accessToken
      );
      if (response && response?.data) {
        setRutas(response?.data);
      }
    } catch (error) {}
  };

  //funciones de cambios
  //acopios
  const onAcopioChange = async (e) => {
    setNombreAcopio(e.target.selectedOptions[0].text);
    const selAcopio = e.target.value;
    await getRutas(selAcopio);
  };
  //Agrega una nueva ruta cuando el select de rutas cambia
  const onRutasChange = async (e) => {
    if (
      e.target.value !== -1 &&
      !recolector?.rutas.some(
        (r) => parseInt(r.id) === parseInt(e.target.value)
      )
    ) {
      const myRecolector = { ...recolector ,
        rutas:[...recolector.rutas,{id:parseInt(e.target.value),nombre:e.target.selectedOptions[0].text}],
      
      };
      setRecolector(myRecolector);
    } else {
      console.log("ruta no agregada");
    }
  };
  //elimina una ruta de la lista de rutas seleccionadas
  const deleteRuta = (r) => {
    console.log("iniciando borrar ruta");
    console.log(JSON.stringify(r));
    if (r.id !== -1) {
      console.log("este es el recolector"+JSON.stringify(recolector))
      const updatedRecolector = {
        ...recolector,
        rutas: recolector.rutas.filter((ruta) => parseInt(ruta.id) !== parseInt(r.id)),
      };
      setRecolector(updatedRecolector);
    } else {
      console.log("no se encontro ruta a borrar");
    }
  };
  //cambio de parametro
  const onParametroChange = (e, parametro, isRelationship) => {
    console.log("parametro a cambiar: " + parametro + "," + e.target.value);
    const myRec = { ...recolector };
    if (isRelationship) {
      myRec[parametro] = parseInt(e.target.value);
    } else {
      myRec[parametro] = e.target.value.toUpperCase();
    }
    setRecolector(myRec);
    console.log("Recolector:" + JSON.stringify(recolector));
    validateData();
  };
  //validar parametro
  const checkParametro = (e, parametro) => {
    switch (parametro) {
      case "ruta":
        if (e.target.value === "-1") {
          setValidateRuta("Seleccione una ruta válida");
        } else {
          setValidateRuta("");
        }
        break;
      case "numero_documento":
        if (e.target.value === "") {
          setValidateNumDoc("Digite un n° de documento válido");
        } else {
          setValidateNumDoc("");
        }
        break;
      case "primer_nombre":
        if (e.target.value === "") {
          setValidatePNombre("Por favor, digite un nombre");
        } else {
          setValidatePNombre("");
        }
        break;
      case "primer_apellido":
        if (e.target.value === "") {
          setValidatePApellido("Por favor, digite un apellido");
        } else {
          setValidatePApellido("");
        }
        break;
      case "telefono":
        if (e.target.value === "" || isNaN(parseInt(e.target.value))) {
          setTelefonoRange("Por favor, digite un teléfono válido");
        } else {
          setTelefonoRange("");
        }
        break;
      case "banco":
        if (e.target.value === "-1") {
          setValidateBanco("Seleccione un banco");
        } else {
          setValidateBanco("");
        }
        break;
      case "tipo_cuenta":
        if (e.target.value === "-1") {
          setValidateTipoCta("Seleccione una cuenta");
        } else {
          setValidateTipoCta("");
        }
        break;
      case "tipo_pago":
        if (e.target.value === "-1") {
          setValidateTipoPago("Seleccione un tipo de pago");
        } else {
          setValidateTipoPago("");
        }
        break;
      case "numero_cuenta":
        if (e.target.value === "" || isNaN(parseInt(e.target.value))) {
          setValidateNumCta("Seleccione un número de cuenta");
        } else {
          setValidateNumCta("");
        }
        break;
      case "documento_titular_cuenta":
        if (e.target.value === "" || isNaN(parseInt(e.target.value))) {
          setValidateNumCta("Seleccione un número de cuenta");
        } else {
          setValidateNumCta("");
        }
        break;

      default:
        break;
    }
  };
  const onActivoChange = () => {
    setRecolector((prevRecolector) => ({
      ...prevRecolector,
      activo: !prevRecolector.activo, // Toggle the value
    }));
  };
  //Valida la data para asegurarse que el Recolector contiene los datos básicos
  const validateData = () => {
    if (
      recolector?.rutas[0]?.id &&
      recolector?.rutas[0]?.id > 0 &&
      recolector?.rutas[0]?.id !== -1 &&
      recolector.primer_nombre !== "" &&
      recolector.primer_apellido !== "" &&
      recolector.numero_documento !== "" &&
      recolector.telefono !== ""
      // productor.banco &&
      // productor.banco> 0 &&
      // productor.banco !== -1
    ) {
      console.log("La ruta esta" + true);
      setDataValid(true);
    } else {
      console.log("la ruta no esta" + false);
      setDataValid(false);
    }
  };
  //Crea el nopmbre corto del recolector
  const createNombreCorto = () => {
    const myReco = { ...recolector };
    myReco["nombre_corto"] =
      recolector["primer_nombre"] +
      " " +
      recolector["segundo_nombre"] +
      " " +
      recolector["primer_apellido"] +
      " " +
      recolector["segundo_apellido"];
    setRecolector(myReco);
    console.log("Recolector:" + JSON.stringify(recolector));
  };
  //Postea  la data del recolector
  const updateData = async () => {
    const notify = notifyPendingNotify();
    //VALIDA LA DATA
    validateData();
    if (dataValid === true) {
      try {
        const response = await makeSafePutRequest(
          "recolectors/" + recolector?.id,
          { data: recolector },
          auth.accessToken
        );
        if (response && response.status === 200) {
          popNotification(
            notify,
            "success",
            "Recolector guardado correctamente."
          );
          props.onsearch(" ")
          props.getRecolectores();
          setRecolector()
        } else {
          popNotification(
            notify,
            "error",
            "No se pudo guardar el recolector. Intente de nuevo"
          );
        }
      } catch (error) {
        popNotification(notify, "error", "Error al guardar el recolector.");
      }
    } else {
      popNotification(
        notify,
        "error",
        "Verifique los datos e intente de nuevo."
      );
    }
  };
  const getBancosEmpresa = async () => {
    try {
      const response = await makeSafeGetRequest("bancos", auth.accessToken);
      if (response && response.status === 200) {
        console.log("bancos obtenidos" + JSON.stringify(response.data));
        setBancos(response.data.data);
      } else {
        console.log("error al obtener los bancos");
      }
    } catch (error) {
      console.log("error al obtener los bancos" + error);
    }
  };
  //carga de rutas
  useEffect(() => {
    if (
      recolector?.rutas &&
      recolector?.rutas[0]?.acopio &&
      recolector?.rutas[0]?.acopio?.id
    ) {
      getRutas(recolector.rutas[0].acopio.id);
    }
  }, [recolector?.rutas[0]]);
  //useeffect recolector cargado
  useEffect(() => {
    if (recolector) {
      console.log("recolector encontrado:" + JSON.stringify(recolector));
      setNombreAcopio(recolector?.rutas[0]?.acopio?.nombre);
    }
  }, [recolector]);
  //useeffect recarga de recolector
  useEffect(() => {
    setRecolector(props.recolector);
  }, [props.recolector]);
  //useEffect Inicial
  useEffect(() => {
    if (idEmpresa) {
      getAcopios();
      getBancosEmpresa();
    }
  }, [idEmpresa]);
  return (
    <div className="grid grid-cols-1">
      {recolector ? (
        <>
          <div className="grid grid-cols-1  py-3">
            <h3 className="text-xl text-left font-bold">Editar Recolector</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 px-5">
              <div className="flex flex-col">
                <label className="label">Acopio </label>
                <select
                  className="select select-bordered"
                  onChange={(e) => {
                    onAcopioChange(e);
                  }}
                  value={
                    recolector?.ruta?.acopio?.id
                      ? recolector?.ruta?.acopio?.id?.toString()
                      : "-1"
                  }
                >
                  <option key="-1" value={"-1"}>
                    Seleccione un Acopio
                  </option>
                  {acopios ? (
                    acopios?.map((acopio) => (
                      <option key={acopio?.id} value={acopio?.id}>
                        {acopio.nombre}
                      </option>
                    ))
                  ) : (
                    <option>Cargando acopios</option>
                  )}
                </select>
              </div>
              <div className="flex flex-col">
                <label className="label">
                  Ruta(s) asignadas<span className="text-error">*</span>
                </label>
                <select
                  className="select select-bordered"
                  onChange={(e) => {
                    onRutasChange(e);
                    checkParametro(e, "ruta");
                  }}
                  onBlur={(e) => {
                    checkParametro(e, "ruta");
                  }}
                  value={recolector ? recolector?.ruta?.id.toString() : "-1"}
                >
                  <option key={"-1"} value={"-1"}>
                    Seleccione una ruta
                  </option>
                  {!rutas ? (
                    <option key={"n/a"} value={"n/a"}>
                      Cargando Rutas
                    </option>
                  ) : (
                    rutas.map((ruta) => (
                      <option key={ruta.id} value={ruta.id}>
                        {ruta?.nombre}
                      </option>
                    ))
                  )}
                </select>
                {recolector?.rutas && recolector?.rutas?.length > 0 ? (
                  <div className="display display-flex my-1">
                    {recolector?.rutas?.map((ruta) => (
                      <span
                        className="badge mx-1"
                        key={ruta?.id}
                        value={ruta.id}
                      >
                        {ruta?.nombre}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                          onClick={() => {
                            deleteRuta(ruta);
                          }}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                          />
                        </svg>
                      </span>
                    ))}
                  </div>
                ) : (
                  <></>
                )}
                {validateRuta ? (
                  <label className="label text-error">{validateRuta}</label>
                ) : (
                  <></>
                )}
              </div>
              <div className="flex flex-col">
                <label className="label">Activo?</label>
                <div className="flex justify-around">
                  <label className="label">No</label>
                  <input
                    type={"checkbox"}
                    className="toggle toggle-accent"
                    checked={!!recolector.activo}
                    onChange={() => {
                      onActivoChange((prevCheck) => !prevCheck);
                    }}
                  ></input>
                  <label className="label">Sí</label>
                </div>
              </div>
            </div>
            {/* Datos personales */}
            <h3 className="text-xl text-left font-bold">Datos Personales</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 px-5">
              <div className="flex flex-col">
                <label className="label">Tipo de Documento</label>
                <select
                  className="select select-bordered"
                  onChange={(e) => onParametroChange(e, "tipo_documento")}
                  onBlur={(e) => {
                    checkParametro(e, "tipo_documento");
                  }}
                  value={recolector ? recolector.tipo_documento : -1}
                >
                  <option key={-1} value={-1}>
                    Seleccione un Tipo de Documento
                  </option>
                  <option key={1} value={1}>
                    Nit{}
                  </option>
                  <option key={2} value={2}>
                    Cédula de Ciudadanía
                  </option>
                  <option key={3} value={3}>
                    Cédula de Extranjería
                  </option>
                  <option key={4} value={4}>
                    Tarjeta de Identidad
                  </option>
                  <option key={5} value={5}>
                    Pasaporte
                  </option>
                </select>
              </div>

              <div className="flex flex-col">
                <label className="label">
                  Número de Documento<span className="text-error">*</span>
                </label>
                <input
                  type={"number"}
                  className="input input-bordered "
                  value={recolector?.numero_documento || ""}
                  min={0}
                  step={1}
                  onChange={(e) => {
                    onParametroChange(e, "numero_documento");
                  }}
                  onBlur={(e) => {
                    checkParametro(e, "numero_documento");
                  }}
                ></input>
                {validateNumDoc ? (
                  <label className="label text-error">{validateNumDoc}</label>
                ) : (
                  <></>
                )}
              </div>
              <div className="flex flex-col">
                <label className="label">Primer Nombre</label>
                <input
                  type={"text"}
                  value={recolector?.primer_nombre || ""}
                  className="input input-bordered"
                  onChange={(e) => {
                    onParametroChange(e, "primer_nombre");
                  }}
                  onBlur={(e) => {
                    createNombreCorto();
                    checkParametro(e, "primer_nombre");
                  }}
                ></input>
                {validatePNombre ? (
                  <label className="label text-error">{validatePNombre}</label>
                ) : (
                  <></>
                )}
              </div>
              <div className="flex flex-col">
                <label className="label">Segundo Nombre</label>
                <input
                  type={"text"}
                  className="input input-bordered"
                  value={recolector?.segundo_nombre || ""}
                  onChange={(e) => {
                    onParametroChange(e, "segundo_nombre");
                  }}
                ></input>
              </div>
              <div className="flex flex-col">
                <label className="label">Primer Apellido</label>
                <input
                  type={"text"}
                  className="input input-bordered"
                  value={recolector?.primer_apellido || ""}
                  onChange={(e) => {
                    onParametroChange(e, "primer_apellido");
                  }}
                  onBlur={(e) => {
                    createNombreCorto();
                    checkParametro(e, "primer_apellido");
                  }}
                ></input>
                {validatePApellido ? (
                  <label className="label text-error">
                    {validatePApellido}
                  </label>
                ) : (
                  <></>
                )}
              </div>

              <div className="flex flex-col">
                <label className="label">Segundo Apellido</label>
                <input
                  type={"text"}
                  className="input input-bordered"
                  value={recolector?.segundo_apellido || ""}
                  onChange={(e) => {
                    onParametroChange(e, "segundo_apellido");
                  }}
                  onBlur={(e) => {
                    createNombreCorto();
                    checkParametro(e, "segundo_apellido");
                  }}
                ></input>
              </div>
              <div className="flex flex-col align-middle">
                <label className="text text-base text-left align-middle">
                  <br></br>
                  <span className="font-semibold">Nombre corto: </span>{" "}
                  {recolector?.nombre_corto || ""}
                </label>
              </div>
              <div className="flex flex-col">
                <label className="label">Lugar de Nacimiento</label>
                <input
                  type={"text"}
                  className="input input-bordered"
                  value={recolector?.lugar_nacimiento || ""}
                  onChange={(e) => {
                    onParametroChange(e, "lugar_nacimiento");
                  }}
                ></input>
              </div>
              <div className="flex flex-col">
                <label className="label">Fecha de Nacimiento</label>
                <input
                  type={"date"}
                  className="input input-bordered"
                  value={recolector?.fecha_nacimiento || ""}
                  onChange={(e) => {
                    onParametroChange(e, "fecha_nacimiento");
                  }}
                ></input>
              </div>
              <div className="flex flex-col">
                <label className="label">
                  Lugar de Expedición del Documento
                </label>
                <input
                  type={"text"}
                  className="input input-bordered"
                  value={recolector?.lugar_expedicion || ""}
                  onChange={(e) => {
                    onParametroChange(e, "lugar_expedicion");
                  }}
                ></input>
              </div>
              <div className="flex flex-col">
                <label className="label">Fecha de Expedición</label>
                <input
                  type={"date"}
                  className="input input-bordered"
                  value={recolector?.fecha_expedicion || ""}
                  onChange={(e) => {
                    onParametroChange(e, "fecha_expedicion");
                  }}
                ></input>
              </div>
            </div>
            {/* Datos de contacto */}
            <h3 className="text-xl text-left font-bold my-5">
              Datos de Contacto
            </h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 px-5">
              <div className="flex flex-col">
                <label className="label">Departamento de Residencia</label>
                <input
                  type={"text"}
                  className="input input-bordered"
                  value={recolector?.departamento || ""}
                  onChange={(e) => {
                    onParametroChange(e, "departamento");
                  }}
                ></input>
              </div>

              <div className="flex flex-col">
                <label className="label">Municipio de Residencia</label>
                <input
                  type={"text"}
                  className="input input-bordered"
                  value={recolector?.municipio || ""}
                  onChange={(e) => {
                    onParametroChange(e, "municipio");
                  }}
                ></input>
              </div>
              <div className="flex flex-col">
                <label className="label">Vereda</label>
                <input
                  type={"text"}
                  className="input input-bordered"
                  value={recolector?.vereda || ""}
                  onChange={(e) => {
                    onParametroChange(e, "vereda");
                  }}
                ></input>
              </div>
              <div className="flex flex-col">
                <label className="label">Dirección /Nombre Finca</label>
                <input
                  type={"text"}
                  className="input input-bordered"
                  value={recolector?.direccion || ""}
                  onChange={(e) => {
                    onParametroChange(e, "direccion");
                  }}
                ></input>
              </div>
              <div className="flex flex-col">
                <label className="label">Teléfono</label>
                <input
                  type={"tel"}
                  className="input input-bordered"
                  value={recolector?.telefono || ""}
                  onChange={(e) => {
                    onParametroChange(e, "telefono");
                  }}
                  onBlur={(e) => {
                    checkParametro(e, "telefono");
                  }}
                ></input>
                {telefonoRange ? (
                  <label className="label text-error">{telefonoRange}</label>
                ) : (
                  <></>
                )}
              </div>
              <div className="flex flex-col">
                <label className="label">Teléfono 2</label>
                <input
                  type={"tel"}
                  className="input input-bordered"
                  value={recolector?.telefono_2 || ""}
                  onChange={(e) => {
                    onParametroChange(e, "telefono_2");
                  }}
                ></input>
              </div>
              <div className="flex flex-col">
                <label className="label">Celular</label>
                <input
                  type={"phone"}
                  className="input input-bordered"
                  value={recolector?.celular || ""}
                  onChange={(e) => {
                    onParametroChange(e, "celular");
                  }}
                ></input>
              </div>
              <div className="flex flex-col">
                <label className="label">Correo</label>
                <input
                  type={"email"}
                  className="input input-bordered"
                  value={recolector?.correo || ""}
                  onChange={(e) => {
                    onParametroChange(e, "correo");
                  }}
                ></input>
              </div>
            </div>
            {/* Datos Bancarios*/}
            <h3 className="text-xl text-left font-bold my-5">
              Datos Bancarios
            </h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 px-5">
              <div className="flex flex-col">
                <label className="label">
                  Tipo de Pago <span className="text-error">*</span>{" "}
                </label>
                <select
                  className="select select-bordered"
                  onChange={(e) => {
                    onParametroChange(e, "tipo_pago");
                  }}
                  onBlur={(e) => checkParametro(e, "tipo_pago")}
                  value={recolector ? recolector.tipo_pago : "-1"}
                >
                  <option key="-1" value={"-1"}>
                    Seleccione un tipo de Pago
                  </option>
                  <option key={1} value={"CONSIGNACION"}>
                    Consignación
                  </option>
                  <option key={2} value={"OTROS BANCOS"}>
                    Otros Bancos
                  </option>
                  <option key={3} value={"EFECTIVO"}>
                    Efectivo
                  </option>
                </select>
                {validateTipoPago ? (
                  <label className="label text-error">{validateTipoPago}</label>
                ) : (
                  <></>
                )}
              </div>
              <div className="flex flex-col">
                <label className="label">
                  Tipo de Cuenta <span className="text-error">*</span>
                </label>
                <select
                  className="select select-bordered"
                  onChange={(e) => {
                    onParametroChange(e, "tipo_cuenta");
                  }}
                  onBlur={(e) => checkParametro(e, "tipo_cuenta")}
                  value={recolector ? recolector.tipo_cuenta : "-1"}
                >
                  <option key="-1" value={"-1"}>
                    Seleccione un tipo de Cuenta
                  </option>
                  <option key={1} value={"AHORROS"}>
                    Ahorros
                  </option>
                  <option key={2} value={"CORRIENTE"}>
                    Corriente
                  </option>
                  <option key={3} value={"EFECTIVO"}>
                    Efectivo
                  </option>
                </select>
                {validateTipoCta ? (
                  <label className="label text-error">{validateTipoCta}</label>
                ) : (
                  <></>
                )}
              </div>
              <div className="flex flex-col">
                <div className=""></div>
                <label className="label">
                  Banco <span className="text-error">*</span>
                </label>
                <select
                  className="select select-bordered"
                  onChange={(e) => {
                    onParametroChange(e, "banco", true);
                  }}
                  onBlur={(e) => checkParametro(e, "banco")}
                  value={recolector ? recolector.banco?.id : "-1"}
                >
                  <option key="-1" value={"-1"}>
                    Seleccione un Banco
                  </option>
                  {bancos ? (
                    bancos?.map((banco) => (
                      <option key={banco.id} value={banco.id}>
                        {banco?.attributes?.nombre_banco}
                      </option>
                    ))
                  ) : (
                    <option>Cargando bancos</option>
                  )}
                </select>
                {validateBanco ? (
                  <label className="label text-error">{validateBanco}</label>
                ) : (
                  <></>
                )}
              </div>

              <div className="flex flex-col">
                <label className="label">
                  Número de cuenta <span className="text-error">*</span>
                </label>
                <input
                  type={"number"}
                  className="input input-bordered"
                  min={0}
                  value={recolector?.numero_cuenta || "null"}
                  onChange={(e) => {
                    onParametroChange(e, "numero_cuenta");
                  }}
                  onBlur={(e) => checkParametro(e, "numero_cuenta")}
                ></input>
                {validateNumCta ? (
                  <label className="label text-error">{validateNumCta}</label>
                ) : (
                  <></>
                )}
              </div>
              <div className="flex flex-col">
                <label className="label">
                  Nombre del Titular <span className="text-error">*</span>
                </label>
                <input
                  type={"text"}
                  className="input input-bordered"
                  value={recolector?.nombre_titular_cuenta || ""}
                  onChange={(e) => {
                    onParametroChange(e, "nombre_titular_cuenta");
                  }}
                  onBlur={(e) => checkParametro(e, "nombre_titular_cuenta")}
                ></input>
              </div>
              <div className="flex flex-col">
                <label className="label">
                  N° Documento del Titular <span className="text-error"></span>
                </label>
                <input
                  type={"number"}
                  className="input input-bordered"
                  min={0}
                  step={1}
                  value={recolector?.documento_titular_cuenta || 0}
                  onChange={(e) => {
                    onParametroChange(e, "documento_titular_cuenta");
                  }}
                ></input>
              </div>
            </div>
          </div>
          <label className="btn btn-accent my-1" htmlFor="modal-confirm">
            Editar Recolector
          </label>
        </>
      ) : (
        <div className="alert">Seleccione un Recolector</div>
      )}
      {/* Modal de confirmar */}
      <input type="checkbox" id="modal-confirm" className="modal-toggle" />
      <div className="modal">
        <div className="modal-box">
          <h3 className="font-bold text-lg">Aviso</h3>
          <p className="py-4">
            Realmente desea actualizar el recolecctor con los siguientes datos?
          </p>
          <p>
            <span className="font-semibold">Empresa:</span>
            {nombreEmpresa}
          </p>
          <p>
            <span className="font-semibold">Acopio: </span>
            {nombreAcopio}
          </p>
          <p>
            <span className="font-semibold">Rutas: </span>
            {recolector?.rutas?.map((ruta) => (
              <span key={ruta.id}>{ruta.nombre + " ,"}</span>
            ))}
          </p>
          <p>
            <span className="font-semibold">Nombre corto: </span>
            {recolector?.nombre_corto}
          </p>
          <p>
            <span className="font-semibold">Número Documento: </span>
            {recolector?.numero_documento}
          </p>
          <p>
            <span className="font-semibold">Teléfono:</span>
            {recolector?.telefono}
          </p>
          <p>
            <span className="font-semibold">Correo: </span>
            {recolector?.correo}
          </p>
          <p>
            <span className="font-semibold">Municipio: </span>
            {recolector?.municipio}
          </p>
          <p>
            <span className="font-semibold">Dirección:</span>
            {recolector?.direccion}
          </p>
          <p>
            <span className="font-semibold">Número de cuenta: </span>
            {recolector?.numero_cuenta}
          </p>

          <div className="modal-action">
            <label
              htmlFor="modal-confirm"
              className="btn btn-accent"
              onClick={updateData}
            >
              Actualizar recolector
            </label>
            <label htmlFor="modal-confirm" className="btn">
              Aún no
            </label>
          </div>
        </div>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default EditRecolector;
