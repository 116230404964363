import React, { useEffect, useState } from "react";
import { makeGetRequest } from "./Requests";

function SearchBar() {
  const [prods, setProds] = useState([
    { id: 1, nombre: "juan", apellido: "perez" },
    { id: 2, nombre: "camilo", apellido: "perez" },
    { id: 3, nombre: "anton", apellido: "perez" },
    { id: 4, nombre: "sofia", apellido: "perez" },
  ]);
  const [clientes, setClientes] = useState();
  const [searchedClientes, setSearchedClientes] = useState();
  const [selectedCliente, setSelectedClient] = useState();
  const [selClienteNombre, setSelClientNombre] = useState();

  //obtiene la lista de clientes
  const getClientes = async () => {
    try {
      const response = await makeGetRequest("clientesempresa/1");
      if (response) {
        setClientes(response.data);
      }
    } catch (error) {}
  };

  //on search
  const onsearch = (searchTerm) => {
    if (searchTerm === "" || searchTerm === undefined) {
      console.log("el input esta en blanco");
      setSearchedClientes();
    } else {
      const filteredItems = clientes.filter((cliente) => {
        return cliente.nombre.toUpperCase().includes(searchTerm.toUpperCase());
      });
      console.log("clientes encontrados: " + JSON.stringify(filteredItems));
      setSearchedClientes(filteredItems.slice(0, 10));
    }
  };
  //pasos a realizar al seleccionar un item buscado
  const onSelectedItem = (e) => {
    setSelectedClient(e.target.value);
    setSelClientNombre(e.target.innerText);
  };
  //Effect selectedProd
  useEffect(() => {
    console.log("cambio clientes buscados" + searchedClientes);
  }, [searchedClientes]);
  //useeffect inicial
  useEffect(() => {
    getClientes();
  }, []);
  return (
    // <div className="grid grid-cols-1 justify-items-start">
    <div className="dropdown dropdown-bottom">
      <label className="input-group ">
        <input
          type={"search"}
          className="input input-bordered  "
          onChange={(e) => onsearch(e.target.value)}
        ></input>
        <span>Buscar</span>
      </label>

      {searchedClientes ? (
        <ul
          tabIndex={0}
          className="dropdown-content menu p-2 shadow bg-base-100 w-52 "
        >
          {searchedClientes.map((cliente) => (
            <li
              className="text-left"
              key={cliente.id}
              value={cliente.id}
              tabIndex={0}
              onClick={(e) => {
                onSelectedItem(e);
              }}
            >
              {cliente.nombre}
            </li>
          ))}
        </ul>
      ) : (
        <></>
      )}
      <div className="alert alert-success">
        clientee seleccionado:{selectedCliente}
      </div>
      {selClienteNombre ? (
        <div className="badge badge-lg">
          {selClienteNombre}
          <span onClick={()=>{setSelClientNombre();setSelectedClient()}}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </span>
        </div>
      ) : (
        <>seleccione un cliente</>
      )}
    </div>
  );
}

export default SearchBar;
