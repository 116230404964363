import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { notifyPendingNotify, popNotification } from "./utils/Notify";
import { makeGetRequest, makePutRequest } from "./utils/Requests";

function ListBancos() {
  const [bancosEmpresa, setBancosEmpresa] = useState();
  const [selectedBanco, setSelectedBanco] = useState();
  const [bancoUpdated, setBancoUpdated] = useState({});
  //==========================CRUD DEL BANCO=================================
  //obtiene la lista de bancos de la  empresa
  const getBancosEmpresa = async () => {
    try {
      const response = await makeGetRequest("bancos");
      if (response) {
        setBancosEmpresa(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  //Obtiene entre la lista de bancos, un banco en particular
  const findBanco = (id) => {
    const banco = bancosEmpresa.find((b) => b.id === id);
    console.log("Banco encontrado:" + JSON.stringify(banco));
    setSelectedBanco(banco);
  };
  //Agrega un atributo al objeto que guarda la data a actualizar
  const addAttributeUpdated = (position, value) => {
    try {
      const bu = bancoUpdated;
      bu[position] = value;
      setBancoUpdated(bu);
    } catch (error) {
      console.log(error);
    }
  };
  //actualiza un banco con los datos suministrados en el modal actualizar
  const updateBanco = async () => {
    const notify = notifyPendingNotify();
    try {
      const response = await makePutRequest("bancos/" + selectedBanco?.id, {
        data: bancoUpdated,
      });
      if (response) {
        console.log(response);
        popNotification(notify, "success", "Banco actualizado correctamente");
      } else {
        popNotification(notify, "error", "Error al actualizar el Banco");
      }
    } catch (error) {
      console.log(error);
      popNotification(
        notify,
        "error",
        "Error al actualizar el Banco. Fallo en conexión"
      );
    }
    clearBancoUpdated();
  };
  //Limpia el objeto de datos para actualizar el banco
  const clearBancoUpdated = () => {
    setBancoUpdated({});
  };
  //Elimina el BANCO seleccionado luego de confirmar la orden en el modal
  const deleteBanco = async () => {
    const notify = notifyPendingNotify();
    try {
      console.log("Eliminando Banco:" + JSON.stringify(selectedBanco));
      const data = { data: { eliminado: true, activo: false } };
      const response = await makePutRequest("bancos/" + selectedBanco.id, data);
      if (response) {
        console.log(response);
        popNotification(notify, "success", "Banco eliminado correctamente");
      } else {
        popNotification(notify, "error", "error al eliminar el banco");
      }
    } catch (error) {
      console.log("ERROR AL ELIMINAR" + error);
      popNotification(
        notify,
        "error",
        "No se pudo eliminar el Banco seleccionado.Fallo en conexión"
      );
    }
  };

  //UseEffect de inicio
  useEffect(() => {
    console.log("Inicio de la ejecución");
    getBancosEmpresa();
  }, []);
  useEffect(()=>{
    console.log("Cambio bancos empresa");
  },[bancosEmpresa]);
  return (
    <div className="h-full">
      <h1 className="text text-4xl">Bancos</h1>
      {!bancosEmpresa ? (
        <></>
      ) : (
        <table className="table overflow-x-scroll">
          <thead>
            <tr>
              <th>ID</th>
              <th>Nombre del banco</th>
              <th>Código</th>
              <th>Observaciones</th>
              <th>Opciones</th>
            </tr>
          </thead>
          <tbody>
            {bancosEmpresa.map((banco) => (
              <tr key={banco?.id}>
                <td>{banco?.id}</td>
                <td>{banco?.attributes.nombre_banco}</td>
                <td>{banco?.attributes.codigo_banco}</td>
                <td>{banco?.attributes.observaciones}</td>
                <td>
                  <div className="flex flex-wrap space-x-1 ">
                    <label
                      htmlFor="modalDetails"
                      className="btn btn-success btn-sm"
                      title="Detalles del banco"
                      onClick={() => findBanco(banco.id)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                        />
                      </svg>
                    </label>
                    <label
                      htmlFor="modalUpdate"
                      className="btn btn-warning btn-sm"
                      onClick={() =>{ findBanco(banco.id); }}
                      title="Actualizar Banco"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                        />
                      </svg>
                    </label>
                    <label
                      className="btn btn-error btn-sm"
                      htmlFor="modalDelete"
                      title="Eliminar Banco (precaución)"
                      onClick={() => findBanco(banco.id)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                        />
                      </svg>
                    </label>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {/* Modal de detalles */}
      <input
        type={"checkbox"}
        id="modalDetails"
        className="modal-toggle"
      ></input>
      <div className="modal">
        <div className="modal-box">
          <h3 className="font-bold text-lg">Detalles del Banco</h3>
          <div className="grid grid-cols-2 text-left">
            <p className="text-left">
              <span className="font-bold">Nombre: </span>
              {selectedBanco?.attributes.nombre_banco}
            </p>
            <p>
              <span className="font-bold">Código: </span>
              {selectedBanco?.attributes.codigo_banco}
            </p>
            <p>
              <span className="font-bold">Observaciones: </span>
              {selectedBanco?.attributes.observaciones}
            </p>
          </div>

          <div className="modal-action">
            <label htmlFor="modalDetails" className="btn">
              Cerrar
            </label>
          </div>
        </div>
      </div>
      {/* Modal de actualización */}
      <input
        type={"checkbox"}
        id="modalUpdate"
        className="modal-toggle"
      ></input>
      <div className="modal">
        <div className="modal-box">
          <h3 className="font-bold text-lg">Actualizar Banco</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 text-left">
            <div className="flex-col">
              <label htmlFor="nombreC">Nombre del Banco</label>
              <input
                type={"text"}
                className="input input-bordered"
                defaultValue={selectedBanco?.attributes.nombre_banco}
                onChange={(e) => {
                  addAttributeUpdated("nombre_banco", e.target.value);
                }}
              ></input>
            </div>
            <div className="flex-col">
              <label htmlFor="numeroDoc">Código</label>
              <input
                type={"number"}
                className="input input-bordered"
                defaultValue={selectedBanco?.attributes.codigo_banco}
                onChange={(e) => {
                  addAttributeUpdated("codigo_banco", e.target.value);
                }}
              ></input>
            </div>
            <div className="flex-col">
              <label htmlFor="pNombre">Observaciones</label>
              <textarea
                type={"text"}
                className="input input-bordered"
                defaultValue={selectedBanco?.attributes.observaciones}
                onChange={(e) => {
                  addAttributeUpdated("observaciones", e.target.value);
                }}
              ></textarea>
            </div>
          </div>
          <div className="modal-action">
            <label
              htmlFor="modalUpdate"
              className="btn"
              onClick={() => {
                updateBanco();
                getBancosEmpresa();
                clearBancoUpdated();
              }}
            >
              Actualizar
            </label>
            <label htmlFor="modalUpdate" className="btn">
              Cerrar
            </label>
          </div>
        </div>
      </div>
      {/* Modal de eliminacion */}
      <input
        type={"checkbox"}
        id="modalDelete"
        className="modal-toggle"
      ></input>
      <div className="modal">
        <div className="modal-box">
          <h3 className="font-bold text-lg">Aviso!</h3>
          <p>
            Seguro que desea eliminar el siguiente banco? Esta acción NO se
            puede deshacer
          </p>
          <p>{selectedBanco?.attributes.nombre_banco}</p>
          <div className="modal-action">
            <label
              htmlFor="modalDelete"
              className="btn"
              onClick={() => {
                deleteBanco();
                //actualiza lista
                getBancosEmpresa();
              }}
            >
              Sí
            </label>
            <label htmlFor="modalDelete" className="btn">
              Aún no
            </label>
          </div>
        </div>
      </div>
      {/* Modal de confirmacion */}
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default ListBancos;
