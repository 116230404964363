import React, { useEffect, useState } from "react";
import useAuth from "../../hooks/useAuth";
import { getEmpresaAdmin } from "../../api/RoleInfo";
import { makeGetRequest, makeSafeGetRequest } from "../utils/Requests";

function VerPedidos() {
  const { auth } = useAuth();
  const [idEmpresa, setIdEmpresa] = useState();
  const [nombreEmpresa, setNombreEmpresa] = useState();
  const [fechaInicial, setFechaInicial] = useState();
  const [fechafinal, setFechaFinal] = useState();
  const [pedidos, setPedidos] = useState([]);
  //obtiene la data de la empresa
  const getEmpresa = async () => {
    const response = await getEmpresaAdmin(auth);
    setIdEmpresa(response?.empresa?.id);
    setNombreEmpresa(response?.empresa.nombre);
  };
  //obtiene los pedidos de la empresa en el rango de fechas seleccionado
  const getPedidos = async () => {
    try {
      const response = await makeGetRequest(
        "pedidosempresa?id=" +
          idEmpresa +
          "&fechainicial=" +
          fechaInicial +
          "&fechafinal=" +
          fechafinal,
        auth
      );
      if (response && response.status === 200) {
        setPedidos(response.data);
      } else {
        setPedidos();
        console.log("No se pudo obtener los pedidos");
      }
    } catch (error) {
      console.log("Error al obtener los pedidos:" + error);
    }
  };
  //useeffect inicial
  useEffect(() => {
    getEmpresa();
  }, []);
  return (
    <div className="section h-full md:w-9/12 my-5 py-5 mx-2">
      <h1 className="text-3xl">Reporte de Pedidos</h1>
      <div className="grid grid-cols-1">
        <label className="label">Empresa: {nombreEmpresa}</label>
        <label className="label">Fecha inicial</label>
        <input
          type="date"
          className="input input-bordered"
          onChange={(e) => setFechaInicial(e.target.value)}
        ></input>
        <label className="label">Fecha final</label>
        <input
          type="date"
          className="input input-bordered"
          onChange={(e) => setFechaFinal(e.target.value)}
        ></input>
        <button className="btn btn-accent my-2" onClick={getPedidos}>
          generar reporte
        </button>
        {/* <div className="text-left">
          <h2 className="text-2xl font-semibold">Lista de Pedidos</h2>
          {pedidos && pedidos.length > 0 ? (
            <>
              {pedidos?.map((pedido, i) => (
                <div className="grid grid-cols-1 p-2" key={pedido.id}>
                  <span>
                    <span className="font-bold">Productor: </span>
                    {pedido.productor?.nombre_corto}
                  </span>
                  <span>
                    <span className="font-bold">Fecha: </span>
                    {pedido.fecha}
                  </span>
                  <span>
                    <span className="font-bold">Hora: </span>
                    {pedido.hora?.split(".")[0]}
                  </span>
                  <span>
                    <span className="font-bold">Recolector: </span>
                    {pedido.recolector?.nombre_corto}
                  </span>
                  <span>
                    <span className="font-bold">Estado: </span>
                    {pedido.estado}
                  </span>
                  <h3 className="font-bold text-xl text-center">Productos</h3>
                  {pedido?.productos_pedidos?.map((productoPedido) => (
                    <div
                      className="card card-compact bg-base-100 shadow-md my-2"
                      key={productoPedido.id}
                      colSpan={2}
                    >
                      <div className="card-body shadow-md grid grid-cols-4 text-left content-center ">
                        <div class="avatar placeholder">
                          <div className="bg-neutral text-neutral-content rounded w-20 md:w:16">
                            <span className="text-3xl">Img</span>
                          </div>
                        </div>
                        <div className="col-span-3 grid grids-col-3">
                          <span>
                            <span className="font-bold">Nombre: </span>
                            {productoPedido?.producto_agricola?.nombre}
                          </span>
                          <span>
                            <span className="font-bold">Cantidad: </span>
                            {productoPedido.cantidad}
                          </span>
                          <span>
                            <span className="font-bold">Observaciones: </span>
                            {productoPedido.observaciones}
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="divider "></div>
                </div>
              ))}
            </>
          ) : (
            <></>
          )}
        </div> */}
        {pedidos && pedidos.length > 0 ? (
          pedidos.map((pedido) => (
            <div className="overflow-x-auto">
              <table
                className="table table-compact my-3 w-full"
                key={pedido.id}
              >
                <thead>
                  <tr>
                    <th></th>
                    <th>Fecha: {pedido?.fecha}</th>
                    <th>Hora: {pedido?.hora}</th>
                    <th>Estado: {pedido?.estado}</th>
                  </tr>
                  <tr>
                    <th></th>
                    <th colSpan={2}>
                      Señor: {pedido?.productor?.nombre_corto}
                    </th>
                    <th>
                      Número de Documento:
                      {pedido?.productor?.numero_documento}
                    </th>
                  </tr>
                  <tr>
                    <th></th>
                    <th>Dirección:{pedido?.productor?.direccion}</th>
                    <th>Teléfono:{pedido?.productor?.telefono}</th>
                    <th>Correo:{pedido?.productor?.correo}</th>
                  </tr>
                </thead>
                <tbody>
                  {pedido.productos_pedidos &&
                  pedido?.productos_pedidos?.length > 0 ? (
                    <>
                      <tr>
                        <th></th>
                        <th>Producto</th>
                        <th>Cantidad</th>
                        <th>Observaciones</th>
                      </tr>
                      {pedido.productos_pedidos?.map((productoPedido) => (
                        <tr key={productoPedido.id}>
                          <td></td>
                          <td>{productoPedido.producto_agricola.nombre}</td>
                          <td>{productoPedido.cantidad}</td>
                          <td>{productoPedido.observaciones}</td>
                        </tr>
                      ))}
                    </>
                  ) : (
                    <tr>
                      <td colSpan={4}>No hay productos agregados al pedido!</td>
                    </tr>
                  )}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan={4}>Observaciones:{pedido.observaciones}</td>
                  </tr>
                </tfoot>
              </table>
            </div>
          ))
        ) : (
          <></>
        )}
        
        
        
      </div>
    </div>
  );
}

export default VerPedidos;
