import { makeSafeGetRequest } from "./Requests";

export async function getAcopiosEmpresa(idEmpresa, tk){
    try {
        const response=await makeSafeGetRequest("acopiosempresa/"+idEmpresa, tk);
        if(response&&response?.status===200){
            return response.data;
        }
        else {
            return null;
        }
    } catch (error) {
        return null;
    }
}