import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import axios from "../api/axios";
import { getOperarioInfo } from "../api/RoleInfo";
import useAuth from "../hooks/useAuth";
import Prueba from "./Prueba";
import { notifyPendingNotify, popNotification } from "./utils/Notify";
import { makeGetRequest, postPruebas } from "./utils/Requests";
import {
  dateToColombianZoneRq,
  timeToColombianZonerq,
} from "./utils/timezoneRequests";
function CreateRecibirTraslado() {
  const URL = process.env.REACT_APP_BACKEND_BASE_URL;
  const { auth } = useAuth();
  const [prueba, setPrueba] = useState({});
  const [trasladosSinRecibir, setTrasladosSinRecibir] = useState();
  const [selectedTraslado, setSelectedTraslado] = useState();
  const [selectedTrasladoCant, setSelectedTrasladoCant] = useState();
  const [selectedTrasladoAcopioOrigen, setSelectedTrasladoAcopioOrigen] =
    useState();
  const [selectedAcopioOrigen, setSelectedAcopioOrigen] = useState();
  const [cantidad, setCantidad] = useState();
  const [diferencia, setDiferencia] = useState(0);
  const [observaciones, setObservaciones] = useState("");
  const [operarioInfo, setOperarioInfo] = useState();
  const [esNovedad, setEsNovedad] = useState();
  //obtiene la data de sesion
  const getOperario = async () => {
    console.log("Obteniendo operario");
    const idUser = auth.id;
    const operario = await getOperarioInfo(idUser);
    console.log("operario obtenido: " + JSON.stringify(operario));
    setOperarioInfo(operario);
  };
  //busca la cantidad de leche del traslado seleccionado
  const getSelectedTrasladoCant = (idTraslado) => {
    console.log(idTraslado);
    console.log(
      "LISTA TRAS SIN RECIBIR: " + JSON.stringify(trasladosSinRecibir)
    );
    const selectedTraslado = trasladosSinRecibir?.find(
      ({ id }) => id == idTraslado
    );
    console.log(selectedTraslado);
    if (selectedTraslado) {
      console.log("funcionaa");
      setEsNovedad(selectedTraslado.traslado_novedad);
      setSelectedTrasladoCant(selectedTraslado.cantidad_leche);
      setSelectedTrasladoAcopioOrigen(selectedTraslado.acopio_origen.id);
    }
  };

  //obtiene el cambio en el componente reutilizable de la prueba
  function handlePruebaChange(pruebaE) {
    setPrueba({ ...pruebaE });
  }
  //Encuentra los traslados que no se hayan recibido y pertenezcan al acopio
  const getTrasladosNoIngresados = async () => {
    try {
      const myUrl = URL + "trasladossiningresar/" + operarioInfo.idAcopio;
      const response = await axios.get(`${myUrl}`);
      console.log(response.data);
      if (response && response.data.length > 0) {
        setTrasladosSinRecibir(response.data);
      } else {
        console.log("No hubo traslados sin ingresarr");
        setTrasladosSinRecibir();
      }
    } catch (error) {
      console.log(error);
    }
  };
  //Ejecuta los métodos de postear Ingreso de traslado y prueba
  const postData = async () => {
    const notify = notifyPendingNotify();
    try {
      if (selectedTraslado) {
        const response = await postPruebas(prueba);
        if (response && response?.status === 200) {
          const idPrueba = response.data.data.id;
          popNotification(notify, "success", "Prueba agregada correctamente");
          await postIngresoTraslado(idPrueba);
        } else {
          popNotification(notify, "error", "No se puede agregar la prueba");
        }
      } else {
        popNotification(
          notify,
          "error",
          "Seleccione un traslado para ingresarlo"
        );
      }
    } catch (error) {
      popNotification(
        notify,
        "error",
        "Error. No se puede agregar la prueba. Error de conexión"
      );
    }
    await getTrasladosNoIngresados();
  };
  //guarda el ingreso que va a ser parte del traslado
  const postIngresoTraslado = async (idPrueba) => {
    const notify = notifyPendingNotify();
    const fechaHoy = await dateToColombianZoneRq();
    const horaHoy = timeToColombianZonerq();
    const myUrl = URL + "traslados-ingresos";
    try {
      const response = await axios.post(myUrl, {
        data: {
          fecha: fechaHoy,
          hora: horaHoy,
          cantidad_recibida: cantidad,
          cantidad_enviada: selectedTrasladoCant,
          diferencia: diferencia,
          acopio: operarioInfo.idAcopio,
          acopio_origen: selectedTrasladoAcopioOrigen,
          traslado: selectedTraslado,
          prueba: idPrueba,
          traslado_novedad: esNovedad,
        },
      });
      if (response && response.status === 200) {
        popNotification(
          notify,
          "success",
          "Ingreso de traslado guardado correctamente"
        );
        console.log("Ha sido guardado");
        console.log(JSON.stringify(response.data));
      }
    } catch (error) {
      console.log("Error al guardar: " + JSON.stringify(error));
      popNotification(
        notify,
        "error",
        "Error al guardar el Ingreso de Traslado"
      );
    }
  };
  //OBTIENE LA DIFERENCIA ENTRE LA LECHE ENVIADA Y LA LECHE RECIBIDA
  const getDiferencia = () => {
    setDiferencia(cantidad - selectedTrasladoCant);
  };
  const clearFields = () => {
    setEsNovedad(false);
    setCantidad(0);
    setDiferencia(0);
    setObservaciones("");
  };

  useEffect(() => {
    getOperario();
  }, []);
  useEffect(() => {
    if (operarioInfo) {
      getTrasladosNoIngresados();
    }
  }, [operarioInfo]);
  useEffect(() => {
    if (selectedTraslado) {
      getSelectedTrasladoCant(selectedTraslado);
    }
    if (selectedTraslado == -1) {
      getTrasladosNoIngresados();
    }
  }, [selectedTraslado]);
  return (
    <section className="h-full">
      <h1 className="text-5xl py-5">Ingreso de Traslado</h1>
      {!trasladosSinRecibir || trasladosSinRecibir.length === 0 ? (
        <div className="alert alert-warning">
          No existen traslados pendientes por recibir en el momento
        </div>
      ) : (
        <div className="flex flex-col ">
          <label htmlFor="traslados" className="label">
            Seleccione un traslado
          </label>
          <select
            className="select select-bordered w-3/4"
            onChange={(e) => {
              setSelectedTraslado(e.target.value);
              getSelectedTrasladoCant(e.target.value);
              setSelectedAcopioOrigen(e.target.selectedOptions[0].text);
            }}
          >
            <option value={-1}>Seleccione un traslado</option>
            {trasladosSinRecibir.map((traslado) => (
              <option key={traslado.id} value={traslado.id}>
                {traslado.fecha} : {traslado.acopio_origen.nombre}
              </option>
            ))}
          </select>
          {selectedTraslado && selectedTraslado != -1 ? (
            <>
              <p className="text-lg py-3 text-left">
                <span className="font-bold">
                  Cantidad de leche en el traslado:{" "}
                </span>
                {selectedTrasladoCant} litros
              </p>
              <p className="text-lg py-1 text-left">
                <span className="font-bold">Es traslado por novedad: </span>
                {esNovedad === true ? <>SI</> : <>NO</>}
              </p>
              <label htmlFor="cantMedida" className="label">
                Leche Medida
              </label>
              <span className="input-group ">
                <input
                  type={"number"}
                  className="input input-bordered"
                  id="cantMedida"
                  min={0}
                  step={1}
                  value={cantidad}
                  onChange={(e) => setCantidad(e.target.value)}
                  onBlur={() => {
                    getDiferencia();
                  }}
                ></input>
                <span>Litros</span>
              </span>
              {diferencia > 0 ? (
                <label className="label label-text-alt text-success">
                  La diferencia es de {diferencia} litros.
                </label>
              ) : (
                <label className="label label-text-alt text-error">
                  La diferencia es de {diferencia} litros.
                </label>
              )}
              <label htmlFor="observaciones" className="label">
                Observaciones
              </label>
              <textarea
                className="textarea textarea-bordered"
                placeholder="Observaciones del ingreso de traslado"
                onChange={(e) => setObservaciones(e.target.value)}
                value={observaciones}
              ></textarea>
              <Prueba onPruebaChange={handlePruebaChange} className="my-5" />
              <label className="btn btn-accent" htmlFor="modal-confirm">
                Guardar traslado
              </label>
            </>
          ) : (
            <div className="alert alert-warning">Seleccione un traslado</div>
          )}
        </div>
      )}
      ;{/* Modal de confirmacion */}
      <input type="checkbox" id="modal-confirm" className="modal-toggle" />
      <div className="modal">
        <div className="modal-box ">
          <label
            htmlFor="modal-confirm"
            className="btn btn-sm btn-circle absolute right-2 top-2"
          >
            ✕
          </label>
          <h3 className="text-lg font-bold">Aviso</h3>
          <p className="py-4">Desea ingresar el siguiente traslado digitado?</p>
          <h6 className="font-bold">Datos del Traslado</h6>
          <p>
            <span className="font-bold">Nombre del Acopio Origen: </span>
            {selectedAcopioOrigen}
          </p>
          <p>
            <span className="font-bold">Cantidad enviada: </span>
            {selectedTrasladoCant} litros
          </p>
          <p>
            <span className="font-bold">Cantidad a recibir por traslado: </span>
            {cantidad} litros
          </p>
          <p>
            <span className="font-bold">Observaciones: </span>
            {observaciones}
          </p>
          <div className="modal-action">
            <label
              htmlFor="modal-confirm"
              onClick={(e) => {
                postData();
                clearFields();
              }}
              className="btn btn-accent"
              type="submit"
            >
              Si
            </label>
            <label htmlFor="modal-confirm" className="btn">
              Aún no
            </label>
          </div>
          <div className="modal-action"></div>
        </div>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </section>
  );
}

export default CreateRecibirTraslado;
