//Verifica si una placa es valida o no
export function validatePlaca(placa) {
  if (placa.length === 6) {
    const letras = placa.substring(0, 2);
    const num = placa.substring(3, 5);
    if (/^[a-zA-Z]+$/.test(letras) && !isNaN(num)) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
  
}
export function validatePlacaUpdated(placa) {
  const regex = /^[a-zA-Z]{3}\d{3}$/; // Regular expression for "abc123" format without any spaces
  const hasSpaces = /\s/.test(placa); // Check for spaces using a separate regular expression
  return regex.test(placa) && !hasSpaces;
}
