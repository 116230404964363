import { useEffect, useState } from "react";
import axios from "../api/axios";
import useAuth from "../hooks/useAuth";

function GetRecorridos() {
  const URL = process.env.REACT_APP_BACKEND_BASE_URL;
  const { auth } = useAuth();
  const [recorridos, setRecorridos] = useState();
  const [idRecolector, setIdRecolector] = useState();

  //Obtiene el id del recolector del usuario logueado
  const getIdRecolector = async () => {
    try {
      console.log(
        "Id del usuario: " + auth.id + "\n nombre del usuario:" + auth.username
      );
      const url = URL + "recolectorusuario/" + auth.id;
      const response = await axios.get(`${url}`);
      console.log("DATOS DEL RECOLECTOR: " + JSON.stringify(response.data));
      setIdRecolector(response.data.id);
    } catch (error) {
      console.log(error);
    }
  };

  //OBTIENE LA LISTA DE RECORRIDOS QUE HAHECHO EL RECOLECTOR
  const recorridosRecolector = async () => {
    try {
      const url = URL + "recoleccionesreport/" + idRecolector;
      const response = await axios.get(`${url}`);
      console.log(JSON.stringify(response.data));
      setRecorridos(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    console.log("ingresando al useefect");

    getIdRecolector();
  }, []);
  //Cambio en id recolector
  useEffect(() => {
    console.log("cambio en id recolector");
    if (idRecolector) {
      recorridosRecolector();
    }
  }, [idRecolector]);

  return (
    <div className=" h-full max-w-full">
      <h2 className="text-4xl py-4">Recolecciones Realizadas</h2>
      {!recorridos ? (
        <div className="alert alert-info shadow-lg ">
          <span>Aún no ha hecho la recoleccion de ninguna ruta.</span>
        </div>
      ) : (
        recorridos.map((recorrido) => (
          <div key={recorrido.id} className="card card-side   shadow-xl">
            <figure></figure>
            <div className="card-body max-w-full">
              <h2 className="card-title max-w-fit">
                Nombre de la ruta: {recorrido.ruta?.nombre}
              </h2>
              <h2 className="card-title">Fecha: {recorrido.fecha}</h2>
              <h3 className="card-title">Cantidades recogidas:</h3>
              <div className="overflow-x-auto">
                <table border="1" className="table  table-compact w-full">
                  <thead>
                    <tr>
                      <th></th>
                      <th><p className="text-center">Nombre</p></th>
                      <th><p className="text-center">Cantidad</p></th>
                      <th><p className="text-center">Registro</p></th>
                    </tr>
                  </thead>
                  <tbody>
                    {/*  */}
                    {recorrido.recolecciones.map((recoleccion, index) => (
                      <tr key={recoleccion.id}>
                        <td>{index+1}</td>
                        <td>{recoleccion.productor?.nombre_corto}</td>
                        <td>
                          <p className="text-center">
                            {recoleccion.cantidad_leche}
                          </p>
                        </td>
                        <td>{recoleccion.hora.split(".", [1])}</td>
                      </tr>
                    ))}
                    <tr>
                      <td></td>
                      <td>
                        <p className="text-xl font-bold text-error">
                          Total:{"   "}
                          {recorrido.recolecciones.reduce(
                            (pValue, cValue) => pValue + cValue.cantidad_leche,
                            0
                          )}{" "}
                          Litros
                        </p>
                      </td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  );
}

export default GetRecorridos;
