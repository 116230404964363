import React, { useEffect, useRef, useState } from "react";
import { useDownloadExcel } from "react-export-table-to-excel";
import { makeGetRequest, makeSafeGetRequest } from "./utils/Requests";
import { getEmpresaAdmin } from "../api/RoleInfo";
import useAuth from "../hooks/useAuth";

function ReporteVentasAcopio() {
  //state de reporte
  const { auth } = useAuth();
  const [idEmpresa, setIdEmpresa] = useState();
  const [empresaNombre, setEmpresaNombre] = useState();
  const [ventasAcopioRango, setVentasAcopioRango] = useState();
  const [selectedVenta, setSelectedVenta] = useState();
  const [acopios, setAcopios] = useState();
  const [selectedAcopio, setSelectedAcopio] = useState();
  const [selAcopioNombre, setSelAcopioNombre]=useState();
  const [fechaInicial, setFechaInicial] = useState();
  const [fechaFinal, setFechaFinal] = useState();
  const tableRef = useRef(null);
  //Obtiene el id de la empresa
  const getIdEmpresa = async () => {
    try {      
      const response = await getEmpresaAdmin(auth);
      if (response) {
        console.log("encontro datos de la empresa" + JSON.stringify(response));
        setIdEmpresa(response.empresa?.id);
        setEmpresaNombre(response.empresa?.nombre);
      }
    } catch (error) {
      console.log("Error al obtener los datos de la empresa");
    }
  };

  //obtiene la lista de acopios de la empresa a la que pertenece el administrador
  const getAcopios = async () => {
    try {
      const response = await makeSafeGetRequest(
        "acopiosempresa/" + idEmpresa,
        auth.accessToken
      );
      if (response) {
        setAcopios(response.data);
      } else {
        setAcopios();
      }
    } catch (error) {
      console.log("Error al obtener los acopios" + error);
    }
  };
  //Obtiena la lista de ventas hechas por el acopio en un rango de tiempo
  const getVentasAcopioRango = async () => {
    try {
      const response = await makeGetRequest(
        "ventasacopiorango?idacopio=" +
          selectedAcopio +
          "&fechainicial=" +
          fechaInicial +
          "&fechafinal=" +
          fechaFinal
      );
      if (response) {
        setVentasAcopioRango(response.data);
      } else {
        setVentasAcopioRango();
      }
    } catch (error) {}
  };
  //Obtiene la venta seleccionada entre las obtenidas según el rango de fechas
  const getSelectedVenta = (idVenta) => {
    console.log("id de la venta" + idVenta);
    const selVenta = ventasAcopioRango.find((venta) => {
      return venta.id == idVenta;
    });
    console.log("venta seleccionada:" + JSON.stringify(selVenta));
    setSelectedVenta(selVenta);
  };
  //realiza la descarga del elemento table
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "ventas_" + empresaNombre + "_"+selAcopioNombre+"_" + fechaInicial + "_" + fechaFinal,
    sheet: "ventas " + empresaNombre,
  });
  const onSelectedAcopio=(e)=>{
    setSelectedAcopio(e.target.value);
    setSelAcopioNombre(e.target.selectedOptions[0].text)
  }
  //useeffect  obtener acopios
  useEffect(() => {
    if (idEmpresa) {
      getAcopios();
    }
  }, [idEmpresa]);
  //useeffect inicial
  useEffect(() => {
    getIdEmpresa();
  }, []);
  //use effect fechas
  useEffect(() => {
    console.log("cambio en fechas");
  }, [fechaInicial, fechaFinal]);
  return (
    <div className="h-full md:w-7/12 m-3">
      <div className="grid grid-cols-1">
        <h1 className="text-3xl">Ventas por Acopio</h1>
        <label className="label">Empresa: {empresaNombre}</label>
        {acopios ? (
          <>
            <label className="label" htmlFor="acopios">
              Seleccione un Acopio
            </label>
            <select
              className="select select-bordered"
              onChange={(e) => onSelectedAcopio(e)}
            >
              <option key={-1} value={-1}>
                Seleccione un Centro de Acopio
              </option>
              {acopios?.map((acopio) => (
                <option key={acopio?.id} value={acopio.id}>
                  {acopio.nombre}
                </option>
              ))}
            </select>
          </>
        ) : (
          <div>cargando acopios</div>
        )}
        <label className="label">Fecha Inicial</label>
        <input
          type={"date"}
          className="input input-bordered"
          onChange={(e) => setFechaInicial(e.target.value)}
        ></input>
        <label className="label">Fecha Final</label>
        <input
          type={"date"}
          className="input input-bordered"
          onChange={(e) => setFechaFinal(e.target.value)}
        ></input>
        <button
          className="btn btn-accent py-3 my-3"
          onClick={getVentasAcopioRango}
        >
          Generar Reporte
        </button>
        {ventasAcopioRango && ventasAcopioRango.length > 0 ? (
          <div className="overflow-x-auto">
            <table className="table table-compact" ref={tableRef}>
              <thead>
                <tr>
                  <th></th>
                  <th>Fecha</th>
                  <th>Acopio</th>
                  <th>Nombre</th>
                  <th>Documento</th>
                  <th>Placa</th>
                  <th>Cantidad</th>
                  <th>Es novedad</th>
                  <th>opciones</th>
                </tr>
              </thead>
              <tbody>
                {ventasAcopioRango?.map((venta) => (
                  <tr key={venta.id} value={venta.id}>
                    <td></td>
                    <td>{venta.fecha}</td>
                    <td>{venta?.acopio?.nombre}</td>
                    <td>{venta.cliente?.nombre}</td>
                    <td>{venta?.cliente?.cedula_nit}</td>
                    <td>{venta?.placa_vehiculo}</td>
                    <td>{venta?.cantidad_leche}</td>
                    <td>
                      {venta?.venta_novedad === true ? (
                        <span className="text-error font-bold">Si</span>
                      ) : (
                        <span className="text-success font-bold">No</span>
                      )}
                    </td>
                    <td>
                      <label
                        className="btn btn-link"
                        htmlFor="modal-details"
                        onClick={() => getSelectedVenta(venta.id)}
                      >
                        Detalles
                      </label>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <button className="btn w-40" onClick={onDownload}>
              Exportar Excel
            </button>
          </div>
        ) : (
          <div className="alert alert-success">
            No hay ventas realizadas en el rango seleccionado
          </div>
        )}
      </div>

      {/* Modal de detalles */}
      <input
        type={"checkbox"}
        className="modal-toggle"
        id="modal-details"
      ></input>
      <div className="modal">
        <div className="modal-box">
          <h3 className="font-bold text-xl mb-1">Detalles de la Venta</h3>
          {selectedVenta ? (
            <>
              <div className="grid grid-cols-2 gap-x-2 gap-y-1 text-left">
                <p>
                  <span className="font-bold">Fecha de venta: </span>
                  {selectedVenta?.fecha}
                </p>
                <p>
                  <span className="font-bold">Hora: </span>
                  {selectedVenta?.hora.split(".")[0]}
                </p>
                <p>
                  <span className="font-bold">Nombre del cliente: </span>
                  {selectedVenta?.cliente?.nombre}
                </p>
                <p>
                  <span className="font-bold">Placa del Vehículo: </span>
                  {selectedVenta?.placa_vehiculo}
                </p>
                <p>
                  <span className="font-bold">Observaciones: </span>
                  {selectedVenta?.observaciones}
                </p>
                <p>
                  <span className="font-bold">
                    Es novedad?:{""}
                    {selectedVenta?.venta_novedad && selectedVenta?.venta_novedad === true ? (
                      <span className="text-warning">Sí</span>
                    ) : (
                      <span className="text-success">No</span>
                    )}
                  </span>
                </p>
                <p>
                  <span className="font-bold">Total venta: </span>
                  {selectedVenta.cantidad_leche} litros
                </p>
              </div>
              <div className="divider "></div>
              <p className="font-bold">Compartimentos</p>
              <div className="stats stats-vertical shadow">
                {selectedVenta?.compartimentos?.map((comp) => (
                  <div className="stat" key={comp.id}>
                    <div className="stat-title">
                      Número de compartimento:{comp?.numero_compartimento}
                    </div>
                    <div className="stat-title">Cantidad de leche</div>
                    <div className="stat-value">
                      {comp?.cantidad_leche} litros.
                    </div>
                    <div className="stat-figure text-error">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15.362 5.214A8.252 8.252 0 0112 21 8.25 8.25 0 016.038 7.048 8.287 8.287 0 009 9.6a8.983 8.983 0 013.361-6.867 8.21 8.21 0 003 2.48z"
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 18a3.75 3.75 0 00.495-7.467 5.99 5.99 0 00-1.925 3.546 5.974 5.974 0 01-2.133-1A3.75 3.75 0 0012 18z"
                        />
                      </svg>
                      Temperatura:{" " + comp.temperatura} °C
                    </div>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <></>
          )}
          <div className="modal-action">
            <label htmlFor="modal-details" className="btn">
              Cerrar
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReporteVentasAcopio;
