import { makeGetRequest, makeSafeGetRequest } from "../components/utils/Requests";
import axios from "./axios";

const URL = process.env.REACT_APP_BACKEND_BASE_URL;
export async function getOperarioInfo(idUser) {
  try {
    const url = URL + "operarioporusuario/" + idUser;
    const response = await axios.get(`${url}`);
    if (response.status === 200) {
      return {
        idOperario: response?.data?.id,
        idAcopio: response?.data?.acopio?.id,
        idEmpresa: response?.data?.acopio?.empresa?.id,
        nombreEmpresa: response?.data?.acopio?.empresa?.nombre,
      };
    } else {
      return null;
    }
  } catch (error) {
    console.log("Error al obtener el idOperario: " + error);
    return null;
  }
}
//Obtiene la empresa a la que pertenece el admin
export async function getEmpresaAdmin(auth) {
  try {
    const resp = await makeSafeGetRequest("adminempresa/" + auth.id,auth.accessToken );
    if (resp && resp?.status === 200) {
      return resp.data;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
}
