import { useEffect, useState } from "react";

function BookTitle(props) {
  const [book, setBook] = useState({ title: "", year: 0 });
  const updateTitle = (titleE) => {
    let myBook = { ...book, title: titleE };
    setBook(myBook);
  };
  const updateYear = (yearE) => {
    let myBook = { ...book, year: yearE };
    setBook(myBook);
  };
  const updateBookParameter=(parameterE,parameterName)=>{
    let myBook={ ...book};
    myBook[parameterName]=parameterE;
    setBook(myBook)
  };
  useEffect(() => {
    console.log("ALGO HIJO");
    props.onBookChange(book);
  }, [book]);
  return (
    <>
      <label>
        Title:
        <input
          onChange={(e) => updateBookParameter(e.target.value,"title")}
          value={book.title}
        />
      </label>
      <input
        onChange={(e) => updateYear(e.target.value)}
        value={book.year}
      ></input>
    </>
  );
}
export default BookTitle;
