import React, { useEffect, useState } from "react";
import axios from "../api/axios";

function GetConsolidados() {
  const URL = process.env.REACT_APP_BACKEND_BASE_URL;
  const [consolidados, setConsolidados] = useState();
  //obtiene la lista de consolidados del acopio
  const getConsolidados = async () => {
    const myUrl = URL + "consolidados/1"; //nota cambiar por el isd del acopio que estemos usando como operarios
    const response = await axios.get(`${myUrl}`);
    console.log(JSON.stringify(response.data));
    setConsolidados(response.data);
  };
  useEffect(() => {
    getConsolidados();
  }, []);
  return (
    <section className="h-full flex flex-col justify-center justify-items-center">
      <h1 className="text-center text-2xl">Consolidados</h1>
      {!consolidados ? (
        <div className="alert alert-info">Cargando consolidados</div>
      ) : (
        <div className="">
          <table className="table">
            <thead>
              <tr>
                <th>Fecha de consolidado</th>
                <th>Cantidad Inicial</th>
                <th>Cantidad Recibida</th>
                <th>Cantidad Vendida</th>
                <th>Cantidad Calculada</th>
                <th>Cantidad Final</th>
                <th>Diferencia</th>
                <th>Merma</th>
                <th>Acopio</th>
              </tr>
            </thead>
            <tbody>
              {consolidados.map((consolidado) => (
                <tr key={consolidado.id}>
                  <th>{consolidado.fecha}</th>
                  <td>{consolidado.cantidad_inicial}</td>
                  <td>{consolidado.cantidad_recibida}</td>
                  <td>{consolidado.cantidad_vendida}</td>
                  <td>{consolidado.cantidad_calculada}</td>
                  <td>{consolidado.cantidad_final_dia}</td>
                  <td>{consolidado.diferencia}</td>                  
                  <td>{consolidado.merma}</td>
                  <td>{consolidado.acopio.nombre}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </section>
  );
}

export default GetConsolidados;
