import { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../api/axios";
import useAuth from "../hooks/useAuth";
import Prueba from "./Prueba";
import { validatePlaca, validatePlacaUpdated } from "./utils/CheckPlaca";
import { notifyPendingNotify, popNotification } from "./utils/Notify";
import { makeGetRequest } from "./utils/Requests";
import {
  dateToColombianZoneRq,
  timeToColombianZonerq,
} from "./utils/timezoneRequests";

function GetRecorridos() {
  const URL = process.env.REACT_APP_BACKEND_BASE_URL;
  const { auth } = useAuth();

  const [rutas, setRutas] = useState([]);

  const [clientes, setClientes] = useState();

  const [selectedCliente, setSelectedCliente] = useState();
  const [selClienteNombre, setSelClienteNombre] = useState();
  const [cantidad, setCantidad] = useState(0);
  const [placa, setPlaca] = useState("");
  const [observaciones, setObservaciones] = useState("");
  const [compartimentos, setCompartimentos] = useState();
  const [ventaNovedad, setVentaNovedad] = useState(false);
  //states del modal de compartimentos
  const [prueba, setPrueba] = useState({});
  const [nComp, setNComp] = useState();
  const [ordenComp, setOrdenComp] = useState();
  const [cant, setCant] = useState(0);
  const [temp, setTemp] = useState(0);
  const [obs, setObs] = useState("");
  //states sesion
  const [idOperario, setIdOperario] = useState();
  const [idAcopio, setIdAcopio] = useState();
  const [inventarioHoy, setInventarioHoy] = useState();
  const [checkPlaca, setCheckPlaca] = useState();

  //Obtiene el id del operarioal que pertenece el usuario logueado
  const getIdOperario = async () => {
    try {
      const idUser = auth.id;
      const url = URL + "operarioporusuario/" + idUser;
      const response = await axios.get(`${url}`);

      if (response?.status === 200) {
        console.log("hay operario asociado al usuario");
        setIdOperario(response?.data?.id);
        getIdAcopio(response.data);
      } else {
        console.log("No hay operario disponiblee");
      }
    } catch (error) {
      console.log("Error al obtener el id del operario: " + error);
    }
  };
  //obtiene el id del acopio del operario
  const getIdAcopio = (dataOperario) => {
    const idAco = dataOperario.acopio.id;
    console.log("Id del acopio del recolector" + idAco);
    setIdAcopio(idAco);
  };
  //Obtiene las cantidades de lecha disponibles por acopio, tanto ingresos normales como por novedad
  const getInventarioHoy = async () => {
    try {
      const response = await makeGetRequest("inventarioacopio/" + idAcopio);
      if (response) {
        console.log(JSON.stringify(response));
        setInventarioHoy(response.data);
      }
    } catch (error) {
      console.log("Error al obtener el inventario:" + error);
    }
  };

  //carga el compartimento por defecto para la venta
  const loadCompartimentos = () => {
    const compartimento = [
      {
        numeroCompartimento: 1,
        ordenCompartimento: 1,
        cantidadLeche: 0,
        temperatura: 0.0,
        observaciones: "Ninguna",
        rutas: [],
        prueba: {},
      },
    ];
    setCompartimentos(compartimento);
  };

  //se obtienen las rutas del acopio al que pertenece el operario.Cambiar el 1 por el id del acopio del operario
  const getRutas = async () => {
    console.log("id del acopio cargado" + idAcopio);
    const url = URL + "rutasacopio/" + idAcopio;
    try {
      const response = await axios.get(`${url}`);
      setRutas(response.data);
      console.log("Rutas del acopio  " + JSON.stringify(rutas));
    } catch (error) {
      console.log(error);
    }
  };
  //obtiene la lista de clientes de la empresa 
  const getClientes = async () => {
    const url = URL + "clientesempresa/1";
    try {
      const response = await axios.get(`${url}`);
      console.log(JSON.stringify(response.data));
      setClientes(response?.data);
    } catch (error) {
      console.log(error);
    }
  };
  //Registra el cambio en el select de Clientes
  const onSelectedCliente = (e) => {
    setSelectedCliente(e.target.value);
    setSelClienteNombre(e.target.selectedOptions[0].text);
  };
  //verifica que hayan existencias para la venta
  const verifyVenta=async()=>{
    try {
      console.log("Verificando existencias");
      const diferencia=calcularDiferenciaVenta();
      
      const notify= notifyPendingNotify();
      if(diferencia>=0){
        popNotification(notify, "success","Disponibilidad. Cargando venta...")
        if(checkPlaca===true){
          await postVenta();
        }else{
          popNotification(notify, "error","Error.Placa incorrecta. El formato es ABC123")
        }
      }else{
        popNotification(notify, "error","No hay disponibilidad de leche en el inventario seleccionado");
      }
      //vuelve a obtener el inventario
      await getInventarioHoy();
    } catch (error) {
      
    }
  }
  //
  const calcularDiferenciaVenta=()=>{
    let diferencia=0;
    if(ventaNovedad){
      diferencia=inventarioHoy.total_novedades-cantidad;
    }else{
      diferencia= inventarioHoy.total_inventario-cantidad;
    }
    console.log("diferencia de la leche"+ diferencia)
    return diferencia;
  }
  //toma los datos ingresados y hace el post de la venta
  const postVenta = async () => {
    const myUrl = URL + "ventas-leche";
    const notify = notifyPendingNotify();
    console.log(selectedCliente + cantidad + placa + observaciones);
    sumCompartimentos();
    try {
      if (selectedCliente && cantidad && placa) {
        const fechaHoy = await dateToColombianZoneRq();
        const horaHoy = timeToColombianZonerq();
        const d = {
          data: {
            fecha: fechaHoy,
            hora: horaHoy,
            cliente: selectedCliente,
            cantidad_leche: cantidad,
            placa_vehiculo: placa.toUpperCase(),
            observaciones: observaciones,
            venta_novedad:ventaNovedad,
            acopio: idAcopio, //cambiar al acopio del operario. Pendiente verificar
          },
        };
        console.log(
          JSON.stringify(d) + "\n LO QUYE SE VA A POSTEAR DE LA VENTA"
        );
        const response = await axios.post(myUrl, d);
        console.log(JSON.stringify(response + "esta es la respuesta"));
        if (response.status === 200) {
          popNotification(notify, "success", "Venta realizada correctamente");

          //postear los compartimentos con el id de la venta
          const idVenta = response.data.data.id;
          console.log("id de la ventaa: " + idVenta);
          await postCompartimentos(idVenta);

          clearVenta();
        }
      } else {
        popNotification(notify, "error", "Complete los datos de la venta");
      }
    } catch (error) {
      console.log(error);
      popNotification(
        notify,
        "error",
        "Error al guardar los datos de la venta."
      );
    }
  };
  //postea el contenido de los compartimentos agregados
  const postCompartimentos = async (idVenta) => {
    for (let i = 0; i < compartimentos.length; i++) {
      const c = compartimentos[i];

      try {
        const url = URL + "compartimentos";
        const rutas = c.rutas.map(function (ruta) {
          delete ruta.nombre;
          return ruta;
        });
        //agregar la prueba del compartimento
        const idPrueba = await postPrueba(c.prueba);
        console.log(JSON.stringify(rutas) + "Rutas con nombre borrado");
        const resp = await axios.post(url, {
          data: {
            numero_compartimento: c.ordenCompartimento,
            cantidad_leche: c.cantidadLeche,
            temperatura: c.temperatura,
            observaciones: c.observaciones,
            venta_leche: { id: idVenta },
            rutas: [...rutas],
            prueba: idPrueba,
          },
        });
        console.log(JSON.stringify(resp.data));
      } catch (error) {
        console.log(error);
      }
    }
  };
  //guarda una prueba en la b.d y retorna el id de la misma
  const postPrueba = async (pruebaComp) => {
    if (pruebaComp) {
      const myUrl = URL + "pruebas";
      console.log("Prueba del compartimento: " + JSON.stringify(pruebaComp));
      const fechaHoy = await dateToColombianZoneRq();
      const horaHoy = timeToColombianZonerq();
      console.log(
        "algunos valores de la prueba:" +
          pruebaComp.acidez +
          pruebaComp.ph +
          pruebaComp.observaciones_acidez
      );
      const data = {
        data: {
          acidez: pruebaComp.acidez,
          observaciones_acidez: pruebaComp.obsAcidez,
          ph: pruebaComp.ph,
          observaciones_ph: pruebaComp.obsPh,
          agua: pruebaComp.agua,
          observaciones_agua: pruebaComp.obsAgua,
          antibiotico: pruebaComp.antibiotico,
          observaciones_antibiotico: pruebaComp.obsAntibiotico,
          proteina: pruebaComp.proteina,
          observaciones_proteina: pruebaComp.obsProteina,
          grasa: pruebaComp.grasa,
          observaciones_grasa: pruebaComp.obsGrasa,
          solidos: pruebaComp.solidos,
          observaciones_solidos: pruebaComp.obsSolidos,
          alcohol: pruebaComp.alcohol,
          observaciones_alcohol: pruebaComp.obsAlcohol,
          neutralizantes: pruebaComp.neutralizantes,
          observaciones_neutralizantes: pruebaComp.obsNeutralizantes,
          adulterantes: pruebaComp.adulterantes,
          observaciones_adulterantes: pruebaComp.obsAdulterantes,
          fecha: fechaHoy,
          hora: horaHoy,
          observaciones_generales: pruebaComp.obsGenerales,
        },
      };
      console.log("Data a guardar de la prueba" + JSON.stringify(data));
      try {
        const response = await axios.post(myUrl, data);
        if (response) {
          console.log(JSON.stringify(response));
          const idPrueba = response.data.data.id;
          return idPrueba;
        }
      } catch (error) {
        console.log(error);
        return null;
      }
    } else {
      console.log("no hay prueba en el compartimento");
    }
  };

  //Agrega una ruta al compartimento

  const addRutaCompartimento = (idRuta, nombreRuta, numCompartimento) => {
    try {
      var comp = compartimentos;
      for (let i = 0; i < comp.length; i++) {
        //Cuando encuentra el compartimento y verifica que no exista una ruta igual, agrega una nueva ruta
        if (comp[i].numeroCompartimento === numCompartimento) {
          const rutaEncontrada = rutaExiste(comp[i].rutas, idRuta);
          if (!rutaEncontrada && idRuta != -1) {
            comp[i].rutas.push({ id: idRuta, nombre: nombreRuta });
          }
        }
      }
      setCompartimentos([...comp]);
      console.log(
        JSON.stringify(compartimentos) +
          "compartimentos con Rutas actualizadas "
      );
    } catch (error) {
      console.log(error);
    }
    sumCompartimentos();
  };

  //Verifica si en un conjunto de rutas ya existe una con un id igual al que viene por parametro
  const rutaExiste = (rutas, idRuta) => {
    const rutaEncontrada = rutas.filter((r) => r.id === idRuta);
    console.log("Ruta fue encontrada?" + JSON.stringify(rutaEncontrada));
    if (rutaEncontrada.length > 0 && !isNaN(idRuta)) {
      console.log("retorna verdad");
      return true;
    } else {
      console.log("retorna falso");
      return false;
    }
  };
  //elimina una ruta del compartimento
  const deleteRutaComp = (idRuta, numCompartimento) => {
    try {
      console.log(
        "id de la ruta a eliminar: " +
          idRuta +
          "numero del compartimento" +
          numCompartimento
      );
      const comp = compartimentos;
      const rutas = comp[0].rutas;
      console.log(JSON.stringify(rutas));
      var c = comp.find(
        (comp) => comp.numeroCompartimento === numCompartimento
      );
      const i = c.rutas.findIndex((ruta) => ruta.id === idRuta);
      c.rutas.splice(i, 1);
      console.log(JSON.stringify(c) + "compartimento elegido");
      setCompartimentos([...comp]);

      console.log(
        JSON.stringify(compartimentos) + "compartimento con ruta eliminaDA"
      );
    } catch (error) {
      console.log(error);
    }
  };

  //suma las cantidades indicadas en los compartimentos
  const sumCompartimentos = () => {
    let sum = compartimentos.reduce(function (prev, current) {
      return prev + +current.cantidadLeche;
    }, 0);
    console.log(sum);
    setCantidad(sum);
    console.log(sum + " suma de leche en los compartimentos");
  };
  //crea un nuevo compartimento por defecto
  const addCompartimento = () => {
    console.log("Agregando compartimento");
    sumCompartimentos();
    try {
      if(compartimentos?.length<=3){
        let nuevoID =
          compartimentos[compartimentos.length - 1].numeroCompartimento + 1;
        setCompartimentos([
          ...compartimentos,
          {
            numeroCompartimento: nuevoID,
            ordenCompartimento: 0,
            cantidadLeche: 0,
            temperatura: 0.0,
            observaciones: "Ninguna",
            rutas: [],
          },
        ]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //abre modal para editar los compartimentos a agregar
  const editCompartimento = () => {
    console.log("actualizando compartimento " + nComp);
    const compartimentoUpdated = compartimentos.map((obj) => {
      if (obj.numeroCompartimento === nComp) {
        return {
          ...obj,
          numeroCompartimento: nComp,
          ordenCompartimento: ordenComp,
          cantidadLeche: cant,
          temperatura: temp,
          observaciones: obs,
        };
      }
      return obj;
    });
    setCompartimentos(compartimentoUpdated);
    sumCompartimentos();
  };
  //elimina el compartimento
  const deleteCompartimento = (numCompartimento) => {
    if (compartimentos.length > 1) {
      setCompartimentos(
        compartimentos.filter(
          (compartimento) =>
            compartimento.numeroCompartimento !== numCompartimento
        )
      );
    }
    console.log("probando eliminacion");
  };
  //agrega la prueba al compartimento indicado
  const addPruebaCompartimento = () => {
    let compActualizado = compartimentos;
    const i = compartimentos.findIndex(
      (obj) => obj.numeroCompartimento === nComp
    );
    console.log(
      "Compartimento sin prueba: " + JSON.stringify(compartimentos[i])
    );
    compActualizado[i].prueba = { ...prueba };
    console.log(
      "Compartimento con prueba: " + JSON.stringify(compActualizado[i])
    );
    setCompartimentos(compActualizado);
    console.log("Nuevos compartimentos: " + JSON.stringify(compartimentos));
  };
  //limpia los campos del modal de edicion de los compartimentos
  const clearModal = () => {
    console.log("limpiando modal");
    setCant(0);
    setTemp(0);
    setObs("");
  };
  //carga los inputs del modal de edicion con la info previa del compartimento seleccionado
  const loadDataEditCompartimento = (nComp) => {
    console.log(typeof compartimentos);
    console.log(JSON.stringify(compartimentos));
    console.log(JSON.stringify(compartimentos[nComp - 1]) + "NCOMP");

    for (let comp of compartimentos) {
      console.log(JSON.stringify(comp) + "FOOF");
      if (comp.numeroCompartimento === nComp) {
        console.log("cambiando valores modal");
        setOrdenComp(comp.ordenCompartimento);
        setCant(comp.cantidadLeche);
        setTemp(comp.temperatura);
        setObs(comp.observaciones);
      }
    }
  };
  //Limpia los inputs de la venta
  const clearVenta = async () => {
    console.log("limpiando los inputs de la venta");

    console.log("cliente seleccionado" + selectedCliente);
    setPlaca("");
    setObservaciones("");
    loadCompartimentos();
  };
  //obtiene el cambio en el componente reutilizable de la prueba
  function handlePruebaChange(pruebaE) {
    setPrueba({ ...pruebaE });
  }
  //effect para empezar
  useEffect(() => {
    getIdOperario();
    getClientes();

    loadCompartimentos();
  }, []);
  useEffect(() => {
    if (idAcopio) {
      getRutas();
      getInventarioHoy();
    }
  }, [idAcopio]);
  useEffect(() => {
    console.log("compartimiento agregado");
    if (compartimentos) {
      sumCompartimentos();
    }
  }, [compartimentos]);
  useEffect(() => {
    console.log("Cambio en ncomp");
  }, [nComp]);
  useEffect(() => {
    console.log("cambio en cliente seleccionado");
    if (selectedCliente && selectedCliente === -1) {
      getClientes();
    }
  }, [selectedCliente]);

  return (
    <section className="h-full py-3">
      <h1 className="text-4xl py-4">Realizar Venta</h1>
      {inventarioHoy ? (
        <>
          <p className="text-xl">Inventario:</p>
          <p className="text-xl">
            Leche disponible:{inventarioHoy.total_inventario} Litros
          </p>
          <p className="text-xl">
            Leche con novedad:{inventarioHoy.total_novedades} Litros
          </p>
        </>
      ) : (
        <></>
      )}
      <div className="grid grid-cols-1 md:grid-cols-2 px-10 py-3">
        <div className="grid grid-cols-1 px-5 content-start py-1">
          <p className="text-2xl">Datos de la Venta</p>

          <label htmlFor="cliente" className="label">
            Cliente{" "}
          </label>

          <select
            id="cliente"
            className="select select-bordered"
            defaultValue={""}
            onChange={(e) => onSelectedCliente(e)}
          >
            <option value={""}>Seleccione un Cliente</option>
            {!clientes ? (
              <option value={"N/A"}>Cargando clientes</option>
            ) : (
              clientes.map((cliente) => (
                <option key={cliente.id} value={cliente.id}>
                  
                  {cliente?.nombre}
                </option>
              ))
            )}
          </select>

          <label htmlFor="placaVehiculo" className="label">
            Placa del Vehiculo
          </label>
          <input
            id="placaVehiculo"
            type={"text"}
            className="input input-bordered"
            onChange={(e) => {
              setPlaca(e.target.value);
              setCheckPlaca(validatePlacaUpdated(e.target.value));
            }}
            value={placa}
          ></input>
          <label htmlFor="label label-alt">
            {checkPlaca === false ? (
              <span className="text-error">
                La placa no es válida.Debe ir en formato ABC123
              </span>
            ) : (
              <></>
            )}
          </label>
          <label htmlFor="observaciones" className="label">
            Observaciones
          </label>
          <textarea
            id="placaVehiculo"
            className="input input-bordered"
            onChange={(e) => setObservaciones(e.target.value)}
            value={observaciones}
          ></textarea>
          <div className="">
            <label htmlFor="novedad" className="label">
              Leche con novedad
            </label>
            <span className="label-text text-lg mx-5">No</span>
            <input
              type="checkbox"
              className="toggle toggle-lg toggle-accent"
              onClick={(e) => {
                setVentaNovedad((prevCheck) => !prevCheck);
              }}
            ></input>
            <span className="label-text text-lg mx-5">Sí</span>
          </div>
        </div>
        <div className="form-control py-1">
          <p className="text-2xl">Distribución de leche en el Vehículo</p>
          {!compartimentos ? (
            <div className="alert alert-warning">
              No hay compartimentos registrados
            </div>
          ) : (
            compartimentos.map((compartimento) => (
              <div
                className="card shadow-md"
                key={compartimento.numeroCompartimento}
              >
                <div className="card-body">
                  <h2 className="card-title">
                    Cargue {compartimento.numeroCompartimento}
                  </h2>
                  <p>
                    Numero del compartimento:{compartimento.ordenCompartimento}
                  </p>
                  <p>Cantidad de leche: {compartimento.cantidadLeche} Litros</p>
                  <p>Temperatura {compartimento.temperatura} °C</p>
                  <p>Observaciones: {compartimento.observaciones}</p>
                  {/* Agregar las rutas */}
                  <label htmlFor="rutasVenta" className="label">
                    Rutas de la venta
                  </label>
                  {!rutas ? (
                    <div className="alert alert-info">carga de rutas</div>
                  ) : (
                    <select
                      id="rutasVenta"
                      className="select select-bordered mb-3"
                      onChange={(e) => {
                        addRutaCompartimento(
                          e.target.value,
                          e.target.selectedOptions[0].text,
                          compartimento.numeroCompartimento
                        );
                      }}
                    >
                      <option datakey={-1} key={-1} value={-1}>
                        Añada una o varias rutas
                      </option>
                      {rutas.map((ruta) => (
                        <option datakey={ruta.id} key={ruta.id} value={ruta.id}>
                          {ruta.nombre}
                        </option>
                      ))}
                    </select>
                  )}

                  {/* Rutas seleccionadas */}
                  <div className="display flex flex-wrap mx-2">
                    {!compartimento.rutas ? (
                      <p>Las rutas aparecerán aquí</p>
                    ) : (
                      compartimento.rutas.map((ruta) => (
                        <div
                          className="badge  badge-lg m-1 p-2"
                          key={ruta.id}
                          value={ruta.id}
                        >
                          {ruta.nombre}
                          <button
                            className="btn  btn-circle btn-xs"
                            key={ruta.id}
                            value={ruta.id}
                            id={ruta.id}
                            onClick={(e) => {
                              deleteRutaComp(
                                e.currentTarget.id,
                                compartimento.numeroCompartimento
                              );
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-5 w-5"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                            >
                              <path
                                fillRule="evenodd"
                                d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </button>
                        </div>
                      ))
                    )}
                  </div>
                  <div className="card-actions justify-end">
                    <label
                      htmlFor="modal-compartimento"
                      className="btn "
                      onClick={(e) => {
                        console.log(
                          compartimento.numeroCompartimento +
                            " numero de compartimento"
                        );
                        loadDataEditCompartimento(
                          compartimento.numeroCompartimento
                        );

                        setNComp(compartimento.numeroCompartimento);
                      }}
                    >
                      Cargar Compartimento
                    </label>
                    <label
                      className="btn btn-accent"
                      htmlFor="modal-prueba"
                      onClick={(e) => {
                        setNComp(compartimento.numeroCompartimento);
                      }}
                    >
                      Prueba
                    </label>

                    <button
                      className="btn btn-error"
                      onClick={(e) => {
                        e.preventDefault();
                        deleteCompartimento(compartimento.numeroCompartimento);
                      }}
                    >
                      Eliminar
                    </button>
                  </div>
                </div>
              </div>
            ))
          )}
          <label
            className="btn btn-active"
            type="button"
            onClick={addCompartimento}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M17 14v6m-3-3h6M6 10h2a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2zm10 0h2a2 2 0 002-2V6a2 2 0 00-2-2h-2a2 2 0 00-2 2v2a2 2 0 002 2zM6 20h2a2 2 0 002-2v-2a2 2 0 00-2-2H6a2 2 0 00-2 2v2a2 2 0 002 2z"
              />
            </svg>
            Añadir compartimento
          </label>
        </div>
        <label className="label-text text-xl text-left my-5">
          {"Total de la venta: "}
          {cantidad}
          {" Litros"}
        </label>
        <label htmlFor="modal-confirm" className="btn btn-accent">
          Guardar Venta
        </label>
      </div>
      {/* Modal para agregar la prueba a un compartimento */}
      <input type="checkbox" id="modal-prueba" className="modal-toggle" />
      <div className="modal">
        <div className="modal-box w-11/12 md:w-5/12 max-w-5xl">
          <Prueba
            onPruebaChange={handlePruebaChange}
            idAcopio={idAcopio}
          ></Prueba>
          <div className="modal-action">
            <label
              htmlFor="modal-prueba"
              className="btn"
              onClick={(e) => {
                addPruebaCompartimento();
              }}
            >
              Guardar Prueba
            </label>
            <label htmlFor="modal-prueba" className="btn">
              Cancelar
            </label>
          </div>
        </div>
      </div>

      {/* Modal para agregar los datos del compartimento */}

      <input
        type="checkbox"
        id="modal-compartimento"
        className="modal-toggle"
      />
      <div className="modal -z-0">
        <div className="modal-box md:w-5/12">
          <h3 className="font-bold text-lg">Editar Compartimento</h3>
          <div className="form-control">
            <label className="label" htmlFor="ordenComp">
              Compartimento número
            </label>
            {/* <input
              type="number"
              className="input input-bordered"
              defaultValue={ordenComp}
              max={4}
              onChange={(e) => {
                setOrdenComp(e.target.value);
              }}
              min={1}
              step={1}
            ></input> */}
            <select
              className="input input-bordered"
              onChange={(e) => {
                setOrdenComp(e.target.value);
              }}
              defaultValue={ordenComp}
            >
              <option key={1} value={1}>
                Comp 1
              </option>
              <option key={2} value={2}>
                Comp 2
              </option>
              <option key={3} value={3}>
                Comp 3
              </option>
              <option key={4} value={4}>
                Comp 4
              </option>
            </select>
            <label className="label" htmlFor="cantidad">
              Cantidad de Leche
            </label>
            <input
              type="number"
              className="input input-bordered"
              value={cant}
              onChange={(e) => {
                setCant(e.target.value);
              }}
              min={1}
              step={1}
            ></input>

            <label className="label" htmlFor="temperatura">
              Temperatura
            </label>
            <input
              type="number"
              className="input input-bordered"
              value={temp}
              onChange={(e) => {
                setTemp(e.target.value);
              }}
              min={1}
              step={0.1}
            ></input>

            <label className="label" htmlFor="observaciones">
              Observaciones
            </label>
            <input
              type="text"
              className="input input-bordered"
              value={obs}
              onChange={(e) => {
                setObs(e.target.value);
              }}
            ></input>
          </div>
          <div className="modal-action">
            <label
              htmlFor="modal-compartimento"
              className="btn"
              onClick={(e) => {
                editCompartimento();
                clearModal();
              }}
            >
              Guardar
            </label>
            <label htmlFor="modal-compartimento" className="btn">
              Cancelar
            </label>
          </div>
        </div>
      </div>
      {/* Modal para confirmar los datos de la venta */}
      <input
        type={"checkbox"}
        id="modal-confirm"
        className="modal-toggle"
      ></input>
      <div className="modal">
        <div className="modal-box">
          <h3 className="font-bold text-lg">Aviso</h3>
          <p>Desea registrar la siguiente venta?</p>
          <p>
            <span className="font-bold">Nombre del Cliente: </span>
            {selClienteNombre}
          </p>
          <p>
            <span className="font-bold">Cantidad a vender: </span>
            {cantidad}
          </p>
          <div className="modal-action">
            <label htmlFor="modal-confirm" className="btn btn-accent" onClick={verifyVenta}>
              Sí
            </label>
            <label htmlFor="modal-confirm" className="btn">
              Aún no
            </label>
          </div>
        </div>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </section>
  );
}

export default GetRecorridos;
