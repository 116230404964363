//funcion que muestra una notificación cuando es llamada
import { toast } from "react-toastify";

export function popNotification(notify, msgType, message) {
  toast.update(notify, {
    render: message,
    type: msgType,
    isLoading: false,
    autoClose: 3000,
  });
};
export function notifyPendingNotify() {
  return toast.loading("Cargando...", {
    position: "bottom-center",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};
