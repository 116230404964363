import React, { useEffect, useState } from "react";
import { makeSafeGetRequest } from "../utils/Requests";
import useAuth from "../../hooks/useAuth";

import RecoleccionesCounter from "../RecoleccionesCounter";

function MonitorAcopio() {
  const { auth } = useAuth();
  
  const [operarioInfo, setOperarioInfo] = useState();
  const [acopiosDetalle, setAcopiosDetalle] = useState();
  //Obtiene el id de la empresa
  const getOpInfo = async () => {
    try {
      const response=await makeSafeGetRequest("operarioporusuario/"+auth.id, auth.accessToken);
      console.log("operario del usuario:"+JSON.stringify(response))
      setOperarioInfo(response?.data);
    } catch (error) {
      console.log(error)
    }
  };
  //obtiene la información detallada de los acopios de una empresa, tal como
  //rutas y recolectores
  const getAcopioDetalle = async () => {
    try {
      const response = await makeSafeGetRequest(
        "acopiodetalle/" + operarioInfo.acopio.id,
        auth.accessToken
      );
      if (response && response?.data) {
        setAcopiosDetalle(response.data);
      }
    } catch (error) {
      console.log("Error al obtener los acopios: _" + error);
    }
  };
  //cuando se obtenga el id del acopio, obtener el detalle de los acopios que pertenecen a ella
  useEffect(() => {
    if (operarioInfo) {
      getAcopioDetalle();
    }
  }, [operarioInfo]);

  //useeffect inicial
  useEffect(() => {
    getOpInfo();
  }, []);
  
  return (
    <div className="section h-full md:w-3/5 my-3 py-5">
      <h1 className="text text-3xl">Monitor de Actividades</h1>
      <div className="grid grid-cols-1">
        
        {acopiosDetalle && acopiosDetalle.length > 0 ? (
          acopiosDetalle?.map((acopio) => (
            <React.Fragment>
              <div
                className="grid md:grid-cols-2 p-5 m-3 gap-3 shadow-md"
                key={acopio.id}
              >
                <h2 className="card-title my-3">Acopio: {acopio.nombre}</h2>

                {/* aperturas y cierres */}
                <div className="grid grid-cols-1">
                  {acopio?.aperturas_consolidados &&
                  acopio.aperturas_consolidados?.length > 0 ? (
                    <div className="collapse">
                      <input type="checkbox"/>
                      <span className="collapse-title text-success">
                        Apertura realizada. Ver
                      </span>
                      {acopio?.aperturas_consolidados.map((apertura) => (
                        <div className="collapse-content grid grid-cols-1 text-left" key={apertura?.id}>
                          <p>
                            <span className="font-semibold">Hora: </span>
                            {apertura.hora.split(".")[0]}
                          </p>
                          <p>
                            <span className="font-semibold">Cant. Anterior: </span>
                            {apertura.cantidad_dia_anterior+" litros"}
                          </p>
                          <p>
                            <span className="font-semibold">Cant. Medida: </span>
                            {apertura.cantidad_medida+" litros"}
                          </p>
                          <p>
                            <span className="font-semibold">Diferencia: </span>
                            {apertura.diferencia+" litros"}
                          </p>
                          <p>
                            <span className="font-semibold">Cant. Novedad Anterior: </span>
                            {apertura.novedad_dia_anterior+" litros"}
                          </p>
                          <p>
                            <span className="font-semibold">Novedad Medida: </span>
                            {apertura.novedad_medida+" litros"}
                          </p>
                          <p>
                            <span className="font-semibold">Diferencia Novedad: </span>
                            {apertura.diferencia_novedad+" litros"}
                          </p>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <span className="text-warning">
                      Apertura de consolidado pendiente
                    </span>
                  )}
                  {acopio.consolidados_leche && acopio.consolidados_leche?.length > 0 ? (
                    <div className="collapse">
                    <input type="checkbox"/>
                    <span className="collapse-title text-success">
                      Cierre realizado. Ver
                    </span>
                    {acopio?.consolidados_leche?.map((consolidado) => (
                      <div className="collapse-content grid grid-cols-1 text-left" key={consolidado?.id}>
                        <p>
                          <span className="font-semibold">Hora: </span>
                          {consolidado.hora?.split(".")[0]}
                        </p>
                        <p>
                          <span className="font-semibold">Cant. Inicial: </span>
                          {consolidado.cantidad_inicial+" litros"}
                        </p>
                        <p>
                          <span className="font-semibold">Ingresos: </span>
                          {consolidado.cantidad_recibida+" litros"}
                        </p>
                        <p>
                          <span className="font-semibold">Ventas: </span>
                          {consolidado.cantidad_vendida+" litros"}
                        </p>
                        <p>
                          <span className="font-semibold">Traslados: </span>
                          {consolidado.traslados_favor+" litros"}
                        </p>
                        <p>
                          <span className="font-semibold">Traslados Salientes: </span>
                          {consolidado.traslados_salientes+" litros"}
                        </p>
                        <p>
                          <span className="font-semibold">Devoluciones: </span>
                          {consolidado.traslados_salientes+" litros"}
                        </p>
                        <p>
                          <span className="font-semibold">Cant. Calculada: </span>
                          {consolidado.cantidad_calculada+" litros"}
                        </p>
                        <p>
                          <span className="font-semibold">Cant. Final: </span>
                          {consolidado.cantidad_final_dia+" litros"}
                        </p>
                        <p>
                          <span className="font-semibold">Diferencia: </span>
                          {consolidado.diferencia+" litros"}
                        </p>
{/* novedad */}          
                      <h6 className="font-semibold text-center">Novedades</h6>
                        <p>
                          <span className="font-semibold">Cant. Novedad Inicial: </span>
                          {consolidado.novedad_inicial+" litros"}
                        </p>
                        <p>
                          <span className="font-semibold">Ingresos Novedad: </span>
                          {consolidado.ingresos_novedad+" litros"}
                        </p>
                        <p>
                          <span className="font-semibold">Ventas Novedad: </span>
                          {consolidado.ventas_novedad+" litros"}
                        </p>
                        <p>
                          <span className="font-semibold">Traslados a favor Novedad: </span>
                          {consolidado.traslados_favor_novedad+" litros"}
                        </p>
                        <p>
                          <span className="font-semibold">Traslados Sal. Novedad: </span>
                          {consolidado.traslados_sal_novedad+" litros"}
                        </p>
                        <p>
                          <span className="font-semibold">Cant. Novedad Calculada: </span>
                          {consolidado.novedad_calculada+" litros"}
                        </p>
                        <p>
                          <span className="font-semibold">Cant. Final Novedad: </span>
                          {consolidado.novedad_final_dia+" litros"}
                        </p>
                        <p>
                          <span className="font-semibold">Diferencia en Novedad: </span>
                          {consolidado.novedad_diferencia+" litros"}
                        </p>
                        <p>
                          <span className="font-semibold">Observaciones: </span>
                          {consolidado.observaciones}
                        </p>
                        
                      </div>
                    ))}
                  </div>
                  ) : (
                    <span className="text-warning">
                      Cierre de consolidado pendiente
                    </span>
                  )}
                </div>

                <p className="text text-xl font-semibold md:col-span-2">
                  Rutas:
                </p>
                {acopio?.rutas?.map((ruta) => (
                  <div className="flex-col mx-3">
                    <p className="font-bold">{ruta.nombre}</p>
                    <RecoleccionesCounter
                      ruta={ruta.id}
                      cant={1}
                      key={ruta.id}
                    ></RecoleccionesCounter>
                  </div>
                ))}

                {acopio.ingresos && acopio?.ingresos?.length > 0 ? (
                  <>
                    <h2 className="text-xl font-semibold md:col-span-2">
                      Ingresos
                    </h2>
                    {acopio.ingresos.map((ingreso) => (
                      <div
                        className="grid grid-cols-1 text-left"
                        key={ingreso?.id}
                      >
                        <span className="text">
                          <span className="text font-semibold">Ruta: </span>
                          {ingreso?.recorrido?.ruta?.nombre}
                        </span>
                        <span className="text">
                          <span className="text font-semibold">Hora: </span>
                          {ingreso?.hora.split(".")[0]}
                        </span>
                        <span className="text">
                          <span className="text font-semibold">
                            Cantidad calculada:{" "}
                          </span>
                          {ingreso?.cantidad_leche + " litros"}
                        </span>
                        <span className="text">
                          <span className="text font-semibold">
                            Cantidad medida:
                          </span>
                          {ingreso?.cantidad_medida + " litros"}
                        </span>

                        <span className="text">
                          <span className="text font-semibold">
                            Cantidad con novedad:
                          </span>
                          {ingreso?.cantidad_rechazada + " litros"}
                        </span>
                        <span className="text">
                          <span className="text font-semibold">
                            Diferencia:
                          </span>
                          {ingreso?.diferencia + " litros"}
                        </span>
                        <span className="text">
                          <span className="text font-semibold">
                            ingreso CON novedad:{" "}
                          </span>
                          {ingreso?.aceptado ? (
                            <span className="text-success">NO</span>
                          ) : (
                            <span className="text-error">SI</span>
                          )}
                        </span>
                        <div className="divider "></div>
                      </div>
                    ))}
                  </>
                ) : (
                  <div className="text text-xl text-error md:col-span-2">
                    El acopio aún no tiene ingresos
                  </div>
                )}
              </div>
            </React.Fragment>
          ))
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default MonitorAcopio;
