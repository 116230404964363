module.exports = {
  BACKEND_BASE_URL: process.env.BACKEND_BASE_URL || "http://192.168.1.78:1337",
  APP_ROLES: ["operario", "superAdministrador", "recolector", "productor","administrador"],
  ROLES: {
    recolector: ["registro-recoleccion"],
    operario: ["registro-recoleccion"],
    productor:["recolecciones-productor-rol"],
    superAdministrador: ["registro-recoleccion", "lista-acopios"],
    administrador: [
      "create-user",
      "list-recolector",
      "create-banco",
      "list-banco",
      "reports",
      "list-acopios",
      "list-rutas",
      "upload-prods",
      "upload-clientes"
    ],
  },
};
