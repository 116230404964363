import { useEffect, useRef, useState } from "react";
import axios from "../api/axios";
import SearchBar from "./SearchBar";
import {
  DownloadTableExcel,
  useDownloadExcel,
} from "react-export-table-to-excel";
import ReporteRecorridos from "./ReporteRecorridos";
import ReporteIngresosFecha from "./ReporteIngresosFecha";
import ReporteLitrosPorRutas from "./ReporteLitrosPorRutas";
import ReporteProductoresFechas from "./ReporteProductoresFechas";
import ReporteIngresosFechaEmpresa from "./ReporteIngresosFechaEmpresa";
import { makeGetRequest, makeSafeGetRequest } from "./utils/Requests";
import useAuth from "../hooks/useAuth";
import { getEmpresaAdmin } from "../api/RoleInfo";

function Reportes() {
  const { auth } = useAuth();
  const [idEmpresa, setIdEmpresa] = useState();
  const [empresaNombre, setEmpresaNombre] = useState();
  const URL = process.env.REACT_APP_BACKEND_BASE_URL;
  const [productores, setProductores] = useState();
  const [searchedProds, setSearchedProds] = useState();
  const [recoleccionesProductor, setRecoleccionesProductor] = useState();
  const [selectedProductor, setSelectedProductor] = useState();
  const [selectedProductorNombre, setSelectedProductorNombre] = useState();
  const [fechaInicial, setFechaInicial] = useState();
  const [fechaFinal, setfechaFinal] = useState();
  //userefs tablas
  const tableRef = useRef(null);
  //Obtiene el id de la empresa
  const getIdEmpresa = async () => {
    try {
      const response = await getEmpresaAdmin(auth);
      if (response) {
        console.log("encontro datos de la empresa" + JSON.stringify(response));
        setIdEmpresa(response.empresa?.id);
        setEmpresaNombre(response.empresa?.nombre);
      }
    } catch (error) {
      console.log("Error al obtener los datos de la empresa");
    }
  };
  //obtener la lista de todos los productores
  const getProductores = async () => {
    try {
      console.log("iniciando consulta productores");
      const myUrl =  "productoresempresa/"+idEmpresa; //cambiar por id empresa
      const response = await makeSafeGetRequest(myUrl,auth.accessToken);
      if (response.status === 200) {
        console.log("datos respuesta " + JSON.stringify(response.data));
        setProductores(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //Obtener las recolecciones de un productor en un rango de fechas
  const getRecoleccionesProductor = async () => {
    try {
      if (fechaFinal && fechaInicial) {
        const myUrl =
          "recoleccionesproductor?idproductor=" +
          selectedProductor +
          "&fechainicial=" +
          fechaInicial +
          "&fechafinal=" +
          fechaFinal; //ajustar para que sea dinamico
        const response = await makeGetRequest(`${myUrl}`);
        if (response.status === 200) {
          setRecoleccionesProductor(response.data);
        }
      } else {
        console.log("seleccione fechas");
      }
    } catch (error) {
      console.log(error);
    }
  };
  //identifica un cambio en la fecha inicial y lo guarda en el state
  const onFechaInicialChange = (e) => {
    const newDate = e.target.value;
    console.log("fecha sel:" + newDate);
    setFechaInicial(newDate);
    console.log("update:" + fechaFinal);
  };
  //identifica un cambio en la fecha y lo guarda en el state
  const onFechaFinalChange = (e) => {
    const newDate = e.target.value;
    console.log("fecha sel:" + newDate);
    setfechaFinal(newDate);
    console.log("update:" + fechaFinal);
  };
  //on search
  const onsearch = (searchTerm) => {
    if (searchTerm === "" || searchTerm === undefined) {
      console.log("el input esta en blanco");
      setSearchedProds();
    } else {
      const filteredItems = productores?.filter((productor) => {
        return productor.nombre_corto
          .toUpperCase()
          .includes(searchTerm.toUpperCase());
      });
      console.log("clientes encontrados: " + JSON.stringify(filteredItems));
      setSearchedProds(filteredItems.slice(0, 10));
    }
  };
  //pasos a realizar al seleccionar un item buscado
  const onSelectedItem = (e) => {
    setSelectedProductor(e.target.value);
    setSelectedProductorNombre(e.target.innerText);
    setSearchedProds([]);
  };
  //realiza la descarga del elemento table
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "recolecciones_"+selectedProductorNombre+"_"+fechaInicial+"_"+fechaFinal,
    sheet: "recolecciones",
  });
  
  useEffect(() => {
    console.log("cambiando productor" + selectedProductor);
  }, [selectedProductor]);
  useEffect(() => {
    console.log(
      "cambio en las recolecciones productor" +
        JSON.stringify(recoleccionesProductor)
    );
  }, [recoleccionesProductor]);
  useEffect(() => {
    console.log("cambio en las fechas");
  }, [fechaInicial, fechaFinal]);
  useEffect(()=>{
    if(idEmpresa){
      getProductores()
    }
  },[idEmpresa])

  //Useeffect inicial
  useEffect(() => {
    getIdEmpresa();
    console.log("useref de table:" + tableRef.current);
  }, []);


  return (
    <div className="h-full py-5 md:w-8/12">
      <h1 className="text-3xl pb-5">Reportes</h1>
      <label className="label">Empresa: {empresaNombre}</label>
      <div className="collapse">
        <input type={"checkbox"} />
        <div className="collapse-title btn ">
          Recolecciones por productor en un rango de días
        </div>
        <div className="collapse-content">
          <div className="flex flex-col px-5 md:px-20">
            <div className="dropdown dropdown-bottom w-full">
              <label className="label">Seleccione un productor</label>
              <label className="input-group w-max">
                <input
                  type={"search"}
                  className="input input-bordered  "
                  onChange={(e) => onsearch(e.target.value)}
                ></input>
                <span>Buscar</span>
              </label>

              {searchedProds ? (
                <ul
                  tabIndex={0}
                  className="dropdown-content menu p-2 shadow bg-base-100 w-52 "
                >
                  {searchedProds.map((productor) => (
                    <li
                      className="text-left"
                      key={productor?.id}
                      value={productor?.id}
                      tabIndex={0}
                      onClick={(e) => {
                        onSelectedItem(e);
                      }}
                    >
                      {productor.nombre_corto}
                    </li>
                  ))}
                </ul>
              ) : (
                <></>
              )}
              {selectedProductorNombre ? (
                <div className="badge badge-lg my-1 py-1">
                  {selectedProductorNombre}
                  <span
                    onClick={() => {
                      setSelectedProductorNombre();
                      setSelectedProductor();
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </span>
                </div>
              ) : (
                <>Seleccione un productor</>
              )}
            </div>
            <label htmlFor="fechainicial" className="label">
              Fecha Inicial
            </label>
            <input
              type={"date"}
              className="input input-bordered"
              id="fechainicial"
              onChange={(e) => {
                onFechaInicialChange(e);
              }}
            ></input>
            <label htmlFor="fechafinal" className="label">
              Fecha final
            </label>
            <input
              type={"date"}
              className="input input-bordered"
              id="fechafinal"
              onChange={(e) => {
                onFechaFinalChange(e);
              }}
            ></input>
            <button
              className="btn btn-accent my-5"
              onClick={(e) => getRecoleccionesProductor()}
            >
              Generar reporte
            </button>
            <div className="overflow-x-auto">
              <table className="table table-compact" ref={tableRef}>
                {recoleccionesProductor &&
                recoleccionesProductor?.length > 0 &&
                selectedProductor ? (
                  <>
                    <thead>
                      <tr>
                        <th colSpan={4}>
                          Productor:{" "}
                          {recoleccionesProductor[0]?.productor?.nombre_corto}
                        </th>
                        <th>
                          Estado:{" "}
                          {recoleccionesProductor[0].productor?.activo ===
                          true ? (
                            <>Activo</>
                          ) : (
                            <>Inactivo</>
                          )}
                        </th>
                      </tr>
                      <tr>
                        <th></th>
                        <th>Fecha</th>
                        <th>Cantidad</th>
                        <th>Observaciones</th>
                        <th>Ruta</th>
                      </tr>
                    </thead>
                    <tbody>
                      {recoleccionesProductor?.map((reco, index) => (
                        <tr key={reco.id}>
                          <th>{index + 1}</th>
                          <td>{reco.fecha}</td>
                          <td>{reco.cantidad_leche}</td>
                          <td>{reco.observaciones.substring(0, 30)}</td>
                          <td>{reco.ruta?.nombre}</td>
                        </tr>
                      ))}
                      {/* <tr colSpan={2}>
                        <td></td>
                      </tr> */}
                      <tr>
                        <th colSpan={2}>Total</th>
                        <td colSpan={3}>
                          {recoleccionesProductor.reduce(
                            (c, reco) => c + reco.cantidad_leche,
                            0
                          ) + " litros"}
                        </td>
                      </tr>
                    </tbody>
                  </>
                ) : (
                  <tbody>
                    <tr colSpan={2}>
                      <td>
                        No hay recolecciones en esa fecha o rango para ese
                        productor
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
            </div>
            {recoleccionesProductor &&
            recoleccionesProductor?.length > 0 &&
            selectedProductor ? (
              <button className="btn w-40" onClick={onDownload}>
                Exportar excel
              </button>
            ) : (
              <div></div>
            )}
          </div>
        </div>
      </div>
      <div className="collapse">
        <input type={"checkbox"}></input>
        <div className="collapse-title btn">
          Recolecciones detalladas por ruta en un día especifico
        </div>
        <div className="collapse-content">
          <ReporteRecorridos empresa={idEmpresa}></ReporteRecorridos>
        </div>
      </div>
      <div className="collapse">
        <input type={"checkbox"}></input>
        <div className="collapse-title btn">
          Totales ingresados en un rango de días en una ruta
        </div>
        <div className="collapse-content">
          <ReporteIngresosFecha empresa={idEmpresa}></ReporteIngresosFecha>
        </div>
      </div>
      <div className="collapse">
        <input type={"checkbox"}></input>
        <div className="collapse-title btn">
          Totales ingresados en un rango de días en la Empresa
        </div>
        <div className="collapse-content">
          <ReporteIngresosFechaEmpresa></ReporteIngresosFechaEmpresa>
        </div>
      </div>
      <ReporteLitrosPorRutas></ReporteLitrosPorRutas>

      <div className="collapse">
        <input type={"checkbox"}></input>
        <div className="collapse-title btn">Todos los productores</div>
        <div className="collapse-content">
          <ReporteProductoresFechas empresa={idEmpresa} nombre={empresaNombre}></ReporteProductoresFechas>
        </div>
      </div>
    </div>
  );
}
export default Reportes;
