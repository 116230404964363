import React, { useEffect, useState } from "react";
import useAuth from "../hooks/useAuth";
import { makeSafeGetRequest, makeSafePutRequest } from "./utils/Requests";
import { getEmpresaAdmin } from "../api/RoleInfo";
import { ToastContainer } from "react-toastify";
import { notifyPendingNotify, popNotification } from "./utils/Notify";

function EditConsolidados() {
  const { auth } = useAuth();
  const [idEmpresa, setIdEmpresa] = useState();
  const [nombreEmpresa, setNombreEmpresa] = useState();
  const [acopios, setsetAcopios] = useState();
  const [selAcopio, setSelAcopio] = useState();
  const [consolidado, setConsolidado] = useState();
  const [inventario, setInventario] = useState();
  const [cantidadFinaldia, setCantidadFinalDia] = useState(0);
  const [cantidadNovedadFinalDia, setCantidadNovedadFinalDia] = useState(0);
  const [diferencia, setDiferencia] = useState(0);
  const [diferenciaNovedad, setDiferenciaNovedad] = useState(0);
  const [observaciones, setObservaciones] = useState("");
  //obtiene la data de la empresa
  const getEmpresa = async () => {
    const response = await getEmpresaAdmin(auth);
    setIdEmpresa(response?.empresa?.id);
    setNombreEmpresa(response?.empresa.nombre);
  };
  //obtiene la lista de rutas de la empresa
  const getAcopios = async () => {
    try {
      const response = await makeSafeGetRequest(
        "acopiosempresa/" + idEmpresa,
        auth
      );
      if (response && response.status === 200) {
        setsetAcopios(response.data);
      } else {
        console.log("no se puede obtener los acopios");
        setsetAcopios();
      }
    } catch (error) {
      console.log("error al obtener los acopios de la empresa" + error);
    }
  };
  //obtiene el consolidado del acopio seleccionado
  const getConsolidado = async () => {
    try {
      const response = await makeSafeGetRequest("cierrehoy/" + selAcopio, auth);
      if (response && response.status === 200) {
        setConsolidado(response.data);
        setObservaciones(response.data.observaciones);
      } else {
        if (response.status === 204) {
          setConsolidado([]);
        }
        setConsolidado();
      }
    } catch (error) {
      console.log("error al obtener el consolidado" + error);
    }
  };
  //limpia el consolidado
  const cleanConsolidado = () => {
    setConsolidado();
  };
  //obtiene el inventario actual de movimientos del consolidado
  const getInventario = async () => {
    try {
      const response = await makeSafeGetRequest(
        "inventarioacopio/" + selAcopio,
        auth
      );
      if (response.status === 200) {
        setInventario(response.data);
        setCantidadFinalDia(response.data.total_inventario);
        setCantidadNovedadFinalDia(response.data.total_novedades);
      }
    } catch (error) {}
  };
  //cambio al obtener el acopio seleccionado
  const onSelectedAcopio = (e) => {
    console.log("Cambio en acopio seleccionado");
    setSelAcopio(e.target.value);
  };
  //establece le cambio en la cantidad final del dia
  const onCantidadFinalDiaChange = (e) => {
    if (e.target.value) {
      setCantidadFinalDia(e.target.value);
      getDiferencia(e.target.value);
    }
  };
  //obtiene la diferencia entre la cantidad final del dia y la cantidad calculada
  const getDiferencia = (cantidadFinal) => {
    setDiferencia(0);
    setDiferencia(cantidadFinal - inventario?.total_inventario);
  };
  //obtiene la diferencia entre la cantidad con novedad final del dia con y la cantidad calculada con novedad
  const onCantidadNovedadFinalDiaChange = (e) => {
    if (e.target.value) {
      setCantidadNovedadFinalDia(e.target.value);
      getDiferenciaNovedad(e.target.value);
    }
  };
  //obtiene la diferencia entre la cantidad final del dia y la cantidad calculada
  const getDiferenciaNovedad = (cantidadFinal) => {
    setDiferenciaNovedad(0);
    setDiferenciaNovedad(cantidadFinal - inventario?.total_novedades);
  };
  //Actualiza el consolidado
  const updateConsolidado = async () => {
    const notify = notifyPendingNotify();
    if (
      selAcopio &&
      selAcopio !== "-1" &&
      cantidadFinaldia &&
      cantidadNovedadFinalDia
    ) {
      try {
        const response = await makeSafePutRequest(
          "consolidados-leche/" + consolidado?.id,
          {
            data: {
              cantidad_inicial: inventario.cantidad_inicial,
              cantidad_recibida: inventario.ingresos,
              cantidad_vendida: inventario.ventas,
              traslados_favor: inventario.trasladosfavor,
              traslados_salientes: inventario.trasladossal,
              devoluciones: inventario.devoluciones,
              cantidad_calculada: inventario.total_inventario,
              cantidad_final_dia: cantidadFinaldia,
              diferencia: diferencia,
              novedad_inicial: inventario.novedad_inicial,
              ingresos_novedad: inventario.ingresos_novedad,
              traslados_favor_novedad: inventario.trasladosfavornovedad,
              traslados_sal_novedad: inventario.trasladossalnovedad,
              devoluciones_novedad: inventario.devolucionesnovedad,
              novedad_final_dia: cantidadNovedadFinalDia,
              observaciones: observaciones,
            },
          },
          auth.accessToken
        );
        if (response && response.status === 200) {
          popNotification(
            notify,
            "success",
            "Consolidado actualizado correctamente"
          );
          cleanConsolidado();
        } else {
          popNotification(
            notify,
            "error",
            "No se pudo actualizar el consolidado. Intente de nuevo"
          );
        }
      } catch (error) {
        popNotification(
          notify,
          "error",
          "Error al actualizar el consolidado. Intente de nuevo"
        );
      }
    } else {
      popNotification(
        notify,
        "error",
        "No se pudo actualizar el consolidado. Verifique los datos y vuelva a intentarlo"
      );
    }
  };
  //useeffect inventario acopio seleccionado
  useEffect(() => {
    if (inventario) {
      getDiferencia(cantidadFinaldia);
      getDiferenciaNovedad(cantidadNovedadFinalDia);
    }
  }, [inventario, cantidadFinaldia, cantidadNovedadFinalDia]);

  //useeffect acopio seleccionado
  useEffect(() => {
    if (selAcopio && selAcopio !== "-1") {
      getConsolidado();
      getInventario();
    }
  }, [selAcopio]);
  //useeffect obtener acopios
  useEffect(() => {
    if (idEmpresa) {
      getAcopios();
    }
  }, [idEmpresa]);
  //useeffect inicial
  useEffect(() => {
    getEmpresa();
  }, []);

  return (
    <div className="section h-full md:w-1/2 mx-3 my-5 py-5">
      <div className="grid grid-cols-1">
        <h1 className="text-2xl">Editor de Consolidados</h1>
        <label className="label">Empresa: {nombreEmpresa}</label>
        <label className="label">Acopio</label>
        {acopios ? (
          <select
            className="select select-bordered"
            onChange={(e) => {
              onSelectedAcopio(e);
            }}
          >
            <option key={"-1"} value={"-1"}>
              Seleccione un centro de acopio
            </option>
            {acopios?.map((acopio) => (
              <option key={acopio.id} value={acopio.id}>
                {acopio?.nombre}
              </option>
            ))}
          </select>
        ) : (
          <>Cargando acopios...</>
        )}
        {consolidado && Object.keys(consolidado).length > 0 ? (
          <>
            <div className="card card-compact shadow-lg">
              <div className="card-body text-left">
                <h2 className="card-title">Consolidado encontrado</h2>
                <p className="text-left">
                  <span className="font-bold justify-start">
                    Fecha de cierre:{" "}
                  </span>
                  {consolidado?.fecha}
                </p>
                <p className="text-left">
                  <span className="font-bold justify-start">
                    Leche al inicio de la jornada:{" "}
                  </span>
                  {consolidado?.cantidad_inicial} litros
                </p>
                <p className="text-left">
                  <span className="font-bold justify-start">
                    Leche Ingresada:{" "}
                  </span>
                  {consolidado?.cantidad_recibida} litros
                </p>
                <p className="text-left">
                  <span className="font-bold">Leche Vendida: </span>
                  {consolidado?.cantidad_vendida} litros
                </p>
                <p className="text-left">
                  <span className="font-bold">
                    Traslados a favor del acopio:{" "}
                  </span>
                  {consolidado?.traslados_favor} litros
                </p>
                <p className="text-left">
                  <span className="font-bold">Traslados salientes: </span>
                  {consolidado?.traslados_salientes} litros
                </p>
                <p className="text-left">
                  <span className="font-bold">Devoluciones: </span>
                  {consolidado?.devoluciones} litros
                </p>
                <p className="text-left">
                  <span className="font-bold">Leche al final del Día: </span>
                  {consolidado?.cantidad_final_dia} litros
                </p>
                <p className="text-left">
                  <span className="font-bold">
                    Leche Calculada por Sistema:{" "}
                  </span>
                  {consolidado?.cantidad_calculada} litros
                </p>

                <p className="text-left">
                  <span className="font-bold">Diferencia: </span>
                  {consolidado?.diferencia} litros
                </p>
                <div className="divider"></div>
                <p className="font-bold">Movimientos de leche con novedad</p>
                <p className="text-left">
                  <span className="font-bold">
                    Leche con novedad al empezar el día:{" "}
                  </span>
                  {consolidado?.novedad_inicial} litros
                </p>
                <p className="text-left">
                  <span className="font-bold">Leche ingresada Novedad : </span>
                  {consolidado?.ingresos_novedad} litros
                </p>
                <p className="text-left">
                  <span className="font-bold">Ventas con Novedad : </span>
                  {consolidado?.ventas_novedad} litros
                </p>
                <p className="text-left">
                  <span className="font-bold">
                    Traslados a favor del acopio con novedad:{" "}
                  </span>
                  {consolidado?.traslados_favor_novedad} litros
                </p>
                <p className="text-left">
                  <span className="font-bold">
                    Traslados salientes con novedad:{" "}
                  </span>
                  {consolidado?.traslados_sal_novedad} litros
                </p>
                <p className="text-left">
                  <span className="font-bold">Devoluciones con novedad: </span>
                  {consolidado?.devoluciones_novedad} litros
                </p>
                <p className="text-left">
                  <span className="font-bold">
                    Leche con novedad calculada:{" "}
                  </span>
                  {consolidado?.novedad_calculada} litros
                </p>
                <p className="text-left">
                  <span className="font-bold">
                    Leche con novedad al final del Día:{" "}
                  </span>
                  {consolidado?.novedad_final_dia} litros
                </p>
                <p className="text-left">
                  <span className="font-bold">Diferencia: </span>
                  {consolidado?.novedad_diferencia} litros
                </p>
                <p className="text-left">
                  <span className="font-bold">Observaciones: </span>
                  {consolidado?.observaciones}
                </p>
              </div>
            </div>
            {
              /* if there is a inventario, show it on a div

         */
              inventario && Object.keys(inventario).length > 0 ? (
                <div className="grid grid-cols-1 my-3">
                  <label className="text-2xl">Inventario recalculado</label>
                  <label className="label">
                    <span className="font-semibold">Cantidad inicial: </span>
                    {inventario?.cantidad_inicial + " litros"}
                  </label>
                  <label className="label">
                    <span className="font-semibold">Cantidad ingresada: </span>
                    {inventario?.ingresos + " litros"}
                  </label>
                  <label className="label">
                    <span className="font-semibold">Cantidad vendida: </span>
                    {inventario?.ventas + " litros"}
                  </label>
                  <label className="label">
                    <span className="font-semibold">
                      Traslados a favor del acopio:{" "}
                    </span>
                    {inventario?.trasladosfavor + " litros"}
                  </label>
                  <label className="label">
                    <span className="font-semibold">Traslados salientes: </span>
                    {inventario?.trasladossal + " litros"}
                  </label>
                  <label className="label">
                    <span className="font-semibold">Devoluciones: </span>
                    {inventario?.devoluciones + " litros"}
                  </label>
                  <label className="label">
                    <span className="font-semibold">Cantidad final: </span>
                    {inventario?.total_inventario + " litros"}
                  </label>
                  <label className="label">
                    <span className="font-semibold">Novedad </span>
                  </label>
                  <label className="label">
                    <span className="font-semibold">
                      Cantidad inicial(novedad):{" "}
                    </span>
                    {inventario.novedad_inicial
                      ? inventario.novedad_inicial + " litros"
                      : "0 litros"}
                  </label>
                  <label className="label">
                    <span className="font-semibold">
                      Cantidad ingresada (novedad):{" "}
                    </span>
                    {inventario.ingresos_novedad
                      ? inventario.ingresos_novedad + " litros"
                      : "0 litros."}
                  </label>
                  <label className="label">
                    <span className="font-semibold">
                      Cantidad ingresada (novedad):{" "}
                    </span>
                    {inventario.ingresos_novedad
                      ? inventario.ingresos_novedad + " litros"
                      : "0 litros"}
                  </label>
                  <label className="label">
                    <span className="font-semibold">Ventas con novedad: </span>
                    {inventario.ventas_novedad
                      ? inventario.ventas_novedad + " litros"
                      : "0 litros"}
                  </label>
                  <label className="label">
                    <span className="font-semibold">
                      Traslados a favor(novedad)
                    </span>
                    {inventario.trasladosfavornovedad + " litros"}
                  </label>
                  <label className="label">
                    <span className="font-semibold">
                      Traslados salientes(novedad)
                    </span>
                    {inventario.trasladossalnovedad + " litros"}
                  </label>
                  <label className="label">
                    <span className="font-semibold">Devoluciones(novedad)</span>
                    {inventario.devolucionesnovedad + " litros"}
                  </label>
                  <label className="label">
                    <span className="font-semibold">
                      Cantidad final(novedad)
                    </span>
                    {inventario.total_novedades + " litros"}
                  </label>
                  <div className="divider"></div>
                  <h1 className="text-xl">Editar Cierre de Consolidado</h1>
                  <label className="label">Cantidad al final del día</label>
                  <input
                    className="input input-bordered"
                    type="number"
                    min={0}
                    step={1}
                    value={cantidadFinaldia}
                    onChange={(e) => {
                      onCantidadFinalDiaChange(e);
                    }}
                  ></input>
                  {diferencia >= 0 ? (
                    <label className="label text-success">
                      Diferencia de {diferencia} litros
                    </label>
                  ) : (
                    <label className="label text-error">
                      Diferencia de {diferencia} litros
                    </label>
                  )}
                  <label className="label">
                    Cantidad con novedad al final del día
                  </label>
                  <input
                    className="input input-bordered"
                    type="number"
                    min={0}
                    step={1}
                    value={cantidadNovedadFinalDia}
                    onChange={(e) => {
                      onCantidadNovedadFinalDiaChange(e);
                    }}
                  ></input>
                  {diferenciaNovedad >= 0 ? (
                    <label className="label text-success">
                      Diferencia de {diferenciaNovedad} litros
                    </label>
                  ) : (
                    <label className="label text-error">
                      Diferencia de {diferenciaNovedad} litros
                    </label>
                  )}
                  <label className="label">Observaciones</label>
                  <textarea
                    className="textarea textarea-bordered"
                    value={observaciones}
                    onChange={(e) => {
                      setObservaciones(e.target.value);
                    }}
                  ></textarea>
                  <label
                    className="btn btn-primary my-3"
                    htmlFor="modal-confirm"
                  >
                    Guardar
                  </label>
                </div>
              ) : (
                <div></div>
              )
            }
          </>
        ) : consolidado?.length === 0 ? (
          <div className="alert alert-warning">
            No se encontró un consolidado para el acopio seleccionado
          </div>
        ) : (
          <div className="alert">Seleccione un centro de acopio</div>
        )}
      </div>
      {/* Modal guardar */}
      <input type="checkbox" id="modal-confirm" className="modal-toggle" />
      <div className="modal">
        <div className="modal-box relative">
          <label
            htmlFor="modal-confirm"
            className="btn btn-sm btn-circle absolute right-2 top-2"
          >
            ✕
          </label>
          <h3 className="text-lg font-bold">Aviso!</h3>
          <p className="py-4">
            ¿Realmente desea actualizar el siguiente consolidado?
          </p>
          <p className="">
            <span className="font-bold">Acopio: </span>
            {consolidado?.acopio?.nombre}
          </p>
          <div className="modal-action">
            <label
              className="btn btn-accent"
              htmlFor="modal-confirm"
              onClick={() => updateConsolidado()}
            >
              Sí
            </label>
            <label className="btn " htmlFor="modal-confirm">
              Aún no
            </label>
          </div>
        </div>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default EditConsolidados;
